import React from 'react';
import { Button, Popover, Typography } from 'ui';
import TickMark from 'ui/src/Assets/TickMark';
import MenuIcon from 'ui/src/Assets/MenuIcon';
import useMapStore, { mapStore } from '../../store/mapStore';
import { container, iconButton, textArea } from './style';

type CommentApisType = {
  commentSave: () => void;
  commentResolve: (id?: string) => Promise<void>;
  commentDelete: (id?: string) => Promise<void>;
  commentUpdate: () => void;
};

const CommentCard = ({ commentApis }: { commentApis: CommentApisType }) => {
  const selectedComment = useMapStore((state: mapStore) => state.selectedComment);
  const setSelectedComment = useMapStore((state: mapStore) => state.setSelectedComment);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedComment({ ...selectedComment, message: e.target.value });
  };

  const handleSubmit = () => {
    if (selectedComment?.isEdit) {
      commentApis.commentUpdate();
    } else commentApis.commentSave();
  };

  const handleEdit = () => {
    setSelectedComment({ ...selectedComment, isEdit: true });
  };
  const handleDelete = () => {
    commentApis.commentDelete(selectedComment?.id);
  };

  const modalContents = (
    <div className=' flex flex-col'>
      <Button size='medium' variant='text' onClick={handleEdit}>
        Edit
      </Button>
      <Button size='medium' variant='text' color='error' onClick={handleDelete}>
        Delete
      </Button>
    </div>
  );

  return (
    <div id='comment-card' className={container} onDoubleClick={handleEdit}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <div>
          <Typography variant={'body4'} sx={{ lineHeight: '1.125rem' }} color='neutral.90'>
            {selectedComment?.author}
          </Typography>
          {selectedComment?.id && (
            <Typography variant={'body4'} sx={{ lineHeight: '1.125rem' }} color='neutral.70'>
              {selectedComment?.date}
            </Typography>
          )}
        </div>
        {selectedComment?.id && (
          <div style={{ display: 'flex', gap: '0.375rem' }}>
            <div onClick={() => commentApis.commentResolve(selectedComment?.id)} className={iconButton}>
              <TickMark color={selectedComment?.isResolved ? '#4361EE' : '#666666'} />
            </div>
            <Popover triggerEvent='click' content={modalContents}>
              <div className={iconButton}>
                <MenuIcon />
              </div>
            </Popover>
          </div>
        )}
      </div>
      <div>
        {!selectedComment?.id || selectedComment?.isEdit ? (
          <>
            <textarea
              value={selectedComment?.message}
              onChange={handleChange}
              placeholder='Type a comment...'
              rows={4}
              className={textArea}
            />
          </>
        ) : (
          <Typography variant={'body3'} sx={{ lineHeight: '1.375rem', wordBreak: 'break-all' }} color='neutral.90'>
            {selectedComment?.message}
          </Typography>
        )}
      </div>
      {(!selectedComment?.id || selectedComment?.isEdit) && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button size='small' onClick={handleSubmit} disabled={!selectedComment?.message}>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(CommentCard);
