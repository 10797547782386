// Routes that can be accessed by unauthorized users
export const UNAUTH_PATHS = [
  '/login',
  '/signup',
  '/forget-password',
  '/reset-password',
  '/shared-view',
  '/blueprint/shared-view',
  '/oauth/google/callback',
  '/oauth/linkedin/callback',
  '/thankyou'
];

export const ACCELERATE_PATH = ['/blueprint/accelerate/', '/auth/accelerate/'];
