/* eslint-disable no-param-reassign */
import { GEOMETRY_TYPES, MAP_TYPE } from 'woodpecker';
import { generateUniqueID } from 'macaw';
import { Circle, LineString, Point } from 'ol/geom';
import { Draw } from 'ol/interaction';
import { Type } from 'ol/geom/Geometry';
import { formatLineCircleCircumference } from '../../../hooks/tools/helpers';
import { drawStyle, labelStyle } from '../../../hooks/tools/helpers/styles';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { isOutOfExtent } from '../../../helpers/helpers';
import { undoRedoPush } from '../../mapLayer/mapInit';

const DEFAULT_SIDES = 48;
class AddLineCircle {
  private mapObj: any;

  private layer: any;

  private draw: Draw | null;

  constructor(mapObj: any) {
    this.mapObj = mapObj;
    this.draw = null;
  }

  init(id: string): void {
    this.layer = this.mapObj.getLayerById(id);

    if (this.layer) {
      const source = this.layer.getSource();
      this.draw = new Draw({
        source,
        type: GEOMETRY_TYPES.LINESTRING as Type,
        style: (feature: any) => this.styleFunction(feature),
        dragVertexDelay: 0,
        snapTolerance: 1,
        maxPoints: 2,
        geometryFunction: this.geometryFunction,
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick === 3 || mouseClick === 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        }
      });

      this.mapObj.map?.addInteraction(this.draw);
      this.draw?.on('drawend', this.onDrawEnd);
      this.draw?.on('drawstart', this.onDrawStart);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  }

  geometryFunction = (coordinates: any, geometry: any) => {
    const radius = this.calculateRadius(coordinates);
    const lineStringCoordinates = [];

    const center = coordinates[0];
    const edge = coordinates[1];

    // Save the center and edge point for radius calculation in style function
    if (geometry) {
      geometry.set('center', center);
      geometry.set('edge', edge);
    }

    for (let i = 0; i < DEFAULT_SIDES; i++) {
      const angle = (i / DEFAULT_SIDES) * 2 * Math.PI;
      const x = coordinates[0][0] + radius * Math.cos(angle);
      const y = coordinates[0][1] + radius * Math.sin(angle);
      lineStringCoordinates.push([x, y]);
    }
    lineStringCoordinates.push(lineStringCoordinates[0]);

    if (!geometry) {
      geometry = new LineString(lineStringCoordinates);
    } else {
      geometry.setCoordinates(lineStringCoordinates);
    }

    return geometry;
  };

  onDrawStart = (e: any) => {
    const { feature } = e;
    const geometry = feature.getGeometry();

    if (geometry instanceof Point) {
      const center = geometry.getCoordinates();
      const radius = 0;

      const circleGeometry = new Circle(center, radius);
      feature.setGeometry(circleGeometry);
    }
  };

  calculateRadius = (coordinates: any[]) => {
    const center = coordinates[0];
    const last = coordinates[1];
    const dx = center[0] - last[0];
    const dy = center[1] - last[1];
    return Math.sqrt(dx * dx + dy * dy);
  };

  onDrawEnd = (event: any) => {
    const lineStringFeature = event.feature;
    const uniqueId = generateUniqueID('line-circle');
    lineStringFeature.setId(uniqueId);

    setTimeout(() => {
      undoRedoPush();
    }, 0);
  };

  styleFunction = (feature: any) => {
    const styles = [drawStyle()];
    const { scale, dpi } = globalStore.AppStore.worksheetParams;
    const geometry = feature.getGeometry();
    const type = geometry.getType();

    // type here will be linestring and not circle
    if (type === GEOMETRY_TYPES.LINESTRING && scale !== null) {
      if (this.mapObj.map_type === MAP_TYPE.AERIAL && !globalStore?.AppStore?.tool?.live_measurement) {
        return styles;
      }

      const center = geometry.get('center');
      const edge = geometry.get('edge');

      if (center && edge) {
        const radius = this.calculateRadius([center, edge]);
        const label = formatLineCircleCircumference(radius, dpi, scale);
        const _labelStyle = labelStyle.clone();
        _labelStyle.setGeometry(geometry);
        _labelStyle.getText().setText(label);
        styles.push(_labelStyle);
      }
    }

    return styles;
  };

  keyDownHandler = (e: KeyboardEvent) => {
    if (e.code === 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code === 'Space') {
      this.draw?.finishDrawing();
    }
  };

  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.draw?.un('drawend', this.onDrawEnd);
    this.draw?.un('drawstart', this.onDrawStart);
    window.removeEventListener('keydown', this.keyDownHandler);
  }
}

export default AddLineCircle;
