import MapBase from '../../mapLayer/mapBase';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import removeFeatures from 'macaw/src/removeFeature';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import { Select } from 'ol/interaction';
import { triggerOverrideOverlap } from '../../../helpers/helpers';
import { generateUniqueID } from 'macaw';

class Reclassify {
  private mapObj: MapBase;
  private features: any;
  private layer: any;
  private featuresMap: Map<string, Feature[]>;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
    this.featuresMap = new Map<string, Feature[]>();
  }

  removeHiddenGeometries() {
    let select: Select | null = null;
    this.mapObj?.map?.getInteractions().forEach((interaction: any) => {
      if (interaction instanceof Select) {
        select = interaction;
      }
    });

    const layerRecord = new Map();
    const selectedFeature = globalStore.AppStore.selectedFeatures;
    const filteredFeatures = selectedFeature?.filter((feature: Feature) => {
      const vector_layer_id = feature?.get('vector_layer_id');
      let isVisible = true;
      if (layerRecord?.has(vector_layer_id)) {
        const layer = layerRecord?.get(vector_layer_id);
        isVisible = isVisible && layer?.getVisible();
      } else {
        const layer = this.mapObj?.getLayerById(vector_layer_id);
        layerRecord?.set(vector_layer_id, layer);
        isVisible = isVisible && layer?.getVisible();
      }
      if (!isVisible) {
        select?.getFeatures()?.remove(feature);
      }
      return isVisible;
    });

    return filteredFeatures;
  }

  execute() {
    this.features = this.removeHiddenGeometries();
    globalStore.AppStore.setSelectedFeatures([]);
    const dest_layer_id = globalStore?.AppStore?.tool?.['reclassify_layer_id'];
    this.layer = this.mapObj?.getLayerById(dest_layer_id);
    this.features.map((feature: Feature) => {
      const layerId = feature?.getProperties()['vector_layer_id'];
      if (this.featuresMap?.has(layerId)) this.featuresMap?.get(layerId)?.push(feature);
      else this.featuresMap?.set(layerId, [feature]);
    });
    setTimeout(() => {
      this.reclassifyFeatures();
    }, 10);
  }

  reclassifyFeatures = () => {
    const dest_source = this.layer?.getSource() as VectorSource;

    this.featuresMap?.forEach((value: Feature[], key) => {
      const allLayerFeatures = value?.map((feature: Feature) => {
        // Check and remove 'buffer', 'parent-id' and 'temp_layer' properties if they exist
        if (feature) {
          const newId = generateUniqueID();
          feature.setId(newId);
          if (feature.get('buffer') !== undefined) {
            feature.unset('buffer');
          }
          if (feature.get('parent_id') !== undefined) {
            feature.unset('parent_id');
          }
          if (feature.get('temp_layer') !== undefined) {
            feature.unset('temp_layer');
          }
        }
        return feature;
      });

      const layer = this.mapObj?.getLayerById(key);
      if (layer) {
        const source = layer?.getSource();
        removeFeatures(source, value);
      }

      if (dest_source) {
        dest_source?.addFeatures(allLayerFeatures);
        allLayerFeatures.map((feature: Feature) => {
          triggerOverrideOverlap(feature);
        });
      }
    });

    undoRedoPush();
    this.featuresMap = new Map<string, Feature[]>();
  };
}

export default Reclassify;
