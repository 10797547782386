import { Coordinate } from "ol/coordinate";

const getRadiusWith3Points = (
  point1: Coordinate,
  point2: Coordinate,
  point3: Coordinate
) => {
  const x1 = point1[0];
  const y1 = point1[1];
  const x2 = point2[0];
  const y2 = point2[1];
  const x3 = point3[0];
  const y3 = point3[1];

  let x12 = x1 - x2;
  let x13 = x1 - x3;

  let y12 = y1 - y2;
  let y13 = y1 - y3;

  let y31 = y3 - y1;
  let y21 = y2 - y1;

  let x31 = x3 - x1;
  let x21 = x2 - x1;

  // x1^2 - x3^2
  let sx13 = Math.pow(x1, 2) - Math.pow(x3, 2);

  // y1^2 - y3^2
  let sy13 = Math.pow(y1, 2) - Math.pow(y3, 2);

  let sx21 = Math.pow(x2, 2) - Math.pow(x1, 2);
  let sy21 = Math.pow(y2, 2) - Math.pow(y1, 2);

  let f =
    (sx13 * x12 + sy13 * x12 + sx21 * x13 + sy21 * x13) /
    (2 * (y31 * x12 - y21 * x13));
  let g =
    (sx13 * y12 + sy13 * y12 + sx21 * y13 + sy21 * y13) /
    (2 * (x31 * y12 - x21 * y13));

  let c = -Math.pow(x1, 2) - Math.pow(y1, 2) - 2 * g * x1 - 2 * f * y1;

  // eqn of circle be x^2 + y^2 + 2*g*x + 2*f*y + c = 0
  // where centre is (h = -g, k = -f) and radius r
  // as r^2 = h^2 + k^2 - c
  let h = -g;
  let k = -f;
  let sqr_of_r = h * h + k * k - c;

  // r is the radius
  const radius = Math.sqrt(sqr_of_r);
  return { radius: radius, center: [h, k] };
};

export default getRadiusWith3Points;
