import {
  // CONVERSION_RATES_TO_METRIC,
  FEATURES_PROPERTIES,
} from "../mapConstants";

const hRange = [0, 360];
const sRange = [50, 75];
const lRange = [25, 60];

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

const getHashOfString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const generateHSL = (name: string) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

const HSLtoString = (hsl: number[]) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const getAvatarShades = (str: string) => {
  return HSLtoString(generateHSL(str));
};

export const getHoverProperties = (geometry_type: number, properties: any) => {
  const option = FEATURES_PROPERTIES[geometry_type];
  let availableProperties: { key: string; value: number }[] = [];
  option?.forEach((key: string) => {
    if (properties.hasOwnProperty(key)) {
      availableProperties = [
        ...availableProperties,
        { key: key, value: properties[key] },
      ];
    }
  });
  return availableProperties;
};

export const convertValue = (value: number, rate: number) => {
  const result = value * rate;
  return Number(result.toFixed(4));
};

// export const convertToMetricSystem = ({
//   key,
//   value,
// }: {
//   key: string;
//   value: number;
// }) => {
//   let convertedValue;

//   switch (key) {
//     case "area":
//     case "edit_area":
//       convertedValue = convertValue(
//         value,
//         CONVERSION_RATES_TO_METRIC.sqFtToSqM
//       );
//       break;

//     case "perimeter":
//     case "edit_perimeter":
//     case "length":
//     case "edit_length":
//       convertedValue = convertValue(value, CONVERSION_RATES_TO_METRIC.ftToM);
//       break;

//     case "volume":
//       convertedValue = convertValue(
//         value,
//         CONVERSION_RATES_TO_METRIC.cuYdToCuM
//       );
//       break;

//     case "weight":
//       convertedValue = convertValue(value, CONVERSION_RATES_TO_METRIC.lbToKg);
//       break;

//     case "weight_ton":
//       convertedValue = convertValue(
//         value,
//         CONVERSION_RATES_TO_METRIC.tonToMetricTon
//       );
//       break;

//     case "count":
//     case "edit_count":
//       convertedValue = value; // No conversion needed for count
//       break;

//     default:
//       throw new Error(`Unsupported key type: ${key}`);
//   }

//   return convertedValue;
// };
