import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import LineString from 'ol/geom/LineString';
import { MultiPoint } from 'ol/geom';
import {
  DIMENSION_TOOL_ID,
  DIMENSION_TOOL_LAYER,
  GEOMETRY_TYPE,
  GEOMETRY_TYPES,
  ORTHO_ANGLE,
  TOOL_SCOPE
} from 'woodpecker';
import MapBase from '../../mapLayer/mapBase';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { LINE_COLOR, crossHairStyle, labelStyle, lineMeasureStyles } from '../../../hooks/tools/helpers/styles';
import { Icon } from 'ol/style';
import { LineSvg, ZERO_LENGTH } from '../../../hooks/tools/helpers/constants';
import { isOutOfExtent } from '../../../helpers/helpers';
import { generateUniqueID, isValidLineString } from 'macaw';
import { showToast } from 'ui';
import { Coordinate } from 'ol/coordinate';
import { formatLengthInFeetAndInches } from '../../../hooks/tools/helpers';
import { Type } from 'ol/geom/Geometry';
import { getAngle } from 'macaw/src/getArc';

class DimensionTool extends ToolAbstract {
  private mapObj: MapBase;
  private layer: any;
  private draw: Draw | null;
  private overlayRef: any;
  private snap: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.layer = null;
    this.draw = null;
  }

  init(id: string) {
    this.layer = this.mapObj.getLayerById(DIMENSION_TOOL_LAYER);

    const layer = this.layer;
    if (layer) this.overlayRef = layer;
    else {
      this.overlayRef = new VectorLayer({
        id: DIMENSION_TOOL_ID,
        source: new VectorSource({ wrapX: false }),
        style: (feature: any) => {
          return this.styleFunction(feature);
        },
        zIndex: 1000
      } as any);
      this.mapObj.map?.addLayer(this.overlayRef);
    }

    const source = this.overlayRef.getSource();

    this.draw = new Draw({
      source: source,
      type: GEOMETRY_TYPES.LINESTRING as Type,
      dragVertexDelay: 0,
      snapTolerance: 1,
      condition: e => {
        const mouseClick = e.originalEvent.which;
        if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
          return false;
        }
        return true;
      },
      style: (feature: any) => {
        return this.styleFunction(feature);
      },
      geometryFunction: this.geometryFunction
    });

    this.mapObj.map?.addInteraction(this.draw);
    this.draw?.on('drawend', this.onDrawEnd);
    window.addEventListener('keydown', this.keyDownHandler);
  }

  // Custom method to handle orthogonal line snapping
  geometryFunction = (coordinates: any[], geometry?: any) => {
    if (!geometry) {
      geometry = new LineString(coordinates);
    } else {
      geometry.setCoordinates(coordinates);
    }
    if (globalStore.AppStore.ortho_mode) {
      if (coordinates.length > 1) {
        const start = coordinates[coordinates.length - 2]; // Last point
        const end = coordinates[coordinates.length - 1]; // Current mouse position

        const snappedEnd = this.snapToOrthoAngle(start, end); // Snap to ortho angle

        geometry.setCoordinates([...coordinates.slice(0, -1), snappedEnd]); // Set orthogonal coordinates
      } else {
        geometry.setCoordinates(coordinates); // For the first point, no snapping needed
      }
    }

    return geometry;
  };

  // Helper function to snap a line segment to the closest ortho angle
  snapToOrthoAngle = (start: Coordinate, end: Coordinate): Coordinate => {
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];

    const angleInDegrees = getAngle(start, end);

    const snappedAngle = Math.round(angleInDegrees / ORTHO_ANGLE) * ORTHO_ANGLE;
    const snappedAngleInRadians = (snappedAngle * Math.PI) / 180;

    // Calculate the distance between the two points
    const length = Math.sqrt(dx * dx + dy * dy);

    // Compute the new snapped end coordinates based on the snapped angle
    const snappedX = start[0] + length * Math.cos(snappedAngleInRadians);
    const snappedY = start[1] + length * Math.sin(snappedAngleInRadians);

    return [snappedX, snappedY];
  };

  onDrawEnd = (e: any) => {
    const unq_id = generateUniqueID('highlight');
    e.feature.setId(unq_id);
    e.feature.setProperties({
      vector_layer_id: DIMENSION_TOOL_LAYER,
      excluded: true,
      external: !!globalStore?.AppStore?.tool?.external,
      tool_source: !!globalStore?.AppStore?.tool?.external ? TOOL_SCOPE.EXTERNAL : TOOL_SCOPE.INTERNAL
    });
    if (!isValidLineString(e.feature)) {
      showToast('Invalid feature: Intersecting Line not allowed', 'error', {
        position: 'top-center',
        hideProgressBar: false
      });
      setTimeout(() => {
        this.overlayRef.getSource().removeFeature(e.feature);
      }, 0);
    }
  };

  keyDownHandler = (e: any) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    } else if (e.shiftKey) {
      if (globalStore?.AppStore?.arc_mode) return;

      const { selectedLayerID, layers } = globalStore?.AppStore;
      const layerData = layers.get(selectedLayerID);

      if (
        !layerData ||
        (layerData?.geometry_type !== GEOMETRY_TYPE.LINE && layerData?.geometry_type !== GEOMETRY_TYPE.POLYGON)
      )
        return;

      const { updateState, ortho_mode: orthoMode } = globalStore.AppStore;
      updateState({ ortho_mode: !orthoMode });
    }
  };

  styleFunction = (feature: any) => {
    let styles = [...lineMeasureStyles];
    // Custom CROSS HAIR style for ortho mode
    if (globalStore.AppStore.ortho_mode) {
      this.mapObj.map!.getViewport().style.cursor = 'default';
      styles.push(crossHairStyle);
    } else {
      this.mapObj.map!.getViewport().style.cursor = 'crosshair';
    }

    const { dpi, scale } = globalStore.AppStore.worksheetParams;
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.LINESTRING) {
      let count = 0;
      const totalSegment = geometry?.getCoordinates().length - 1;
      geometry?.forEachSegment((start: Coordinate, end: Coordinate) => {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx);
        let point: Coordinate[] = [];
        if (count === 0) point = [start];
        if (count === totalSegment - 1) point.push(end);
        styles.push(
          new Style({
            geometry: new MultiPoint(point),
            image: new Icon({
              src: `data:image/svg+xml;utf8,${encodeURIComponent(LineSvg(LINE_COLOR))}`,
              anchor: [0.5, 0.5],
              rotateWithView: true,
              rotation: -rotation
            })
          })
        );
        count++;
        const segment = new LineString([start, end]);
        const label = formatLengthInFeetAndInches(segment, dpi, scale);
        if (label !== ZERO_LENGTH) {
          const _labelStyle = labelStyle.clone();
          _labelStyle.setGeometry(segment);
          _labelStyle.getText().setText(label);
          styles.push(_labelStyle);
        }
      });
    }
    return styles;
  };

  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.overlayRef = null;
    window.removeEventListener('keydown', this.keyDownHandler);
    globalStore.AppStore.updateState({ addScaleLength: 0, ortho_mode: false });
    this.mapObj.map!.getViewport().style.cursor = 'pointer';
  }
}
export default DimensionTool;
