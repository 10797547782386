import { Translate } from 'ol/interaction';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import { GEOMETRY_TYPE, MAP_TYPE } from 'woodpecker';
import { showToast } from 'ui';
import { FeatureisOutOfExtent } from 'macaw/src/getValidFeatures';
import { EVENT_TYPE } from '../../../hooks/tools/helpers/constants';
import { undoRedoPush } from '../../mapLayer/mapInit';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import MapBase from '../../mapLayer/mapBase';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { selectStyle } from '../../../hooks/tools/helpers/styles';
import { triggerOverrideOverlap } from '../../../helpers/helpers';

class MoveTool extends ToolAbstract {
  private mapObj: MapBase;

  private translate: Translate | null;

  private hitTolerance: number;

  private isTranslating: boolean;

  private outsideMapevent: any;

  private layer: any;

  private features: any;

  private originalPositionMap: Map<Feature, any>;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.translate = null;
    this.hitTolerance = 10;
    this.isTranslating = false;
    this.outsideMapevent = null;
    this.originalPositionMap = new Map();
  }

  emptyFeatureArray() {
    if (this.features) {
      this.features.clear();
    }
  }

  init(id: string) {
    this.off();
    if (!globalStore.AppStore?.selectedFeatures?.length) {
      showToast('Please select some features', 'error');
      globalStore.AppStore.setTool({ tool_id: 'select_feature' });
      return;
    }

    this.features = new Collection<Feature>(
      globalStore.AppStore.selectedFeatures.map((feature: Feature) => {
        // Apply your custom style here, assuming `style` is a function that returns a style.
        const feat_vector_layer_id = feature?.getProperties()?.vector_layer_id;
        const layerData = globalStore?.AppStore?.layers?.get(feat_vector_layer_id) || {};
        feature.setStyle(
          selectStyle(
            layerData?.geometry_type === GEOMETRY_TYPE.ABSTRACT,
            layerData?.geometry_type === GEOMETRY_TYPE.TYPICAL
          )
        );
        return feature;
      })
    );

    // globalStore.AppStore.setSelectedFeatures([]);
    this.layer = this.mapObj?.getLayerById(id);

    this.translate = new Translate({
      layers: [this.layer],
      hitTolerance: this.hitTolerance,
      features: this.features
    });

    this.translate.handleEvent = e => {
      this.outsideMapevent = e;
      if (e.type === EVENT_TYPE.CLICK && !this.isTranslating) {
        //@ts-ignore
        this.translate?.handleDownEvent(e);
      } else if (e.type === EVENT_TYPE.CLICK && this.isTranslating) {
        if (this.mapObj.map_type === MAP_TYPE.BLUEPRINT) {
          const isOutOfExtent = this.features?.getArray?.()?.some?.((feature: any) => {
            return FeatureisOutOfExtent(feature?.getGeometry()?.getExtent(), this.mapObj.map);
          });
          if (isOutOfExtent) {
            return true;
          }
        }

        //@ts-ignore
        this.translate?.handleUpEvent(e);
        this.isTranslating = false;
      } else if (e.type === EVENT_TYPE.POINTER_MOVE) {
        //@ts-ignore
        this.translate?.handleMoveEvent(e);
        //@ts-ignore
        this.translate?.handleDragEvent(e);
      }
      return true;
    };

    this.mapObj.map?.addInteraction(this.translate);
    this.mapObj.map?.on('pointermove', this.pointerMoveHandler);
    this.translate.on('translatestart', () => {
      this.features?.forEach((feature: any) => {
        // storing initial position in map when translation starts
        if (this.mapObj.map_type === MAP_TYPE.BLUEPRINT)
          this.originalPositionMap.set(feature, feature.getGeometry().clone());
        // Apply your custom style here, assuming `style` is a function that returns a style.
        feature.setStyle(null);
      });
      this.isTranslating = true;
    });
    this.translate.on('translateend', () => {
      // Clearing map if translation completes
      this.originalPositionMap.clear();
      globalStore.AppStore.setSelectedFeatures([]);

      this.features?.forEach((feature: any) => {
        triggerOverrideOverlap(feature);
      });
      undoRedoPush();
    });
  }

  pointerMoveHandler = (e: any) => {
    const canvas = document.getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.style.cursor = '';

      this.mapObj.map?.forEachFeatureAtPixel(
        e.pixel,
        feature => {
          if (feature) {
            canvas.style.cursor = 'move';
          }
        },
        {
          layerFilter: layer_candidate => {
            return this.layer == layer_candidate;
          },
          hitTolerance: this.hitTolerance
        }
      );
    }
  };

  off() {
    this.features?.forEach((feature: any) => {
      // resetting to initial position if translation interrupted by changing tools (Pressing esc key will change tool from 'move' to 'pan')
      if (this.mapObj.map_type === MAP_TYPE.BLUEPRINT) {
        const originalGeometry = this.originalPositionMap.get(feature);
        if (originalGeometry) {
          feature.setGeometry(originalGeometry); // Revert to original geometry
        }
      }
      // Apply your custom style here, assuming `style` is a function that returns a style.
      feature.setStyle(null);
    });

    //@ts-ignore
    this.translate?.handleUpEvent(this.outsideMapevent);
    this.isTranslating = false;

    this.mapObj.map?.un('pointermove', this.pointerMoveHandler);
    if (this.translate) {
      this.mapObj.map?.removeInteraction(this.translate);
      // @ts-expect-error
      this.translate.un('translateend', undoRedoPush);
    }
    // clearing map
    this.originalPositionMap.clear();
  }
}

export default MoveTool;
