import 'ol/ol.css';
import { Draw } from 'ol/interaction';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import MapBase from '../../mapLayer/mapBase';
import { HAND_TOOL_LAYER, GEOMETRY_TYPES } from 'woodpecker';
import { FeatureisOutOfExtent, drawStyle, isOutOfExtent } from '../../../helpers/helpers';
import { Circle, Polygon } from 'ol/geom';
import { Feature } from 'ol';
import { generateUniqueID } from 'macaw';
import { handDrawStore } from '../../utilityclasses/HandDrawStoreListener';
import { HandDrawingToolTypeEnum } from '../../../store/handDrawStore';
import { createBox } from 'ol/interaction/Draw';
import { fromCircle } from 'ol/geom/Polygon';
import { boundingExtent, getBottomLeft, getBottomRight, getTopLeft, getTopRight } from 'ol/extent';
import { cleanCoords, polygon } from '@turf/turf';

export class HandDrawingTool extends ToolAbstract {
  mapObj: MapBase;
  draw: Draw | null;
  layer: any;
  active: boolean;
  width: number;
  color: string;
  controlPanelEl: HTMLElement | null;
  rightPanel: HTMLElement | null;
  drawingType: HandDrawingToolTypeEnum;

  constructor(map: MapBase) {
    super();
    this.mapObj = map; // Reference to the map
    this.draw = null;
    this.layer = null;
    this.width = 3;
    this.color = '#212121';
    this.drawingType = HandDrawingToolTypeEnum.draw;
    this.active = false;
    this.controlPanelEl = document.getElementById('handtool-control-panel');
    this.rightPanel = document.getElementById('worksheet-panel');
  }

  // Start drawing by adding the interaction
  init() {
    this.off();
    if (this.active) return;

    this.layer = this.mapObj.getLayerById(HAND_TOOL_LAYER);

    if (this.layer) {
      const source = this.layer?.getSource();

      const { color, width, type } = handDrawStore.AppStore;
      this.color = color;
      this.width = width;
      this.drawingType = type;

      let drawOptions: any = {
        source: source,
        style: drawStyle(HAND_TOOL_LAYER, this.width, this.color),
        freehand: this.drawingType === HandDrawingToolTypeEnum.draw,
        condition: (e: any) => {
          const mouseClick = e.originalEvent.which;
          return !(mouseClick === 3 || mouseClick === 2 || isOutOfExtent(e, this.mapObj.map));
        }
      };

      if (type === HandDrawingToolTypeEnum.box) {
        drawOptions.type = GEOMETRY_TYPES.CIRCLE;
        drawOptions.geometryFunction = createBox(); // Use createBox to make rectangle geometry
      } else if (type === HandDrawingToolTypeEnum.circle) {
        drawOptions.type = GEOMETRY_TYPES.CIRCLE;
      } else if (type === HandDrawingToolTypeEnum.cloud) {
        drawOptions.type = GEOMETRY_TYPES.CIRCLE;
        drawOptions.geometryFunction = this.generateCloudGeometry.bind(this);
      } else {
        drawOptions.type = GEOMETRY_TYPES.LINESTRING as any;
      }

      this.draw = new Draw(drawOptions);

      this.mapObj.map?.addInteraction(this.draw);
      this.draw.on('drawend', this.onDrawEnd.bind(this));
      this.showControlPanel();
      this.active = true;
    }
  }

  generateCloudGeometry(coords: any, geometry?: Polygon) {
    if (!geometry) {
      geometry = new Polygon([]);
    }

    // Ensure at least 2 coordinates to form a box
    if (coords.length < 2) {
      geometry.setCoordinates(coords);
      return geometry;
    }
    const extent = boundingExtent([coords[0], coords[coords.length - 1]]);
    const coordinates = [getBottomLeft(extent), getBottomRight(extent), getTopRight(extent), getTopLeft(extent)];

    const cloudCoordinates: any = [];
    const arcRadius = 10; // Radius of arcs for cloud effect
    const arcSegments = 12; // Points per arc

    // Loop through each edge of the box
    for (let i = 0; i < coordinates.length; i++) {
      const [x1, y1] = coordinates[i];
      const [x2, y2] = coordinates[(i + 1) % coordinates.length]; // Next point (wrap around)

      cloudCoordinates.push([x1, y1]); // Add the starting point of the edge

      const dx = x2 - x1;
      const dy = y2 - y1;
      const edgeLength = Math.sqrt(dx * dx + dy * dy);
      const angle = Math.atan2(dy, dx);

      const numArcs = Math.max(1, Math.floor(edgeLength / (arcRadius * 2)));

      // Create cloud-like arcs for each segment
      for (let j = 0; j < numArcs; j++) {
        const t = j / numArcs;
        const xt = x1 + t * dx;
        const yt = y1 + t * dy;

        for (let k = 0; k <= arcSegments; k++) {
          const theta = angle - Math.PI / 2 + (Math.PI * k) / ((j === numArcs - 1 ? 1.5 : 2) * arcSegments);
          const arcX = xt + arcRadius * Math.cos(theta);
          const arcY = yt + arcRadius * Math.sin(theta);
          cloudCoordinates.push([arcX, arcY]);
        }
      }
    }

    cloudCoordinates.push(cloudCoordinates[0]);

    //this function is to remove redundant coordinates if any
    const poly = polygon([cloudCoordinates]);
    const cleanPoly = cleanCoords(poly);

    geometry.setCoordinates(cleanPoly.geometry.coordinates);

    return geometry;
  }

  showControlPanel = () => {
    if (this.controlPanelEl) {
      this.controlPanelEl!.style.display = 'flex';

      const rightPanelWidth = this.rightPanel?.offsetWidth || 320;

      const buffer = 10;

      this.controlPanelEl!.style.right = `${rightPanelWidth + buffer}px`;

      this.controlPanelEl!.style.top = '0';

      this.controlPanelEl!.style.position = 'absolute';
    }
  };

  hideControlPanel = () => {
    if (this.controlPanelEl) {
      this.controlPanelEl!.style.display = 'none';
    }
  };

  // Stop drawing by removing the interaction
  off() {
    if (this.active) {
      if (this.draw) {
        this.mapObj.map?.removeInteraction(this.draw);
        this.draw.un('drawend', this.onDrawEnd);
      }
      this.active = false;

      this.hideControlPanel();
    }
  }

  applyFeatureStyle(feature: Feature) {
    feature.setStyle(drawStyle('feature-style', this.width, this.color));
  }

  // Event handler for draw end
  onDrawEnd(e: any) {
    const feature = e.feature as Feature<any>;

    if (this.drawingType === HandDrawingToolTypeEnum.circle) {
      const geometry = feature.getGeometry();
      if (geometry instanceof Circle) {
        // Replace the Circle geometry with a Polygon approximation
        const polygon = fromCircle(geometry, 64); // 64 segments for smoothness
        feature.setGeometry(polygon);
      }
    }

    const isOutExtent = FeatureisOutOfExtent(feature?.getGeometry()?.getExtent() as any, this.mapObj.map);

    if (!isOutExtent) {
      const unq_id = generateUniqueID('hand_tool');
      feature.setId(unq_id);
      feature.setProperties({
        vector_layer_id: HAND_TOOL_LAYER,
        color: this.color,
        width: this.width
      });
      this.applyFeatureStyle(feature);
    } else {
      setTimeout(() => {
        this.layer.getSource().removeFeature(e.feature);
      }, 0);
    }
  }
}
