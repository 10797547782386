import { css } from '@emotion/css';

export const container = css`
  display: none;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 1rem 1rem 1rem 0.75rem;
  width: 290px;
  z-index: 100000;
  background: #f6f7fe;
`;

export const iconButton = css`
  cursor: pointer;
  padding: 0.375rem;
  border-radius: 9999px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const textArea = css`
  border: none;
  outline: transparent;
  resize: none;
  width: 100%;
  font-size: 0.875rem;
  background: transparent;
`;
