import { useCallback } from 'react';
import { GEO_JSON, getInvalidPolys, getMultiPolyFeatures } from 'macaw';
import { showToast } from 'ui';
import VectorLayer from 'ol/layer/Vector';
import {
  AbstractSvg,
  ERROR_INVALID_FEATURES,
  LAYER,
  MULTIPOLYGON_ERROR,
  CurrentJob,
  GEOMETRY_TYPE,
  Layer,
  TypicalSvg
} from 'woodpecker';
import { Overlay, Feature, Map as olMap } from 'ol';
import { Stroke, Style, Fill, Circle, Icon, RegularShape } from 'ol/style';
import { getCenter } from 'ol/extent';
import { getLayerType } from '../helpers/helpers';
import { highlightFeatures } from './tools/helpers';
import useMapStore from '../store/mapStore';
import { layerStyleMap, blinkIntervalRef } from '../constants';

export const returnGeojson = (map: any, bptProjection: any, featureId: string) => {
  return map
    ?.getAllLayers()
    .filter((feature: any) => feature?.get('id') === featureId)
    .map((feature: any, index: number) => {
      const featuresArray = feature?.getSource()?.getFeatures ? feature?.getSource()?.getFeatures() : [];
      const geojson = GEO_JSON.writeFeatures(featuresArray, bptProjection);
      return JSON.parse(geojson);
    })[0];
};

export const addOverlay = (map: any, element: HTMLElement, coordinate: any, overlay: Overlay) => {
  if (!element || !map) return;

  map.addOverlay(overlay);
  overlay?.setPosition(coordinate);
  element.style.display = 'flex';
};

export const getId = (map: any, selectedLayerId: any) => {
  if (!map || !selectedLayerId) return;

  const projection = map.getView().getProjection();
  const GeoJson = returnGeojson(map, projection, selectedLayerId);

  if (!GeoJson) return '';
  const { features } = GeoJson;

  return features?.length + 1;
};

export function hexToRgbArray(hexColor: string = '#000000') {
  // Remove the '#' character from the beginning of the hex color string
  hexColor = hexColor?.substring(1);

  // Extract the red, green, and blue components
  const red = parseInt(hexColor.substring(0, 2), 16);
  const green = parseInt(hexColor.substring(2, 4), 16);
  const blue = parseInt(hexColor.substring(4, 6), 16);

  // Return the RGB values as an array
  return [red, green, blue, 1];
}

export const copyTextToClipboard = (text: string, message: string | null = null) => {
  navigator.clipboard.writeText(text).then(() => {
    if (message) {
      showToast(message, 'success', { autoClose: 1000, hideProgressBar: true });
    }
  });
};

export function resizeImage(
  url: string,
  width: number,
  height: number,
  x: number,
  y: number,
  callback: (url: string) => void
) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const imageObj = new Image();

  // set canvas dimensions
  canvas.width = width;
  canvas.height = height;

  imageObj.onload = function () {
    context?.drawImage(imageObj, x, y, width, height, 0, 0, width, height);

    callback(canvas.toDataURL() || '');
  };
  imageObj.crossOrigin = 'Anonymous';
  imageObj.src = url;
}

const useMapHelpers = () => {
  const selectedLayer = useMapStore((state: any) => state.selectedLayer);

  const map = useMapStore((state: any) => state.map);
  const layers = useMapStore((state: any) => state.layers);

  const getVectorLayerById = useCallback(
    (id: string) => {
      if (!id) return null;
      return map
        ?.getLayers()
        .getArray()
        .find((lyr: any) => id == lyr.get('id'));
    },
    [map]
  );

  const setOpacity = (id: string, value: number) => {
    const layer = getVectorLayerById(id);

    if (layer) {
      const style = layer.getStyle();
      const hexColor = style.getStroke().getColor();
      const color = hexToRgbArray(hexColor);

      color[3] = value;
      const fill = style.getFill();
      fill.setColor(color);
      layer.setStyle(style);
    }
  };

  const setLayerColor = (id: string, color: string, value: number, geometryType: number) => {
    const layer = getVectorLayerById(id);

    const isTypical = geometryType === GEOMETRY_TYPE.TYPICAL;
    const isAbstract = geometryType === GEOMETRY_TYPE.ABSTRACT;
    // in case of line string, we need to set fill color to transparent
    // this case is for Line Rectangle and Line Circle tools
    const isLineString = geometryType === GEOMETRY_TYPE.LINESTRING;

    if (layer) {
      const color_ = hexToRgbArray(color);

      const fill_color = [...color_];
      fill_color[3] = value;

      const AbstarctIcon = AbstractSvg(color);
      const TypicalIcon = TypicalSvg(color);

      const iconToDisplay = isAbstract ? AbstarctIcon : TypicalIcon;

      const newStyle = new Style({
        stroke: new Stroke({
          color,
          width: 3
        }),
        fill: new Fill({
          color: isLineString ? 'rgba(255, 255, 255, 0)' : fill_color
        }),
        // Add visualisation for point features
        image:
          isAbstract || isTypical
            ? new Icon({
                src: `data:image/svg+xml;utf8,${encodeURIComponent(iconToDisplay)}`
              })
            : new Circle({
                radius: 7,
                fill: new Fill({
                  color
                })
              })
      });
      layer.setStyle(newStyle);
    }
  };
  const isIgnorableLayer = (l: Layer | any) => {
    const id = l.get('id');
    return (
      !(l instanceof VectorLayer) ||
      getLayerType(l.get('id'))?.geometry_type != GEOMETRY_TYPE.POLYGON ||
      id === LAYER.PARCEL
    );
  };

  const getEditableLayers = function () {
    return map
      ?.getLayers()
      ?.getArray()
      ?.filter((l: any) => !isIgnorableLayer(l))!;
  };

  const isValidData = (jobData: CurrentJob) => {
    const invalidPolys = getInvalidPolys(map, layers);
    if (invalidPolys.length) {
      highlightFeatures(invalidPolys);
      showToast(ERROR_INVALID_FEATURES, 'error');
      return false;
    }

    if (jobData && jobData.source_type.includes('fms')) {
      const multiPolyParcelFeatures = getMultiPolyFeatures(map);
      if (multiPolyParcelFeatures.length > 1) {
        highlightFeatures(multiPolyParcelFeatures);
        showToast(MULTIPOLYGON_ERROR, 'error');
      }
      return true;
    }

    return true;
  };

  const highlightLocatedFeature = (features: any, isAbstract: boolean, isTypical: boolean) => {
    const getStyle = (width: any, fillStyle: any) => {
      const strokeStyle = new Stroke({
        color: 'red',
        width
      });
      const imageStyle = new RegularShape({
        stroke: strokeStyle,
        fill: fillStyle,
        points: 4,
        radius: width * Math.sqrt(2),
        angle: Math.PI / 4
      });
      return new Style({
        fill: fillStyle,
        stroke: strokeStyle,
        image: imageStyle
      });
    };
    const orgWidth = 3;
    let width = orgWidth + 3;
    const fillStyle = new Fill({ color: 'rgba(255,0,0,0.3)' });
    let scale = 1.3;
    const AbstarctIcon = AbstractSvg('red', 'red');
    const TypicalIcon = TypicalSvg('red', 'red');
    const blinkIntervalId = setInterval(() => {
      if (isAbstract || isTypical) {
        scale = scale === 1 ? 2 : 1;
        features.setStyle(
          new Style({
            image: new Icon({
              src: `data:image/svg+xml;utf8,${encodeURIComponent(isTypical ? TypicalIcon : AbstarctIcon)}`,
              color: 'rgba(255,0,0,0.7)',
              scale,
              crossOrigin: 'anonymous'
            })
          })
        );
      } else {
        width = width === orgWidth ? orgWidth + 3 : orgWidth;
        features.setStyle(getStyle(width, fillStyle));
      }
    }, 250);
    setTimeout(() => {
      clearInterval(blinkIntervalId);
    }, 3000);
    return blinkIntervalId;
  };

  const setZoomLevel = (height: number, width: number, value = 2) => {
    const extent = [0 - 1500, -height - 1500, width + 1500, 0 + width];
    const minExtent = [0, -height, width, 0];

    const center = getCenter(extent);
    map?.getView()?.setCenter(center);
    map?.getView()?.fit(minExtent, {
      padding: [100, 100, 100, 100], // Optional: Adjust padding to avoid tight fitting
      minZoom: 5 // Set a minimum zoom level to avoid the map being too small
    });
  };

  const saveLayerStyle = (layerId: string) => {
    const vectorFeatureLayer = getVectorLayerById(layerId);
    const style = vectorFeatureLayer?.getStyle();

    // Save the layerId and style in the map
    layerStyleMap.set(layerId, style);
  };

  // Retrieve and remove style from the map
  const retrieveAndRemoveLayerStyle = (layerId: string) => {
    if (layerStyleMap.has(layerId)) {
      const style = layerStyleMap.get(layerId);
      layerStyleMap.delete(layerId); // Remove the entry after retrieving
      return style;
    }
    return null;
  };

  const locateFeat = (blinkedLayerId: string, layerId: string, isAbstract: boolean, isTypical: boolean) => {
    if (blinkedLayerId !== layerId) {
      saveLayerStyle(layerId);
    }

    if (blinkIntervalRef.current) {
      clearInterval(blinkIntervalRef.current);
      blinkIntervalRef.current = null;
    }

    const vectorFeatureBlinkedLayer = getVectorLayerById(blinkedLayerId);
    const vectorFeaturelayer = getVectorLayerById(layerId);
    if (blinkedLayerId === layerId) {
      const previousStyle = retrieveAndRemoveLayerStyle(blinkedLayerId);
      return vectorFeatureBlinkedLayer?.setStyle(previousStyle);
    }
    const previousStyle = retrieveAndRemoveLayerStyle(blinkedLayerId);
    vectorFeatureBlinkedLayer?.setStyle(previousStyle);
    blinkIntervalRef.current = highlightLocatedFeature(vectorFeaturelayer, isAbstract, isTypical);
  };

  return {
    getVectorLayerById,
    setOpacity,
    setLayerColor,
    isValidData,
    locateFeat,
    setZoomLevel
  };
};

export default useMapHelpers;
