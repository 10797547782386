/* eslint-disable @typescript-eslint/naming-convention */
export enum FilterBy {
  BOTH = 1,
  TAG,
  GROUP
}

export enum LogicalOperation {
  OR = 1,
  AND
}

export const TAGGING_SCOPES = {
  COMPANY: 1,
  GLOBAL: 2,
  TAKEOFF: 3
};

export enum TAG_ASSIGN_TYPE {
  TAG = 1,
  GROUP,
  NOT_ALLOWED
}

export enum FETCHING_STATUS {
  IDLE = 1,
  LOADING,
  SUCCESS,
  ERROR
}

export const layerStyleMap = new Map();
export const DEFAULT_COLOR = '#4CAF50';

export const blinkIntervalRef: { current: null | ReturnType<typeof setTimeout> } = {
  current: null
};
