import { Draw } from 'ol/interaction';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { drawStyle, isOutOfExtent } from '../../../helpers/helpers';
import { COMMENT_LAYER_ID, GEOMETRY_TYPES } from 'woodpecker';
import { Type } from 'ol/geom/Geometry';
import { generateUniqueID } from 'macaw';
import TOOL_MAP, { TOOL_TYPE } from '../constants';
import { containsCoordinate } from 'ol/extent';
import { showToast } from 'ui';
import { Point } from 'ol/geom';

class DrawPoint extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null = null;
  private id: string;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.id = '';
  }

  /**
   * Initializes the tool for the specified layer ID.
   * Turns off any existing tool and sets up the draw interaction for the layer's source.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init(id: string) {
    this.off();
    const layer = this.mapObj?.getLayerById(id);
    this.id = id;

    if (layer) {
      let source = layer.getSource();
      if (globalStore.AppStore.tool.add_comment) {
        source = this.mapObj.getLayerById(COMMENT_LAYER_ID).getSource();
      }

      this.draw = new Draw({
        source: source,
        type: GEOMETRY_TYPES.POINT as Type,
        dragVertexDelay: 0,
        snapTolerance: 1,
        style: drawStyle(id),
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        },
        geometryFunction: (coords: any, geom) => {
          const mapExtent = this.mapObj?.map?.getView()?.getProjection()?.getExtent();
          if (!mapExtent || !coords) return geom;
          // This check will not allow creating point geometry if point coordinates are not inside map extent
          if (!containsCoordinate(mapExtent, coords)) {
            return geom || new Point([]);
          }

          if (geom) {
            geom.setCoordinates(coords);
          } else {
            geom = new Point(coords);
          }

          return geom;
        }
      });
      this.mapObj.map?.addInteraction(this.draw);

      if (globalStore.AppStore.tool.add_comment) {
        const commentTool = TOOL_MAP[TOOL_TYPE.ADD_COMMENT]?.getObject();
        this.draw.on('drawstart', commentTool.onDrawStart);
        this.draw.on('drawend', commentTool.onDrawEnd);
      } else {
        this.draw.on('drawend', this.onDrawEnd);
        window.addEventListener('keydown', this.keyDownHandler);
      }
    }
  }

  onDrawEnd = (event: any) => {
    const feature = event.feature;
    const source = this.mapObj?.getLayerById(this.id)?.getSource();
    const coords = feature?.getGeometry()?.getCoordinates();
    const mapExtent = this.mapObj?.map?.getView()?.getProjection()?.getExtent();

    if (!coords || !mapExtent) return;
    if (!containsCoordinate(mapExtent, coords)) {
      showToast("Feature can't go out of map extent", 'error');
      return;
    }

    // Manually add feature to source if not added automatically
    if (source && !source.getFeatures().includes(feature)) {
      source.addFeature(feature);
    }

    const unq_id = generateUniqueID('point');
    event.feature.setId(unq_id);
    this.pushTimeout();
  };

  /**
   * Handles key down events, specifically removing the last point from the draw interaction if the Backspace key is pressed.
   * @param {KeyboardEvent} e - The keyboard event object.
   */
  keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      this.draw?.removeLastPoint();
    }
  };

  pushTimeout() {
    setTimeout(() => {
      undoRedoPush();
    }, 0);
  }

  /**
   * Turns off the draw interaction by removing it from the map and removing event listeners.
   */
  off() {
    if (this.draw) {
      this.mapObj.map?.removeInteraction(this.draw);
      this.draw.un('drawend', this.pushTimeout);
      window.removeEventListener('keydown', this.keyDownHandler);
    }
  }
}

export default DrawPoint;
