import { captureException } from '@sentry/react';
import { v4 as uuid4 } from 'uuid';
import html2canvas from 'html2canvas';

import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Overlay from 'ol/Overlay';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Draw, { createBox } from 'ol/interaction/Draw';
import { containsCoordinate, getBottomLeft, getTopRight } from 'ol/extent';
import { DragPan, DoubleClickZoom, MouseWheelZoom } from 'ol/interaction';

import { LABEL_ACTIONS, TOOLS_ID } from '../../../Constants/Constant';
import { changeMapCursor } from '../../../Utils/HelperFunctions';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { Observer } from '../../../Utils/Observer';
import { outputMap, toolController } from '../MapInit';
import CustomWindowProps from '../../../types/customWindow';

export interface LabelDataProps {
  text: string;
  text_color: string;
  text_size: string;
  box_color: string;
  id: string;
  width: number;
  height: number;
  action: number;
  box_image?: object | string;
  box_lonlat?: number[];
  extent?: any;
  box_coords?: any;
  lon?: any;
  lat?: any;
}

interface DomElementsStyleProps {
  mapContainer: HTMLElement;
  box_lonlat: number[];
  width: number;
  height: number;
  box_color?: string;
  text_color?: string;
  text_size?: number;
}

class LabelBox extends Observer {
  copiedLabelBoxData: any;

  copyLonlat: any;

  dblClickZoom: any;

  domElements: any;

  dragPan: any;

  draw: any;

  editedOverlay: any;

  isToolActive: boolean;

  lastCoords: any;

  lonLat: any;

  mapObj: any;

  mouseWheelZoom: any;

  positionChanged: any;

  selectedOverlay: any;

  textareaHeightAdjusted: any;

  textareaWidthAdjusted: any;

  copiedLabelElement: any;

  isBlueprint: boolean;

  constructor(mapObj: any) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.domElements = null;
    this.lastCoords = [];
    this.textareaWidthAdjusted = null;
    this.textareaHeightAdjusted = null;
    this.lonLat = null;
    this.selectedOverlay = null;
    this.isToolActive = false;
    this.dragPan = null;
    this.editedOverlay = null;
    this.dblClickZoom = null;
    this.mouseWheelZoom = null;
    this.positionChanged = false;
    this.copiedLabelBoxData = null;
    this.copiedLabelElement = null;
    this.copyLonlat = null;
    this.isBlueprint = false;
  }

  on() {
    this.off();

    this.isBlueprint = this.mapObj.isBlueprintMap;
    const customWindow: CustomWindowProps = window;
    this.isToolActive = customWindow.activeTool === TOOLS_ID.LABEL_BOX;

    if (!this.isToolActive) return;

    this.mapObj.map.on('singleclick', this.onMapClick);
    this.mapObj.map.on('pointermove', this.handlePointerMove);
    this.mapObj.map.on('pointerup', this.handlePointerUp);

    changeMapCursor(false, '', 'crosshair');

    this.mapObj.map.getInteractions().forEach((interaction: any) => {
      if (interaction instanceof DragPan) {
        this.dragPan = interaction;
      } else if (interaction instanceof DoubleClickZoom) {
        this.dblClickZoom = interaction;
      } else if (interaction instanceof MouseWheelZoom) {
        this.mouseWheelZoom = interaction;
      }
    });
    this.mapObj.map.removeInteraction(this.dblClickZoom);

    this.draw = new Draw({
      type: 'Circle',
      geometryFunction: this.createBox,
      style: new Style({
        fill: new Fill({
          color: this.isBlueprint ? '#e8e8e8' : '#ffffff'
        }),
        stroke: new Stroke({
          color: this.isBlueprint ? '#e8e8e8' : '#ffffff',
          width: 1
        })
      })
    });

    this.draw.on('drawend', this.handleDrawEnd);

    const overlays = [...this.mapObj.map.getOverlays().getArray()];
    if (overlays.length) {
      overlays.forEach(overlay => {
        if (overlay.get('labelBoxData')) {
          this.addListeners(overlay);
        }
      });
    }

    this.domElements = {
      container: document.getElementById('label-box-container'),
      textArea: document.getElementById('label-box-text-area'),
      textColor: document.getElementById('label-box-text-color'),
      textColorSelector: document.getElementById('label-box-text-color-selector'),
      textSize: document.getElementById('label-box-text-size'),
      textBoxColor: document.getElementById('label-box-bg-color'),
      textBoxColorSelector: document.getElementById('label-box-bg-selector'),
      deleteBtn: document.getElementById('label-box-delete'),
      copyBtn: document.getElementById('label-box-copy'),
      labelText: document.getElementById('label-box-text')
    };

    this.mapObj.map.addInteraction(this.draw);
    document.addEventListener('keydown', this.duplicateOverlay);
  }

  duplicateOverlay = (e: KeyboardEvent) => {
    if (this.domElements.container.style.display === 'block') return;
    if (e.stopPropagation) e.stopPropagation();

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 67) {
      e.stopImmediatePropagation();
      e.preventDefault();

      if (!this.selectedOverlay) return;
      this.copiedLabelBoxData = { ...this.selectedOverlay.get('labelBoxData') };
      this.copiedLabelElement = this.selectedOverlay.getElement();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 86) {
      e.stopImmediatePropagation();
      e.preventDefault();
      if (!this.copiedLabelBoxData || !this.copyLonlat) return;

      this.lonLat = null;
      this.textareaHeightAdjusted = null;
      this.textareaWidthAdjusted = null;

      const originalLabelBoxData = this.copiedLabelBoxData;
      const deltaLon = this.copyLonlat[0] - originalLabelBoxData.box_lonlat[0];
      const deltaLat = this.copyLonlat[1] - originalLabelBoxData.box_lonlat[1];

      const newExtent = [
        parseFloat(originalLabelBoxData.extent[0]) + deltaLon,
        parseFloat(originalLabelBoxData.extent[1]) + deltaLat,
        parseFloat(originalLabelBoxData.extent[2]) + deltaLon,
        parseFloat(originalLabelBoxData.extent[3]) + deltaLat
      ];

      const duplicatedLabelBoxData = {
        ...originalLabelBoxData,
        id: uuid4(),
        box_lonlat: this.copyLonlat,
        extent: newExtent,
        action: LABEL_ACTIONS.CREATED
      };

      this.addLabelBox(duplicatedLabelBoxData, true);
    }
  };

  onMapClick = (e: MapBrowserEvent<MouseEvent>) => {
    if (!this.lonLat) this.lonLat = e.coordinate;
    this.lastCoords = [e.originalEvent.pageX, e.originalEvent.pageY];
  };

  handleDrawEnd = (event: any) => {
    if (!this.lonLat) return;

    this.draw.setActive(false);
    this.dragPan.setActive(false);

    const extent = event.feature.getGeometry().getExtent();

    const bottomLeft = getBottomLeft(extent);
    const topRight = getTopRight(extent);
    const pixelBottomLeft = this.mapObj.map.getPixelFromCoordinate(bottomLeft);
    const pixelTopRight = this.mapObj.map.getPixelFromCoordinate(topRight);
    const mapContainer = document.getElementById('map');

    if (mapContainer) {
      const resolution = this.mapObj.map.getView().getResolution();
      const scaleFactor = 1 / resolution;

      const width = pixelTopRight[0] - pixelBottomLeft[0];
      const height = pixelBottomLeft[1] - pixelTopRight[1];

      // This part calculates the difference between the original width/height and the
      // width/height scaled by the scaleFactor. This effectively calculates the difference
      // between the original width and what the width would be at the map's current resolution.
      this.textareaWidthAdjusted = width + (width - width * scaleFactor) / scaleFactor;
      this.textareaHeightAdjusted = height + (height - height * scaleFactor) / scaleFactor;

      this.addDomElementsStyle({ mapContainer, box_lonlat: this.lonLat, width, height });
    }
  };

  // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
  createBox = (coordinates: any, geometry: any) => createBox()(coordinates, geometry);

  loadLabelBoxes(labelBoxes: LabelDataProps[]) {
    try {
      if (labelBoxes.length) {
        for (let i = 0; i < labelBoxes.length; i++) {
          this.loadLabelBox(labelBoxes[i]);
        }
      }
    } catch (err) {
      captureException(err);
    }
  }

  loadLabelBox = (labelData: LabelDataProps) => {
    if (typeof labelData.box_image !== 'string') return;

    const image = new Image();
    image.src = labelData.box_image;

    image.onload = () => {
      const overlay = new Overlay({
        position: this.lonLat || labelData?.box_lonlat,
        element: image,
        stopEvent: false
      });

      const updatedLonLat = overlay.getPosition();
      let editedExtent;
      if (updatedLonLat) {
        const lonLatString = updatedLonLat[0];
        editedExtent = [
          lonLatString,
          updatedLonLat[1] - this.textareaHeightAdjusted,
          lonLatString + this.textareaWidthAdjusted,
          updatedLonLat[1]
        ];
      }

      const data = {
        ...labelData,
        box_lonlat: this.lonLat || labelData?.box_lonlat,
        width: this.textareaWidthAdjusted || labelData?.width,
        height: this.textareaHeightAdjusted || labelData?.height,
        extent: this.textareaWidthAdjusted || this.textareaWidthAdjusted ? editedExtent : labelData?.extent
      };

      overlay.set('labelBoxData', data);
      this.mapObj.map.addOverlay(overlay);
      outputMap.updateOverlays();
    };
  };

  addLabelBox = (labelData: LabelDataProps, isCopied: boolean = false) => {
    const domElement = isCopied ? this.copiedLabelElement : this.domElements.labelText;

    return new Promise((resolveTop, rejectTop) => {
      html2canvas(domElement, { allowTaint: true, backgroundColor: labelData?.box_color })
        .then((canvas: HTMLCanvasElement) => {
          return new Promise<HTMLCanvasElement>(resolve => {
            canvas.toBlob(blob => {
              if (blob) {
                labelData.box_image = blob;
              }
              resolve(canvas);
            }, 'image/png');
          });
        })
        .then((canvas: HTMLCanvasElement) => {
          const overlay = new Overlay({
            position: this.lonLat || labelData?.box_lonlat,
            element: canvas,
            stopEvent: false
          });

          const updatedLonLat = overlay.getPosition();
          let editedExtent;
          if (updatedLonLat) {
            const lonLatString = updatedLonLat[0];
            editedExtent = [
              lonLatString,
              updatedLonLat[1] - this.textareaHeightAdjusted,
              lonLatString + this.textareaWidthAdjusted,
              updatedLonLat[1]
            ];
          }

          const data: LabelDataProps = {
            ...labelData,
            box_lonlat: this.lonLat || labelData?.box_lonlat,
            width: this.textareaWidthAdjusted || labelData?.width,
            height: this.textareaHeightAdjusted || labelData?.height,
            extent: this.textareaWidthAdjusted || this.textareaWidthAdjusted ? editedExtent : labelData?.extent
          };

          overlay.set('labelBoxData', data);
          this.mapObj.map.addOverlay(overlay);
          outputMap.updateOverlays();

          if (this.isToolActive) {
            if (Object.prototype.hasOwnProperty.call(data, 'action')) {
              this.notifyObservers(TOOL_EVENT.UPDATE_LABEL_BOX, data);
            }

            this.lonLat = null;
            this.selectedOverlay = null;
            toolController.dispatchEvent(new CustomEvent('label-box-text', { detail: '' }));

            if (this.editedOverlay) {
              this.domElements.deleteBtn.style.display = 'none';
              this.domElements.copyBtn.style.display = 'none';
              this.editedOverlay = null;
            }
            canvas.addEventListener('mouseenter', this.handleMouseEnter);
            canvas.addEventListener('mouseout', this.handleMouseOut);
            canvas.addEventListener('dblclick', this.editLabelBox);
            canvas.addEventListener('mousedown', this.handleMouseDown);

            this.hideContainer();
            changeMapCursor(false, '', 'crosshair');
            this.draw.setActive(true);
            this.dragPan.setActive(true);
          }
        })
        .catch(err => {
          rejectTop(err);
        })
        .finally(() => {
          resolveTop('done');
        });
    });
  };

  handleMouseEnter = (e: Event) => {
    e.preventDefault();
    if (this.draw.getActive()) {
      this.draw.setActive(false);
      this.lonLat = null;
    }
    if (!(this.domElements.container.style.display === 'block')) {
      changeMapCursor(false, '', 'move');
    }
  };

  handleMouseOut = (e: Event) => {
    e.preventDefault();
    if (!(this.domElements.container.style.display === 'block')) {
      this.draw.setActive(true);
      changeMapCursor(false, '', 'crosshair');
    }
  };

  handleMouseDown = (e: Event) => {
    e.preventDefault();
    if (this.domElements.container.style.display === 'block' || !this.selectedOverlay) return;

    this.dragPan.setActive(false);
    this.selectedOverlay && this.selectedOverlay.set('dragging', true);
  };

  handlePointerMove = (evt: MapBrowserEvent<MouseEvent>) => {
    if (this.domElements.container.style.display === 'block') return;
    this.copyLonlat = evt.coordinate;
    evt.preventDefault();
    if (this.dragPan.getActive()) {
      const overlays = this.mapObj.map.getOverlays().getArray();
      overlays.find((overlay: any) => {
        if (overlay.get('labelBoxData')) {
          const { extent } = overlay.get('labelBoxData');
          if (containsCoordinate(extent, evt.coordinate)) {
            this.selectedOverlay = overlay;
            return overlay;
          }
        }
        return null;
      });
    }
    if (this.selectedOverlay && this.selectedOverlay.get('dragging') === true) {
      this.selectedOverlay.setPosition(evt.coordinate);
      this.positionChanged = true;
    }
  };

  handlePointerUp = (evt: MapBrowserEvent<MouseEvent>) => {
    if (this.selectedOverlay && this.selectedOverlay.get('dragging') === true) {
      this.dragPan.setActive(true);
      this.selectedOverlay.set('dragging', false);

      const labelBoxData = this.selectedOverlay.get('labelBoxData');

      if (this.positionChanged) {
        this.positionChanged = false;

        const { extent } = labelBoxData;
        const deltaLon = evt.coordinate[0] - labelBoxData.box_lonlat[0];
        const deltaLat = evt.coordinate[1] - labelBoxData.box_lonlat[1];

        const newExtent = [+extent[0] + deltaLon, +extent[1] + deltaLat, +extent[2] + deltaLon, +extent[3] + deltaLat];

        const updatedLabelBoxData = {
          ...labelBoxData,
          extent: newExtent,
          box_lonlat: evt.coordinate
        };

        this.selectedOverlay.set('labelBoxData', updatedLabelBoxData);

        this.notifyObservers(TOOL_EVENT.UPDATE_LABEL_BOX, {
          ...updatedLabelBoxData,
          action: LABEL_ACTIONS.MOVED
        });
      }
    }
  };

  editLabelBox = () => {
    if (!this.selectedOverlay || this.domElements.container.style.display === 'block') return;

    this.mapObj.map.removeInteraction(this.mouseWheelZoom);
    this.draw.setActive(false);
    this.dragPan.setActive(false);

    const {
      text,
      text_color,
      text_size,
      box_color,
      width: intialWidth,
      height: initialHeight,
      box_lonlat
    } = this.selectedOverlay.get('labelBoxData');
    toolController.dispatchEvent(new CustomEvent('label-box-text', { detail: text }));
    this.lonLat = box_lonlat;
    this.editedOverlay = this.selectedOverlay;
    this.mapObj.map.removeOverlay(this.selectedOverlay);

    const mapContainer = document.getElementById('map');

    if (mapContainer) {
      const resolution = this.mapObj.map.getView().getResolution();
      const scaleFactor = 1 / resolution;

      const width = intialWidth * scaleFactor;
      const height = initialHeight * scaleFactor;
      this.domElements.textArea.value = text;
      this.domElements.deleteBtn.style.display = 'block';
      this.domElements.copyBtn.style.display = 'block';

      this.addDomElementsStyle({
        mapContainer,
        box_lonlat,
        width,
        height,
        box_color,
        text_color,
        text_size
      });
    }
  };

  addDomElementsStyle = ({
    mapContainer,
    box_lonlat,
    width,
    height,
    box_color,
    text_color,
    text_size
  }: DomElementsStyleProps) => {
    const mapContainerRect = mapContainer.getBoundingClientRect();
    const boxCoordinates = this.mapObj.map.getPixelFromCoordinate(box_lonlat);

    this.domElements.container.style.left = `${parseInt(mapContainerRect.left + boxCoordinates[0], 10)}px`;
    this.domElements.container.style.top = `${parseInt(mapContainerRect.top + boxCoordinates[1], 10)}px`;
    this.domElements.textArea.style.width = `${width}px`;
    this.domElements.textArea.style.height = `${height}px`;
    this.domElements.textArea.style.backgroundColor = box_color || (this.isBlueprint ? '#e8e8e8' : '#ffffff');
    this.domElements.textArea.style.color = text_color || '#000000';
    this.domElements.textArea.style.fontSize = `${text_size || 12}px`;
    this.domElements.textColor.value = text_color || '#000000';
    this.domElements.textColorSelector.style.backgroundColor = text_color || '#000000';
    this.domElements.textSize.value = text_size || 12;
    this.domElements.textBoxColor.value = box_color || (this.isBlueprint ? '#e8e8e8' : '#ffffff');
    this.domElements.textBoxColorSelector.style.fill = '#000000';
    this.domElements.textArea.style.display = 'block';
    this.domElements.container.style.display = 'block';
    this.domElements.textArea.focus();
  };

  addEditLabelBox() {
    const resolution = this.mapObj.map.getView().getResolution();
    const scaleFactor = 1 / resolution;
    const textAreaRect = this.domElements.textArea.getBoundingClientRect();
    this.textareaWidthAdjusted =
      textAreaRect.width + (textAreaRect.width - textAreaRect.width * scaleFactor) / scaleFactor;
    this.textareaHeightAdjusted =
      textAreaRect.height + (textAreaRect.height - textAreaRect.height * scaleFactor) / scaleFactor;

    this.domElements.labelText.style.display = 'block';
    this.domElements.labelText.style.width = `${textAreaRect.width}px`;
    this.domElements.labelText.style.height = `${textAreaRect.height}px`;
    this.domElements.labelText.style.color = this.domElements.textColor.value;
    this.domElements.labelText.style.backgroundColor = this.domElements.textBoxColor.value;
    this.domElements.labelText.style.fontSize = `${this.domElements.textSize.value}px`;
    this.domElements.textArea.style.display = 'none';

    let labelBoxData: LabelDataProps;
    if (this.editedOverlay) {
      if (this.mouseWheelZoom) this.mapObj.map.addInteraction(this.mouseWheelZoom);

      labelBoxData = {
        ...this.editedOverlay.get('labelBoxData'),
        ...this.getLabelData(),
        width: textAreaRect.width,
        height: textAreaRect.height,
        action: LABEL_ACTIONS.EDITED
      };
    } else {
      labelBoxData = {
        ...this.getLabelData(),
        id: uuid4(),
        width: textAreaRect.width,
        height: textAreaRect.height,
        action: LABEL_ACTIONS.CREATED
      };
    }
    return this.addLabelBox(labelBoxData);
  }

  getLabelData() {
    const labelData = {
      text: this.domElements.textArea.value,
      text_color: this.domElements.textColor.value,
      text_size: this.domElements.textSize.value,
      box_color: this.domElements.textBoxColor.value
    };

    return labelData;
  }

  deleteLabelBox() {
    if (this.selectedOverlay) {
      const data = { ...this.selectedOverlay.get('labelBoxData'), action: LABEL_ACTIONS.DELETED };
      this.notifyObservers(TOOL_EVENT.UPDATE_LABEL_BOX, data);
      this.mapObj.map.removeOverlay(this.selectedOverlay);
      this.selectedOverlay = null;
      this.editedOverlay = null;
      this.hideContainer();
    }
  }

  copyLabelBox() {
    if (this.selectedOverlay) {
      this.copiedLabelBoxData = { ...this.editedOverlay.get('labelBoxData') };
      this.copiedLabelElement = this.editedOverlay.getElement();
      this.hideContainer();
    }
  }

  addListeners = (overlay: any) => {
    const element = overlay.getElement();

    element.addEventListener('mouseenter', this.handleMouseEnter);
    element.addEventListener('mouseout', this.handleMouseOut);
    element.addEventListener('dblclick', this.editLabelBox);
    element.addEventListener('mousedown', this.handleMouseDown);
  };

  hideContainer() {
    if (this.editedOverlay) {
      this.mapObj.map.addOverlay(this.editedOverlay);
      this.domElements.deleteBtn.style.display = 'none';
      this.domElements.copyBtn.style.display = 'none';
      this.editedOverlay = null;
    }
    if (this.mouseWheelZoom) this.mapObj.map.addInteraction(this.mouseWheelZoom);
    this.draw.setActive(true);
    this.dragPan.setActive(true);
    this.lonLat = null;
    this.selectedOverlay = null;
    this.domElements.container.style.display = 'none';
    this.domElements.textArea.value = '';
    this.domElements.labelText.style.display = 'none';
    this.domElements.labelText.innerHTML = '';
    changeMapCursor(false, '', 'crosshair');
  }

  toggleLabelBoxes(val: boolean) {
    const overlays = this.mapObj.map.getOverlays().getArray();

    overlays.forEach((overlay: any) => {
      if (overlay.get('labelBoxData')) {
        overlay.getElement().style.display = val ? 'flex' : 'none';
      }
    });
  }

  removeOverlayEventListeners() {
    const overlays = this.mapObj.map.getOverlays().getArray();
    overlays.forEach((overlay: any) => {
      if (overlay.get('labelBoxData')) {
        overlay.getElement().removeEventListener('mouseenter', this.handleMouseEnter);
        overlay.getElement().removeEventListener('mouseout', this.handleMouseOut);
        overlay.getElement().removeEventListener('dblclick', this.editLabelBox);
        overlay.getElement().removeEventListener('mousedown', this.handleMouseDown);
      }
    });
  }

  off() {
    if (this.isToolActive) {
      this.removeOverlayEventListeners();
      this.hideContainer();
      this.isToolActive = false;
    }
    if (this.draw) {
      this.mapObj.map.removeInteraction(this.draw);
      this.draw.un('drawend', this.handleDrawEnd);
    }
    this.mapObj.map.un('singleclick', this.onMapClick);
    this.mapObj.map.un('pointermove', this.handlePointerMove);
    this.mapObj.map.un('pointerup', this.handlePointerUp);
    const mapContainer = document.getElementById('map');
    if (mapContainer) {
      mapContainer.style.cursor = 'default';
    }
    if (this.dblClickZoom) {
      this.mapObj.map.addInteraction(this.dblClickZoom);
      this.dblClickZoom = null;
    }
    if (this.mouseWheelZoom) {
      this.mapObj.map.addInteraction(this.mouseWheelZoom);
      this.mouseWheelZoom = null;
    }
    this.selectedOverlay = null;
    this.copiedLabelBoxData = null;
    document.removeEventListener('keydown', this.duplicateOverlay);
  }
}

export default LabelBox;
