import { css } from '@emotion/css';

export const modalOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
`;

export const modalWrapper = css`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  width: 500px;
  max-height: 90%;
`;

export const modalContent = css`
  text-align: center;
`;

export const modalButtons = css`
  margin-top: 20px;
`;

export const button = css`
  margin: 0 10px;
  padding: 5px 10px;
`;

export const layerContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  max-height: 500px;
  overflow: scroll;
  flex: 1;
`;

export const layerContainerItem = css`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  width: 100%;
`;

export const layerItemIcons = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const layerItemName = css`
  margin-left: 30px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

export const searchContainer = css`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
`;

export const searchContainerCheckbox = css`
  display: flex;
  align-items: center;
`;
