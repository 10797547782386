import pointsWithinPolygon from "@turf/points-within-polygon";
import { point, Position } from "@turf/helpers";
import {
  FeatureCollection,
  Geometry,
  MultiPolygon,
  Feature,
  Polygon,
  Properties,
} from "@turf/turf";
import VectorImageLayer from "ol/layer/VectorImage";
import { Layer } from "woodpecker";

export const zoomToExtent = (extent: any, map: any) => {
  map?.getView().fit(extent, {
    duration: 300,
    maxZoom: 21,
    size: map?.getSize(),
  });
};

export const isPointWithinPolygon = (
  coord: Position,
  poly:
    | Polygon
    | MultiPolygon
    | Feature<Polygon | MultiPolygon, Properties>
    | FeatureCollection<Polygon | MultiPolygon, Properties>
) => {
  const fc = pointsWithinPolygon(point(coord), poly);
  return fc.features.length;
};

export const generateUniqueID = (salt: string = "") => {
  // you can use a timestamp or a UUID generator
  return "id-" + Date.now().toString(36) + Math.random().toString(36);
};

export const hideUnhideLayersMap = (
  map: any,
  hiddenLayersId: string[],
  data: Layer[]
) => {
  data?.forEach((layer: Layer) => {
    const vector_layer = map
      .getAllLayers()
      .find((v_layer: any) => v_layer.get("id") === layer.id);
    const isHidden: boolean = hiddenLayersId?.indexOf(layer.id as string) >= 0;
    if (!vector_layer) return;
    vector_layer.setVisible(!isHidden);
  });
};
