import { GEOMETRY_TYPES, MAP_TYPE } from 'woodpecker';
import { drawStyle, labelStyle } from '../../../hooks/tools/helpers/styles';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import Draw from 'ol/interaction/Draw';
import { formatArea } from '../../../hooks/tools/helpers';
import { fromCircle } from 'ol/geom/Polygon';
import { generateUniqueID } from 'macaw';
import { isOutOfExtent, triggerOverrideOverlap } from '../../../helpers/helpers';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { Circle } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';

const DEFAULT_RADIUS = 0.762; // it is in meters which is equal to 2.5 feet because 1ft=0.3048m

class AddCircle extends ToolAbstract {
  private mapObj: MapBase;
  private layer: any;
  private draw: Draw | null;
  private maxRadius: number | null;
  private defaultLimitFlag: boolean;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.maxRadius = null;
    this.defaultLimitFlag = false;
  }

  init(id: string): void {
    this.layer = this.mapObj.getLayerById(id);

    if (this.layer) {
      const source = this.layer.getSource();
      this.draw = new Draw({
        source: source,
        type: GEOMETRY_TYPES.CIRCLE as Type,
        style: (feature: any) => this.styleFunction(feature),
        dragVertexDelay: 0,
        snapTolerance: 1,
        maxPoints: 2,
        geometryFunction: this.geometryFunction,
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        }
      });

      this.mapObj.map?.addInteraction(this.draw);
      this.draw?.on('drawend', this.onDrawEnd);
      this.draw?.on('drawstart', this.onDrawStart);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  }

  styleFunction = (feature: any) => {
    let styles = [drawStyle()];
    const { scale, dpi } = globalStore.AppStore.worksheetParams;
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.CIRCLE && scale !== null) {
      //disable/enable live measurement
      if (this.mapObj.map_type === MAP_TYPE.AERIAL && !globalStore?.AppStore?.tool?.live_measurement) {
        return styles;
      }

      const label = formatArea(geometry, dpi, scale);
      const _labelStyle = labelStyle.clone();
      _labelStyle.setGeometry(geometry);
      _labelStyle.getText().setText(label);
      styles.push(_labelStyle);
    }
    return styles;
  };

  keyDownHandler = (e: KeyboardEvent) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  onDrawEnd = (event: any) => {
    const circle = fromCircle(event.feature.getGeometry(), 48);
    event.feature.setGeometry(circle);
    const unq_id = generateUniqueID('circular-poly');
    event.feature.setId(unq_id);
    const feature = event.feature;
    setTimeout(() => {
      triggerOverrideOverlap(feature);
      undoRedoPush();
    }, 0);
  };

  calculateRadius = (coordinates: any[]) => {
    const center = coordinates[0];
    const last = coordinates[coordinates.length - 1];
    const dx = center[0] - last[0];
    const dy = center[1] - last[1];
    return Math.sqrt(dx * dx + dy * dy);
  };

  onDrawStart = (e: any) => {
    const circularFeature = e.feature;
    const center = circularFeature?.getGeometry().getCenter();

    const XLength = Math.min(Math.abs(center[0]), globalStore.AppStore.extendCoordinates[2] - Math.abs(center[0]));
    const YLength = Math.min(
      Math.abs(center[1]),
      Math.abs(globalStore.AppStore.extendCoordinates[1]) - Math.abs(center[1])
    );

    this.maxRadius = Math.min(XLength, YLength);
  };

  geometryFunction = (coordinates: any, geometry: any) => {
    let radius = DEFAULT_RADIUS;
    if (!geometry) {
      geometry = new Circle(coordinates[0], radius);
    } else {
      if (this.maxRadius === null) return;
      radius = this.calculateRadius(coordinates);
      if (radius > DEFAULT_RADIUS) {
        this.defaultLimitFlag = true;
      }

      if (this.defaultLimitFlag) {
        if (this.maxRadius && radius >= this.maxRadius) {
          radius = this.maxRadius;
        }

        geometry.setRadius(radius);
      }
    }
    return geometry;
  };

  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.draw?.un('drawend', this.onDrawEnd);
    this.draw?.un('drawstart', this.onDrawStart);
    window.removeEventListener('keydown', this.keyDownHandler);
  }
}

export default AddCircle;
