import { v4 as uuid4 } from 'uuid';

import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Modify, Select } from 'ol/interaction';
import Draw from 'ol/interaction/Draw';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';
import RegularShape from 'ol/style/RegularShape';
import Fill from 'ol/style/Fill';

import { LAYER_INDEX, MAP_LAYERS } from '../../../Constants/Constant';
import { layerTracker, outputMap } from '../MapInit';
import { MODIFY_STYLE } from '../MapBase';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { getNewLayerWidth } from '../../../Utils/olutils';

class ArrowTool extends Observer {
  arrowLayer: $TSFixMe;

  arrowVisibility: $TSFixMe;

  bpSheetId: $TSFixMe;

  currentStyle: $TSFixMe;

  draw: $TSFixMe;

  isDrawing: $TSFixMe;

  mapObj: $TSFixMe;

  modify: $TSFixMe;

  select: $TSFixMe;

  constructor(mapObj: $TSFixMe) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.arrowLayer = null;
    this.select = null;
    this.arrowVisibility = true;
    this.isDrawing = false;
    this.currentStyle = { color: '#4361ee', width: 3 };
    this.bpSheetId = null;
  }

  on() {
    this.arrowLayer = this.mapObj.getLayerById(MAP_LAYERS.ARROW);
    if (this.mapObj.isBlueprintMap) this.bpSheetId = this.mapObj.baseLayer?.getProperties()?.bp_page_id;
    const src = this.arrowLayer.getSource();
    this.draw = new Draw({
      type: 'LineString',
      source: src,
      condition: e => {
        const mouseClick = e.originalEvent.button;
        if (mouseClick === 2 || mouseClick === 1) {
          return false;
        }
        return true;
      },
      snapTolerance: 1,
      ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
    });

    this.select = new Select({
      // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: (featu... Remove this comment to see the full error message
      wrapX: false,
      filter: (feature, layer) => {
        // Do not select parcel layer while editing features
        if (layer?.get('id') === 'arrow-layer') {
          this.draw.abortDrawing();
          return true;
        }
        return false;
      },
      style: MODIFY_STYLE
    });

    this.modify = new Modify({
      features: this.select.getFeatures(),
      snapToPointer: true
    });

    outputMap.setVisibility(MAP_LAYERS.ARROW, true);
    this.mapObj.map.addInteraction(this.draw);
    this.mapObj.map.addInteraction(this.select);
    this.mapObj.map.addInteraction(this.modify);
    this.draw.on('drawstart', () => {
      this.isDrawing = true;
    });
    this.draw.on('drawend', () => {
      this.isDrawing = false;

      layerTracker.push(MAP_LAYERS.ARROW, MAP_LAYERS.ARROW);
      this.notifyObservers(TOOL_EVENT.ARROW_TOOL);
    });
    document.addEventListener('keydown', this.onKeyPress);
  }

  styleFunction = ({ feature, color = null, width = null }: $TSFixMe) => {
    const geometry = feature.getGeometry();
    const coords = geometry.getCoordinates();
    const [start, end] = [coords[coords.length - 2], coords[coords.length - 1]];

    const styles = [
      new Style({
        stroke: new Stroke({ ...this.currentStyle, ...(color && { color }), ...(width && { width }) })
      })
    ];

    if (!start) return styles;

    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const rotation = Math.atan2(dy, dx);

    const arrowRadius = 2 * (width || this.currentStyle?.width || 0);

    styles.push(
      // arrow pointer
      new Style({
        geometry: new Point(end),
        image: new RegularShape({
          fill: new Fill({ color: color || this.currentStyle?.color }),
          points: 3,
          radius: arrowRadius,
          rotation: -rotation,
          angle: Math.PI / 2 // rotate 90°
        })
      })
    );

    return styles;
  };

  updateColor = (color: $TSFixMe) => {
    this.currentStyle = { ...this.currentStyle, color };
  };

  updateWidth = (width: $TSFixMe) => {
    this.currentStyle = { ...this.currentStyle, width };
  };

  getStyle = () => {
    return { style: this.currentStyle };
  };

  onKeyPress = (event: $TSFixMe) => {
    if (event.stopPropagation) event.stopPropagation();

    const KeyID = event.keyCode;
    if (KeyID === 8 && this.isDrawing) {
      this.draw.removeLastPoint();
    }
    if (KeyID === 27) {
      this.draw.abortDrawing();
    }
    if (KeyID === 46 || KeyID === 8) {
      this.select
        .getFeatures()
        .getArray()
        .forEach(
          (feature: $TSFixMe) =>
            this.arrowLayer?.getSource()?.hasFeature(feature) && this.arrowLayer.getSource().removeFeature(feature)
        );
    }
  };

  getArrowsGeojson = () => {
    const arrowLayer = this.mapObj.getLayerById(MAP_LAYERS.ARROW);
    const geojson = outputMap.getGeojsonByLayer(arrowLayer);
    const layerId = arrowLayer?.get('arrowRequestId');

    const data = { id: layerId, geojson, style: this.currentStyle };

    return data;
  };

  loadArrows = (arrows: $TSFixMe) => {
    if (arrows?.style) this.currentStyle = arrows.style;

    // while loading arrow layer we're changing the width according to map resolution
    const { isBlueprintMap } = this.mapObj;
    const resolution = this.mapObj.map.getView().getResolution();
    const width = getNewLayerWidth({ width: this.currentStyle?.width, isBlueprintMap, resolution });
    this.updateWidth(width);

    const prevLayer = this.mapObj.getLayerById(MAP_LAYERS.ARROW);
    if (prevLayer) {
      this.mapObj.removeLayer(prevLayer);
    }
    let features;
    if (arrows?.geojson) {
      features = this.mapObj.isBlueprintMap
        ? new GeoJSON().readFeatures(arrows.geojson)
        : new GeoJSON().readFeatures(arrows.geojson, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          });
    } else {
      features = new GeoJSON().readFeatures({ type: 'FeatureCollection', features: [] });
    }

    const src = new VectorSource({ features, wrapX: false });

    src.forEachFeature(f => {
      f.setProperties({ layerId: MAP_LAYERS.ARROW }, true);
    });

    const layer = new VectorLayer({
      // @ts-expect-error TS(2345): Argument of type '{ id: string; name: string; laye... Remove this comment to see the full error message
      id: MAP_LAYERS.ARROW,
      name: MAP_LAYERS.ARROW,
      layerData: { name: MAP_LAYERS.ARROW, style: this.styleFunction },
      source: src,
      style: feature => this.styleFunction({ feature }),
      arrowRequestId: arrows?.id ?? uuid4(),
      zIndex: LAYER_INDEX.ARROW
    });
    layer.setVisible(this.arrowVisibility);
    this.mapObj.addLayer(layer);
  };

  setArrowVisibility = (val: $TSFixMe) => {
    this.arrowVisibility = val;
    outputMap.setVisibility(MAP_LAYERS.ARROW, val);
  };

  off() {
    if (this.draw) this.mapObj.map.removeInteraction(this.draw);
    if (this.select) this.mapObj.map.removeInteraction(this.select);
    if (this.modify) this.mapObj.map.removeInteraction(this.modify);
    outputMap.setVisibility(MAP_LAYERS.ARROW, this.arrowVisibility);
  }
}

export default ArrowTool;
