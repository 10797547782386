import { create } from 'zustand';
import { CurrentJob, CurrentSheet } from 'woodpecker';
import { DEFAULT_TOOL, Tool } from 'woodpecker';
import { Feature as olFeature, Map as olMap } from 'ol';
import { Projection } from 'ol/proj';
import { Select } from 'ol/interaction';
import Overlay from 'ol/Overlay';

export type Layer = {
  default_tags: TagsInfoType;
  final_geojson: { type: string; features: Feature[]; properties: { [x: string]: string } };
  name?: string;
  layer_type?: string;
  original?: any;
  meta_data: { color: string; 'z-index': number };
  id?: string;
  data_status?: number;
  original_json: {
    type: string;
    properties: { [x: string]: any };
    default_tags: {};
    geometry_type: number;
    features: Feature[];
  };
  is_editable?: boolean;
  geometry_type?: number;
};

export type Feature = olFeature & {
  id: string;
  type: string;
  geometry: {
    type: string;
    coordinates: number[][][][];
  };
  properties: { id: string | number; tags_info?: {}; vector_layer_id?: string; [x: string]: any } | null;
};

type KeyType =
  | 'count'
  | 'length'
  | 'area'
  | 'perimeter'
  | 'edit_count'
  | 'edit_length'
  | 'edit_area'
  | 'edit_perimeter';

export type TagsInfoType = {
  [tagTypeId: string]: {
    tagId: string;
    subtagId: string | null;
    isFeatureTag?: boolean;
  };
};

export type TooltipDataType = {
  id: string;
  name: string;
  color: string;
  geometry_type: number;
  properties: {
    key: KeyType;
    value: number;
  }[];
  tags_info: TagsInfoType;
  default_tags: TagsInfoType;
};

export type ToolsSetting = {
  allowSelectionAcrossLayers: boolean;
  showVertices: boolean;
  showLiveMeasurements: boolean;
  overrideOverlap: boolean;
  avoidOverlap: boolean;
};

export type mapStoreVariable = {
  map: olMap | null;
  bptProjection: Projection | null;
  stackRef: any[];
  onsaveData: null | (() => void);
  current_job_data: CurrentJob | null | CurrentSheet;
  comments: Array<any>;
  selectedComment: any;
  layerFeatureCount: any;
  isBlueprint: boolean;
  stackPointerRef: number;
  toolTipData: TooltipDataType[];
  overlay: Overlay | null;
  extendCoordinates: any[];
  addScaleLength: number;
  worksheetParams: { scale: number; dpi: number; convertScale: number };
  avoidOverlap: boolean;
  toolBarOverlay: Overlay | null;
  selectedFeaturesLayer: Set<string>;
  selectObjRef: Select | null;
  tool: Tool;
  layers: Map<string, any>;
  current_layers: Array<Layer>;
  selectedLayer: any;
  selectedFeatures: Feature[] | null;
  isUpdatingForm: boolean;
  undoRedoIns: any;
  mapObj: any;
  tilesData: any;
  ortho_mode: boolean;
  selectedLayerID: string;
  arc_mode: boolean;
  notVisibleFeatures: string[];
  measurementSystem: number;
  featurePropsAtContext: any[] | null;
  isRotationAngleChanged: boolean;
  isDrawingPolygonOrPolyline: boolean;
  toolsSetting: ToolsSetting;
};

export interface mapStore extends mapStoreVariable {
  setCurrentJob: (current_job_data: CurrentJob | CurrentSheet) => void;
  setOverLay: (data: Overlay) => void;
  setToolBarOverLay: (data: Overlay) => void;
  setComments: (data: any[]) => void;
  setSelectedComment: (data: any) => void;
  setToolTipData: (data: any) => void;
  setBptProjection: (projection: Projection) => void;
  populateMap: (mapGenerated: olMap | null) => void;
  removeMap: (state: any) => void;
  setTool: (tool: Tool) => void;
  populateLayers: (layers: Map<string, any>) => void;
  setSelectedLayer: (layer: Layer) => void;
  setCurrentLayers: (layers: Layer[]) => void;
  setSelectedFeatures: (features: Feature[]) => void;
  updateState: (updatedState: any) => void;
  updateStateWithPrevValue: (updateCallback: any) => void;
  resetUndoRedo: () => void;
  getState: (callBack: (...args: any) => void) => void;
  setisBluePrint: (value: boolean) => void;
  setOnsaveData: (fn: () => void) => void;
  setShowModalLineScale: () => void;
  setLayerFeatureCount: (data: any) => void;
  setUndoRedoIns: (data: any) => void;
  setMapObj: (data: any) => void;
  setNotVisibleFeatures: (sheet: string[] | ((value: string[]) => string[])) => void;
  setIsUpdatingForm: (isUpdatingForm: boolean) => void;
  setToolsSetting: (value: ToolsSetting) => void;
  selectedFeaturesLayer: Set<string>;
  selectObjRef: Select | null;
  undoRedoIns: any;
  mapObj: any;
  measurementSystem: number;
  setMeasurementSystem: (data: number) => void;
  tilesData: any;
  setTilesData: (data: any) => void;
  setIsRotationAngleChanged: (angle: boolean) => void;
  setIsDrawingPolygonOrPolyline: (data: boolean) => void;
}

export const DEFAULT_SELECTED_TOOL = {
  ...DEFAULT_TOOL,
  snapping_mode: true,
  edge_snapping: true,
  feature_tracing: false,
  snap_tolerance: 10,
  avoid_overlap: false,
  clickId: Math.random().toString(36).substring(7)
};

const InitialData: mapStoreVariable = {
  map: null,
  layerFeatureCount: null,
  bptProjection: null,
  current_job_data: null,
  comments: [],
  selectedComment: null,
  stackRef: [],
  isBlueprint: false,
  stackPointerRef: -1,
  toolTipData: [],
  overlay: null,
  extendCoordinates: [],
  addScaleLength: 1,
  worksheetParams: { scale: 1, dpi: 200, convertScale: 1 },
  avoidOverlap: false,
  current_layers: [],
  onsaveData: null,
  tool: {
    ...DEFAULT_SELECTED_TOOL,
    clickId: Math.random().toString(36).substring(7)
  },
  // Selected Features
  selectedFeatures: [],
  isUpdatingForm: false,
  layers: new Map(),
  selectedLayer: {},
  selectedFeaturesLayer: new Set<string>(),
  toolBarOverlay: null,
  selectObjRef: null,
  undoRedoIns: {},
  mapObj: {},
  tilesData: {},
  ortho_mode: false,
  arc_mode: false,
  selectedLayerID: '',
  notVisibleFeatures: [],
  measurementSystem: 1,
  isDrawingPolygonOrPolyline: false,
  featurePropsAtContext: null,
  isRotationAngleChanged: false,
  toolsSetting: {
    allowSelectionAcrossLayers: false,
    showVertices: false,
    showLiveMeasurements: false,
    overrideOverlap: false,
    avoidOverlap: false
  }
};

const useMapStore = create<mapStore>((set, get) => ({
  ...InitialData,
  /**set functions**/
  setLayerFeatureCount: (data: any) =>
    set((state: any) => ({
      layerFeatureCount: { ...state.layerFeatureCount, ...data }
    })),
  setShowModalLineScale: () => {},
  setComments: (comments: any) => set({ comments }),
  setSelectedComment: (comment: any) => set({ selectedComment: comment }),
  setTool: (tool: Tool) => set((state: any) => ({ ...state, tool: { ...state.tool, ...tool } })),
  setCurrentLayers: (layers: Layer[]) => set({ current_layers: layers }),
  setCurrentJob: (current_job_data: CurrentJob | CurrentSheet) => set((state: any) => ({ ...state, current_job_data })),
  setOverLay: (data: any) => set((state: any) => ({ ...state, overlay: data })),
  setToolBarOverLay: (data: any) => set((state: any) => ({ ...state, toolBarOverlay: data })),
  setToolTipData: (data: any) => set((state: any) => ({ ...state, toolTipData: data })),
  setBptProjection: (projection: any) => set((state: any) => ({ ...state, bptProjection: projection })),
  populateMap: (mapGenerated: olMap | null) => set((state: any) => ({ ...state, map: mapGenerated })),
  removeMap: (state: any) => set({ ...state, map: null }),
  populateLayers: (layers: Map<string, any>) => set((state: any) => ({ ...state, layers })),
  setSelectedLayer: (layer: Layer) => set((state: any) => ({ ...state, selectedLayer: layer })),
  setSelectedFeatures: (features: any[]) => set((state: any) => ({ ...state, selectedFeatures: features })),
  setIsUpdatingForm: (isUpdatingForm: boolean) => set((state: any) => ({ ...state, isUpdatingForm })),
  updateState: (updatedState: any) => set((state: any) => ({ ...state, ...updatedState })),
  updateStateWithPrevValue: (updateCallback: any) => set((state: any) => updateCallback({ ...state })),
  resetUndoRedo: () => set((state: any) => ({ ...state, stackRef: [], stackPointerRef: -1 })),
  getState: (callBack: (...args: any) => void) => {
    const params = get() as any;
    callBack({ ...params });
  },
  setisBluePrint: (value: boolean) => set({ isBlueprint: value }),
  setOnsaveData: (fn: () => void) => set({ onsaveData: fn }),
  setUndoRedoIns: (payload: any) => {
    set({ undoRedoIns: payload });
  },
  setMapObj: (payload: any) => set({ mapObj: payload }),
  setNotVisibleFeatures: list =>
    set(state => {
      let value: string[] = [];
      if (typeof list === 'function') value = list(state.notVisibleFeatures);
      else value = list;
      return {
        ...state,
        notVisibleFeatures: value
      };
    }),
  setMeasurementSystem: (data: number) => set((state: any) => ({ ...state, measurementSystem: data })),
  setTilesData: (payload: any) =>
    set({
      tilesData: payload
    }),
  setIsRotationAngleChanged: (flag: boolean) => set(state => ({ ...state, isRotationAngleChanged: flag })),
  setIsDrawingPolygonOrPolyline: (data: boolean) => set(state => ({ ...state, isDrawingPolygonOrPolyline: data })),
  setToolsSetting: (data: ToolsSetting) => {
    set(state => ({ ...state, toolsSetting: { ...state.toolsSetting, ...data } }));
  }
}));

export default useMapStore;
