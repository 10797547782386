import { css } from "@emotion/css";

export const navContainer = css`
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 0.3rem 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const baseRightContainer = css`
  display: flex;
  flex-grow: 2;
`;
export const logoContainer = css`
  display: flex;
  flex-grow: 2;
  align-items: center;
`;

export const centerAlign = css`
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const navItemsContainer = css`
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;

  @media (width <= 750px) {
    justify-content: start;
    align-items: start;
    font-size: 0.6rem;
  }
`;

export const navItems = css`
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.85rem;

  @media (width <= 750px) {
    font-size: 0.6rem;
  }

  a {
    text-decoration: none;
    color: black;
  }
`;

export const navItemsActive = css`
  background-color: #e1f4ea;
  font-weight: bold;
  color: #2d764f;

  a {
    color: #2d764f;
    text-decoration: none;
  }
`;

export const leftContainer = css`
  padding: 0.2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
`;

export const logoutContainer = css`
  display: flex;
  column-gap: 10px;
  cursor: pointer;
  align-items: center;
`;
