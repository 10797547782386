export const DRAW_MODES = {
  ONE_CLICK: 'one-click-draw',
  FREEHAND: 'freehand-draw'
};

export const KEY_CODE = {
  DELETE: 46,
  RIGHT_CLICK: 3,
  SCROLL_CLICK: 2,
  LEFT_CLICK: 1
};
export const CONSTRUCTION_REQUEST_STATUS_ENUM = {
  DRAFT: 1,
  PROCESSING: 2,
  IN_REVIEW: 3,
  IN_PROGRESS: 4,
  COMPLETED: 5,
  FAILED: 6,
  RESUBMITTED: 8
};

export const EVENT_TYPE = {
  CLICK: 'click',
  POINTER_MOVE: 'pointermove',
  DOUBLE_CLICK: 'doubleclick',
  CONTEXTMENU: 'contextmenu'
};

export const LAWN_KEY_MAP = {
  1: 'F',
  2: 'B',
  3: 'L',
  4: 'R'
};

export const LAWN_ATTRIBUTE = 'LawnType';

export const PARCEL = 'parcel_layer';
export const BOUNDARY = 'parcel';

export const ACTION = {
  MERGE: 'merge',
  DELETE: 'delete',
  CUT: 'cut',
  COPY: 'copy',
  PASTE: 'paste',
  RIGHT_CLICK: 'right_click',
  CLIPPING: 'clipping',
  UNDO: 'undo',
  REDO: 'redo',
  LAWN_BED_MERGE: 'lawn_bed_merge'
};

export const AbstractSvg = (
  color: string
) => `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1275_35944)">
<path d="M7.784 14L8.204 10H4V8H8.415L8.94 3H10.951L10.426 8H14.415L14.94 3H16.951L16.426 8H20V10H16.216L15.796 14H20V16H15.585L15.06 21H13.049L13.574 16H9.585L9.06 21H7.049L7.574 16H4V14H7.784ZM9.795 14H13.785L14.205 10H10.215L9.795 14Z" fill="${color}"/>
</g>
<defs>
<clipPath id="clip0_1275_35944">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export const TypicalSvg = (color: string) => `<svg
  width="26"
  height="26"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M10.0006 15.2167L4.12271 18.5069L5.43548 11.9L0.48999 7.32658L7.17919 6.53346L10.0006 0.416748L12.8219 6.53346L19.5111 7.32658L14.5657 11.9L15.8784 18.5069L10.0006 15.2167Z"
    fill="${color}"  />
</svg>`;

export const LineSvg = (
  color: string
) => `<svg width="6" height="23" viewBox="0 0 6 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="1.68378" y1="22.4931" x2="1.68378" y2="0.647686" stroke="${color}" stroke-width="2"/>
</svg>
`;

export const ZERO_LENGTH = '0.0 ft';
