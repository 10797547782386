import { captureException } from '@sentry/react';
import message from 'antd/es/message';
import booleanIntersects from '@turf/boolean-intersects';

import union from '@turf/union';
import clone from '@turf/clone';

import { featureCollection } from '@turf/helpers';

import GeoJSON from 'ol/format/GeoJSON';
import Layer from 'ol/layer/Layer';
import Feature from 'ol/Feature';

import { useRequest } from '../../../Stores/Request';
import { layerTracker } from '../MapInit';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import MapBase from '../MapBase';

// MergeFeatures class for handling the merging of selected map features
class MergeFeatures extends Observer {
  layerInstance: Layer | null;

  mapObj: MapBase;

  selectedFeatures: Array<Feature>;

  hasNonIntersectingFeatures: boolean;

  // Constructor initializes the map object and sets default states
  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.layerInstance = null;
    this.selectedFeatures = [];
    this.hasNonIntersectingFeatures = false;
  }

  on() {
    this.selectedFeatures = useRequest.getState()?.selectedFeatures;
    if (this.selectedFeatures.length === 0) {
      message.error('No features selected to merge');
      return;
    }
    if (this.selectedFeatures.length === 1) {
      message.error('Only one feature selected, select more than one to merge');
      return;
    }

    // Ensure all selected features are from the same layer
    const layerId = this.selectedFeatures[0].get('layerId');
    const isSameLayer = this.selectedFeatures.every(feature => feature.get('layerId') === layerId);
    if (!isSameLayer) {
      message.error('Features selected are not from the same layer');
      return;
    }

    // Get the layer of the first feature
    this.layerInstance = this.mapObj.getLayerById(this.selectedFeatures[0].get('layerId'));
    this.mergeFeats();
  }

  // Helper method to remove features from a source
  removeFeatures = (source: any, features: Array<Feature>) => {
    if (!source) return;

    features.forEach(feature => {
      const featureId = feature.get('id');
      if (featureId) {
        const featureToRemove = source.getFeatures().find((f: Feature) => f.get('id') === featureId);
        if (featureToRemove) {
          source.removeFeature(featureToRemove);
        }
      }
    });
  };

  // Main method to merge the selected features
  mergeFeats = () => {
    const layerId = this.selectedFeatures[0].get('layerId');

    const geoJsonFormat = new GeoJSON();
    const projection = {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    };

    // Convert selected features to GeoJSON objects
    const allGeojsons = this.selectedFeatures.map(feature => {
      return geoJsonFormat.writeFeatureObject(feature, projection);
    });

    const mergedFeature = this.turfMerge(allGeojsons);

    // Check if any features failed to merge
    if (this.hasNonIntersectingFeatures) {
      message.error('Some of the features are not intersecting');
      return;
    }

    // If merging failed or resulted in invalid data, throw an error
    if (!mergedFeature || typeof mergedFeature !== 'object') {
      throw new Error('Merged feature is invalid or undefined.');
    }

    // Convert merged GeoJSON back into OpenLayers features
    const mergedGeojson = geoJsonFormat.readFeatures(mergedFeature, { featureProjection: 'EPSG:3857' });
    if (!mergedGeojson.length) {
      throw new Error('No features were created from the merged GeoJSON.');
    }

    const source = this.layerInstance?.getSource() as any;

    this.removeFeatures(source, this.selectedFeatures);

    source.addFeatures(mergedGeojson);

    // Track the merged layer and notify observers
    layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
    this.notifyObservers(TOOL_EVENT.FEATURE_ADDED);
  };

  // Helper method for merging features using Turf.js
  turfMerge = (features: any) => {
    let mergedFeature = clone(features[0]);
    let currentFeature;
    const unmergedFeatures = [];
    try {
      // Loop through all features and attempt to merge them
      for (let i = 1, len = features.length; i < len; i++) {
        currentFeature = features[i];
        if (currentFeature.geometry) {
          // If the features intersect, merge them using Turf.js
          if (booleanIntersects(mergedFeature, currentFeature)) {
            try {
              const { properties } = currentFeature;
              mergedFeature = union(mergedFeature, currentFeature);
              mergedFeature.properties = properties;
            } catch (err) {
              captureException(err);
            }
          } else {
            unmergedFeatures.push(currentFeature);
          }
        }
      }

      // If there were non-intersecting features, set the flag
      if (unmergedFeatures.length > 0) {
        this.hasNonIntersectingFeatures = true;
      }

      return featureCollection([mergedFeature]);
    } catch (err) {
      captureException(err);
      return undefined;
    }
  };

  // Method to clear the selected features and reset the state
  off() {
    this.layerInstance = null;
    this.selectedFeatures = [];
    this.hasNonIntersectingFeatures = false;
  }
}

export default MergeFeatures;
