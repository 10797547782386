import { captureException } from '@sentry/react';

import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import Icon from 'ol/style/Icon';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import Style from 'ol/style/Style';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, toLonLat } from 'ol/proj';
import { linear } from 'ol/easing';

import {
  BP_PREFIX,
  ENABLED_ICON_COLOR,
  LAYER_INDEX,
  MAP_LAYERS,
  NOTES_ACCESS_ENUM,
  AERIAL_NOTES_CATEGORY,
  AERIAL_NOTES_CATEGORY_COLOR,
  AERIAL_NOTES_CATEGORY_ENUM,
  NOTES_STATUS,
  NOTE_MEDIA_TYPE,
  PRIMARY_ICON_COLOR,
  TOOLS_ID,
  BPT_NOTES_CATEGORY_COLOR,
  BPT_NOTES_CATEGORY,
  BPT_NOTES_CATEGORY_ENUM
} from '../../../Constants/Constant';
import { getAPI, interpolate, patchAPI, openAPI, multipartAPI, postAPI } from '../../../Utils/ApiCalls';
import {
  BLUEPRINT_SHARED_REQUEST_NOTE,
  BLUEPRINT_SHARED_REQUEST_NOTES,
  REQUEST_NOTE,
  REQUEST_NOTES,
  REQUEST_NOTE_RESOLVE,
  SHARED_REQUEST_NOTE,
  SHARED_REQUEST_NOTES
} from '../../../Constants/Urls';
import { adjustOverlayPosition, changeMapCursor, getDateString } from '../../../Utils/HelperFunctions';
import { outputMap, toolController } from '../MapInit';
import { useRequest } from '../../../Stores/Request';
import { useUserDetails } from '../../../Stores/UserDetails';
import { htmlEncode } from '../OutputMap';
import NotesDataInterface, { Assignee, Media } from '../../../types/notesData';

class Notes {
  addCoord: any;

  domElements: any;

  isSharedView: boolean;

  mapObj: any;

  notesLayer: any;

  notesVisibility: boolean;

  requestId: string | null;

  selectedFeature: any;

  isBlueprint: boolean;

  constructor(mapObj: any) {
    this.mapObj = mapObj;
    this.notesLayer = null;
    this.domElements = null;
    this.selectedFeature = null; // Current Selected Feature
    this.requestId = null; // Output Request ID or Sharedview Request ID
    this.isSharedView = false; // Is tool running on sharedview
    this.addCoord = []; // Coordinate where new note will be added
    this.notesVisibility = true;
    this.isBlueprint = false;
  }

  on({ requestId, isSharedView = false }: { requestId: string; isSharedView: boolean }) {
    this.isSharedView = isSharedView;
    this.requestId = requestId;
    this.isBlueprint = this.mapObj.isBlueprintMap;

    this.mapObj.map.on('singleclick', this.onMapClick);

    this.domElements = {
      container: 'notes-container',
      addNotesContainer: 'add-notes',
      categoryStatus: 'category-select',
      noteStatus: 'note-access-select',
      viewImagesContainer: 'view-images-container',
      viewNotesContainer: 'view-notes',
      viewNotesText: 'view-notes-text',
      viewNotesIcon: 'notes-icon-color',
      viewIconText: 'view-icon-text',
      noteTypeHeader: 'note-type-header',
      noteAccessIcon: 'note-access',
      creatorName: 'creator-name',
      createdDate: 'created-date',
      nameAvatar: 'name-avatar',
      noteStatusIcon: 'note-status',
      viewEditBtn: 'view-edit-btn'
    };
  }

  getIsAddingNote() {
    return useRequest.getState()?.toolbar?.isAddingNote;
  }

  loadNotes = async ({ requestId, worksheetId = '' }: { requestId: string; worksheetId: string }) => {
    const { dispatch } = useRequest.getState() || {};

    const url = interpolate(
      this.isSharedView
        ? this.mapObj.isBlueprintMap
          ? BLUEPRINT_SHARED_REQUEST_NOTES
          : SHARED_REQUEST_NOTES
        : REQUEST_NOTES,
      [requestId]
    );
    const params = this.mapObj.isBlueprintMap ? { worksheet_id: worksheetId } : {};
    const apiCall = this.isSharedView
      ? openAPI(url, {}, 'GET', {}, params)
      : getAPI(url, {
          prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : '',
          params
        });
    return apiCall.then((res: any) => {
      this.addNotes(res);
      dispatch({ type: 'SET_NOTES', payload: [...res] });
    });
  };

  // This function is called on page loading and renders all the notes
  addNotes(notes: NotesDataInterface[]) {
    try {
      if (this.notesLayer) {
        this.mapObj.removeLayer(this.notesLayer);
      }
      this.notesLayer = new VectorLayer({
        source: new VectorSource({ wrapX: false }),
        // @ts-expect-error
        id: MAP_LAYERS.NOTES,
        name: MAP_LAYERS.NOTES,
        zIndex: LAYER_INDEX.NOTES,
        layerData: { name: MAP_LAYERS.NOTES },
        style: feature => this.getFeatureStyle(feature.get('noteData'))
      });
      this.mapObj.addLayer(this.notesLayer);
      this.notesLayer.setVisible(this.notesVisibility);

      if (notes.length) {
        for (let i = 0; i < notes.length; i++) {
          this.addNote(notes[i]);
        }
      }
    } catch (err) {
      captureException(err);
    }
  }

  // Renders the newly added notes
  addNote(noteData: NotesDataInterface) {
    if (!this.notesLayer) return;

    const feature = new Feature({
      geometry: new Point(fromLonLat([noteData.lon, noteData.lat])),
      id: noteData.id,
      noteData,
      isNote: true
    });
    this.notesLayer.getSource().addFeature(feature);
  }

  // Set the icon style for each note/feature.
  getFeatureStyle = (noteData: NotesDataInterface) => {
    const colors = this.isBlueprint ? BPT_NOTES_CATEGORY_COLOR : AERIAL_NOTES_CATEGORY_COLOR;
    const categories = this.isBlueprint ? BPT_NOTES_CATEGORY : AERIAL_NOTES_CATEGORY;

    const style = new Style({
      image: new Icon({
        src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/Subtract.svg',
        color: colors[noteData.category],
        crossOrigin: 'anonymous'
      })
    });

    const textStyle = new Style({
      text: new Text({
        text: `${categories[noteData.category][0] + noteData.count}`,
        font: '9px Arial,sans-serif',
        fill: new Fill({ color: 'white' }),
        offsetX: 0,
        offsetY: 0,
        textAlign: 'center'
      })
    });

    return [style, textStyle];
  };

  changeNoteTitle(title: string) {
    const titleNode = document.getElementById('note-page-title');
    if (titleNode) titleNode.innerText = title;
  }

  showContainerAt(pageX: number, pageY: number) {
    const container = document.getElementById(this.domElements.container);
    if (container) {
      container.style.display = 'block';
      const { offsetWidth: overlayWidth, offsetHeight: overlayHeight } = container || {};

      const [positionX, positionY] = adjustOverlayPosition({
        pageX,
        pageY,
        overlayWidth,
        overlayHeight
      });
      container.style.left = `${parseInt(String(positionX), 10)}px`;
      container.style.top = `${parseInt(String(positionY), 10)}px`;
      container.style.right = `${parseInt(String(positionY), 10)}px`;
    }
  }

  hideContainer() {
    const container = document.getElementById(this.domElements.container);
    if (container) container.style.display = 'none';
  }

  addViewNote = (noteData: NotesDataInterface | null = null) => {
    if (noteData) {
      const { user } = useUserDetails.getState();

      this.toggleViewNotesContainer(true);

      const colors = this.isBlueprint ? BPT_NOTES_CATEGORY_COLOR : AERIAL_NOTES_CATEGORY_COLOR;
      const categories = this.isBlueprint ? BPT_NOTES_CATEGORY : AERIAL_NOTES_CATEGORY;

      const viewNotesText = document.getElementById(this.domElements.viewNotesText);
      if (viewNotesText) {
        viewNotesText.innerHTML = noteData?.text;
      }

      const noteTypeHeader = document.getElementById(this.domElements.noteTypeHeader);
      if (noteTypeHeader) {
        noteTypeHeader.innerHTML = categories[noteData?.category];
      }

      const notesIconColor = document.getElementById(this.domElements.viewNotesIcon);
      if (notesIconColor) {
        notesIconColor.style.fill = colors[noteData?.category];
      }

      const noteAccessIcon = document.getElementById(this.domElements.noteAccessIcon);
      if (noteAccessIcon) {
        noteAccessIcon.style.display = noteData?.access_type === NOTES_ACCESS_ENUM.ALL ? 'block' : 'none';
      }

      const resolutionIcon = document.getElementById(this.domElements.noteStatusIcon);
      if (resolutionIcon) {
        const showResolutionIcon = noteData?.assignee_details.length > 0;
        resolutionIcon.style.display = showResolutionIcon ? 'block' : 'none';
        resolutionIcon.style.cursor =
          // @ts-expect-error
          showResolutionIcon && noteData?.assignee_details.includes(user?.id) ? 'pointer' : 'default';
        resolutionIcon.style.fill =
          noteData?.status === NOTES_STATUS.RESOLVED ? PRIMARY_ICON_COLOR : ENABLED_ICON_COLOR;
      }

      const viewEditBtn = document.getElementById(this.domElements.viewEditBtn);
      if (viewEditBtn) {
        // @ts-expect-error
        viewEditBtn.style.display = user?.id && noteData?.user_details?.id === user?.id ? 'block' : 'none';
      }

      const creatorName = document.getElementById(this.domElements.creatorName);
      if (creatorName) {
        creatorName.innerHTML = htmlEncode(noteData?.user_details?.full_name) || 'Anonymous';
      }

      const viewIconText = document.getElementById(this.domElements.viewIconText);
      if (viewIconText) {
        viewIconText.innerHTML = `${categories[noteData.category][0] + noteData.count}`;
      }

      const createdDate = document.getElementById(this.domElements.createdDate);
      if (createdDate) {
        createdDate.innerHTML = getDateString({ timestamp: noteData?.created_at });
      }

      const nameAvatar = document.getElementById(this.domElements.nameAvatar);
      if (nameAvatar) {
        nameAvatar.style.backgroundColor = '#FF9800';
        nameAvatar.innerHTML = noteData?.user_details?.full_name ? noteData?.user_details?.full_name[0] : 'A';
      }

      const allowResolution =
        noteData?.status === NOTES_STATUS.UNRESOLVED &&
        // @ts-expect-error
        noteData?.assignee_details.some(assignee => assignee.id === user.id);

      this.setNoteData(
        noteData?.media?.filter((m: Media) => m.type === NOTE_MEDIA_TYPE.IMAGE),
        noteData?.media?.filter((m: Media) => m.type === NOTE_MEDIA_TYPE.VIDEO),
        '',
        noteData?.assignee_details,
        String(NOTES_ACCESS_ENUM.ALL)
      );

      toolController.dispatchEvent(
        new CustomEvent('note-replies', {
          detail: { replies: noteData.replies, noteId: noteData.id, allowResolution }
        })
      );
    } else {
      this.toggleAddNotesContainer(true);
      this.changeNoteTitle('Add note');
      this.setNoteData([], [], '', [], String(NOTES_ACCESS_ENUM.ALL));

      const enums = this.isBlueprint ? BPT_NOTES_CATEGORY_ENUM : AERIAL_NOTES_CATEGORY_ENUM;
      const categoryStatus = document.getElementById(this.domElements.categoryStatus) as HTMLInputElement;
      if (categoryStatus) {
        categoryStatus.value = String(enums.NOTES);
      }
    }
  };

  resolveNote = (noteId: string) => {
    const { notes, dispatch } = useRequest.getState() || {};

    const url = interpolate(REQUEST_NOTE_RESOLVE, [this.requestId, noteId]);
    postAPI(url, {
      prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : ''
    }).then(() => {
      const resolutionIcon = document.getElementById(this.domElements.noteStatusIcon);
      if (resolutionIcon) resolutionIcon.style.fill = PRIMARY_ICON_COLOR;
      const index = notes.findIndex((note: any) => note.id === noteId);
      // @ts-expect-error
      notes[index].status = NOTES_STATUS.RESOLVED;
      dispatch({ type: 'SET_NOTES', payload: [...notes] });
    });
  };

  toggleAddNotesContainer(show: boolean) {
    const addContainer = document.getElementById(this.domElements.addNotesContainer);
    if (addContainer) addContainer.style.display = show ? 'block' : 'none';
  }

  toggleViewNotesContainer(show: boolean) {
    const viewContainer = document.getElementById(this.domElements.viewNotesContainer);
    if (viewContainer) viewContainer.style.display = show ? 'block' : 'none';
  }

  onMapClick = (e: any) => {
    const activeTool = toolController.getActiveTool();
    // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
    if (activeTool && activeTool?.toolId !== TOOLS_ID.NOTES_TOOL) return;

    this.selectedFeature = this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: any, _layer: any) => {
      if (_layer.get('id') === MAP_LAYERS.NOTES) {
        return _feature;
      }
      return null;
    });

    // User wants to add new note. Open add notes container.
    if (this.getIsAddingNote()) {
      this.addCoord = toLonLat(e.coordinate);

      // Close any note if it is open because we are going to add new note
      this.closeNote(false);
      this.addViewNote();
      this.showContainerAt(e.originalEvent.pageX, e.originalEvent.pageY);
    }
    // A feature is selected open view notes container
    else if (this.selectedFeature) {
      const noteData = this.selectedFeature.get('noteData');
      this.addViewNote(noteData);
      this.showContainerAt(e.originalEvent.pageX, e.originalEvent.pageY);
    } else {
      this.closeNote();
    }
  };

  setNoteData = (
    images: Media[] | undefined,
    videos: Media[] | undefined,
    text: string,
    taggedUsers: Assignee[] | undefined,
    noteAccess: string
  ) => {
    toolController.dispatchEvent(
      new CustomEvent('note-data', {
        detail: {
          images: images || [],
          videos: videos || [],
          text,
          taggedUsers: taggedUsers || [],
          noteAccess
        }
      })
    );
  };

  closeNote = (resetAdding = true) => {
    this.setNoteData([], [], '', [], String(NOTES_ACCESS_ENUM.ALL));
    this.hideContainer();
    this.toggleAddNotesContainer(false);
    this.toggleViewNotesContainer(false);
    this.selectedFeature = null;
    changeMapCursor(true, 'default');
    toolController.dispatchEvent(new CustomEvent('active-note', { detail: '' }));

    if (resetAdding) {
      this.addCoord = [];
      toolController.dispatch({ type: 'SET_ADDING_NEW_NOTE', payload: false });
      const activeTool = toolController.getActiveTool();
      // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
      if (activeTool?.toolId === TOOLS_ID.NOTES_TOOL) {
        toolController.updateActive(null);
      }
    }
  };

  viewNote(noteData: NotesDataInterface) {
    this.selectedFeature = this.getFeatureById(noteData.id);
    this.addViewNote(noteData);
    this.toggleAddNotesContainer(false);

    this.zoomToNote(noteData.lon, noteData.lat);
  }

  zoomToNote = (lon: number, lat: number) => {
    const mapContainer = document.getElementById('map');
    const projectedCoord = fromLonLat([lon, lat]);
    const duration = 500;
    const easing = linear;

    this.mapObj.map.getView().animate({
      center: projectedCoord,
      duration,
      easing
    });

    setTimeout(() => {
      if (mapContainer) {
        const mapRect = mapContainer.getBoundingClientRect();
        const pixel = this.mapObj.map.getPixelFromCoordinate(projectedCoord);
        const x = pixel[0] + mapRect.left;
        const y = pixel[1] + mapRect.top;

        this.showContainerAt(x, y);
      }
    }, duration);
  };

  editNote(data: NotesDataInterface | null = null) {
    if (data) {
      this.selectedFeature = this.getFeatureById(data.id);
      this.zoomToNote(data.lon, data.lat);
    }
    const noteData = this.selectedFeature?.get('noteData');

    this.toggleViewNotesContainer(false);
    this.toggleAddNotesContainer(true);
    this.changeNoteTitle('Edit note');

    const categoryStatus = document.getElementById(this.domElements.categoryStatus) as HTMLInputElement;
    if (categoryStatus) {
      categoryStatus.value = noteData?.category;
    }

    this.setNoteData(
      noteData?.media?.filter((m: Media) => m.type === NOTE_MEDIA_TYPE.IMAGE),
      noteData?.media?.filter((m: Media) => m.type === NOTE_MEDIA_TYPE.VIDEO),
      noteData?.text,
      noteData?.assignee_details,
      noteData?.access_type
    );
  }

  setIsAdding = (isAdding: any) => {
    toolController.dispatch({ type: 'SET_ADDING_NEW_NOTE', payload: isAdding });
  };

  saveNote = (
    imagesToUpload: any,
    videosToUpload: any,
    noteCreatorId: string,
    worksheetId: string,
    noteText: string,
    taggedUsers: string[],
    deletedMedia: string[]
  ) => {
    return new Promise((res, rej) => {
      const noteData = new FormData();

      // if the note alredy exists then we fetch its content
      if (this.selectedFeature) {
        const oldData = this.selectedFeature.get('noteData');
        noteData.append('id', oldData.id);
      } else if (noteCreatorId) noteData.append('user', noteCreatorId);

      const category = document.getElementById(this.domElements.categoryStatus) as HTMLInputElement;
      noteData.append('category', category?.value);

      const visibility = document.getElementById(this.domElements.noteStatus) as HTMLInputElement;
      noteData.append('access_type', visibility?.value);

      if (noteText) noteData.append('text', noteText);
      if (taggedUsers.length) taggedUsers.map((user: any) => noteData.append('assignees', user));
      if (imagesToUpload.length) imagesToUpload.map((img: any) => noteData.append('images', img));
      if (videosToUpload.length) videosToUpload.map((vid: any) => noteData.append('videos', vid));
      if (deletedMedia.length) deletedMedia.map((media: any) => noteData.append('delete_files', media));

      const { notes, dispatch } = useRequest.getState() || {};

      if (noteData.get('id')) {
        // Editing note
        const url = interpolate(
          this.isSharedView
            ? this.mapObj.isBlueprintMap
              ? BLUEPRINT_SHARED_REQUEST_NOTE
              : SHARED_REQUEST_NOTE
            : REQUEST_NOTE,
          [this.requestId, noteData.get('id')]
        );

        const apiCall = this.isSharedView
          ? openAPI(url, noteData, 'PATCH')
          : multipartAPI(url, {
              data: noteData,
              prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : '',
              method: 'PATCH'
            });

        apiCall
          .then((res: any) => {
            this.notesLayer.getSource().removeFeature(this.selectedFeature);
            this.addNote({ ...res });

            const index = notes.findIndex((note: any) => note.id === res.id);
            // @ts-expect-error
            notes[index] = res;
            dispatch({ type: 'SET_NOTES', payload: [...notes] });
            this.closeNote();
            res('');
          })
          .catch(err => rej(err));
      } else {
        // Adding new note
        noteData.append('lon', parseFloat(this.addCoord[0]).toFixed(15));
        noteData.append('lat', parseFloat(this.addCoord[1]).toFixed(15));
        if (this.mapObj.isBlueprintMap) {
          noteData.append('worksheet_id', worksheetId);
        }
        const url = interpolate(
          this.isSharedView
            ? this.mapObj.isBlueprintMap
              ? BLUEPRINT_SHARED_REQUEST_NOTES
              : SHARED_REQUEST_NOTES
            : REQUEST_NOTES,
          [this.requestId]
        );

        const apiCall = this.isSharedView
          ? openAPI(url, noteData, 'POST')
          : multipartAPI(url, {
              data: noteData,
              prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : ''
            });

        apiCall
          .then((data: any) => {
            noteData.append('id', data.id);
            this.closeNote();
            this.addNote(data);
            dispatch({ type: 'SET_NOTES', payload: [...notes, data] });
            res('');
          })
          .catch(err => rej(err));
      }
    });
  };

  deleteNote = (id: string = '') => {
    if (id) {
      this.selectedFeature = this.getFeatureById(id);
    }
    if (this.selectedFeature) {
      const noteData = this.selectedFeature.get('noteData');

      const url = interpolate(
        this.isSharedView
          ? this.mapObj.isBlueprintMap
            ? BLUEPRINT_SHARED_REQUEST_NOTE
            : SHARED_REQUEST_NOTE
          : REQUEST_NOTE,
        [this.requestId, noteData.id]
      );

      const apiCall = this.isSharedView
        ? openAPI(url, {}, 'DELETE')
        : patchAPI(url, {
            method: 'DELETE',
            prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : ''
          });

      apiCall.then(() => {
        this.notesLayer.getSource().removeFeature(this.selectedFeature);
        this.closeNote();

        const { notes, dispatch } = useRequest.getState() || {};
        const updatedNotes = notes.filter((note: any) => note.id !== noteData.id);

        dispatch({ type: 'SET_NOTES', payload: updatedNotes });
      });
    }
  };

  getFeatureById(id: string) {
    const features = this.notesLayer.getSource().getFeatures();
    for (let i = 0; i < features.length; i++) {
      if (features[i].get('id') === id) {
        return features[i];
      }
    }
    return null; // Feature with specified ID not found
  }

  setNotesVisibility = (val: boolean) => {
    this.notesVisibility = val;
    outputMap.setVisibility(MAP_LAYERS.NOTES, val);
  };

  off() {
    this.mapObj.map.un('singleclick', this.onMapClick);
    this.closeNote();
  }
}

export default Notes;
