import { useCallback, useEffect, useRef } from 'react';
import { getArea, getLength } from 'ol/sphere';
import { GEOMETRY_TYPES, GEOMETRY_TYPES_FEATURE, MAP_TYPE, UNIT_CONVERSION_FACTORS } from 'woodpecker';
import { GEO_JSON, generateUniqueID, getTurfFeature, turfMerge } from 'macaw';
import { combine, difference, featureCollection } from '@turf/turf';
import { Feature } from 'ol';
import { Geometry, MultiPolygon } from 'ol/geom';
import _ from 'lodash';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import useLiveMeasurementStore, { LiveMeasurementStoreType } from '../../store/liveMeasurementStore';
import { getMeasurements, shapelyObj } from './helpers/measurements';
import { mapObj } from '../../js-layer/mapLayer/mapInit';
import { calculateBPArea, calculateBPLength, highlightFeature } from '../../helpers/helpers';
import useMapStore from '../../store/mapStore';
import useMapHelpers from '../helpers';

/**
 * @name highlightFeatures
 * @function
 * @description To hightlight a feature by changing its style for specific time
 * @param {Feature} feature feature
 */
export const highlightFeatures = (features: any) => {
  for (const f of features) {
    highlightFeature(f);
  }
};

const getChangedFeature = (lastState: any, currentState: any) => {
  let changedState = {};
  for (const [key, value] of Object.entries(currentState)) {
    if (lastState.hasOwnProperty(key)) {
      const value2 = lastState[key];
      if (JSON.stringify(value) !== JSON.stringify(value2)) {
        changedState = { ...changedState, [key]: value };
      }
    } else {
      changedState = { ...changedState, [key]: value };
    }
  }
  return changedState;
};

export const usePointCountUpdate = () => {
  const { getVectorLayerById } = useMapHelpers();
  const { updateStateWithPrevValue, lastScaleState } = useLiveMeasurementStore((state: LiveMeasurementStoreType) => ({
    updateStateWithPrevValue: state.updateStateWithPrevValue,
    lastScaleState: state.lastScaleState
  }));
  const setLayerFeatureCount = useMapStore((state: any) => state.setLayerFeatureCount);
  const getState = useMapStore((state: any) => state.getState);
  const bptProjection = useMapStore((state: any) => state.bptProjection);
  const lastFeatureState = useRef<any>({});
  const worksheetParams = useMapStore((state: any) => state.worksheetParams);

  const update = useCallback(() => {
    let pointCount = {};
    getState(({ layers: allLayers, map, worksheetParams, ...prevValue }: any) => {
      for (const key of allLayers.keys()) {
        const layer = getVectorLayerById(key);
        const allFeatures = layer?.getSource()?.getFeatures();
        if (!allFeatures) continue;
        const geojson = JSON.parse(GEO_JSON.writeFeatures(allFeatures || [], bptProjection));
        const shapelyFormat = shapelyObj({
          geojson,
          itemDetails: allLayers.get(key)
        });
        pointCount = { ...pointCount, [`${key}`]: shapelyFormat };
      }
      const changedFeature = getChangedFeature(lastFeatureState.current, pointCount);
      lastFeatureState.current = pointCount;

      if (changedFeature && Object.keys(changedFeature).length) {
        if (mapObj.map_type === MAP_TYPE.BLUEPRINT) {
          getMeasurements({
            layersPointCount: changedFeature,
            scale: worksheetParams?.scale,
            layers: { data: allLayers },
            map,
            updateState: updateStateWithPrevValue
          });
        } else {
          const updatedFeatureCount: any = {};
          Object.entries(changedFeature).forEach(([key, value]: any) => {
            updatedFeatureCount[key] = value?.output_geojson?.features?.length ?? 0;
          });
          setLayerFeatureCount(updatedFeatureCount);

          Object.keys(changedFeature)?.forEach((id: string) => {
            const vectorLayer = mapObj.getLayerById(id);
            const source = vectorLayer.getSource();

            // Get all features from the source
            const features = source.getFeatures();

            // // Loop through the features
            features.forEach((feature: any) => {
              // Do something with each feature
              feature.set('vector_layer_id', id, true);
            });
          });
        }
      }
    });
  }, [bptProjection, getState, getVectorLayerById, setLayerFeatureCount, updateStateWithPrevValue]);

  const updateOnDebounce = useCallback(
    _.debounce(() => update(), 500),
    [update]
  );

  const refreshState = () => {
    lastFeatureState.current = {};
  };

  useEffect(() => {
    if (lastScaleState !== JSON.stringify(worksheetParams)) {
      lastFeatureState.current = {};
      updateOnDebounce();
      updateStateWithPrevValue(({ ...prev }) => ({
        ...prev,
        lastScaleState: JSON.stringify(worksheetParams)
      }));
    }
  }, [lastScaleState, updateOnDebounce, updateStateWithPrevValue, worksheetParams]);

  return { update, updateOnDebounce, refreshState };
};

export const formatLengthInFeetAndInches = (line: any, dpi: number, scale: number) => {
  let length = getLength(line);
  if (mapObj.map_type === MAP_TYPE.BLUEPRINT) length = calculateBPLength({ dpi, scale, length });

  const totalFeet = length * 3.28084; // Convert meters to feet; 1m = 3.28084ft
  const feet = Math.floor(totalFeet); // Get feet without fraction
  // Calculate remaining inches in decimal form and converting it into inches; 1ft = 12 inches
  const remainingInches = (totalFeet - feet) * 12;
  const inches = remainingInches.toFixed(1);
  return `${feet} ft ${inches} in`;
};

export const formatLength = (line: any, dpi: number, scale: number) => {
  let length = getLength(line);
  if (mapObj.map_type === MAP_TYPE.BLUEPRINT) length = calculateBPLength({ dpi, scale, length });
  return `${(length * UNIT_CONVERSION_FACTORS.METERS_TO_FEET).toFixed(1)} ft`;
};

export const getLengthInFt = (line: any, dpi: number, scale: number) => {
  let length = getLength(line);
  if (mapObj.map_type === MAP_TYPE.BLUEPRINT) length = calculateBPLength({ dpi, scale, length });
  return Math.round(length * UNIT_CONVERSION_FACTORS.METERS_TO_FEET);
};

export const calculateAreaOfCircle = (radius: number) => {
  return Math.PI * radius * radius;
};

export const calculateCircumferenceOfCircle = (radius: number) => {
  return 2 * Math.PI * radius;
};

const formatAreatoBPAreaAndFt = (area: number, dpi: number, scale: number) => {
  let calculatedArea = area;
  if (mapObj.map_type === MAP_TYPE.BLUEPRINT) calculatedArea = calculateBPArea({ dpi, scale, area });
  return `${(calculatedArea * UNIT_CONVERSION_FACTORS.SQ_METERS_TO_SQ_FEET).toFixed(1)} ft`;
};

export const formatArea = (polygon: any, dpi: number, scale: number) => {
  const type = polygon.getType();
  let area = 0;
  if (type === GEOMETRY_TYPES.CIRCLE) {
    const radius = polygon.getRadius();
    area = calculateAreaOfCircle(radius);
  } else area = getArea(polygon);

  return formatAreatoBPAreaAndFt(area, dpi, scale);
};

export const formatLineCircleCircumference = (radius: number, dpi: number, scale: number) => {
  let circumference = calculateCircumferenceOfCircle(radius);
  if (mapObj.map_type === MAP_TYPE.BLUEPRINT) circumference = calculateBPLength({ dpi, scale, length: circumference });
  return `${(circumference * UNIT_CONVERSION_FACTORS.METERS_TO_FEET).toFixed(1)} ft`;
};

export const formatLineRectanglePerimeter = (perimeter: any, dpi: number, scale: number) => {
  let length = perimeter;
  if (mapObj.map_type === MAP_TYPE.BLUEPRINT) length = calculateBPLength({ dpi, scale, length });
  return `${(length * UNIT_CONVERSION_FACTORS.METERS_TO_FEET).toFixed(1)} ft`;
};

export const getAreaInFt = (polygon: any, dpi: number, scale: number) => {
  const type = polygon.getType();
  let area = 0;
  if (type === GEOMETRY_TYPES.CIRCLE) {
    const radius = polygon.getRadius();
    area = Math.PI * radius * radius;
  } else area = getArea(polygon);

  if (mapObj.map_type === MAP_TYPE.BLUEPRINT) area = calculateBPArea({ dpi, scale, area });
  return Math.round(area * UNIT_CONVERSION_FACTORS.SQ_METERS_TO_SQ_FEET);
};

export const useAvoidOverlap = () => {
  const allLayers = useMapStore((state: any) => state.layers);
  const selectedLayer = useMapStore((state: any) => state.selectedLayer);
  const { getVectorLayerById } = useMapHelpers();

  const avoidOverlap = (drawnFeature: Feature<MultiPolygon> | Feature<Geometry>) => {
    const layers: string[] = [];
    for (const [key, layerDetails] of allLayers) {
      if (layerDetails.geometry_type === GEOMETRY_TYPES_FEATURE.POLYGON) layers.push(key);
    }

    let featuresArr: Feature[] = [];
    const newAddedFeatureExtent = drawnFeature.getGeometry()?.getExtent();
    layers.forEach(lId => {
      const layer = getVectorLayerById(lId);
      featuresArr.push(...layer.getSource().getFeaturesInExtent(newAddedFeatureExtent));
    });

    featuresArr = featuresArr.filter((feat: Feature) => feat != drawnFeature);
    if (!featuresArr.length) {
      return;
    }
    try {
      const geojson = GEO_JSON.writeFeaturesObject(featuresArr);
      const merged = turfMerge(geojson);
      let fc;
      if (!merged.features) {
        fc = featureCollection([merged]);
      } else {
        fc = merged;
      }
      const _combine = combine(fc);
      const turfDrawnFeature = getTurfFeature(drawnFeature.clone()) as any;

      _combine.features.forEach((feature: any, index: number) => {
        const diff = difference(turfDrawnFeature, feature);
        if (diff) {
          drawnFeature.setGeometry(GEO_JSON?.readFeature(diff)?.getGeometry() as MultiPolygon);
          if (diff.geometry.type === GEOMETRY_TYPES.MULTI_POLYGON) {
            drawnFeature
              ?.getGeometry()
              // @ts-ignore: Unreachable code error
              ?.getPolygons()
              .forEach((geometry: Geometry, featIndex: number) => {
                const intersection = new Feature({
                  geometry
                });
                const unq_id = `${generateUniqueID('ao')}${Math.round(Math.random() * 100)}${index}${featIndex}`;
                intersection.setId(unq_id);
                selectedLayer.getSource().addFeature(intersection);
              });
            selectedLayer.getSource().removeFeature(drawnFeature);
          }
        } else {
          selectedLayer.getSource().removeFeature(drawnFeature);
        }
      });
    } catch (e) {
      // console.log(e);
    }
  };

  return avoidOverlap;
};

export const highlightStyle = () => {
  const fillStyle = new Fill({ color: 'rgba(255,0,0,0.3)' });

  const strokeStyle = new Stroke({
    color: 'red',
    width: 3
  });
  const imageStyle = new Circle({
    fill: fillStyle,
    stroke: strokeStyle,
    radius: 3
  });

  return new Style({
    fill: fillStyle,
    stroke: strokeStyle,
    image: imageStyle
  });
};
