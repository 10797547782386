import { css } from '@emotion/css';

export const container = css`
  display: none;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  background: var(--color-background-paper, #fff);
  box-shadow: 5.565px 5.565px 16.696px 0px rgba(0, 0, 0, 0.08);
  z-index: 101;
  align-items: center;
  transition: right 0.4s;
`;

export const line = css`
  display: inline-block;
  width: 1px;
  height: 28px;
  background: var(--color-neutral-30, #e0e0e0);
`;

export const toolContainer = css`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const inputContainer = css`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const input = css`
  width: 35px;
  height: 24px;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: var(--color-neutral-15, #f0f0f0);
  color: var(--color-neutral-90, #212121);
  border: none;
  font-family: 'EuclidSquare';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.15px;
`;

export const colorDot = css`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
`;

export const typeContainer = css`
  display: inline-flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
`;
