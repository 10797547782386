import Worker from 'worker-loader!./webworker.js';

let asyncRun2;
if (typeof window !== 'undefined') {
  const pyodideWorker = new Worker(Worker);
  const callbacks = {};

  pyodideWorker.onmessage = event => {
    const { id, ...data } = event.data;
    const onSuccess = callbacks[id];
    delete callbacks[id];
    onSuccess(data);
  };

  asyncRun2 = (() => {
    let id = 0;
    return (script, context) => {
      id = (id + 1) % Number.MAX_SAFE_INTEGER;
      return new Promise(onSuccess => {
        callbacks[id] = onSuccess;
        pyodideWorker.postMessage({ python: script, id, ...context });
      });
    };
  })();
}

export default asyncRun2;
