import { Draw, Interaction } from 'ol/interaction';
import { undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { avoidOverlap, drawStyle } from '../../../helpers/helpers';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { GEOMETRY_TYPES, UNSET_PROPERTIES } from 'woodpecker';
import { Type } from 'ol/geom/Geometry';
import { containsCoordinate } from 'ol/extent';
import { unByKey } from 'ol/Observable';

class DeleteRing extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null = null;
  private layer: any = null;
  private clickRef: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.layer = null;
    this.clickRef = null;
  }

  init(id: string): void {
    this.layer = this.mapObj.getLayerById(id);
    if (this.layer) {
      this.mapObj.map!.getViewport().style.cursor = 'crosshair';
      const id = this.layer.get('id');
      const source = this.layer.getSource();
      this.clickRef = this.mapObj.map?.on('singleclick', (e: any) => {
        let _l = this.mapObj.map?.forEachFeatureAtPixel(e.pixel, function (f: any, layer: any) {
          return layer;
        });

        // ignore self overlap only
        if (_l && _l.get('id') === id) {
          return;
        }

        let coord = e.coordinate;
        let selected_feature: any = null;
        source.getFeatures().forEach((feature: any) => {
          const extent = feature.getGeometry().getExtent();
          const contains = containsCoordinate(extent, coord);
          if (contains) {
            selected_feature = feature;
            return;
          }
        });
        if (selected_feature) {
          let poly_geom = selected_feature.getGeometry();
          if (poly_geom?.getType() !== GEOMETRY_TYPES.POLYGON) return;
          let rings = poly_geom.getLinearRings();
          let poly_coord_no_ring = [rings[0].getCoordinates()];

          rings.forEach((ring: any, index: number) => {
            const ring_coord = ring.getCoordinates();
            const ring_extent = ring.getExtent();
            const ring_at_pointer = containsCoordinate(ring_extent, coord);
            if (!ring_at_pointer && index != 0) {
              poly_coord_no_ring.push(ring_coord);
            }
          });
          poly_geom.setCoordinates(poly_coord_no_ring);
          UNSET_PROPERTIES.forEach(property => {
            selected_feature.unset(property, false);
          });
          undoRedoPush();
        }
      });
    }
  }

  /**
   * Turns off the draw interaction by removing it from the map, removing the snap interaction, and removing event listeners.
   */
  off(): void {
    this.mapObj.map!.getViewport().style.cursor = '';
    unByKey(this.clickRef);
  }
}

export default DeleteRing;
