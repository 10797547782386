import { captureException } from '@sentry/react';

import message from 'antd/es/message';

import booleanIntersects from '@turf/boolean-intersects';
import buffer from '@turf/buffer';
import { getGeom } from '@turf/invariant';
import union from '@turf/union';
import clone from '@turf/clone';
import { featureCollection, polygon } from '@turf/helpers';
import pointOnFeature from '@turf/point-on-feature';

import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { MAP_LAYERS, MINUS_ICON, PLUS_ICON } from '../../../Constants/Constant';
import { layerTracker } from '../MapInit';
import { useRequest } from '../../../Stores/Request';

interface ZoneMergeData {
  zoneId: string;
}

class ZoneMerge {
  geoJsonZone: any;

  layers: any;

  mapObj: any;

  mergeCalled: boolean;

  zoneFeatures: Array<any>;

  zoneId: string;

  zoneLayer: any;

  zoneLayers: Array<any>;

  otherZoneFeatures: any;

  constructor(mapObj: any) {
    this.mapObj = mapObj;
    this.layers = [];
    this.zoneLayers = [];
    this.zoneId = '';
    this.zoneLayer = null;
    this.zoneFeatures = [];
    this.geoJsonZone = null;
    this.otherZoneFeatures = [];
    this.mergeCalled = false;
  }

  on({ zoneId }: ZoneMergeData) {
    // Zone Draw/Measurement tool is disabled when editZoneActive is true
    this.dispatch({ type: 'EDIT_ZONE_ACTIVE', payload: true });

    // Load all zone layers
    this.loadZoneLayers();

    this.zoneId = zoneId;
    // zone layer in which other zones will be merged (main zone layer)
    this.zoneLayer = this.mapObj.getLayerById(zoneId);

    let zoneFeatures: Array<any> = [];
    this.zoneLayers.forEach((layer: any) => {
      const src = layer?.getSource();
      const features = src?.getFeatures();
      zoneFeatures = [...zoneFeatures, ...features];
      if (layer.get('id') !== zoneId) {
        features.forEach((feature: any) => feature.setStyle(this.styleFn({ layer })));
      }
    });
    this.selectAdjacentZones();
    this.mapObj.map.on('click', this.selectZones);
  }

  getMergedZones() {
    const mergedZones: Array<any> = [];
    this.zoneFeatures.forEach((feat: any) => {
      const id = feat.get('layerId');
      const layer = this.mapObj.getLayerById(id);
      mergedZones.push(layer);
    });
    this.mergeFeatures();
    this.mergeCalled = true;
    return mergedZones;
  }

  loadZoneLayers() {
    this.layers = this.mapObj.map.getLayers()?.getArray();
    this.layers.forEach((layer: any) => {
      const isVisible = layer.getVisible();
      if (isVisible && layer?.get('name') === MAP_LAYERS.ZONE && !layer?.get('isTemp')) {
        this.zoneLayers.push(layer);
      }
    });
  }

  selectAdjacentZones() {
    const adjZoneLayers = this.getAdjacentZones();
    if (adjZoneLayers.length === 0) {
      message.error('No adjacent zones available to merge!!');
    }
  }

  selectZones = (e: MapBrowserEvent<MouseEvent>) => {
    this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: any, _layer: any) => {
      if (_layer.get('name') === MAP_LAYERS.ZONE && _layer.get('id') !== this.zoneLayer.get('id')) {
        const allFeatures = _layer?.getSource()?.getFeatures();
        let removeAllFeatures = false;
        allFeatures.forEach((feature: any) => {
          if (this.zoneFeatures.includes(feature)) {
            removeAllFeatures = true;
          }
        });
        if (removeAllFeatures) {
          this.zoneFeatures = this.zoneFeatures.filter((feat: any) => {
            if (allFeatures.includes(feat)) {
              return false;
            } else return true;
          });
        } else {
          // Add allFeatures
          allFeatures.forEach((feat: any) => this.zoneFeatures.push(feat));
        }
      }
    });

    const allOtherZoneLayers = this.zoneLayers.filter((zoneLayer: any) => {
      if (zoneLayer.get('id') === this.zoneLayer.get('id')) return false;
      const feat = zoneLayer.getSource().getFeatures()[0];
      if (this.zoneFeatures.includes(feat)) return false;
      return true;
    });

    const allOtherZoneFeats: Array<any> = [];
    allOtherZoneLayers.forEach((layer: any) => {
      const feats = layer?.getSource().getFeatures();
      feats.forEach((feat: any) => allOtherZoneFeats.push(feat));
    });

    this.zoneFeatures.forEach((feat: any) => feat.setStyle(this.styleFn({})));

    allOtherZoneFeats.forEach(feat => {
      const layerId = feat.get('layerId');
      const layer = this.mapObj.getLayerById(layerId);
      feat.setStyle(this.styleFn({ layer }));
    });
    this.updateLayerTracker();
  };

  getAdjacentZones = () => {
    const adjZoneLayers: Array<any> = [];
    const FormatGeoJSON = new GeoJSON();
    const projection = {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    };
    // Add buffer to the main zone layer
    const feats = this.zoneLayer.getSource()?.getFeatures();
    const zoneFeature = feats[0];
    // If main zone have multiple features/ polygon
    this.otherZoneFeatures = [...feats.slice(1)]?.map((feat: any) =>
      FormatGeoJSON.writeFeatureObject(feat, projection)
    );
    const geoJson = FormatGeoJSON.writeFeatureObject(zoneFeature, projection);
    const bufferedFeat = buffer(geoJson, 0.0000001, { units: 'kilometers' });
    const bufferGeojson: any = new GeoJSON().readFeatures(featureCollection([bufferedFeat]), projection);
    const zoneGeom: any = getGeom(bufferGeojson[0]);
    this.geoJsonZone = FormatGeoJSON.writeFeatureObject(zoneGeom, projection);

    // Determine the adjacent zones
    this.zoneLayers.forEach((layer: any) => {
      if (layer !== this.zoneLayer) {
        adjZoneLayers.push(layer);
      }
    });

    return adjZoneLayers;
  };

  updateLayerTracker = () => {
    // Empty layerTracker everytime to avoid repeated zones layers to be pushed
    layerTracker.clearAtKey(MAP_LAYERS.ZONE);

    // Push each zone layer to be merged into layerTracker
    this.zoneFeatures.forEach((zoneFeature: any) => {
      const layerId = zoneFeature.get('layerId');
      layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
    });
  };

  mergeFeatures = () => {
    const FormatGeoJSON = new GeoJSON();
    const projection = {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    };
    // Add the main zone layer feature to the allGeojson array
    // allGeojson[] defined all the features/zones to be merged together
    const allGeojson = this.otherZoneFeatures ? [this.geoJsonZone, ...this.otherZoneFeatures] : [this.geoJsonZone];

    this.zoneFeatures.forEach((feature: any) => {
      const geojson = FormatGeoJSON.writeFeatureObject(feature, projection);
      allGeojson.push(geojson);
    });

    // Resulting merged feature
    const merged = this.turfMerge(allGeojson);

    // Clear all the existing features from the main zone layer
    this.zoneLayer.getSource().clear();

    const mergedGeojson = new GeoJSON().readFeatures(merged, projection);
    mergedGeojson.forEach(feature => {
      feature.set('layerId', this.zoneLayer.get('id'));
    });

    // Add the merged feature to the main zone layer
    this.zoneLayer.getSource().addFeatures(mergedGeojson);

    // Push the merged zone in the layerTracker
    const zoneId = this.zoneLayer.get('id');
    layerTracker.push(this.mapObj.getLayerName(zoneId), zoneId);
  };

  turfMerge = (features: Array<any>) => {
    let merged = clone(features[0]);
    let poly;
    const unmergedPoly = [];
    try {
      for (let i = 1, len = features.length; i < len; i++) {
        poly = features[i];
        if (poly.geometry) {
          if (booleanIntersects(merged, poly)) {
            try {
              const { properties } = poly;
              merged = union(merged, poly);
              merged.properties = properties;
            } catch (err) {
              captureException(err);
            }
          } else {
            unmergedPoly.push(poly);
          }
        }
      }
      if (unmergedPoly.length > 0) {
        unmergedPoly.push(merged);
        return featureCollection(unmergedPoly);
      }
      return featureCollection([merged]);
    } catch (err) {
      captureException(err);
      return undefined;
    }
  };

  styleFn({ layer }: any) {
    const zoneName = layer ? layer?.get('zoneName') : this.zoneLayer?.get('zoneName');
    const imgSrc = layer ? PLUS_ICON : MINUS_ICON;

    const featureStyle = new Style({
      stroke: new Stroke({
        width: 3,
        color: layer ? 'red' : '#4361ee'
      }),
      fill: new Fill({
        color: layer ? 'rgba(255,0,0,0.12)' : 'rgba(255,255,255,0.4)'
      }),
      text: new Text({
        text: zoneName,
        fill: new Fill({
          color: '#ffffff' // White text color
        }),
        backgroundFill: new Fill({
          color: 'rgba(0, 0, 0, 1)' // Gray background color with opacity
        }),
        placement: 'point',
        textBaseline: 'top',
        font: '12px sans-serif',
        overflow: true
      })
    });

    return [
      featureStyle,
      new Style({
        image: new Icon({
          src: imgSrc,
          scale: 0.6,
          displacement: [10, 10],
          crossOrigin: 'anonymous'
        }),
        geometry: (feature: any) => {
          const poly = polygon(feature.getGeometry().getCoordinates());
          const turfCenter = pointOnFeature(poly).geometry.coordinates;
          const shiftedCenter = [turfCenter[0], turfCenter[1]];

          return new Point(shiftedCenter);
        }
      })
    ];
  }

  dispatch(action: any) {
    useRequest?.getState()?.dispatch(action);
  }

  off() {
    // Remove the zones that are merged into the main zone layer from the map
    this.mergeCalled &&
      this.zoneFeatures.forEach((feature: any) => {
        const layerId = feature.get('layerId');
        const layer = this.mapObj.getLayerById(layerId);
        this.mapObj.removeLayer(layer);
      });
    this.mergeCalled = false;
    this.mapObj.map.un('click', this.selectZones);
    this.layers = [];
    this.zoneLayers = [];
    this.zoneId = '';
    this.zoneLayer = null;
    this.zoneFeatures = [];
    this.geoJsonZone = null;
    this.otherZoneFeatures = [];
    this.dispatch({ type: 'EDIT_ZONE_ACTIVE', payload: false });
  }
}

export default ZoneMerge;
