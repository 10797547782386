import Draw from 'ol/interaction/Draw';
import { undoRedoObj, undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { getAllFeaturesSource, isOutOfExtent, triggerOverrideOverlap } from '../../../helpers/helpers';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { COMMENT_LAYER_ID, GEOMETRY_TYPES, MAP_TYPE, ORTHO_ANGLE } from 'woodpecker';
import { generateUniqueID, getArc, getIntersection, isValid } from 'macaw';
import { crossHairStyle, drawStyle, labelStyle, pointStyle } from '../../../hooks/tools/helpers/styles';
import { formatArea } from '../../../hooks/tools/helpers';
import { LineString, Polygon } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { FeatureisOutOfExtent } from 'macaw/src/getValidFeatures';
import { showToast } from 'ui';
import TOOL_MAP, { TOOL_TYPE } from '../constants';
import { getAngle, getSnappingAngle } from 'macaw/src/getArc';
import { Stroke, Style } from 'ol/style';

type ArcCoordinateType = {
  coordinate: Coordinate;
  isMidPoint: boolean;
};

class DrawPolygon extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null = null;
  private drawDone: boolean;
  private coordinateRef: any;
  private prevState: any;
  private didPointMoved: any;
  private layer: any = null;
  private mouseCoords: any = [0, 0];
  private isIntersecting: boolean = false;
  private isInValid: boolean = false;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.drawDone = false;
    this.layer = null;
    this.mouseCoords = [0, 0];
    this.isIntersecting = false;
    this.isInValid = false;
  }

  /**
   * Initializes the useAddPolygon interaction.
   * Creates a new Draw interaction with the selectedlayer's source as the target source.
   * Sets the type to "Polygon", style to drawStyle(), dragVertexDelay to 0, and snapTolerance to 1.
   * Sets the condition to allow drawing only on left-click.
   * Adds the Draw interaction to the map.
   * Adds an event listener for drawend and keydown.
   */
  init = (id: string, featureTracing: boolean = false) => {
    this.layer = this.mapObj.getLayerById(id);
    if (this.layer) {
      let source = this.layer.getSource();
      if (globalStore.AppStore.tool.add_comment) {
        source = this.mapObj.getLayerById(COMMENT_LAYER_ID).getSource();
      }

      const isAerial = this.mapObj.map_type === MAP_TYPE.AERIAL;
      const targetLayerSource = getAllFeaturesSource();

      this.draw = new Draw({
        source: source,
        type: 'Polygon',
        style: feature => this.styleFunction(feature),
        dragVertexDelay: 0,
        snapTolerance: 1,
        finishCondition: () => {
          if (this.isInValid) {
            showToast('Invalid feature: Intersecting polygon not allowed', 'error', {
              position: 'top-center',
              hideProgressBar: false
            });
            return false;
          }
          return true;
        },
        condition: e => {
          const mouseClick = e.originalEvent.which;

          if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map) || this.isIntersecting) {
            if (this.isIntersecting) {
              showToast('Invalid feature: Intersecting polygon not allowed', 'error', {
                position: 'top-center',
                hideProgressBar: false
              });
            }
            return false;
          }
          return true;
        },
        ...(!isAerial && { geometryFunction: this.geometryFunction }),
        trace: featureTracing,
        traceSource: targetLayerSource
      });

      this.mapObj.map?.addInteraction(this.draw);

      this.draw?.on('drawend', this.onDrawEnd);
      this.draw?.on('drawstart', this.onDrawStart);

      this.draw?.on('drawabort', () => {
        this.resetToInitialState();
      });
      window.addEventListener('keydown', this.keyDownHandler);
    }
  };

  styleFunction = (feature: any) => {
    const { scale, dpi } = globalStore.AppStore.worksheetParams;
    if (feature?.getGeometry().getType() == 'LineString') {
      return [];
    }
    let styles = [drawStyle()];

    if (globalStore.AppStore.ortho_mode) {
      styles = [crossHairStyle];
    }

    if (globalStore?.AppStore?.arc_mode) {
      styles = [...styles, pointStyle];
    }
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.POLYGON && scale !== null) {
      //disable/enable live measurement
      if (this.mapObj.map_type === MAP_TYPE.AERIAL && !globalStore?.AppStore?.tool?.live_measurement) {
        return styles;
      }
      const label = formatArea(geometry, dpi, scale);
      const _labelStyle = labelStyle.clone();
      _labelStyle.setGeometry(geometry);
      _labelStyle.getText().setText(label);
      styles.push(_labelStyle);
    }

    const coordinates = geometry.getCoordinates();
    const [segments, intersectingSegments, lastSegmentIntersects] = this.findIntersectingSegments(coordinates[0]);

    if (typeof coordinates[0] == 'number') {
      this.mouseCoords = [coordinates[0], coordinates[1]];
    }
    this.isIntersecting = false;
    if (intersectingSegments.length > 0) {
      this.isInValid = true;
    } else {
      this.isInValid = false;
    }
    if (lastSegmentIntersects) {
      this.isIntersecting = true;
      for (let i = 0; i < segments.length; i++) {
        let coord = JSON.stringify(this.mouseCoords);
        const start = segments[i][0];
        const end = segments[i][1];
        let startCoords = JSON.stringify(start);
        let endCoords = JSON.stringify(end);

        if (coord !== startCoords && coord !== endCoords) {
          continue;
        }
        const edge = new LineString([start, end]);

        const edgeStyle = new Style({
          geometry: edge,
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, 1)',
            width: 3,
            lineDash: [10, 5]
          })
        });

        styles.push(edgeStyle);
      }
    }
    return styles;
  };

  resetGeometry = () => {
    undoRedoObj.undo();
    this.prevState
      ?.getGeometry()
      ?.getCoordinates()
      .forEach((coord: any) => {
        this.draw?.appendCoordinates(coord);
      });
    this.draw?.removeLastPoint();
  };

  /**
   * Event handler for the keydown event.
   * Removes the last drawn point if the Backspace key is pressed.
   * Finishes the drawing if the Space key is pressed.
   * @param e - The keydown event object.
   */
  keyDownHandler = (e: KeyboardEvent) => {
    if (e.code == 'Backspace') {
      if (this.drawDone && this.prevState) {
        this.resetGeometry();
        this.drawDone = false;
      } else {
        this.draw?.removeLastPoint();
        if (this.coordinateRef.length > 0) {
          this.coordinateRef.pop();
          if (this.didPointMoved) {
            this.coordinateRef.pop();
          }
        }
        this.didPointMoved = false;
      }
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    } else if (e.code == 'KeyQ') {
      if (globalStore.AppStore.ortho_mode && globalStore.AppStore.isDrawingPolygonOrPolyline) return;
      const updateState = globalStore.AppStore?.updateState;
      updateState({ arc_mode: true, ortho_mode: false });
    } else if (e.shiftKey) {
      if (globalStore?.AppStore?.arc_mode && globalStore.AppStore.isDrawingPolygonOrPolyline) return;
      const updateState = globalStore.AppStore?.updateState;
      updateState({ ortho_mode: !globalStore.AppStore?.ortho_mode, arc_mode: false });
    }
  };

  // Find intersecting segments

  findIntersectingSegments(coordinates: number[][]) {
    const segments: number[][][] = [];
    const intersectingSegments: number[][][] = [];
    let lastSegmentIntersects = false;

    for (let i = 0; i < coordinates.length - 1; i++) {
      segments.push([coordinates[i], coordinates[i + 1]]);
    }

    const lastSegment = segments[segments.length - 2];

    for (let i = 0; i < segments.length - 1; i++) {
      for (let j = i + 1; j < segments.length; j++) {
        if (j === i + 1 || (i === 0 && j === segments.length - 1)) continue;
        if (this.doSegmentsIntersect(segments[i], segments[j])) {
          if (!intersectingSegments.includes(segments[i])) {
            intersectingSegments.push(segments[i]);
          }
          if (!intersectingSegments.includes(segments[j])) {
            intersectingSegments.push(segments[j]);
          }
          if (this.areSegmentsEqual(segments[i], lastSegment) || this.areSegmentsEqual(segments[j], lastSegment)) {
            lastSegmentIntersects = true;
          }
        }
      }
    }
    for (let i = intersectingSegments.length - 1; i >= 0; i--) {
      const segment = intersectingSegments[i];
      let stillIntersects = false;
      for (const otherSegment of segments) {
        if (segment !== otherSegment && this.doSegmentsIntersect(segment, otherSegment)) {
          stillIntersects = true;
          break;
        }
      }
      if (!stillIntersects) {
        intersectingSegments.splice(i, 1);
      }
    }

    return [segments, intersectingSegments, lastSegmentIntersects];
  }

  areSegmentsEqual(segment1: number[][], segment2: number[][]): boolean {
    const [[x1, y1], [x2, y2]] = segment1;
    const [[a1, b1], [a2, b2]] = segment2;

    return (
      (x1 === a1 && y1 === b1 && x2 === a2 && y2 === b2) || // Check same order
      (x1 === a2 && y1 === b2 && x2 === a1 && y2 === b1) // Check reversed order
    );
  }

  // Check if two line segments intersect
  doSegmentsIntersect(seg1: number[][], seg2: number[][]): boolean {
    const [p1, p2] = seg1;
    const [q1, q2] = seg2;

    function crossProduct(p: number[], q: number[], r: number[]) {
      return (q[0] - p[0]) * (r[1] - p[1]) - (q[1] - p[1]) * (r[0] - p[0]);
    }

    const d1 = crossProduct(p1, p2, q1);
    const d2 = crossProduct(p1, p2, q2);
    const d3 = crossProduct(q1, q2, p1);
    const d4 = crossProduct(q1, q2, p2);

    return d1 * d2 < 0 && d3 * d4 < 0;
  }

  onDrawStart = () => {
    this.drawDone = false;
    this.prevState = null;

    const updateState = globalStore.AppStore?.updateState;
    updateState({ isDrawingPolygonOrPolyline: true });

    if (globalStore.AppStore.tool.add_comment) {
      const commentTool = TOOL_MAP[TOOL_TYPE.ADD_COMMENT]?.getObject();
      commentTool.onDrawStart();
    }
  };

  /**
   * Event handler for the drawend event.
   * @param e - The drawend event object.
   */
  onDrawEnd = (event: any) => {
    const feature = event.feature;
    const isAerial = this.mapObj.map_type === MAP_TYPE.AERIAL;
    try {
      if (feature.getGeometry().getType() === 'Polygon') {
        const isIntersecting = isValid(feature);

        const isOutExtent =
          this.mapObj.map_type === MAP_TYPE.AERIAL
            ? false
            : FeatureisOutOfExtent(feature.getGeometry().getExtent(), this.mapObj.map);
        if ((!isIntersecting || isOutExtent) && !isAerial) {
          if (!isOutExtent) {
            showToast('Invalid feature', 'error', {
              position: 'top-center',
              hideProgressBar: false
            });
          }
          setTimeout(() => {
            this.layer.getSource().removeFeature(feature);
          }, 0);
        } else {
          if (isAerial && !feature.isValid()) {
            showToast('Invalid feature please fix the highlighed feature', 'error', {
              position: 'top-center',
              hideProgressBar: false
            });
          }

          if (globalStore.AppStore.tool.add_comment) {
            const commentTool = TOOL_MAP[TOOL_TYPE.ADD_COMMENT]?.getObject();
            commentTool.onDrawEnd(event);
            this.drawDone = true;
          } else {
            const source = this.layer?.getSource();

            const unq_id = generateUniqueID('polyline');
            feature.setId(unq_id);

            // Manually add feature to source if not added automatically
            if (source && !source.getFeatures().includes(feature)) {
              source.addFeature(feature);
            }

            setTimeout(() => {
              triggerOverrideOverlap(feature);
              this.prevState = feature.clone();
              undoRedoPush();
              this.drawDone = true;
            }, 0);
          }
        }
      }
    } catch (error) {}
    this.resetToInitialState();
  };

  getUniqCoordArr = (coords: Coordinate[]) => {
    const map = new Map<string, boolean>();
    const newCoords: Coordinate[] = [];
    for (let index = 0; index < coords.length - 1; index++) {
      const element = coords[index];
      const key = JSON.stringify(element);
      if (!!map.get(key) === false) {
        newCoords.push(element);
        map.set(key, true);
      }
    }
    newCoords.push(coords[0]);
    return newCoords;
  };

  getUniqCoord = (coords: ArcCoordinateType[]) => {
    const map = new Map<string, boolean>();
    const newCoords: ArcCoordinateType[] = [];
    for (let index = 0; index < coords.length - 1; index++) {
      const element = coords[index];
      const key = JSON.stringify(element.coordinate);
      if (!!map.get(key) === false) {
        newCoords.push(element);
        map.set(key, true);
      }
    }
    newCoords.push(coords[0]);
    return newCoords;
  };

  getArcCoordinate = (allCoords: ArcCoordinateType[]) => {
    let coords: Coordinate[] = [];
    const geoCoords = this.getUniqCoord(allCoords);
    for (let index = 0; index < geoCoords.length; index++) {
      const { coordinate, isMidPoint } = geoCoords[index];
      if (isMidPoint) {
        coords = [...coords, ...getArc(geoCoords[index - 1].coordinate, coordinate, geoCoords[index + 1].coordinate)];
      } else {
        if (index + 1 < geoCoords.length) {
          if (geoCoords[index + 1].isMidPoint === false) {
            coords.push(coordinate);
          }
        } else coords.push(coordinate);
      }
    }
    coords = this.getUniqCoordArr(coords);
    return coords;
  };

  geometryFunction = (coordinates: any, geometry: any, event: any) => {
    if (!geometry) {
      geometry = new Polygon(coordinates);
    } else {
      geometry.setCoordinates(coordinates);
    }
    this.didPointMoved = true;

    const points = coordinates[0];
    if (points?.length > 1) {
      const lastAppendedInd = this.coordinateRef.length;
      const lastClickedInd = points.length - 1;
      if (lastAppendedInd) {
        if (
          JSON.stringify(this.coordinateRef[lastAppendedInd - 1].coordinate) !==
          JSON.stringify(points[lastClickedInd - 1])
        ) {
          if (globalStore?.AppStore?.arc_mode) {
            const updateState = globalStore.AppStore?.updateState;
            if (this.coordinateRef[lastAppendedInd - 1].isMidPoint) {
              updateState({ arc_mode: false });
            }
            this.coordinateRef.push({
              coordinate: points[lastClickedInd - 1],
              isMidPoint: globalStore?.AppStore?.arc_mode
            });
            updateState({ arc_mode: false });
          } else {
            this.coordinateRef.push({
              coordinate: points[lastClickedInd - 1],
              isMidPoint: false
            });
          }
        }
      } else {
        this.coordinateRef.push({
          coordinate: points[0],
          isMidPoint: false
        });
      }

      if (this.coordinateRef.length > 1) {
        const newCoordinates: ArcCoordinateType[] = [
          ...this.coordinateRef,
          { coordinate: points[lastClickedInd], isMidPoint: false },
          { coordinate: points[0], isMidPoint: false }
        ];
        const newGeometryCoord = this.getArcCoordinate(newCoordinates);
        geometry.setCoordinates([newGeometryCoord]);
      }
    }

    if (globalStore.AppStore.ortho_mode) {
      const coords = geometry?.getCoordinates()[0];
      if (coords.length === 2) {
        const lastCoord = coords[coords.length - 1];
        const sourceCoord = coords[coords.length - 2];

        const angle = getSnappingAngle(getAngle(sourceCoord, lastCoord), ORTHO_ANGLE);

        const interactionCoord = getIntersection(
          {
            angle: angle,
            coordinate: sourceCoord
          },
          {
            angle: 90,
            coordinate: lastCoord
          }
        );
        coords[coords.length - 1] = interactionCoord;
        geometry.setCoordinates([coords]);
      } else if (coords.length > 2) {
        const lastCoord = coords[coords.length - 2];
        const sourceCoord = coords[coords.length - 3];

        const angle = getSnappingAngle(getAngle(sourceCoord, lastCoord), ORTHO_ANGLE);

        const interactionCoord = getIntersection(
          {
            angle: angle,
            coordinate: sourceCoord
          },
          {
            angle: 90,
            coordinate: lastCoord
          }
        );
        coords[coords.length - 2] = interactionCoord;
        geometry.setCoordinates([coords]);
      }
    }
    return geometry;
  };

  resetToInitialState = (resetOrthoMode = false) => {
    const updateState = globalStore.AppStore?.updateState;
    updateState({
      arc_mode: false,
      isDrawingPolygonOrPolyline: false,
      ...(resetOrthoMode ? { ortho_mode: false } : {})
    });
    this.coordinateRef = [];
  };

  /**
   * Disables the useAddPolygon interaction.
   * Removes the Draw interaction from the map.
   * Removes the event listener for drawend and keydown.
   */
  off = () => {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.draw?.un('drawend', this.onDrawEnd);
    window.removeEventListener('keydown', this.keyDownHandler);
    this.drawDone = false;
    this.prevState = null;
    this.resetToInitialState(true);
  };
}

export default DrawPolygon;
