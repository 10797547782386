import { styled } from '@attentive-platform/stem-ui';
import { css } from '@emotion/css';

export const SelectMenuContainer = styled('div')<{ top: number; left: number }>(({ top, left }) => ({
  position: 'absolute',
  backgroundColor: 'white',
  borderRadius: '0.375rem',
  padding: '4px 14.451px 6px 14.451px',
  top,
  left,
  boxShadow: '6px 6px 18px 0px #00000014',
  display: 'flex',
  gap: '1rem'
}));

export const iconContainer = css`
  display: flex;
  gap: 0.5rem;
`;

export const iconButton = css`
  height: 2.25rem;
  cursor: pointer;
  width: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const disabled = css`
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
`;
