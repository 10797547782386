import { Snap } from 'ol/interaction';
import { create } from 'zustand';

export type snapStoreType = {
  snapEnabled: boolean;
  snaps: Snap[] | null;
  populateSnaps: (snaps: Snap[]) => void;
  toggleSnap: (val: boolean) => void;
  geoJson: any;
  clearSnap: () => void;
};

const useSnapStore = create<snapStoreType>(set => ({
  snaps: [],
  populateSnaps: (snaps: Snap[]) => set((state: snapStoreType) => ({ ...state, snaps: snaps })),

  snapEnabled: true,
  toggleSnap: (val: boolean) =>
    set((state: snapStoreType) => ({
      ...state,
      snapEnabled: val
    })),

  geoJson: null,
  setGeoJson: (val: any) =>
    set((state: snapStoreType) => ({
      ...state,
      geoJson: val
    })),
  clearSnap: () => set((state: snapStoreType) => ({ ...state, snaps: [] }))
}));

export default useSnapStore;
