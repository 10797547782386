import { Draw } from 'ol/interaction';
import MapBase from '../../mapLayer/mapBase';
import Interaction from 'ol/interaction/Interaction';
import { isOutOfExtent } from '../../../helpers/helpers';
import { ARROW_TOOL, GEOMETRY_TYPES, TOOL_SCOPE } from 'woodpecker';
import { undoRedoPush } from '../../mapLayer/mapInit';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { LineString } from 'ol/geom';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { Type } from 'ol/geom/Geometry';
import { generateUniqueID, isValidLineString } from 'macaw';
import { showToast } from 'ui';
import { styleFunctionArrow } from '../../../hooks/addition';
import { Feature } from 'ol';

class ArrowTool extends ToolAbstract {
  private mapObj: MapBase;
  draw: Draw | null;
  private snap: Interaction[] | null = null;
  private layer: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.snap = null;
  }
  /**
   * Initializes the tool for drawing lines on the map.
   * Turns off any existing tool and sets up the draw interaction for the specified layer ID.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init(id: string) {
    this.off();
    let layer = this.mapObj.getLayerById(ARROW_TOOL);

    if (layer) {
      const source = layer.getSource();
      this.mapObj.map!.getViewport().style.cursor = 'crosshair';

      this.draw = new Draw({
        source: source,
        type: GEOMETRY_TYPES.LINESTRING as Type,
        style: (feature: any) => {
          return styleFunctionArrow(feature);
        },
        dragVertexDelay: 0,
        snapTolerance: 1,
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        }
      });

      this.mapObj.map?.addInteraction(this.draw);
      this.draw?.on('drawend', this.onDrawEnd);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  }

  keyDownHandler = (e: KeyboardEvent) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  /**
   * Handles the "drawend" event, adding snap interactions back if the draw mode is freehand and pushing undo/redo actions.
   * @param {any} e - The event object containing information about the draw end.
   */
  /**
   * Event handler for the drawend event.
   * @param e - The drawend event object.
   */
  onDrawEnd = (e: { feature: Feature }) => {
    if (isValidLineString(e.feature)) {
      const tool = globalStore?.AppStore?.tool;
      const unq_id = generateUniqueID('arrow');
      e.feature.setId(unq_id);
      e.feature?.setProperties({
        vector_layer_id: ARROW_TOOL,
        excluded: true,
        external: !!globalStore?.AppStore?.tool?.external,
        tool_source: !!globalStore?.AppStore?.tool?.external ? TOOL_SCOPE.EXTERNAL : TOOL_SCOPE.INTERNAL
      });
      setTimeout(() => {
        undoRedoPush();
      }, 0);
    } else {
      e.feature.setGeometry(new LineString([]));
      showToast('Invalid feature: Intersecting Line not allowed', 'error', {
        position: 'top-center',
        hideProgressBar: false
      });
      setTimeout(() => {
        this.layer.getSource().removeFeature(e.feature);
      }, 0);
    }
  };

  /**
   * Turns off the draw interaction by removing it from the map, removing the snap interaction, and removing event listeners.
   */
  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.mapObj.map?.removeInteraction(this.snap as any);
    this.draw && this.draw.un('drawend', this.onDrawEnd);
    window.removeEventListener('keydown', this.keyDownHandler);
    this.mapObj.map!.getViewport().style.cursor = '';
  }
}

export default ArrowTool;
