import React, { RefObject, useEffect, useState } from 'react';
import { SelectMenuContainer, iconContainer } from './styles';
import { Typography, Divider } from 'ui';
import useMapStore from '../../store/mapStore';
import olFeature from 'ol/Feature';
import ToolList from './components/ToolList';
import { GEOMETRY_TYPE, GEOMETRY_TYPES_VALUE, ToolBarItemsTypes, renderSvg } from 'woodpecker';
import CustomToolList from './components/CustomToolList';
import UpArrow from 'ui/src/Assets/UpArrow';
import DownArrowOutlined from 'ui/src/Assets/DownArrowOutlined';
import { DropdownMenu } from 'ui';
import { TOOL_TYPE } from '../../js-layer/toolLayer/constants';

const getUnique = (list: string[]) => {
  const map = new Map<string, boolean>();
  const uniList = list.filter(item => {
    if (map.has(item)) return false;
    else {
      map.set(item, true);
      return true;
    }
  });
  return uniList;
};

const ToolBarOverlay = ({
  toolBarOverlayRef,
  customToolBarItems,
  disabled
}: {
  toolBarOverlayRef: RefObject<HTMLDivElement>;
  customToolBarItems: ToolBarItemsTypes[];
  disabled: boolean;
}) => {
  const [types, setTypes] = useState<{ label: string; value: string; icon: JSX.Element }[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const selectedFeatures = useMapStore(state => state.selectedFeatures);
  const { layers, updateState, selectedTool } = useMapStore(state => ({
    layers: state.layers,
    updateState: state.updateState,
    selectedTool: state.tool
  }));
  const selectObjRef = useMapStore(state => state.selectObjRef);
  const [featuresMap, setFeaturesMap] = useState<Map<number, string[]>>(new Map());
  const count = selectedFeatures?.length ?? 0;

  const getIsAbstract = (feature: olFeature) => {
    const layerID = feature.getProperties().vector_layer_id;
    return layers.get(layerID)?.geometry_type === GEOMETRY_TYPE.ABSTRACT || false;
  };
  const getIsTypical = (feature: olFeature) => {
    const layerID = feature.getProperties().vector_layer_id;
    return layers.get(layerID)?.geometry_type === GEOMETRY_TYPE.TYPICAL || false;
  };

  const handleMenuClick = (item: string) => {
    const featuresToBeRemoved: olFeature[] = [];
    selectObjRef
      ?.getFeatures()
      .getArray()
      ?.forEach(feature => {
        const geom = feature?.getGeometry()?.getType();
        if (geom === 'Point' && item === 'Abstract') {
          if (!getIsAbstract(feature)) {
            featuresToBeRemoved.push(feature);
          }
        } else if (geom === 'Point' && item === 'Typical') {
          if (!getIsTypical(feature)) {
            featuresToBeRemoved.push(feature);
          }
        } else if (geom === 'Point' && item === 'Point') {
          if (getIsAbstract(feature) || getIsTypical(feature)) {
            featuresToBeRemoved.push(feature);
          }
        } else if (geom !== item) {
          featuresToBeRemoved.push(feature);
        }
      });
    featuresToBeRemoved.forEach(feature => {
      selectObjRef?.getFeatures().remove(feature);
    });
    selectObjRef?.dispatchEvent('select');
    setOpen(false);
  };

  const getPlaceholder = () => {
    if (featuresMap.size === 1) {
      if (featuresMap.has(GEOMETRY_TYPE.POINT)) return `${count} Points`;
      if (featuresMap.has(GEOMETRY_TYPE.POLYGON)) return `${count} Polygon`;
      if (featuresMap.has(GEOMETRY_TYPE.LINE)) return `${count} Line`;
      if (featuresMap.has(GEOMETRY_TYPE.ABSTRACT)) return `${count} Abstract`;
      if (featuresMap.has(GEOMETRY_TYPE.TYPICAL)) return `${count} Typical`;
    }
    return `${count} Objects`;
  };

  useEffect(() => {
    const map = new Map<number, string[]>();
    const selectedLayerSet = new Set<string>();
    const features = selectedFeatures || [];
    const newType = new Set();
    features?.forEach(feature => {
      const layerID = feature?.getProperties()?.vector_layer_id;
      let geometry_type = layers?.get(layerID)?.geometry_type || 1;
      const record: string[] = map.get(geometry_type) || [];
      record.push(layerID);
      map.set(geometry_type, getUnique(record));
      selectedLayerSet.add(layerID);
      const geom = feature.getGeometry()?.getType();
      if (geom === 'Point') {
        if (getIsAbstract(feature)) {
          newType.add('Abstract');
        } else if (getIsTypical(feature)) {
          newType.add('Typical');
        } else {
          newType.add(geom);
        }
      } else newType.add(geom);
    });
    setFeaturesMap(map);
    updateState({ selectedFeaturesLayer: selectedLayerSet });
    const finalTypes: { label: string; value: string; icon: JSX.Element }[] = [];
    Array.from(newType).forEach(type => {
      finalTypes.push({
        label: type as string,
        value: type as string,
        icon: renderSvg[GEOMETRY_TYPES_VALUE[type as string]]('#000')
      });
    });
    setTypes(finalTypes);

    return () => {
      updateState({ selectedFeaturesLayer: new Set<string>() });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeatures, layers]);

  return (
    <div
      id='tool_overlay'
      ref={toolBarOverlayRef}
      style={{
        zIndex: 100,
        ...(selectedTool?.tool_id !== TOOL_TYPE.MULTI_SELECT && {
          display: 'none'
        })
      }}>
      <SelectMenuContainer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Typography variant='body5' color='#808080' sx={{ lineHeight: '1.125rem' }}>
            Selected
          </Typography>
          <span style={{ width: '8rem' }}>
            {featuresMap.size <= 1 ? (
              <Typography variant='body2' sx={{ lineHeight: '1.375rem' }}>
                {getPlaceholder()}
              </Typography>
            ) : (
              <DropdownMenu
                noValuePlaceholder={getPlaceholder()}
                color='neutral'
                open={open}
                style={{
                  background: 'none',
                  height: '2rem',
                  padding: '0px 2px 0 2px',
                  width: '100%'
                }}
                menuItems={types}
                endUpIcon={<UpArrow />}
                onButtonClick={() => setOpen(prevState => !prevState)}
                endDownIcon={<DownArrowOutlined />}
                handleClose={() => setOpen(false)}
                onMenuItemClick={(e, item) => handleMenuClick(item.value as string)}
              />
            )}
          </span>
        </div>
        <Divider orientation='vertical' flexItem />
        {!disabled && (
          <div>
            <Typography variant='body5' color='#808080' sx={{ lineHeight: '1.125rem' }}>
              Geometries
            </Typography>
            <div className={iconContainer}>
              <ToolList featuresMap={featuresMap} />
            </div>
          </div>
        )}
        {!!customToolBarItems.length && (
          <>
            <Divider orientation='vertical' flexItem />
            <div>
              <Typography variant='body5' color='#808080' sx={{ lineHeight: '1.125rem' }}>
                Features
              </Typography>
              <div className={iconContainer}>
                <CustomToolList featuresMap={featuresMap} customToolBarItems={customToolBarItems} />
              </div>
            </div>
          </>
        )}
      </SelectMenuContainer>
    </div>
  );
};

export default ToolBarOverlay;
