import { mapObj } from '../../mapLayer/mapInit';

class Action {
  title: string;
  action_id: string;
  actionClass: any;
  actionObj?: any;

  constructor(title: string, action_id: string, actionClass: any) {
    this.title = title;
    this.action_id = action_id;
    this.actionClass = actionClass;
  }
  /**
   * Gets the instance of the action object.
   * If the action object does not exist, creates a new instance.
   * @returns {any} The instance of the action object.
   */
  getObject = (): any => {
    const actionClass = this.actionClass;
    if (!this.actionObj && actionClass) {
      this.actionObj = new actionClass(mapObj);
    }
    return this.actionObj;
  };
}

export default Action;
