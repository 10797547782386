import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON'; // <= 1e-160 raise error after that
import { Geometry } from 'ol/geom';
import { GEO_JSON_OBJECT_PROJECTION } from 'woodpecker';

const GEO_JSON = {
  writeFeaturesObject: (features: Feature<Geometry>[], projection = true) => {
    if (!projection) {
      return new GeoJSON().writeFeaturesObject(features);
    }
    return new GeoJSON().writeFeaturesObject(features, GEO_JSON_OBJECT_PROJECTION);
  },
  writeFeatureObject: (feature: Feature<Geometry>, projection = true) => {
    if (!projection) {
      return new GeoJSON().writeFeatureObject(feature);
    }
    return new GeoJSON().writeFeatureObject(feature, GEO_JSON_OBJECT_PROJECTION);
  },
  readFeature: (feature: any, projection = true) => {
    if (!projection) {
      return new GeoJSON().readFeature(feature);
    }
    return new GeoJSON().readFeature(feature, GEO_JSON_OBJECT_PROJECTION);
  },
  readFeatures: (features: any, projection = GEO_JSON_OBJECT_PROJECTION) => {
    if (!projection) {
      return new GeoJSON().readFeatures(features);
    }
    return new GeoJSON().readFeatures(features, projection);
  },
  writeFeatures: (features: Feature<Geometry>[], projection = GEO_JSON_OBJECT_PROJECTION) => {
    if (!projection) {
      return new GeoJSON()?.writeFeatures(features);
    }
    return new GeoJSON()?.writeFeatures(features, projection);
  }
};

export default GEO_JSON;
