import { styled } from '@attentive-platform/stem-ui';

export const ContextMenu = styled('div')<{ top: number; left: number; display: string }>(
  ({ top, left, theme, display }) => ({
    position: 'absolute',
    backgroundColor: 'white',
    color: theme.palette.neutral?.['100'],
    borderRadius: '0.375rem',
    boxSizing: 'border-box',
    top,
    left,
    boxShadow: '6px 6px 18px 0px #00000014',
    display
  })
);

export const ContextMenuList = styled('ul')(() => ({
  margin: '0',
  listStyle: 'none'
}));

export const EllipsisView = styled('span')(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

export const TileContainer = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  width: '7.875rem',
  height: '2.938rem',
  padding: '0 0 0 0.5rem',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary[10]
  }
}));
