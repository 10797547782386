import create from 'zustand';
import { DEFAULT_COLOR } from '../constants';

interface ColorState {
  color: string;
  setColor: (newColor: string) => void;
}

export const getDataFromLocalStorage = (key: string) => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem(key);
};

export const setLocalstorageData = (key: string, value: any) => {
  if (typeof window === 'undefined') return null;
  localStorage.setItem(key, value);
};

const getStoredHighlightColor = (defaultColor: string): string => {
  const userInfo = getDataFromLocalStorage('user');
  const userId = userInfo ? JSON.parse(userInfo).id : null;
  const highLightColorInfo = getDataFromLocalStorage('tool.highLight-layer-colors');
  const colors = highLightColorInfo ? JSON.parse(highLightColorInfo) : {};

  return userId && colors[userId] ? colors[userId] : defaultColor;
};

const useHighlightColorStore = create<ColorState>(set => ({
  color: getStoredHighlightColor(DEFAULT_COLOR),
  setColor: newColor => {
    set({ color: newColor });
    const userInfo = getDataFromLocalStorage('user');
    const userId = userInfo ? JSON.parse(userInfo).id : null;
    if (userId) {
      const highLightColorInfo = getDataFromLocalStorage('tool.highLight-layer-colors');
      const colors = highLightColorInfo ? JSON.parse(highLightColorInfo) : {};
      colors[userId] = newColor;
      setLocalstorageData('tool.highLight-layer-colors', JSON.stringify(colors));
    }
  }
}));

export default useHighlightColorStore;
