import { css } from '@emotion/css';

export const textBox = css`
  background: #fff;
  z-index: 10;
  resize: both !important;
  overflow: auto;
  padding: 0.625rem;
  min-height: 5rem;
  min-width: 10rem;
  border-radius: 4px;
  border: 2px solid rgba(235, 235, 235, 1);
`;

export const textLabel = css`
  background: #fff;
  z-index: 10;
  padding: 0.625rem;
  min-height: 5rem;
  min-width: 10rem;
  border-radius: 4px;
  border: 2px solid rgba(235, 235, 235, 1);
  display: none;
  flex-direction: column;
`;

export const container = css`
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
  position: fixed;
  border-radius: 4px;
  padding: 1px;
  display: none;
`;

export const styleBox = css`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -4rem;
  left: 0;
  padding: 0.5rem;
  box-shadow: 5.57px 5.57px 16.7px 0px rgba(0, 0, 0, 0.08);
  background: white;
  gap: 1rem;
  height: 3rem;
  border-radius: 4px;
  align-items: center;
`;

export const textColor = css`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
`;
