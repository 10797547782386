import { Draw } from 'ol/interaction';
import Interaction from 'ol/interaction/Interaction';
import { GEOMETRY_TYPES, ORTHO_ANGLE } from 'woodpecker';
import { Coordinate } from 'ol/coordinate';
import { LineString } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';
import { generateUniqueID, getIntersection, isValidLineString } from 'macaw';
import { showToast } from 'ui';
import { getAngle, getSnappingAngle } from 'macaw/src/getArc';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { formatLength } from '../../../hooks/tools/helpers';
import { crossHairStyle, labelStyle, polylineStyle } from '../../../hooks/tools/helpers/styles';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { getAllFeaturesSource, isOutOfExtent } from '../../../helpers/helpers';
import MapBase from '../../mapLayer/mapBase';

class AddSegment extends ToolAbstract {
  mapObj: MapBase;

  draw: Draw | null;

  snap: Interaction[] | null = null;

  layer: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.snap = null;
  }

  /**
   * Initializes the tool for drawing lines on the map.
   * Turns off any existing tool and sets up the draw interaction for the specified layer ID.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init(id: string, featureTracing: boolean = false) {
    this.off();

    const layer = this.mapObj.getLayerById(id);
    this.layer = layer;
    if (layer) {
      const source = layer.getSource();

      const targetLayerSource = getAllFeaturesSource();
      this.draw = new Draw({
        source,
        type: GEOMETRY_TYPES.LINESTRING as Type,
        style: (feature: any) => {
          return this.styleFunction(feature);
        },
        dragVertexDelay: 0,
        snapTolerance: 1,
        maxPoints: 2,
        minPoints: 2,
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        },
        trace: featureTracing,
        traceSource: targetLayerSource,
        geometryFunction: this.geometryFunction
      });

      this.mapObj.map?.addInteraction(this.draw);
      this.draw.on('drawstart', this.onDrawStart);
      this.draw?.on('drawend', this.onDrawEnd);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  }

  styleFunction(feature: any) {
    const { scale, dpi } = globalStore.AppStore.worksheetParams;

    let styles = [...polylineStyle];

    if (globalStore.AppStore.ortho_mode) {
      styles = [crossHairStyle];
    }

    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.LINESTRING && scale !== null) {
      geometry?.forEachSegment((a: Coordinate, b: Coordinate) => {
        const segment = new LineString([a, b]);
        const label = formatLength(segment, dpi, scale || 0);
        const _labelStyle = labelStyle.clone();
        _labelStyle.setGeometry(segment);
        _labelStyle.getText().setText(label);
        styles.push(_labelStyle);
      });
    }
    return styles;
  }

  /**
   * Handles the "drawstart" event, removing snap interactions if the draw mode is freehand.
   * @param {any} e - The event object containing information about the draw start.
   */
  onDrawStart = (e: any) => {
    this.snap?.forEach(snap => {
      this.mapObj.map?.removeInteraction(snap);
    });

    // let overlay = appStore?.measurementOverlay;

    // let sketch = e.feature;
    // this.listner = sketch.getGeometry().on('change', event => {
    //     const geom = event.target;

    //     if (geom instanceof LineString) {
    //         showLength(geom, overlay);
    //     }
    // });
  };

  /**
   * Handles key down events, specifically removing the last point from the draw interaction if the Backspace key is pressed,
   * and finishing the drawing if the Space key is pressed.
   * @param {KeyboardEvent} e - The keyboard event object.
   */
  keyDownHandler = (e: KeyboardEvent) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    } else if (e.shiftKey) {
      const updateState = globalStore.AppStore?.updateState;
      updateState({ ortho_mode: !globalStore.AppStore?.ortho_mode });
    }
  };

  /**
   * Handles the "drawend" event, adding snap interactions back if the draw mode is freehand and pushing undo/redo actions.
   * @param {any} e - The event object containing information about the draw end.
   */
  /**
   * Event handler for the drawend event.
   * @param e - The drawend event object.
   */
  onDrawEnd = (e: any) => {
    if (isValidLineString(e.feature)) {
      const unq_id = generateUniqueID('segment');
      e.feature.setId(unq_id);
      setTimeout(() => {
        undoRedoPush();
      }, 0);
    } else {
      e.feature.setGeometry(new LineString([]));
      showToast('Invalid feature: Intersecting Line not allowed', 'error', {
        position: 'top-center',
        hideProgressBar: false
      });
      setTimeout(() => {
        this.layer.getSource().removeFeature(e.feature);
      }, 0);
    }
  };

  geometryFunction = (coordinates: any, geometry: any) => {
    if (!geometry) {
      geometry = new LineString(coordinates);
    } else {
      geometry.setCoordinates(coordinates);
    }

    if (globalStore.AppStore.ortho_mode) {
      const coords = geometry?.getCoordinates();
      const lastCoord = coords[coords.length - 1];
      const sourceCoord = coords[coords.length - 2];
      const angle = getSnappingAngle(getAngle(sourceCoord, lastCoord), ORTHO_ANGLE);

      const interactionCoord = getIntersection(
        {
          angle,
          coordinate: sourceCoord
        },
        {
          angle: 90,
          coordinate: lastCoord
        }
      );
      coords[coords.length - 1] = interactionCoord;
      geometry.setCoordinates(coords);
    }

    return geometry;
  };

  /**
   * Turns off the draw interaction by removing it from the map, removing the snap interaction, and removing event listeners.
   */
  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.mapObj.map?.removeInteraction(this.snap as any);
    this.draw && this.draw.un('drawend', this.onDrawEnd);
    window.removeEventListener('keydown', this.keyDownHandler);
    const updateState = globalStore.AppStore?.updateState;
    updateState({ ortho_mode: false });
  }
}

export default AddSegment;
