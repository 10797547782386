import Split from 'ol-ext/interaction/Split';
import turfDistance from '@turf/distance';
import { point } from '@turf/helpers';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { LineString } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { lineString } from '@turf/helpers';
import { length, lineSplit, nearestPointOnLine } from '@turf/turf';
import VectorLayer from 'ol/layer/Vector';
import { generateUniqueID } from 'macaw';
import { UNSET_PROPERTIES } from 'woodpecker';
import Feature from 'ol/Feature';
import { fromLonLat, toLonLat } from 'ol/proj';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import MapBase from '../../mapLayer/mapBase';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { lineStyles } from '../../../hooks/tools/helpers/styles';

export const SNIPPING_MODES = {
  ONE_CLICK: 'line_snipping',
  TWO_CLICK: 'line_snipping_two_point'
};

const DEFAULT_MODE = SNIPPING_MODES.ONE_CLICK;
const DISTANCE_FACTOR = 1e-2;

const getRemovalLayer = (zIndex: number) => {
  return new VectorLayer({
    source: new VectorSource({
      wrapX: false
    }),
    //@ts-ignore
    id: 'removal_layer',
    zIndex,
    style: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({ color: 'rgba(0, 255, 0, 0.8)' }),
        stroke: new Stroke({ color: 'white', width: 1 })
      }),
      stroke: new Stroke({
        color: 'red',
        width: 5
      })
    })
  });
};

class LineSnippingTool extends ToolAbstract {
  private mapObj: MapBase;
  private split: any;
  private transactionComplete: boolean;
  private layer: any;
  private snap: any;
  private mode: string;
  private event: any;
  private firstPoint: Array<any> | null;
  private lastPoint: Array<any> | null;
  private features: any;
  private original: any;
  private removalLayer: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.split = null;
    this.mode = DEFAULT_MODE;
    this.transactionComplete = true;
    this.firstPoint = null;
    this.lastPoint = null;
    this.removalLayer = null;
  }

  init(id: string, featureTracing: boolean = false, mode = DEFAULT_MODE) {
    this.off();
    this.mode = mode;
    this.layer = this.mapObj.getLayerById(id);
    if (this.layer) {
      const splitOptions: any = {
        sources: this.layer.getSource(),
        snapDistance: 25,
        tolerance: 1e-7
      };

      if (this.mode === SNIPPING_MODES.TWO_CLICK) {
        splitOptions.filter = this.filterFn;
      }
      this.split = new Split(splitOptions);

      const existingLayerIndex = this.layer.getZIndex();
      const overlayLayerZIndex = existingLayerIndex + 10;

      this.split.overlayLayer_.setStyle(lineStyles);
      this.split.overlayLayer_.setZIndex(overlayLayerZIndex);

      const source = this.split?.overlayLayer_?.getSource();
      if (source?.wrapX_) source.wrapX_ = false;
      this.mapObj.map?.addInteraction(this.split);

      this.removalLayer = getRemovalLayer(existingLayerIndex + overlayLayerZIndex);
      this.mapObj.map?.addLayer(this.removalLayer);

      this.split.on('aftersplit', this.afterSplit);
      this.split.on('pointermove', this.handlePointerMove);
      if (this.mode === SNIPPING_MODES.TWO_CLICK) {
        this.resetValues();
        this.split.on('beforesplit', this.beforeSplit);
      }
      window.addEventListener('keydown', this.handleKeyDawn);
    }
  }

  off() {
    if (!this.transactionComplete) {
      this.backToInitialStage();
    } else {
      this.resetValues();
    }
    this.removalLayer && this.mapObj.map?.removeLayer(this.removalLayer);
    this.split && this.mapObj.map?.removeInteraction(this.split);
    this.split && this.split.un('aftersplit', this.afterSplit);
    this.split && this.split.un('beforesplit', this.beforeSplit);
    this.split && this.split.un('pointermove', this.handlePointerMove);
    window.removeEventListener('keydown', this.handleKeyDawn);
  }

  handlePointerMove = (e: any) => {
    this.event = e;
    const source = this.split.overlayLayer_.getSource();
    const features = source.getFeatures();
    if (features.length > 2 && (this.mode === SNIPPING_MODES.ONE_CLICK || this.firstPoint)) {
      const actualLineCoordinates = features[0].getGeometry().getCoordinates();
      const pointCoordinates = features[1].getGeometry().getCoordinates();
      // const drawLineCoordinates = features[2].getGeometry().getCoordinates();
      const nearestPointFromLine = toLonLat(pointCoordinates);

      const line = lineString(actualLineCoordinates.map(c => toLonLat(c)));
      const snappedPoint = nearestPointOnLine(line, nearestPointFromLine);
      const splitLine = lineSplit(line, point(snappedPoint.geometry.coordinates));

      if (splitLine.features.length > 1) {
        let featureIndex = 0;
        if (this.mode === SNIPPING_MODES.TWO_CLICK && this.firstPoint) {
          const fp = point(toLonLat(this.firstPoint));
          const nearestFp0 = nearestPointOnLine(splitLine.features[0], fp);
          const nearestFp1 = nearestPointOnLine(splitLine.features[1], fp);
          const dist0 = nearestFp0.properties.dist as number;
          const dist1 = nearestFp1.properties.dist as number;
          featureIndex = dist0 > dist1 ? 1 : 0;
        } else {
          const length0 = length(splitLine.features[0]);
          const length1 = length(splitLine.features[1]);
          featureIndex = length0 < length1 ? 0 : 1;
        }
        const lineUpToPoint = splitLine.features[featureIndex];

        if (lineUpToPoint) {
          const newLineCoordinates = lineUpToPoint.geometry.coordinates.map(c => fromLonLat(c));
          const newLineFeature = new Feature({
            geometry: new LineString(newLineCoordinates)
          });
          this.removalLayer.getSource().clear();
          this.removalLayer.getSource().addFeature(newLineFeature);
        }
      }
    } else {
      this.removalLayer.getSource().clear();
    }
  };

  beforeSplit = (e: any) => {
    this.transactionComplete = false;
    if (!this.firstPoint) {
      this.firstPoint = this.event.coordinate;
    } else if (!this.lastPoint) {
      this.lastPoint = this.event.coordinate;
    }
  };

  afterSplit = (e: any) => {
    e.features.map((feature: Feature) => {
      const unq_id = generateUniqueID('line_spinning');
      feature.setId(unq_id);
      UNSET_PROPERTIES.forEach(property => {
        feature.unset(property, false);
      });
      return feature;
    });
    if (this.mode === SNIPPING_MODES.TWO_CLICK) {
      if (this.firstPoint && this.lastPoint) {
        const feature = this.getRemovableFeature(e.features);
        feature && this.layer.getSource().removeFeature(feature);
        this.resetValues();
        this.removalLayer.getSource().clear();
        undoRedoPush();
      } else {
        this.features = e.features;
        this.original = e.original;
      }
    } else {
      const feature = this.getRemovableFeature(e.features);
      feature && this.layer.getSource().removeFeature(feature);
      this.transactionComplete = true;
      this.removalLayer.getSource().clear();
      undoRedoPush();
    }
  };

  handleKeyDawn = (e: any) => {
    if (e.key == 'Backspace' && !this.transactionComplete) {
      this.backToInitialStage();
    }
  };

  filterFn = (f: any) => {
    if (this.features) {
      return this.features.some((ft: any) => f === ft);
    }
    return true;
  };

  backToInitialStage = () => {
    if (this.original && this.features) {
      const source = this.layer.getSource();
      this.features.forEach((f: any) => {
        source.removeFeature(f);
      });
      source.addFeature(this.original);
      this.resetValues();
    }
  };

  getDistanceFactor(d: number) {
    const view = this.mapObj.map?.getView();

    // @ts-ignore: Unreachable code error
    const zoom = parseInt(view.getZoom());

    let distanceFactor = DISTANCE_FACTOR;
    if (zoom > 16) {
      distanceFactor = 1e-3;
    } else if (zoom < 10) {
      distanceFactor = 1;
    } else {
      distanceFactor = 1e-1; //Math.pow(10, -zoom % 10);
    }
    return distanceFactor;
  }

  getCurrentMode = () => {
    return this.mode;
  };

  resetValues = () => {
    this.firstPoint = null;
    this.lastPoint = null;
    this.transactionComplete = true;
    this.features = null;
    this.original = null;
  };

  isClosestPoints = (p1: Array<any>, p2: Array<any>) => {
    const _p1 = toLonLat(p1);
    const _p2 = toLonLat(p2);
    const distance = turfDistance(point(_p1), point(_p2));
    const distanceFactor = this.getDistanceFactor(distance);
    return distance < distanceFactor;
  };

  getDistance = (p1: Array<any>, p2: Array<any>) => {
    const _p1 = toLonLat(p1);
    const _p2 = toLonLat(p2);
    const distance = turfDistance(point(_p1), point(_p2));
    return distance;
  };

  getRemovableFeature = (features: Array<any>) => {
    const geom0 = features[0].getGeometry();
    const geom1 = features[1].getGeometry();

    let found = 0;
    if (this.mode === SNIPPING_MODES.TWO_CLICK) {
      if (this.firstPoint && this.lastPoint) {
        const geom0fp = geom0.getFirstCoordinate();
        const geom0lp = geom0.getLastCoordinate();
        const geom1fp = geom1.getFirstCoordinate();
        const geom1lp = geom1.getLastCoordinate();

        const [fp_g0fp, fp_g1fp, fp_g0lp, fp_g1lp] = [
          this.getDistance(this.firstPoint, geom0fp),
          this.getDistance(this.firstPoint, geom1fp),
          this.getDistance(this.firstPoint, geom0lp),
          this.getDistance(this.firstPoint, geom1lp)
        ];

        if (fp_g0fp < fp_g1lp || fp_g0lp < fp_g1fp) {
        } else {
          found = 1;
        }
        return features[found];
      }
    } else {
      const length_0 = geom0.getLength();
      const length_1 = geom1.getLength();
      found = length_1 < length_0 ? 1 : 0;
      return features[found];
    }
  };
}

export default LineSnippingTool;
