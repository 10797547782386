// @ts-expect-error TS(7016): Could not find a declaration file for module 'ol-r... Remove this comment to see the full error message
import RotateFeatureInteraction from 'ol-rotate-feature';
import Feature from 'ol/Feature';
import { Style } from 'ol/style';
import { Select } from 'ol/interaction';
import { fromExtent } from 'ol/geom/Polygon';
import { pointerMove } from 'ol/events/condition';

import { layerTracker } from '../MapInit';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { isNumericalLayer } from '../../../Utils/HelperFunctions';
import { HIGHLIGHT_STYLE_NUMERICAL, ROTATE_STYLE, highlightStyle } from '../MapBase';
import { GEOMETRY_TYPE_STRING, selectNonHighLightLayers } from '../../../Constants/Constant';

class DragRotate extends Observer {
  bpLotExtent: $TSFixMe;

  hover: $TSFixMe;

  lastFeature: $TSFixMe;

  mapObj: $TSFixMe;

  rotate: $TSFixMe;

  select: $TSFixMe;

  constructor(mapObj: $TSFixMe) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.hover = null;
    this.bpLotExtent = null;
    this.lastFeature = null;
  }

  on() {
    this.off();

    if (this.mapObj.isBlueprintMap) {
      const bpSheetExtent = this.mapObj.baseLayer.get('bp_page_extent');
      this.bpLotExtent = new Feature(fromExtent(bpSheetExtent));
    }

    this.select = new Select({
      toggleCondition: () => false,
      // @ts-expect-error TS(2345): Argument of type '{ toggleCondition: () => false; ... Remove this comment to see the full error message
      wrapX: false,
      filter: (feature, layer) =>
        !selectNonHighLightLayers(layer?.get('name')) &&
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        !(feature.getGeometry().getType() === GEOMETRY_TYPE_STRING.POINT),
      style: ROTATE_STYLE(this.mapObj.isBlueprintMap)
    });
    this.select.on('select', (e: $TSFixMe) => {
      const selectedFeatures = e.target.getFeatures().getArray();
      this.notifyObservers(TOOL_EVENT.SELECT_FEATURES, selectedFeatures);
    });
    this.mapObj.map.addInteraction(this.select);

    this.hover = new Select({
      condition: pointerMove,
      toggleCondition: () => false,
      filter: (feature, layer) =>
        !selectNonHighLightLayers(layer?.get('name')) &&
        !this.select.getFeatures().getArray().includes(feature) &&
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        !(feature.getGeometry().getType() === GEOMETRY_TYPE_STRING.POINT),
      style: feature => {
        const layer = this.mapObj.getLayerById(feature.get('layerId'));
        return layer && isNumericalLayer(layer)
          ? HIGHLIGHT_STYLE_NUMERICAL
          : // @ts-expect-error TS(2322): Type 'FeatureLike' is not assignable to type 'null... Remove this comment to see the full error message
            highlightStyle({ layer, feature });
      }
    });
    this.mapObj.map.addInteraction(this.hover);

    this.rotate = new RotateFeatureInteraction({
      features: this.select.getFeatures(),
      style: [new Style({})]
    });
    this.rotate.on('rotatestart', this.rotateStart);
    this.rotate.on('rotateend', this.rotateEnd);
    this.mapObj.map.addInteraction(this.rotate);
  }

  rotateStart = () => {
    this.hover.setActive(false);
    this.lastFeature = this.select.getFeatures().getArray()[0].clone();
  };

  rotateEnd = () => {
    this.hover.setActive(true);
    if (!this.select.getFeatures().getArray().length) return;

    const feature = this.select.getFeatures().getArray()[0];
    const geom = feature.getGeometry();
    const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
      geom,
      boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : null
    });

    if (is_out_of_extent) {
      if (!this.lastFeature) return;

      if (
        this.lastFeature.get('layerId') === feature.get('layerId') &&
        this.lastFeature.get('id') === feature.get('id')
      ) {
        feature.setGeometry(this.lastFeature.getGeometry());
      }
    } else {
      const layerId = feature.get('layerId');
      layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
      this.notifyObservers(TOOL_EVENT.ROTATE_TOOL);
    }
  };

  off() {
    if (this.select) {
      this.select.getFeatures().clear();
      this.mapObj.map.removeInteraction(this.select);
    }
    this.hover && this.mapObj.map.removeInteraction(this.hover);
    if (this.rotate) {
      this.mapObj.map.removeInteraction(this.rotate);
      this.rotate.un('rotatestart', this.rotateStart);
      this.rotate.un('rotateend', this.rotateEnd);
    }
    this.bpLotExtent = null;
    this.lastFeature = null;
    this.notifyObservers(TOOL_EVENT.SELECT_FEATURES, []);
  }
}

export default DragRotate;
