export const METRIC_ATTRIBUTE_UNIT_ENUM = {
  M: 5,
  SQM: 6,
  CUM: 7,
  KG: 12,
  TONNE: 13,
  COUNT: 4,
  RATIO: 10,
  FREETEXT: 11,
  MM: 16,
  SQMM: 17,
  LITRE: 19,
  BAGS: 23,
  SHEETS: 24,
  ROLLS: 25,
  KG_PER_M: 27
};
export const IMPERIAL_ATTRIBUTE_UNIT_ENUM = {
  FT: 1,
  SQFT: 2,
  CUYD: 3,
  COUNT: 4,
  LB: 8,
  TON: 9,
  RATIO: 10,
  FREETEXT: 11,
  INCH: 14,
  SQINCH: 15,
  GALLON: 18,
  BAGS: 23,
  SHEETS: 24,
  ROLLS: 25,
  LBS_PER_FT: 26
};

export const METRIC_TO_IMPERIAL_UNIT = {
  [METRIC_ATTRIBUTE_UNIT_ENUM.M]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.FT,
  [METRIC_ATTRIBUTE_UNIT_ENUM.SQM]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.SQFT,
  [METRIC_ATTRIBUTE_UNIT_ENUM.CUM]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.CUYD,
  [METRIC_ATTRIBUTE_UNIT_ENUM.KG]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.LB,
  [METRIC_ATTRIBUTE_UNIT_ENUM.TONNE]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.TON,
  [METRIC_ATTRIBUTE_UNIT_ENUM.COUNT]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.COUNT,
  [METRIC_ATTRIBUTE_UNIT_ENUM.RATIO]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.RATIO,
  [METRIC_ATTRIBUTE_UNIT_ENUM.FREETEXT]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.FREETEXT,
  [METRIC_ATTRIBUTE_UNIT_ENUM.MM]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.INCH,
  [METRIC_ATTRIBUTE_UNIT_ENUM.SQMM]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.SQINCH,
  [METRIC_ATTRIBUTE_UNIT_ENUM.LITRE]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.GALLON,
  [METRIC_ATTRIBUTE_UNIT_ENUM.BAGS]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.BAGS,
  [METRIC_ATTRIBUTE_UNIT_ENUM.SHEETS]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.SHEETS,
  [METRIC_ATTRIBUTE_UNIT_ENUM.ROLLS]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.ROLLS,
  [METRIC_ATTRIBUTE_UNIT_ENUM.KG_PER_M]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.LBS_PER_FT
};
export const IMPERIAL_TO_METRIC_UNIT = {
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.FT]: METRIC_ATTRIBUTE_UNIT_ENUM.M,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.SQFT]: METRIC_ATTRIBUTE_UNIT_ENUM.SQM,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.CUYD]: METRIC_ATTRIBUTE_UNIT_ENUM.CUM,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.LB]: METRIC_ATTRIBUTE_UNIT_ENUM.KG,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.TON]: METRIC_ATTRIBUTE_UNIT_ENUM.TONNE,
  [METRIC_ATTRIBUTE_UNIT_ENUM.COUNT]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.COUNT,
  [METRIC_ATTRIBUTE_UNIT_ENUM.RATIO]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.RATIO,
  [METRIC_ATTRIBUTE_UNIT_ENUM.FREETEXT]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.FREETEXT,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.INCH]: METRIC_ATTRIBUTE_UNIT_ENUM.MM,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.SQINCH]: METRIC_ATTRIBUTE_UNIT_ENUM.SQMM,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.GALLON]: METRIC_ATTRIBUTE_UNIT_ENUM.LITRE,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.BAGS]: METRIC_ATTRIBUTE_UNIT_ENUM.BAGS,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.SHEETS]: METRIC_ATTRIBUTE_UNIT_ENUM.SHEETS,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.ROLLS]: METRIC_ATTRIBUTE_UNIT_ENUM.ROLLS,
  [IMPERIAL_ATTRIBUTE_UNIT_ENUM.LBS_PER_FT]: METRIC_ATTRIBUTE_UNIT_ENUM.KG_PER_M
};
export const ATTRIBUTE_UNITS_ENUM = {
  ...METRIC_ATTRIBUTE_UNIT_ENUM,
  ...IMPERIAL_ATTRIBUTE_UNIT_ENUM
};
export const ATTRIBUTE_UNITS_VALUE = {
  [ATTRIBUTE_UNITS_ENUM.FT]: 'ft',
  [ATTRIBUTE_UNITS_ENUM.SQFT]: 'sqft',
  [ATTRIBUTE_UNITS_ENUM.CUYD]: 'cuyd',
  [ATTRIBUTE_UNITS_ENUM.COUNT]: 'count',
  [ATTRIBUTE_UNITS_ENUM.M]: 'm',
  [ATTRIBUTE_UNITS_ENUM.SQM]: 'sqm',
  [ATTRIBUTE_UNITS_ENUM.CUM]: 'cum',
  [ATTRIBUTE_UNITS_ENUM.LB]: 'lb',
  [ATTRIBUTE_UNITS_ENUM.TON]: 'ton',
  [ATTRIBUTE_UNITS_ENUM.RATIO]: 'ratio',
  [ATTRIBUTE_UNITS_ENUM.KG]: 'kg',
  [ATTRIBUTE_UNITS_ENUM.TONNE]: 't',
  [ATTRIBUTE_UNITS_ENUM.FREETEXT]: 'free text',
  [ATTRIBUTE_UNITS_ENUM.MM]: 'mm',
  [ATTRIBUTE_UNITS_ENUM.SQMM]: 'sq mm',
  [ATTRIBUTE_UNITS_ENUM.INCH]: 'inch',
  [ATTRIBUTE_UNITS_ENUM.SQINCH]: 'sq inch',
  [ATTRIBUTE_UNITS_ENUM.LITRE]: 'litre',
  [ATTRIBUTE_UNITS_ENUM.BAGS]: 'bags',
  [ATTRIBUTE_UNITS_ENUM.SHEETS]: 'sheets',
  [ATTRIBUTE_UNITS_ENUM.ROLLS]: 'rolls',
  [ATTRIBUTE_UNITS_ENUM.KG_PER_M]: 'kg/m',
  [ATTRIBUTE_UNITS_ENUM.GALLON]: 'gallon',
  [ATTRIBUTE_UNITS_ENUM.LBS_PER_FT]: 'lbs/ft',
  [ATTRIBUTE_UNITS_ENUM.KG_PER_M]: 'kg/m'
};

// assemblies units
export const METRIC_ASSEMBLY_UNIT_ENUM = {
  COUNT: 4,
  M: 5,
  SQM: 6,
  CUM: 7,
  KG: 12,
  TONNE: 13,
  MM: 16,
  SQMM: 17,
  LITRE: 19,
  BAGS: 23,
  SHEETS: 24,
  ROLLS: 25,
  KG_PER_M: 27
};
export const IMPERIAL_ASSEMBLY_UNIT_ENUM = {
  FT: 1,
  SQFT: 2,
  CUYD: 3,
  COUNT: 4,
  LB: 8,
  TON: 9,
  INCH: 14,
  SQINCH: 15,
  GALLON: 18,
  BAGS: 23,
  SHEETS: 24,
  ROLLS: 25,
  LBS_PER_FT: 26,
};
export const ASSEMBLY_UNITS_ENUM = {
  ...METRIC_ASSEMBLY_UNIT_ENUM,
  ...IMPERIAL_ASSEMBLY_UNIT_ENUM
};
export const ASSEMBLY_UNITS_VALUE = {
  [ASSEMBLY_UNITS_ENUM.FT]: 'ft',
  [ASSEMBLY_UNITS_ENUM.SQFT]: 'sqft',
  [ASSEMBLY_UNITS_ENUM.CUYD]: 'cuyd',
  [ASSEMBLY_UNITS_ENUM.COUNT]: 'count',
  [ASSEMBLY_UNITS_ENUM.M]: 'm',
  [ASSEMBLY_UNITS_ENUM.SQM]: 'sqm',
  [ASSEMBLY_UNITS_ENUM.CUM]: 'cum',
  [ASSEMBLY_UNITS_ENUM.KG]: 'kg',
  [ASSEMBLY_UNITS_ENUM.TONNE]: 't',
  [ASSEMBLY_UNITS_ENUM.MM]: 'mm',
  [ASSEMBLY_UNITS_ENUM.SQMM]: 'sqmm',
  [ASSEMBLY_UNITS_ENUM.LB]: 'lbs',
  [ASSEMBLY_UNITS_ENUM.TON]: 'ton',
  [ASSEMBLY_UNITS_ENUM.INCH]: 'inch',
  [ASSEMBLY_UNITS_ENUM.SQINCH]: 'sqinch',
  [ASSEMBLY_UNITS_ENUM.GALLON]: 'gallon',
  [ASSEMBLY_UNITS_ENUM.LITRE]: 'litre',
  [ASSEMBLY_UNITS_ENUM.BAGS]: 'bags',
  [ASSEMBLY_UNITS_ENUM.SHEETS]: 'sheets',
  [ASSEMBLY_UNITS_ENUM.ROLLS]: 'rolls',
  [ASSEMBLY_UNITS_ENUM.LBS_PER_FT]: 'lbs/ft',
  [ASSEMBLY_UNITS_ENUM.KG_PER_M]: 'kg/m'
};
export const IMPERIAL_ASSEMBLY_UNIT_NAMES = {
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.FT]: 'ft',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.SQFT]: 'ft2',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.CUYD]: 'yd3',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.COUNT]: 'count',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.LB]: 'lbs',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.TON]: 'ton',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.INCH]: 'inch',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.SQINCH]: 'sqinch',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.GALLON]: 'gallon',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.BAGS]: 'bags',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.SHEETS]: 'sheets',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.ROLLS]: 'rolls',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.LBS_PER_FT]: 'lbs/ft'
};
export const METRIC_ASSEMBLY_UNIT_NAMES = {
  [METRIC_ASSEMBLY_UNIT_ENUM.M]: 'm',
  [METRIC_ASSEMBLY_UNIT_ENUM.SQM]: 'm²',
  [METRIC_ASSEMBLY_UNIT_ENUM.CUM]: 'm³',
  [METRIC_ASSEMBLY_UNIT_ENUM.COUNT]: 'count',
  [METRIC_ASSEMBLY_UNIT_ENUM.KG]: 'kg',
  [METRIC_ASSEMBLY_UNIT_ENUM.TONNE]: 't',
  [METRIC_ASSEMBLY_UNIT_ENUM.MM]: 'mm',
  [METRIC_ASSEMBLY_UNIT_ENUM.SQMM]: 'sqmm',
  [METRIC_ASSEMBLY_UNIT_ENUM.LITRE]: 'litre',
  [METRIC_ASSEMBLY_UNIT_ENUM.BAGS]: 'bags',
  [METRIC_ASSEMBLY_UNIT_ENUM.SHEETS]: 'sheets',
  [METRIC_ASSEMBLY_UNIT_ENUM.ROLLS]: 'rolls',
  [METRIC_ASSEMBLY_UNIT_ENUM.KG_PER_M]: 'kg/m'
};
export const ASSEMBLY_UNIT_NAMES = {
  ...IMPERIAL_ASSEMBLY_UNIT_NAMES,
  ...METRIC_ASSEMBLY_UNIT_NAMES
};
export const IMPERIAL_ASSEMBLY_UNIT_NAMES_V2 = {
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.FT]: 'ft',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.SQFT]: 'ft2',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.CUYD]: 'yd3',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.COUNT]: 'count',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.LB]: 'lbs',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.TON]: 'ton',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.INCH]: 'inch',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.SQINCH]: 'sqinch',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.GALLON]: 'gallon',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.BAGS]: 'bags',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.SHEETS]: 'sheets',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.ROLLS]: 'rolls',
  [IMPERIAL_ASSEMBLY_UNIT_ENUM.LBS_PER_FT]: 'lbs/ft'
};
export const METRIC_ASSEMBLY_UNIT_NAMES_V2 = {
  [METRIC_ASSEMBLY_UNIT_ENUM.M]: 'm',
  [METRIC_ASSEMBLY_UNIT_ENUM.SQM]: 'm2',
  [METRIC_ASSEMBLY_UNIT_ENUM.CUM]: 'm3',
  [METRIC_ASSEMBLY_UNIT_ENUM.COUNT]: 'count',
  [METRIC_ASSEMBLY_UNIT_ENUM.KG]: 'kg',
  [METRIC_ASSEMBLY_UNIT_ENUM.TONNE]: 't',
  [METRIC_ASSEMBLY_UNIT_ENUM.MM]: 'mm',
  [METRIC_ASSEMBLY_UNIT_ENUM.SQMM]: 'sqmm',
  [METRIC_ASSEMBLY_UNIT_ENUM.LITRE]: 'litre',
  [METRIC_ASSEMBLY_UNIT_ENUM.BAGS]: 'bags',
  [METRIC_ASSEMBLY_UNIT_ENUM.SHEETS]: 'sheets',
  [METRIC_ASSEMBLY_UNIT_ENUM.ROLLS]: 'rolls',
  [METRIC_ASSEMBLY_UNIT_ENUM.KG_PER_M]: 'kg/m'
};
export const ASSEMBLY_UNIT_NAMES_V2 = {
  ...IMPERIAL_ASSEMBLY_UNIT_NAMES_V2,
  ...METRIC_ASSEMBLY_UNIT_NAMES_V2
};

export const IMPERIAL_ASSEMBLY_UNIT_OPTIONS = [
  { name: 'ft', value: ASSEMBLY_UNITS_ENUM.FT },
  { name: 'ft2', value: ASSEMBLY_UNITS_ENUM.SQFT },
  { name: 'yd3', value: ASSEMBLY_UNITS_ENUM.CUYD },
  { name: 'count', value: ASSEMBLY_UNITS_ENUM.COUNT },
  { name: 'lbs', value: ASSEMBLY_UNITS_ENUM.LB },
  { name: 'ton', value: ASSEMBLY_UNITS_ENUM.TON },
  { name: 'inch', value: ASSEMBLY_UNITS_ENUM.INCH },
  { name: 'sqinch', value: ASSEMBLY_UNITS_ENUM.SQINCH },
  { name: 'gallon', value: ASSEMBLY_UNITS_ENUM.GALLON },
  { name: 'bags', value: ASSEMBLY_UNITS_ENUM.BAGS },
  { name: 'sheets', value: ASSEMBLY_UNITS_ENUM.SHEETS },
  { name: 'rolls', value: ASSEMBLY_UNITS_ENUM.ROLLS },
  { name: 'lbs/ft', value: ASSEMBLY_UNITS_ENUM.LBS_PER_FT }
];

export const METRIC_ASSEMBLY_UNIT_OPTIONS = [
  { name: 'm', value: ASSEMBLY_UNITS_ENUM.M },
  { name: 'm2', value: ASSEMBLY_UNITS_ENUM.SQM },
  { name: 'm3', value: ASSEMBLY_UNITS_ENUM.CUM },
  { name: 'count', value: ASSEMBLY_UNITS_ENUM.COUNT },
  { name: 'kg', value: ASSEMBLY_UNITS_ENUM.KG },
  { name: 't', value: ASSEMBLY_UNITS_ENUM.TONNE },
  { name: 'mm', value: ASSEMBLY_UNITS_ENUM.MM },
  { name: 'sqmm', value: ASSEMBLY_UNITS_ENUM.SQMM },
  { name: 'litre', value: ASSEMBLY_UNITS_ENUM.LITRE },
  { name: 'bags', value: ASSEMBLY_UNITS_ENUM.BAGS },
  { name: 'sheets', value: ASSEMBLY_UNITS_ENUM.SHEETS },
  { name: 'rolls', value: ASSEMBLY_UNITS_ENUM.ROLLS },
  { name: 'kg/m', value: ASSEMBLY_UNITS_ENUM.KG_PER_M }
];
