import Draw from 'ol/interaction/Draw';

class CustomDraw extends Draw {
  constructor(options) {
    super(options);
  }
  appendCoordinates(coordinates) {
    if (this.sketchCoords_ && this.sketchCoords_.length > 0) {
      const point1 = this.sketchCoords_[0][0];
      const point2 = this.sketchCoords_[0][1];
      const coordinate = coordinates[0];

      if (point1[0] === coordinate[0] && point1[1] === coordinate[1]) {
        this.sketchCoords_[0][0] = point2;
        this.sketchCoords_[0][1] = coordinate;
      }
    }
    const mode = this.mode_;
    const newDrawing = !this.sketchFeature_;
    if (newDrawing) {
      this.startDrawing_(coordinates[0]);
    }
    /** @type {LineCoordType} */
    let sketchCoords;
    if (mode === 'LineString' || mode === 'Circle') {
      sketchCoords = /** @type {LineCoordType} */ (this.sketchCoords_);
    } else if (mode === 'Polygon') {
      sketchCoords =
        this.sketchCoords_ && this.sketchCoords_.length ? /** @type {PolyCoordType} */ (this.sketchCoords_)[0] : [];
    } else {
      return;
    }

    if (newDrawing) {
      sketchCoords.shift();
    }

    // Remove last coordinate from sketch drawing (this coordinate follows cursor position)
    sketchCoords.pop();

    // Append coordinate list
    for (let i = 0; i < coordinates.length; i++) {
      this.addToDrawing_(coordinates[i]);
    }

    const ending = coordinates[coordinates.length - 1];
    // Duplicate last coordinate for sketch drawing (cursor position)
    this.addToDrawing_(ending);
    this.modifyDrawing_(ending);
  }
}

export default CustomDraw;
