import { Style, Stroke, Circle, Fill, Icon, RegularShape } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { asString } from 'ol/color';
import { LineString, MultiPoint, Point, Polygon } from 'ol/geom';
import Text from 'ol/style/Text';
import { GEOMETRY_TYPES, AbstractSvg, TypicalSvg } from 'woodpecker';

import { Coordinate } from 'ol/coordinate';

const DRAW_WIDTH = 3;

export const drawStyle = () => {
  return new Style({
    stroke: new Stroke({
      color: 'orange',
      width: DRAW_WIDTH
    }),
    image: new Circle({
      radius: 7,
      stroke: new Stroke({
        color: 'skyblue'
      })
    })
  });
};

export const selectStyle = (isabstract = false, isTypical = false) => {
  const AbstarctIcon = AbstractSvg('orange');
  const TypicalIcon = TypicalSvg('orange');

  return new Style({
    stroke: new Stroke({
      color: 'orange',
      width: DRAW_WIDTH
    }),
    image:
      isabstract || isTypical
        ? new Icon({
            src: `data:image/svg+xml;utf8,${encodeURIComponent(isabstract ? AbstarctIcon : TypicalIcon)}`
          })
        : new Circle({
            radius: 7,
            stroke: new Stroke({
              color: 'orange'
            }),
            fill: new Fill({
              color: 'orange'
            })
          }),
    fill: new Fill({
      color: 'rgba(255,255,255,0.2)'
    })
  });
};

const SELECT_STROKE = new Stroke({
  color: 'rgba(255, 165, 0, 1)',
  width: DRAW_WIDTH
});

const getImage = (geom: string | null, isAbstract: boolean, isTypical: boolean, layerStyle: Style | null) => {
  if (geom !== 'Point') {
    return new Circle();
  }
  const fillColor = layerStyle?.getFill().getColor() as string;
  const colorCode = `rgba(${fillColor?.[0]},${fillColor?.[1]},${fillColor?.[2]},1)`;
  const AbstractIcon = AbstractSvg(colorCode, 'rgba(255, 165, 0, 1)');
  const TypicalIcon = TypicalSvg(colorCode, 'rgba(255, 165, 0, 1)');

  if (isAbstract || isTypical) {
    return new Icon({
      src: `data:image/svg+xml;utf8,${encodeURIComponent(isAbstract ? AbstractIcon : TypicalIcon)}`
    });
  }
  if (geom === 'Point') {
    return new Circle({
      radius: 7,
      fill: layerStyle?.getFill(),
      stroke: SELECT_STROKE
    });
  }
  return layerStyle?.getImage();
};

export const multiSelectStyle = (
  layerStyle: Style | null,
  layerGeometry: string | null,
  isAbstract: boolean,
  isTypical: boolean
) => {
  return new Style({
    stroke: SELECT_STROKE,

    fill:
      typeof layerStyle === 'function'
        ? new Fill({
            color: 'rgba(255, 165, 0, 0.1)'
          })
        : layerStyle?.getFill(),
    image: getImage(layerGeometry, isAbstract, isTypical, layerStyle)
  });
};

export const POINT_COLOR = asString([0, 0, 255, 0.5]);
export const LINE_COLOR = asString([255, 191, 0, 1]);
export const SEGMENT_COLOR = asString([0, 0, 255, 1]);

export const lineStyles = [
  new Style({
    stroke: new Stroke({
      color: LINE_COLOR,
      width: 3
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: POINT_COLOR
      }),
      fill: new Fill({
        color: POINT_COLOR
      })
    })
  }),
  new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: POINT_COLOR
      }),
      fill: new Fill({
        color: POINT_COLOR
      })
    }),
    geometry: feature => {
      const geom = feature.getGeometry() as any;
      const coordinates = geom.getCoordinates();
      const type = geom.getType();
      if (typeof coordinates === 'object') {
        if (type === GEOMETRY_TYPES.LINESTRING) {
          return new MultiPoint(coordinates);
        } else if (type === GEOMETRY_TYPES.POLYGON) {
          return new MultiPoint(coordinates[0]);
        }
      }
    }
  })
];

export const segmentHighlightStyle = new Style({
  stroke: new Stroke({
    color: SEGMENT_COLOR,
    width: 3,
    zIndex: 1000
  } as any)
});

export const labelStyle = new Style({
  text: new Text({
    font: 'bold 16px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(0, 0, 0, 1)'
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 1)',
      width: 3
    })
  })
});

export const lineMeasureStyles = [
  new Style({
    fill: new Fill({
      color: LINE_COLOR
    }),
    stroke: new Stroke({
      color: LINE_COLOR,
      width: 2
    })
  })
];

export const polylineStyle = [
  new Style({
    stroke: new Stroke({
      color: LINE_COLOR,
      width: 3
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: POINT_COLOR
      })
    })
  })
];

export const pointStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: 'red'
    }),
    fill: new Fill({
      color: 'red'
    })
  }),
  geometry: feature => {
    const geom = feature.getGeometry() as any;
    const coordinates = geom.getCoordinates();
    const type = geom.getType();
    if (typeof coordinates === 'object') {
      if (type === GEOMETRY_TYPES.LINESTRING) {
        return new MultiPoint([coordinates[coordinates.length - 1]]);
      } else if (type === GEOMETRY_TYPES.POLYGON) {
        if (coordinates[0].length > 2) return new MultiPoint([coordinates[0][coordinates[0].length - 2]]);
        else return new MultiPoint([coordinates[0][coordinates[0].length - 1]]);
      }
    }
  }
});

export const crossHairStyle = new Style({
  stroke: new Stroke({
    color: LINE_COLOR,
    width: 3
  }),
  image: new RegularShape({
    fill: new Fill({
      color: 'blue'
    }),
    points: 4,
    radius1: 15,
    radius2: 1
  })
});

export const ROTATE_STYLE = () => [
  new Style({
    stroke: new Stroke({
      color: '#4361ee',
      width: 2
    }),
    fill: new Fill({
      color: '#4361ee4f'
    })
  }),
  new Style({
    image: new Icon({
      src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/rotate.svg',
      scale: 0.8,
      color: 'rgb(0,0,0)',
      crossOrigin: 'anonymous'
    }),
    geometry: feature => {
      const geom = feature.getGeometry();
      const coordinates = (geom as Polygon | LineString)?.getCoordinates();

      const type = geom?.getType();

      if (typeof coordinates === 'object') {
        if (type === GEOMETRY_TYPES.LINESTRING) {
          return new Point(coordinates[0] as Coordinate);
        } else if (type === GEOMETRY_TYPES.POLYGON) {
          return new Point(coordinates[0][0] as Coordinate);
        }
      }
      return undefined;
    }
  })
];
