import { cleanCoords, kinks } from "@turf/turf";
import { SLIVER_POLYGON_AREA_LIMIT } from "woodpecker";
import getTurfFeature from "../getTurfFeature";
import { getLength } from "ol/sphere";
import { Feature } from "ol";

export const isSelfIntersect = (feature: any) => {
  const poly = getTurfFeature(feature) as any;
  const invalid = kinks(poly);
  return Boolean(invalid.features.length);
};

export const hasRedundantCoords = (feature: any) => {
  const poly = getTurfFeature(feature);
  const initialCoords = poly.geometry.coordinates[0] as any;
  const cleanPoly = cleanCoords(poly);
  const finalCoords = cleanPoly.geometry.coordinates[0];
  return initialCoords?.length !== finalCoords?.length;
};

export const isSliverPolygon = (feature: any) => {
  return Boolean(feature.getGeometry().getArea() < SLIVER_POLYGON_AREA_LIMIT);
};

export const isZeroLength = (feature: Feature) => {
  const geometry = feature?.getGeometry();
  if (geometry) {
    let length = getLength(geometry);
    return length === 0;
  } else return true;
};

export const isValid = (feature: any) => {
  try {
    const ifHasRedundantCoords = hasRedundantCoords(feature);
    const ifSelfIntersect = isSelfIntersect(feature);
    const ifSliverPolygon = isSliverPolygon(feature);
    return !(ifHasRedundantCoords || ifSelfIntersect || ifSliverPolygon);
  } catch (e) {}
};

export const isValidLineString = (feature: Feature) => {
  let isValid = true;
  const geometry = feature?.getGeometry() as any;

  if (geometry?.getType() === "LineString") {
    if (geometry?.getCoordinates().length < 2) return false;
  } else if (geometry?.getType() === "MultiLineString") {
    const coordinates = geometry?.getCoordinates();
    for (let index = 0; index < coordinates.length; index++) {
      const coords = coordinates[index];
      if (coords.length < 2) return false;
    }
  } else return false;

  try {
    const ifHasRedundantCoords = hasRedundantCoords(feature);
    const ifZeroLength = isZeroLength(feature);
    isValid = !(ifHasRedundantCoords || ifZeroLength);
  } catch (e) {
    isValid = false;
  }
  return isValid;
};
