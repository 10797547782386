import MapBase from '../../mapLayer/mapBase';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { Geometry } from 'ol/geom';
import Feature from 'ol/Feature';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { mergeFeatures } from 'macaw';
import { showToast } from 'ui';
import { Select } from 'ol/interaction';

class Merge {
  private mapObj: MapBase;
  private features: any;
  private layer: any;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
  }

  removeHiddenGeometries() {
    let select: Select | null = null;
    this.mapObj?.map?.getInteractions().forEach((interaction: any) => {
      if (interaction instanceof Select) {
        select = interaction;
      }
    });

    const layerRecord = new Map();
    const selectedFeature = globalStore.AppStore.selectedFeatures;
    const filteredFeatures = selectedFeature?.filter((feature: Feature) => {
      const vector_layer_id = feature?.get('vector_layer_id');
      let isVisible = true;
      if (layerRecord?.has(vector_layer_id)) {
        const layer = layerRecord?.get(vector_layer_id);
        isVisible = isVisible && layer?.getVisible();
      } else {
        const layer = this.mapObj?.getLayerById(vector_layer_id);
        layerRecord?.set(vector_layer_id, layer);
        isVisible = isVisible && layer?.getVisible();
      }
      if (!isVisible) {
        select?.getFeatures()?.remove(feature);
      }
      return isVisible;
    });

    return filteredFeatures;
  }

  execute() {
    this.features = this.removeHiddenGeometries();
    globalStore.AppStore.setSelectedFeatures([]);
    this.layer = this.mapObj?.getLayerById(globalStore.AppStore.selectedLayerID);
    setTimeout(() => {
      this.mergeFeatures();
    }, 10);
  }

  mergeFeatures = () => {
    const features = this.features
      ? this.features.filter((feature: Feature) => {
          const properties = feature.getProperties();
          return !!!properties['excluded'];
        })
      : [];
    if (!features.length) {
      return;
    } else {
      const vector_layer_id = features[0]?.getProperties()['vector_layer_id'];
      const layer = this.mapObj?.getLayerById(vector_layer_id);
      // @ts-ignore: Unreachable code error
      mergeFeatures(features, layer);
      undoRedoPush();
    }
  };
}

export default Merge;
