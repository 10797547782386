import { DATA_PROJECTION, FEATURE_PROJECTION, GEOMETRY_TYPES, MAP_TYPE, UNSET_PROPERTIES } from 'woodpecker';
import { drawStyle, labelStyle, selectStyle } from '../../../hooks/tools/helpers/styles';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import Draw from 'ol/interaction/Draw';
import { formatArea } from '../../../hooks/tools/helpers';
import { fromCircle } from 'ol/geom/Polygon';
import { GEO_JSON, generateUniqueID } from 'macaw';
import { avoidOverlap, isOutOfExtent } from '../../../helpers/helpers';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { Circle, LinearRing, MultiPolygon, Point } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';
import { point as turfPoint } from '@turf/helpers';
import Select from 'ol/interaction/Select';
import { lineSegment, pointToLineDistance } from '@turf/turf';

const DEFAULT_RADIUS = 0.762; // it is in meters which is equal to 2.5 feet because 1ft=0.3048m
const TO_METERS = 1150;

class AddCicularHole extends ToolAbstract {
  private mapObj: MapBase;
  private layer: any;
  private draw: Draw | null;
  private maxRadius: number | null;
  private defaultLimitFlag: boolean;
  private select: Select | null;
  private isDrawing: boolean;
  private features: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.maxRadius = null;
    this.defaultLimitFlag = false;
    this.select = null;
    this.isDrawing = false;
    this.features = [];
  }

  getFeatureAtPixel = (px: any) => {
    return this.mapObj.map?.forEachFeatureAtPixel(px, (f: any, l: any) => {
      return f;
    });
  };

  init(id: string) {
    this.layer = this.mapObj.getLayerById(id);

    this.select = new Select({
      layers: [this.layer],
      style: selectStyle()
    });

    this.select.setActive(false);

    const options = {
      type: GEOMETRY_TYPES.CIRCLE,
      dragVertexDelay: 0,
      clickTolerance: 12,
      features: this.select.getFeatures(),
      style: (feature: any) => {
        return this.styleFunction(feature);
      },
      geometryFunction: this.geometryFunction,
      condition: (e: any) => {
        const mouseClick = e.originalEvent.which;
        const feature = this.getFeatureAtPixel(e.pixel);
        if (isOutOfExtent(e, this.mapObj.map)) return false;

        if (this.isDrawing && mouseClick === 1) {
          this.isDrawing = false;
          return true;
        }
        if (mouseClick === 2) {
          return false;
        } else if (mouseClick === 3) {
          this.defaultLimitFlag = false;
          this.draw?.abortDrawing();
          return false;
        } else if (!feature) {
          return false;
        } else if (feature && feature.getGeometry().getType() != GEOMETRY_TYPES.POLYGON) {
          return false;
        }
        return true;
      }
    };

    this.draw = new Draw(options as any);
    this.mapObj.map?.addInteraction(this.select);
    this.mapObj.map?.addInteraction(this.draw);
    this.draw?.on('drawend', this.onDrawEnd);
    this.draw?.on('drawstart', this.onDrawStart);
    this.mapObj.map?.on('pointermove', this.handlePointerMove);
    window.addEventListener('keydown', this.keyDownHandler);
  }

  styleFunction = (feature: any) => {
    let styles = [drawStyle()];
    const { scale, dpi } = globalStore.AppStore.worksheetParams;
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.CIRCLE && scale !== null) {
      //disable/enable live measurement
      if (this.mapObj.map_type === MAP_TYPE.AERIAL && !globalStore?.AppStore?.tool?.live_measurement) {
        return styles;
      }

      const label = formatArea(geometry, dpi, scale);
      const _labelStyle = labelStyle.clone();
      _labelStyle.setGeometry(geometry);
      _labelStyle.getText().setText(label);
      styles.push(_labelStyle);
    }
    return styles;
  };

  keyDownHandler = (e: KeyboardEvent) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  handlePointerMove = (e: any) => {
    const currentFeature = this.getFeatureAtPixel(e.pixel);
    if (currentFeature && currentFeature.getGeometry().getType() == GEOMETRY_TYPES.POLYGON) {
      const layers = [this.layer];
      const allFeatures = this.mapObj.map?.getFeaturesAtPixel(e.pixel, {
        layerFilter: function (l: any) {
          return layers.indexOf(l) >= 0;
        }
      });
      this.features = allFeatures;
      this.select?.setActive(true);
      this.draw?.setActive(true);
    }
  };

  onDrawEnd = (e: any) => {
    const circularFeature = e.feature;
    const circularGeom = circularFeature.getGeometry();
    try {
      if (!circularGeom.getRadius()) {
        return;
      }
      const featuresArr = this.features;
      let intersectFeature = false;
      if (featuresArr?.length) {
        for (const feature of featuresArr) {
          if (feature) {
            const poly = feature.getGeometry() as any;
            const innerRing = fromCircle(circularGeom, 360);
            const coords = innerRing.getCoordinates()[0];
            for (const coord of coords) {
              if (!poly?.intersectsCoordinate(coord)) {
                intersectFeature = true;
                break;
              }
            }

            if (intersectFeature) {
              continue;
            }

            const linearRing = new LinearRing(coords);
            if (poly?.getType() === GEOMETRY_TYPES.POLYGON) {
              poly.appendLinearRing(linearRing);
            } else if (poly?.getType() === GEOMETRY_TYPES.MULTI_POLYGON) {
              const newGeom = new MultiPolygon([]);
              for (let i = 0, pi; (pi = poly.getPolygon(i)); i++) {
                pi.appendLinearRing(new LinearRing(linearRing as any));
                newGeom.appendPolygon(pi);
              }
              feature.setGeometry(newGeom);
            }
            UNSET_PROPERTIES.forEach(property => {
              feature.unset(property, false);
            });
          }
        }
      }
      undoRedoPush();
      this.select?.setActive(false);
      this.select?.getFeatures().clear();
      this.features = [];
      this.defaultLimitFlag = false;
      this.isDrawing = false;
    } catch (error) {}
  };

  calculateRadius = (coordinates: any[]) => {
    const center = coordinates[0];
    const last = coordinates[coordinates.length - 1];
    const dx = center[0] - last[0];
    const dy = center[1] - last[1];
    return Math.sqrt(dx * dx + dy * dy);
  };

  onDrawStart = (e: any) => {
    const circularFeature = e.feature;
    const center = circularFeature?.getGeometry().getCenter();
    this.maxRadius = this.getMaxRadius(center);
    if (this.maxRadius) this.isDrawing = true;
  };

  getMaxRadius = (center: any) => {
    try {
      const features = this.features;
      const fc = GEO_JSON.writeFeaturesObject(features as any);
      const point = turfPoint(
        // @ts-ignore: Unreachable code error
        new Point(center)
          .transform(FEATURE_PROJECTION, DATA_PROJECTION)
          // @ts-ignore: Unreachable code error
          .getCoordinates()
      );
      let distances: any = [];
      for (const poly of fc.features) {
        if (poly) {
          const segments = lineSegment(poly as any);
          segments.features.forEach(lineSeg => {
            let distance = pointToLineDistance(point, lineSeg);
            distances.push(distance);
          });
        }
      }
      if (distances.length) {
        const maxRadius = Math.min(...distances);
        return maxRadius * TO_METERS;
      }
    } catch (error) {
      this.draw?.abortDrawing();
    }

    return null;
  };

  geometryFunction = (coordinates: any, geometry: any) => {
    let radius = DEFAULT_RADIUS;
    if (!geometry) {
      geometry = new Circle(coordinates[0], radius);
    } else {
      if (this.maxRadius === null) return;
      radius = this.calculateRadius(coordinates);
      if (radius > DEFAULT_RADIUS) {
        this.defaultLimitFlag = true;
      }

      if (this.defaultLimitFlag) {
        if (this.maxRadius && radius >= this.maxRadius) {
          radius = this.maxRadius;
        }

        geometry.setRadius(radius);
      }
    }
    return geometry;
  };

  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.mapObj.map?.removeInteraction(this.select as Select);
    this.mapObj.map?.un('pointermove', this.handlePointerMove);
    this.draw?.un('drawend', this.onDrawEnd);
    this.draw?.un('drawstart', this.onDrawStart);
    window.removeEventListener('keydown', this.keyDownHandler);
  }
}

export default AddCicularHole;
