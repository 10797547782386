import VectorSource from "ol/source/Vector.js";
import Feature from "ol/Feature";

const removeFeatures = function (
  source: VectorSource,
  features: Feature[] = []
) {
  if (!source) return;
  const _features = features.length ? features : source.getFeatures();
  for (let i = 0; i < _features.length; i++) {
    const feature = _features[i];
    source.removeFeature(feature);
  }
};

export default removeFeatures;
