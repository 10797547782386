import { create } from 'zustand';

interface StoreType {
  layersPointCount: Object;
  isMeasurementsLoading: boolean;
  layerChanged: any;
  lastScaleState: string;
  allFeaturesObj: any;
  default_tags: any;
}

export interface LiveMeasurementStoreType extends StoreType {
  updateState: (...args: any) => void;
  updateStateWithPrevValue: (updateCallback: (args: LiveMeasurementStoreType) => LiveMeasurementStoreType) => void;
}

const InitialState: StoreType = {
  layersPointCount: {},
  isMeasurementsLoading: true,
  layerChanged: {},
  lastScaleState: '',
  allFeaturesObj: {},
  default_tags: {}
};

const useLiveMeasurementStore = create<LiveMeasurementStoreType>(set => ({
  ...InitialState,
  updateState: (updatedState: any) => set((state: LiveMeasurementStoreType) => ({ ...state, ...updatedState })),
  updateStateWithPrevValue: updateCallback => set((state: LiveMeasurementStoreType) => updateCallback({ ...state }))
}));

export default useLiveMeasurementStore;
