import { Draw } from 'ol/interaction';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { COMMENT_LAYER_ID, GEOMETRY_TYPES } from 'woodpecker';
import VectorSource from 'ol/source/Vector';
import { Overlay } from 'ol';
import { addOverlay } from '../../../hooks/helpers';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorImageLayer from 'ol/layer/VectorImage';
import { easeOut } from 'ol/easing';

class AddComment extends ToolAbstract {
  private mapObj: MapBase;
  private contextMenuElement: Overlay | null;
  private overlayLayerId = COMMENT_LAYER_ID;
  private overlayRef: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.contextMenuElement = new Overlay({
      element: document.getElementById('comment-card') as any,
      positioning: 'bottom-left',
      offset: [5, -5]
    });
  }

  init(id: string) {
    this.off();
    this.overlayRef = new VectorImageLayer({
      id: COMMENT_LAYER_ID,
      source: new VectorSource({ wrapX: false }),
      zIndex: 10000
    } as any);

    this.mapObj.map?.addLayer(this.overlayRef);
  }

  /**
   closes the Context menu and undo the abstarct feature drawn on clicking outside of the map
   */

  onDrawStart = () => {
    this.overlayRef.getSource().clear();
    this.contextMenuElement?.setPosition(undefined);
    const emptyComment = {
      author: 'You',
      date: '',
      message: '',
      id: null,
      resolved: null
    };
    globalStore.AppStore.setSelectedComment(emptyComment);
  };

  showCommentCard(feature: any) {
    let coordinates = feature.getGeometry().getCoordinates();

    if (feature.getGeometry().getType() === 'Polygon') {
      // For polygons, get the first coordinate of the exterior ring
      const polygonCoordinates = coordinates[0];
      coordinates = polygonCoordinates[0];
    }

    const view = this.mapObj?.map?.getView();

    view?.animate({
      center: coordinates,
      duration: 500,
      easing: easeOut
    });

    const element = this.contextMenuElement?.getElement() as any;

    if (!element) return;
    if (this.contextMenuElement) {
      addOverlay(this.mapObj.map, element, coordinates, this.contextMenuElement);
    }
  }

  onDrawEnd = (event: DrawEvent) => {
    try {
      const feature = event.feature;
      this.showCommentCard(feature);
    } catch (error) {}
  };

  /**
   * Turns off the draw interaction by removing it from the map and removing event listeners.
   */
  off() {
    this.mapObj.map?.removeLayer(this.overlayRef);
    this.contextMenuElement?.setPosition(undefined);
    this.overlayRef = null;
    globalStore.AppStore.setSelectedComment(null);
  }
}

export default AddComment;
