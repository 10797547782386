import message from 'antd/es/message';

import Feature from 'ol/Feature';
import { Circle, LinearRing } from 'ol/geom';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import Draw, { createRegularPolygon } from 'ol/interaction/Draw';

import { HIGHLIGHT_STYLE } from '../MapBase';
import { Observer } from '../../../Utils/Observer';
import { layerTracker, outputMap } from '../MapInit';
import { MAP_LAYERS } from '../../../Constants/Constant';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';

class CircularHole extends Observer {
  coords_length: $TSFixMe;

  draw: $TSFixMe;

  feature: $TSFixMe;

  handleDrawEnd: $TSFixMe;

  hole: $TSFixMe;

  intersected: $TSFixMe;

  isShiftKeyPressed: $TSFixMe;

  layer: $TSFixMe;

  layerCopy: $TSFixMe;

  layerData: $TSFixMe;

  layerId: $TSFixMe;

  mapObj: $TSFixMe;

  selectedFeature: $TSFixMe;

  selectedFeatureCopy: $TSFixMe;

  style: $TSFixMe;

  temporaryFeature: Feature | null;

  constructor(mapObj: $TSFixMe) {
    super();
    this.mapObj = mapObj;
    this.layer = null;
    this.layerId = null;
    this.selectedFeature = null;
    this.style = null;
    this.draw = null;
    this.isShiftKeyPressed = false;
    this.layerCopy = null;
    this.selectedFeatureCopy = null;
    this.temporaryFeature = null;
  }

  on() {
    const geometryFunction = this.createCircle;

    this.draw = new Draw({
      type: 'Circle',
      stopClick: true,
      style: HIGHLIGHT_STYLE,
      condition: event => {
        const feature = this.getFeatureAtPixel(event.pixel);
        if (this.selectedFeature && feature !== this.selectedFeature) {
          message.error('Hole cannot be drawn outside the feature');
          return false;
        }

        // Check if the selected feature is available and it is a Polygon
        if (feature && feature.getGeometry().getType() === 'Polygon') {
          this.selectedFeature = feature;
          if (!this.selectedFeatureCopy) this.selectedFeatureCopy = feature.clone();
          this.layerId = this.selectedFeature.get('layerId');
          this.layer = this.mapObj.getLayerById(this.layerId);
          this.layerData = this.layer.getProperties()?.layerData;
          this.feature = this.selectedFeature;
          this.style = this.layer.getStyle();
          this.selectedFeature.setStyle(HIGHLIGHT_STYLE);
          const mainGeometry = this.selectedFeature.getGeometry();
          const { coordinate } = event;

          const isInside = mainGeometry.intersectsCoordinate(coordinate);
          if (!isInside) message.error('Hole cannot be drawn outside the feature');
          return isInside;
        }

        // Return false if the selected feature is not available or not a Polygon
        return false;
      },
      geometryFunction,
      snapTolerance: 1,
      ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
    });

    this.draw.on('drawstart', this.onDrawStart);
    this.draw.on('drawend', this.onDrawEnd);
    this.mapObj.map.addInteraction(this.draw);

    this.mapObj.map.on('click', this.handleMapClick);
    document.addEventListener('keydown', this.handleKeyDownEvents);
    document.addEventListener('keyup', this.handleKeyUpEvents);
  }

  // This function will be called when you start drawing holes
  onDrawStart = (e: $TSFixMe) => {
    // Get Polygon geometry on drawstart that intersects with currently drawing holes.
    this.intersected = this.selectedFeature;
    // Abort Polygon hole drawing when there is no feature underneath it.
    if (!this.intersected) {
      message.error('No feature found to draw holes');
      e.target.abortDrawing();
      return;
    }
    this.coords_length = this.intersected.getGeometry().getCoordinates().length;

    // Binding onGeomChange function with drawing feature f. This function will be called only when you are drawing holes over a polygon.
    e.feature.getGeometry().on('change', this.onGeomChange);
    this.temporaryFeature = null;
  };

  getFeatureAtPixel = (px: $TSFixMe) => {
    return this.mapObj.map.forEachFeatureAtPixel(px, (feature: $TSFixMe, layer: $TSFixMe) => {
      if (layer && layer.get('name') === MAP_LAYERS.OUTPUT) {
        return feature;
      }
      return null;
    });
  };

  // This function will be called only when you are drawing holes and it will continously invoked on geometry change.
  onGeomChange = (e: $TSFixMe) => {
    // Get hole coordinates for polygon
    const linear_ring = new LinearRing(e.target.getCoordinates()[0]);

    const coordinates = this.intersected.getGeometry().getCoordinates();
    const geom = new Polygon(coordinates.slice(0, this.coords_length));

    // Add hole coordinates to polygon and reset the polygon geometry
    geom.appendLinearRing(linear_ring);
    this.intersected.setGeometry(geom);
  };

  isHoleBoundaryOutside = (hole: $TSFixMe, feature: $TSFixMe) => {
    if (hole && feature) {
      const coords = hole.getGeometry().getCoordinates()[0];
      const geometry = feature.getGeometry();
      for (let i = 0; i < coords.length; i++) {
        const coord = coords[i];
        if (!geometry.intersectsCoordinate(coord)) {
          return true;
        }
      }
    }
    return false;
  };

  // This function will be called when your hole drawing is finished.
  onDrawEnd = (e: $TSFixMe) => {
    const isOutside = this.isHoleBoundaryOutside(e.feature, this.selectedFeatureCopy);
    this.selectedFeature.setStyle(this.style);

    if (!isOutside) {
      layerTracker.push(this.mapObj.getLayerName(this.layerId), this.layerId);
      const geojson = outputMap.getGeojsonByLayer(this.layer);
      this.layer.set('layerData', { ...this.layerData, output_geojson: geojson });
      this.notifyObservers(TOOL_EVENT.DRAW_HOLE);
    } else {
      outputMap.loadVector(this.layerData);
      message.error('Oops! The hole cannot be saved because it crossed feature boundary.');
    }

    this.intersected = undefined;
    this.style = null;
    this.selectedFeature = null;
    this.selectedFeatureCopy = null;
    this.layer = null;
    this.layerData = null;
  };

  createCircle = (coordinates: $TSFixMe, geometry: $TSFixMe) => {
    let result;
    if (this.isShiftKeyPressed) {
      // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
      result = createRegularPolygon(100)(coordinates, geometry);
      if (!this.temporaryFeature) {
        this.temporaryFeature = new Feature(result);
      } else {
        this.temporaryFeature.setGeometry(result);
      }
    } else {
      const start = coordinates[0];
      const end = coordinates[1];
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];

      const radius = Math.sqrt(dx * dx + dy * dy);
      const center = [start[0] + dx / 2, start[1] + dy / 2];
      const circle = new Circle(center, radius / 2);
      const polygon = fromCircle(circle, 100);
      polygon.scale(dx / radius, dy / radius);

      if (!geometry) {
        result = polygon;
      } else {
        result = geometry.setCoordinates(polygon.getCoordinates());
      }
    }

    return result;
  };

  handleKeyDownEvents = (event: $TSFixMe) => {
    if (event.stopPropagation) event.stopPropagation();
    const KeyID = event.keyCode;
    if (KeyID === 16) {
      this.isShiftKeyPressed = true;
    }
  };

  handleKeyUpEvents = (event: $TSFixMe) => {
    if (event.stopPropagation) event.stopPropagation();
    const KeyID = event.keyCode;
    if (KeyID === 16) {
      this.isShiftKeyPressed = false;
    }
  };

  handleMapClick = () => {
    if (this.draw.getActive() && this.temporaryFeature) {
      this.onDrawEnd({ feature: this.temporaryFeature });
    }
  };

  off() {
    if (this.hole) {
      this.mapObj.map.removeInteraction(this.draw);
      this.draw.un('drawend', this.handleDrawEnd);
    }
    this.mapObj.map.removeInteraction(this.draw);
    this.mapObj.map.un('click', this.handleMapClick);
    document.removeEventListener('keydown', this.handleKeyDownEvents);
    document.removeEventListener('keyup', this.handleKeyUpEvents);
    this.layer = null;
    this.layerId = null;
    this.selectedFeature = null;
    this.style = null;
    this.draw = null;
    this.temporaryFeature = null;
  }
}

export default CircularHole;
