import Feature from 'ol/Feature';
import { Geometry, LineString, Polygon } from 'ol/geom';
import { getCenter } from 'ol/extent';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { GEOMETRY_TYPES } from 'woodpecker';
import { TOOL_TYPE } from '../constants';
import { FeatureisOutOfExtent } from '../../../helpers/helpers';
import { showToast } from 'ui';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { selectStyle } from '../../../hooks/tools/helpers/styles';

class FlipTool extends ToolAbstract {
  private mapObj: MapBase;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
  }

  init(id: string) {
    this.off();

    let selectedFeatures: Feature[] = [...globalStore.AppStore.selectedFeatures] || [];

    if (!selectedFeatures.length) {
      showToast('Please select some features', 'error');
      globalStore.AppStore.setTool({ tool_id: TOOL_TYPE.SELECT });
      return;
    }

    selectedFeatures = selectedFeatures.filter(f => f.getGeometry()?.getType() !== GEOMETRY_TYPES.POINT);

    if (selectedFeatures.length) {
      selectedFeatures.forEach(f => {
        f.setStyle(selectStyle());
        const geom = f.getGeometry() as Geometry;
        const originalGeom = geom?.clone();
        const center = getCenter(geom.getExtent());

        if (globalStore.AppStore.tool.tool_id === TOOL_TYPE.FLIP_HORIZONTAL) {
          // Flip the polygon horizontally
          geom.applyTransform((coords, _, stride) => {
            for (let i = 0; i < coords.length; i += stride || 0) {
              coords[i] = 2 * center[0] - coords[i];
            }
            return coords;
          });
          f.setGeometry(geom);
        } else {
          let flippedGeom;
          if (geom.getType() === GEOMETRY_TYPES.POLYGON) {
            // Flip the polygon vertically
            const coords = (geom as Polygon).getCoordinates();
            const flippedCoords = coords.map(ring => ring.map(point => [point[0], 2 * center[1] - point[1]]));
            // Create a new polygon geometry with the flipped coords
            flippedGeom = new Polygon(flippedCoords);
          } else if (geom.getType() === GEOMETRY_TYPES.LINESTRING) {
            // Flip the linestring vertically
            const coords = (geom as LineString).getCoordinates();
            const flippedCoords = coords.map(point => [point[0], 2 * center[1] - point[1]]);
            // Create a new LineString geometry with the flipped coordinates
            flippedGeom = new LineString(flippedCoords);
          }
          f.setGeometry(flippedGeom);
        }

        // handling out_of_extent case
        const isOutOfExtent = FeatureisOutOfExtent(f.getGeometry()?.getExtent(), this.mapObj.map);

        if (isOutOfExtent) {
          f.setGeometry(originalGeom);
        } else {
          setTimeout(() => {
            undoRedoPush();
          }, 0);
        }
      });
    }
  }

  off() {
    globalStore.AppStore.selectedFeatures?.forEach((feature: Feature) => {
      feature.setStyle(undefined);
    });
  }
}

export default FlipTool;
