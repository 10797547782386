import { mapObj } from '../../mapLayer/mapInit';
import { TOOL_TYPE } from '../constants';

/**
 *Tool class to initialize a tool object
 */
class Tool {
  title: string;
  tool_id: string;
  toolClass: any;
  geometry_available: Array<number>;
  toolObj?: any;

  constructor(title: string, tool_id: string, geometry_available: Array<number>, toolClass: any) {
    this.title = title;
    this.tool_id = tool_id;
    this.toolClass = toolClass;
    this.geometry_available = geometry_available;
  }

  /**
   * Gets the instance of the tool object.
   * If the tool object does not exist and the tool class is defined and not the pan tool, creates a new instance.
   * @returns {any} The instance of the tool object.
   */
  getObject = (): any => {
    const toolClass = this.toolClass;
    if (!this.toolObj && toolClass && this.tool_id !== TOOL_TYPE.PAN) {
      this.toolObj = new toolClass(mapObj);
    }
    return this.toolObj;
  };
}

export default Tool;
