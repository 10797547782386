import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import MapBase from '../../mapLayer/mapBase';
import { Overlay } from 'ol';
import { commentStore } from '../../utilityclasses/CommentStoreListener';
import { CommentType } from '../../../store/commentStore';
import { unByKey } from 'ol/Observable';
import { Coordinate } from 'ol/coordinate';
import { generateUniqueID } from 'macaw';
import { COMMENT_LIMIT, COMMENT_STATUS, RESOLVED_COMMENT_COLOR, getAvatarShades } from 'woodpecker';
import { easeOut } from 'ol/easing';
import { isOutOfExtent } from '../../../helpers/helpers';
import { showToast } from 'ui';

class AddCommentBPT extends ToolAbstract {
  private mapObj: MapBase;
  private contextMenuElement: any;
  private eventRef: any;
  private commentBoxOverLay: Overlay;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.contextMenuElement = document.getElementById('comment-box');
    this.eventRef = null;
    this.commentBoxOverLay = new Overlay({
      element: document.getElementById('comment-box') as any,
      positioning: 'center-center'
    });
  }

  init(id: string) {
    this.removeComments();

    const commentList = commentStore?.AppStore?.commentList || [];
    this.eventRef = this.mapObj?.map?.on('click', e => this.clickHandle(e));

    this.mapObj?.map?.addOverlay(this.commentBoxOverLay);

    commentList?.forEach((comment: CommentType) => this.addCommentToOverlay(comment));

    commentStore?.AppStore?.updateSelectedComment();
  }

  addCommentToOverlay(comment: CommentType) {
    const coordinates = comment?.comment_geojson?.features[0]?.geometry?.coordinates;
    const element = document.createElement('div');

    const resolved = comment?.status === COMMENT_STATUS.RESOLVED;
    element.innerHTML = comment?.user_email[0].toUpperCase();
    element.classList.add('talkbubble');
    element.style.backgroundColor = resolved ? RESOLVED_COMMENT_COLOR : getAvatarShades(comment?.user_email);

    const overlay = new Overlay({
      element: element,
      positioning: 'center-center',
      position: coordinates
    });

    overlay.set('comment_data', {
      tool_id: comment?.comment_geojson?.features[0]?.id
    });

    element?.addEventListener('click', () => {
      commentStore?.AppStore?.setSelectedComment(overlay?.get('comment_data')?.tool_id || '');
      this.setPositionToComment(overlay?.getPosition() as Coordinate);
    });

    this.mapObj?.map?.addOverlay(overlay);

    this.mapObj.map!.getViewport().style.cursor = 'arrow';
  }

  setPositionToComment(coordinate: Coordinate) {
    this.contextMenuElement.style.display = 'block';
    this.adjustOverlayPosition(coordinate);
  }

  getGeojson(coordinate: Coordinate) {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coordinate
          },
          id: generateUniqueID()
        }
      ]
    };
  }

  clickHandle(event: any) {
    if (commentStore?.AppStore?.commentList?.length >= COMMENT_LIMIT) {
      return showToast('Limit to add comments exceeded.', 'error');
    }
    if (isOutOfExtent(event, this.mapObj.map)) return;

    this.contextMenuElement = document.getElementById('comment-box');
    this.commentBoxOverLay?.setElement(this.contextMenuElement);
    this.commentBoxOverLay?.setPosition(event.coordinate);
    this.contextMenuElement.style.display = 'block';

    this.adjustOverlayPosition(event.coordinate);
    const geojson = this.getGeojson(event.coordinate);

    commentStore?.AppStore?.updateState(() => ({
      commentGeojson: geojson,
      selectedComment: null,
      selectedCommentToolID: geojson?.features[0]?.id
    }));
  }

  /**
   * Adjusts overlay position if it's too close to the map edges
   * @param {Array<number>} position - Initial position of the overlay
   */
  adjustOverlayPosition(position: number[]) {
    const mapSize = this.mapObj.map?.getSize() || [];
    const pixelPosition = this.mapObj.map?.getPixelFromCoordinate(position) || [];

    // Offset for buffer space from the edges (adjust as needed)
    const buffer = 10;

    // Get overlay dimensions
    const overlayWidth = this.contextMenuElement.offsetWidth;
    const overlayHeight = this.contextMenuElement.offsetHeight;
    const rightPanelWidth = document.getElementById('worksheet-panel')?.offsetWidth || 320;

    // Calculate adjusted pixel position if close to the map edges
    let adjustedPixelX = pixelPosition[0];
    let adjustedPixelY = pixelPosition[1];

    if (pixelPosition[0] + overlayWidth + buffer + rightPanelWidth > mapSize[0]) {
      adjustedPixelX = mapSize[0] - overlayWidth - rightPanelWidth - buffer;
    } else if (pixelPosition[0] < buffer) {
      adjustedPixelX = buffer;
    }

    if (pixelPosition[1] + overlayHeight + buffer > mapSize[1]) {
      adjustedPixelY = mapSize[1] - overlayHeight - buffer;
    } else if (pixelPosition[1] < buffer) {
      adjustedPixelY = buffer;
    }

    // Convert adjusted pixel position back to map coordinates
    const adjustedPosition = this.mapObj.map?.getCoordinateFromPixel([adjustedPixelX, adjustedPixelY]);

    // Update overlay position
    this.commentBoxOverLay?.setPosition(adjustedPosition);
  }

  removeComments() {
    const overlays = [...(this.mapObj.map?.getOverlays()?.getArray() || [])];
    overlays.forEach(overlay => {
      if (!!overlay?.get('comment_data')) {
        this.mapObj.map?.removeOverlay(overlay);
      }
    });
    this.eventRef && unByKey(this.eventRef);
  }

  showCommentCard(comment: CommentType, shouldScrollToView = false) {
    const coordinates = comment?.comment_geojson?.features[0]?.geometry?.coordinates;

    if (shouldScrollToView) {
      const view = this.mapObj?.map?.getView();
      view?.animate({
        center: coordinates,
        duration: 100,
        easing: easeOut
      });

      if (coordinates) {
        setTimeout(() => this.setPositionToComment(coordinates), 150);
      }
    } else {
      if (coordinates) {
        this.setPositionToComment(coordinates);
      }
    }
  }

  /**
   * Turns off the draw interaction by removing it from the map and removing event listeners.
   */
  off() {
    this.removeComments();
    // this.eventRef && unByKey(this.eventRef);
    if (this.contextMenuElement) {
      this.contextMenuElement.style.display = 'none';
    }
    this.commentBoxOverLay && this.mapObj?.map?.removeOverlay(this.commentBoxOverLay);
    this.mapObj.map!.getViewport().style.cursor = '';
    commentStore?.AppStore?.updateState(() => ({
      commentGeojson: null,
      selectedComment: null,
      selectedCommentToolID: ''
    }));
  }
}

export default AddCommentBPT;
