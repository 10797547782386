import useCommentStore from '../../../store/commentStore';

class CommentStoreListener {
  public AppStore: any = null;
  private static instance: CommentStoreListener;

  private constructor() {
    useCommentStore.subscribe(state => this.handleStoreChange(state));
    this.AppStore = useCommentStore?.getState();
  }

  handleStoreChange(state: any) {
    this.AppStore = state;
  }

  static getInstance() {
    if (!CommentStoreListener.instance) {
      CommentStoreListener.instance = new CommentStoreListener();
    }
    return CommentStoreListener.instance;
  }
}

export const commentStore = CommentStoreListener.getInstance();

export default CommentStoreListener;
