import { Feature } from "ol";
import { Circle, Point } from "ol/geom";
import Layer from "ol/layer/Layer";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Icon, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import {
  CurrentJob,
  DATA_PROJECTION,
  DEFAULT_ZOOM_VALUE,
  ERROR_INVALID_FEATURES,
  FEATURE_PROJECTION,
  GEOMETRY_TYPE,
  GEOMETRY_TYPES,
  HIGHLIGHT_INVALIDTIES,
  IMAGE_STATIC_ORTHO,
  LAYER,
  SUPER_ZOOM_VALUE,
  CORRDINATES_LAYER,
  GEOMETRY_ENUM,
} from "woodpecker";
import getTurfFeature from "../getTurfFeature";
import { kinks } from "@turf/turf";
import removeFeatures from "../removeFeature";
import { fromLonLat } from "ol/proj";
import Static from "ol/source/ImageStatic";
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";

const isIgnorableLayer = (l: Layer | any) => {
  const id = l.get("id");
  const geometryType = l.get("geometryType");
  return (
    !(l instanceof VectorLayer) ||
    geometryType != GEOMETRY_ENUM[GEOMETRY_TYPES.POLYGON] ||
    id === LAYER.PARCEL
  );
};

export const getEditableLayers = function (map: any) {
  return map
    ?.getLayers()
    ?.getArray()
    ?.filter((l: any) => !isIgnorableLayer(l))!;
};

export const getInvalidPolys = function (map: any, layers?: any) {
  const layersData = getEditableLayers(map);
  const invalidPolys = [];

  for (const l of layersData) {
    if (layers?.get(l.get("id"))?.geometry_type) {
      const features = l
        ?.getSource()
        .getFeatures()
        .filter((f: any) => {
          return (
            !f.isValid() && f.getGeometry().getType() === GEOMETRY_TYPES.POLYGON
          );
        });
      invalidPolys.push(...features);
    }
  }
  return invalidPolys;
};

export const getMultiPolyFeatures = function (map: any) {
  const parcel_layer = map
    ?.getLayers()
    ?.getArray()
    .find((lyr: any) => "parcel_layer" == lyr.get("id"));

  const multiPolyFeatures = [];
  const features = parcel_layer?.getSource().getFeatures();
  multiPolyFeatures.push(...features);

  return multiPolyFeatures;
};

const makeFeatureFromPoint = (pointGeom: any) => {
  const pointFeature = new Feature({
    geometry: pointGeom,
  });
  const color = "rgba(255, 0, 0, 0.6)";

  const style = new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: color,
      }),
      fill: new Fill({
        color: color,
      }),
    }),
  });
  pointFeature.setStyle(style);

  return pointFeature;
};

function getLayerById(map: any, id: string): any {
  return map!
    .getLayers()
    .getArray()
    .find((lyr) => id == lyr.get("id"));
}

const highlightPoint = (
  map: any,
  feature: any,
  pointGeom: any,
  highlightedPointFeatures: Map<string, any>,
  previousHighlightedFeature: any
) => {
  if (previousHighlightedFeature.current === feature) {
    if (highlightedPointFeatures.has(feature.ol_uid)) {
      const layer = getLayerById(map, HIGHLIGHT_INVALIDTIES);
      const addedFeature = makeFeatureFromPoint(pointGeom);
      layer.getSource().addFeature(addedFeature);
      highlightedPointFeatures.get(feature.ol_uid).push(addedFeature);
    }
  } else {
    if (previousHighlightedFeature.current) {
      const features = highlightedPointFeatures.get(
        previousHighlightedFeature.current.ol_uid
      );
      const layer = previousHighlightedFeature.current.getLayer();
      removeFeatures(layer.getSource(), features);
      highlightedPointFeatures.delete(
        previousHighlightedFeature.current.ol_uid
      );
    }
    const layer = getLayerById(map, HIGHLIGHT_INVALIDTIES);
    const addedFeature = makeFeatureFromPoint(pointGeom);
    layer.getSource().addFeature(addedFeature);

    if (!highlightedPointFeatures.has(feature.ol_uid)) {
      highlightedPointFeatures.set(feature.ol_uid, [addedFeature]);
    }
    previousHighlightedFeature.current = feature;
  }
};

export const zoomIn = (
  map: any,
  feature: any,
  highlightedPointFeatures: Map<string, any>,
  previousHighlightedFeature: any,
  setSelectedFeature: any,
  selectedFeature: any
) => {
  let _layer = getLayerById(map, HIGHLIGHT_INVALIDTIES);

  if (!_layer) {
    _layer = new VectorLayer({
      source: new VectorSource({
        wrapX: false,
      }),
      id: HIGHLIGHT_INVALIDTIES, // need to be unique,
      zIndex: 100,
    });
    map?.addLayer(_layer);
  }

  if (feature === selectedFeature) {
    return; // Do nothing if the user has selected the same feature again
  }
  setSelectedFeature(feature);
  map?.getView().fit(feature.getGeometry().getExtent(), {
    duration: 300,
    maxZoom: DEFAULT_ZOOM_VALUE,
    padding: [125, 125, 125, 125],
  });

  if (feature.hasRedundantCoords()) {
    const coords = feature.getGeometry().getCoordinates()[0];
    const coordSet = new Set();
    const redundantCoords: any[] = [];

    coords.forEach((coord: any) => {
      const stringifiedCoord = JSON.stringify(coord);
      if (coordSet.has(stringifiedCoord)) {
        redundantCoords.push(coord);
      } else {
        coordSet.add(stringifiedCoord);
      }
    });
    const uniqueCoordinates = redundantCoords.reduce((acc, coord) => {
      // Check if the current coordinate is already in the accumulator array
      if (!acc.some((c) => c[0] === coord[0] && c[1] === coord[1])) {
        // If not, add it to the accumulator array
        acc.push(coord);
      }
      return acc;
    }, []);
    uniqueCoordinates.forEach((coord: any) => {
      const redundantPoint = Object.values(coord);
      const pointGeom = new Point(redundantPoint);
      highlightPoint(
        map,
        feature,
        pointGeom,
        highlightedPointFeatures,
        previousHighlightedFeature
      );
    });
  } else if (feature.isSelfIntersect()) {
    const poly = getTurfFeature(feature);
    const invalid = kinks(poly);

    invalid.features.forEach((feat) => {
      const intersectionPoint = feat.geometry.coordinates;
      const pointGeom = new Point(intersectionPoint).transform(
        DATA_PROJECTION,
        FEATURE_PROJECTION
      );
      highlightPoint(
        map,
        feature,
        pointGeom,
        highlightedPointFeatures,
        previousHighlightedFeature
      );
    });
  } else if (feature.isSliverPolygon()) {
    const sliverPolygonCoord = feature.getGeometry().getCoordinates()[0][0];
    const pointGeom = new Point(sliverPolygonCoord);
    highlightPoint(
      map,
      feature,
      pointGeom,
      highlightedPointFeatures,
      previousHighlightedFeature
    );
  }
};

const markerSvg = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M24.4853 23.1512L16 31.6366L7.51467 23.1512C5.83646 21.473 4.69358 19.3348 4.23057 17.007C3.76756 14.6793 4.00521 12.2665 4.91346 10.0738C5.82172 7.88107 7.35979 6.00694 9.33318 4.68837C11.3066 3.3698 13.6266 2.66602 16 2.66602C18.3734 2.66602 20.6934 3.3698 22.6668 4.68837C24.6402 6.00694 26.1783 7.88107 27.0865 10.0738C27.9948 12.2665 28.2324 14.6793 27.7694 17.007C27.3064 19.3348 26.1636 21.473 24.4853 23.1512ZM16 19.9992C17.4145 19.9992 18.771 19.4373 19.7712 18.4371C20.7714 17.437 21.3333 16.0804 21.3333 14.6659C21.3333 13.2514 20.7714 11.8949 19.7712 10.8947C18.771 9.89448 17.4145 9.33258 16 9.33258C14.5855 9.33258 13.229 9.89448 12.2288 10.8947C11.2286 11.8949 10.6667 13.2514 10.6667 14.6659C10.6667 16.0804 11.2286 17.437 12.2288 18.4371C13.229 19.4373 14.5855 19.9992 16 19.9992ZM16 17.3326C15.2928 17.3326 14.6145 17.0516 14.1144 16.5515C13.6143 16.0514 13.3333 15.3732 13.3333 14.6659C13.3333 13.9587 13.6143 13.2804 14.1144 12.7803C14.6145 12.2802 15.2928 11.9992 16 11.9992C16.7072 11.9992 17.3855 12.2802 17.8856 12.7803C18.3857 13.2804 18.6667 13.9587 18.6667 14.6659C18.6667 15.3732 18.3857 16.0514 17.8856 16.5515C17.3855 17.0516 16.7072 17.3326 16 17.3326Z" fill="#4361EE"/>
</g>
</svg>
`;
const MarkerFeatureStyle = new Style({
  image: new Icon({
    src: `data:image/svg+xml;utf8,${encodeURIComponent(markerSvg)}`,
  }),
});

export const zoomToCoordinates = (map: any, lon: number, lat: number) => {
  const layer = getLayerById(map, CORRDINATES_LAYER);

  // Create a new point feature at a specific coordinate
  const point = new Feature({
    geometry: new Point([lon, lat]).transform(
      DATA_PROJECTION,
      FEATURE_PROJECTION
    ), // Assuming longitude and latitude are the coordinates
  });

  // Apply the custom icon style to the point feature
  point.setStyle(MarkerFeatureStyle);

  // Add the point feature to a vector layer
  layer.getSource().clear();
  layer.getSource().addFeature(point);

  map.getView().setCenter(fromLonLat([lon, lat]));
  map.getView().setZoom(18);
};

export const setImageSrc = (map: any, url: string) => {
  map.getAllLayers().forEach((layer: any) => {
    if (layer.get("id") === IMAGE_STATIC_ORTHO) {
      if (layer instanceof TileLayer) {
        const extent = layer.getExtent();
        const img_src = new Static({
          url: url,
          imageExtent: extent,
        });
        const img_layer = new ImageLayer({
          // @ts-ignore id is private
          id: IMAGE_STATIC_ORTHO,
          source: img_src,
          zIndex: 1,
        });
        map!.addLayer(img_layer);
      } else if (layer instanceof ImageLayer) {
        const img_src = new Static({
          url: url,
          imageExtent: layer.getSource().getImageExtent(),
        });
        layer.setSource(img_src);
      }
    }
  });
};
