import { Select, DragBox } from 'ol/interaction';
import { click, shiftKeyOnly, all } from 'ol/events/condition';
import { unByKey } from 'ol/Observable';
import { selectStyle } from '../../../hooks/tools/helpers/styles';
import { executeAction, undoRedoPush } from '../../mapLayer/mapInit';
import { KEY_CODE, LAWN_ATTRIBUTE, PARCEL } from '../../../hooks/tools/helpers/constants';
import { ACTION, ROTATE_SUBTOOLS, TOOL_TYPE } from '../constants';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { Geometry } from 'ol/geom';
import Feature from 'ol/Feature';
import { Collection, MapBrowserEvent } from 'ol';
import {
  AUTO_SCALE_TOOL_LAYER,
  ARROW_TOOL,
  DIMENSION_TOOL_LAYER,
  GEOMETRY_TYPE,
  HIGHLIGHT_TOOL_LAYER
} from 'woodpecker';
import { showToast } from 'ui';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';

const LAWN_KEY_MAP: { [key: string]: string } = {
  1: 'F',
  2: 'B',
  3: 'L',
  4: 'R'
};

class SelectTool extends ToolAbstract {
  private mapObj: MapBase;
  private select: Select | null;
  private layer: any;
  private drag: DragBox | null;
  private key: any;

  private constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.drag = null;
    this.layer = null;
    this.key = null;
  }

  init(id: string) {
    this.off();
    globalStore.AppStore.setSelectedFeatures([]);

    if (!this.isMultiLayerSelectionEnable()) {
      this.layer = this.mapObj?.getLayerById(id);
      if (!id || !this.layer) {
        this.noLayerError();
        return;
      }
    }

    let preSelectedFeatures = new Collection<Feature>(
      globalStore.AppStore.selectedFeatures.map((feature: any) => {
        // Apply your custom style here, assuming `style` is a function that returns a style.
        // feature.setStyle(selectStyle(feature));

        return feature;
      })
    );

    const selectLayers = this.getLayers();
    /** Define interactions */
    this.select = new Select({
      layers: selectLayers,
      filter: feature => feature?.get('vector_layer_id') !== AUTO_SCALE_TOOL_LAYER,
      multi: false,
      condition: click || all(shiftKeyOnly, click),
      style: feature => {
        const feat_vector_layer_id = feature?.getProperties()?.['vector_layer_id'];
        const layerData = globalStore?.AppStore?.layers?.get(feat_vector_layer_id) || {};

        return selectStyle(
          layerData?.geometry_type === GEOMETRY_TYPE.ABSTRACT,
          layerData?.geometry_type === GEOMETRY_TYPE.TYPICAL
        );
      },
      features: preSelectedFeatures
    });
    this.drag = new DragBox();

    /** Add interactions */
    this.mapObj.map?.addInteraction(this.select);
    this.mapObj.map?.addInteraction(this.drag);

    /** Add events */
    this.drag.on('boxdrag', this.onBoxDrag);
    this.drag.on('boxend', this.onBoxDragEnd);
    this.key = this.mapObj.map?.on('singleclick', this.onMapSingleClick);
    window.addEventListener('keydown', this.handleKeydown, false);
  }

  onBoxDrag = () => {
    this.emptyFeatureArray();
  };

  onMapSingleClick = (e: MapBrowserEvent<any>) => {
    showToast(`${this.getSelectedFeaturesCount()} features selected.`);
    let layer = this.mapObj.map?.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
      return layer;
    });

    if (!layer) {
      this.emptyFeatureArray();
    } else if (layer.get('id') == PARCEL) {
      this.emptyFeatureArray();
    }

    globalStore.AppStore.setSelectedFeatures(this.select?.getFeatures().getArray() ?? []);

    // if (!this.select.getFeatures().getLength()) {
    //   message.destroy("selection-tool");
    // }
  };

  onBoxDragEnd = () => {
    let extent = this.drag?.getGeometry().getExtent();
    const layers = this.getLayers();
    layers.forEach(layer => {
      const source = layer?.getSource();
      if (source && extent) {
        source.forEachFeatureIntersectingExtent(extent, (feature: Feature<Geometry>) => {
          this.selectFeature(feature);
        });
      }
    });

    globalStore.AppStore.setSelectedFeatures(this.select?.getFeatures().getArray() ?? []);
    const selectedFeaturesCount = this.getSelectedFeaturesCount();
    if (selectedFeaturesCount) showToast(`${selectedFeaturesCount} features selected.`);
  };

  handleKeydown = (evt: any) => {
    if ([1, 2, 3, 4].includes(parseInt(evt.key))) {
      this.changeLawnAttribute(LAWN_KEY_MAP[evt.key]);
    } else if (evt.keyCode === KEY_CODE.DELETE) {
      executeAction(ACTION.DELETE);
    }
  };

  removeHiddenGeometries() {
    const layerRecord = new Map();
    const selectedFeature = globalStore.AppStore.selectedFeatures;
    const filteredFeatures = selectedFeature?.filter((feature: Feature) => {
      const vector_layer_id = feature?.get('vector_layer_id');
      let isVisible = true;
      if (layerRecord?.has(vector_layer_id)) {
        const layer = layerRecord?.get(vector_layer_id);
        isVisible = isVisible && layer?.getVisible();
      } else {
        const layer = this.mapObj?.getLayerById(vector_layer_id);
        layerRecord?.set(vector_layer_id, layer);
        isVisible = isVisible && layer?.getVisible();
      }
      if (!isVisible) {
        this.removeSelectedFeature(feature);
      }
      return isVisible;
    });

    return filteredFeatures;
  }

  changeLawnAttribute(lawnType: any) {
    if (globalStore.AppStore.isUpdatingForm) return;

    const features = this.select ? this.select.getFeatures().getArray() : [];
    if (!features.length) {
      //   message.error("No feature is selected.");
    } else {
      for (let i = 0; i < features.length; i++) {
        let feature = features[i];
        feature.setProperties({ [LAWN_ATTRIBUTE]: lawnType });
      }
      this.emptyFeatureArray();
      undoRedoPush();
    }
  }

  getLayers = (): (VectorLayer<VectorSource> | VectorImageLayer<VectorSource>)[] => {
    const dimensionLayer = this.mapObj?.getLayerById(DIMENSION_TOOL_LAYER);
    const highlight = this.mapObj?.getLayerById(HIGHLIGHT_TOOL_LAYER);
    const arrow = this.mapObj?.getLayerById(ARROW_TOOL);
    const layers = [dimensionLayer, highlight, arrow];
    if (!this.isMultiLayerSelectionEnable()) {
      if (!this.layer) {
        this.noLayerError();
        return [];
      }
      layers.push(this.layer);
    } else {
      const currentLayers = globalStore.AppStore.current_layers || [];
      currentLayers.forEach((l: { id: string }) => {
        if (l.id !== PARCEL) {
          const layer = this.mapObj.getLayerById(l.id);
          if (layer && layer.getVisible()) layers.push(layer);
        }
      });
    }
    return layers;
  };

  selectFeature = (feature: Feature<Geometry>) => {
    if (this.select) this.select.getFeatures().push(feature);
  };

  getSelectedFeaturesCount = (): number => {
    if (this.select) {
      return this.select?.getFeatures()?.getLength();
    }
    return 0;
  };

  removeSelectedFeature = (feature: Feature<Geometry>) => {
    if (this.select) this.select.getFeatures().remove(feature);
  };

  isMultiLayerSelectionEnable = () => globalStore.AppStore.toolsSetting.allowSelectionAcrossLayers;

  emptyFeatureArray() {
    if (this.select) {
      this.select.getFeatures().clear();
    }
  }

  noLayerError() {
    showToast('No layer provided to perform select action.', 'error');
    this.off();
  }

  off() {
    if (
      [...ROTATE_SUBTOOLS, TOOL_TYPE.COPY_AND_MOVE, TOOL_TYPE.MOVE].includes(globalStore.AppStore.tool.tool_id) ||
      globalStore.AppStore.tool.is_action
    ) {
      globalStore.AppStore.setSelectedFeatures(
        globalStore.AppStore.selectedFeatures?.length ? [...this.removeHiddenGeometries()] : []
      );
    }

    this.emptyFeatureArray();
    this.mapObj.map?.removeInteraction(this.select as Select);
    this.mapObj.map?.removeInteraction(this.drag as DragBox);
    unByKey(this.key);
    window.removeEventListener('keydown', this.handleKeydown);
    // message.destroy("selection-tool");
  }
}

export default SelectTool;
