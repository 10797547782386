export const PasswordIcon = () => (
  <svg
    fill="#000000"
    width="1.5rem"
    height="1.5rem"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
  >
    <path d="M17,9V7c0-2.8-2.2-5-5-5S7,4.2,7,7v2c-1.7,0-3,1.3-3,3v7c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3v-7C20,10.3,18.7,9,17,9z M9,7c0-1.7,1.3-3,3-3s3,1.3,3,3v2H9V7z" />
  </svg>
);
