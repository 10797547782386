import * as React from 'react';
export * from './reactQueryDefaultConfigs';
export * from './apiCalls';
export * from './hooks/useQueryFilters';
export * from './string';

export const getLocalStorageData = (key: string, isToken: boolean = false) => {
  return isToken
    ? localStorage.getItem(key)
    : localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key)!)
    : null;
};

export const handleLogout = () => {
  localStorage.clear();
};
