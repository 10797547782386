import React, { ReactElement } from 'react';
import { PostHog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

const PosthogProvider = ({ children, client }: { children: ReactElement; client: PostHog }) => {
  const initializePosthog = process.env.APP_ENV === 'prod' || process.env.APP_ENV === 'stage';
  if (initializePosthog) {
    return <PostHogProvider client={client}>{children}</PostHogProvider>;
  }
  return children;
};

export default PosthogProvider;
