import useMapStore from '../../../store/mapStore';

class AppStoreListener {
  public AppStore: any = null;
  private static instance: AppStoreListener;

  private constructor() {
    useMapStore.subscribe(state => this.handleStoreChange(state));
  }

  handleStoreChange(state: any) {
    this.AppStore = state;
  }

  static getInstance() {
    if (!AppStoreListener.instance) {
      AppStoreListener.instance = new AppStoreListener();
    }
    return AppStoreListener.instance;
  }
}

export const globalStore = AppStoreListener.getInstance();

export default AppStoreListener;
