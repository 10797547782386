import { BEAM_USER, BRIGHTVIEW_USER, REXEL_USER } from '../Constants/Constant';

export const isRexelUser = () => {
  return window.location.hostname.startsWith(REXEL_USER);
};

export const isBeamUser = () => {
  const domain = window.location.hostname.split('.');
  return domain.includes(BEAM_USER);
};

export const isBrightviewUser = () => {
  const domain = window.location.hostname.split('.');
  return domain.includes(BRIGHTVIEW_USER);
};

/**
 * A user is pro user if they are paid and is_lite_user flag is false for them
 * @returns true or false
 */
export const isProUser = (user: $TSFixMe) => {
  return user.is_in_free_trial?.is_subscribe && !user.is_lite_user;
};

/**
 * A user is lite user if they are paid and is_lite_user flag is true for them
 * @returns true or false
 */
export const isLiteUser = (user: $TSFixMe) => {
  return user.is_in_free_trial?.is_subscribe && user.is_lite_user;
};
