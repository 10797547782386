import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import VectorTileLayer from 'ol/layer/VectorTile';
import { Feature } from 'ol';
import { GEO_JSON, generateUniqueID, getTurfFeature, isValidLineString } from 'macaw';
import { polygonToLine } from '@turf/turf';
import { GEOMETRY_TYPES } from 'woodpecker';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { unByKey } from 'ol/Observable';
import { MultiLineString } from 'ol/geom';

class PolygonToLine extends ToolAbstract {
  private mapObj: MapBase;
  private evenListener: any;
  private layer: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
  }

  getFeatureAtPixel = (px: any) => {
    return this.mapObj.map?.forEachFeatureAtPixel(
      px,
      (feature: any, layer: any) => {
        return feature;
      },
      {
        hitTolerance: 10
      }
    );
  };

  init(id: string): void {
    this.layer = this.mapObj.getLayerById(id);
    this.mapObj.map!.getViewport().style.cursor = 'crosshair';
    this.evenListener = this.mapObj.map?.on('singleclick', this.onClick);
  }

  onClick = (e: any) => {
    const feature = this.getFeatureAtPixel(e.pixel);
    if (feature && feature?.getGeometry()?.getType() !== GEOMETRY_TYPES.POLYGON) return;
    if (feature) {
      const polyLine = GEO_JSON.readFeature(polygonToLine(getTurfFeature(feature) as any));
      if (polyLine) {
        if (polyLine.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_LINESTRING) {
          const multiLine = polyLine.getGeometry() as MultiLineString;
          const lines = multiLine?.getLineStrings();
          lines.forEach((line, index) => {
            const unq_id = generateUniqueID('ptl' + index);
            const newFeature = new Feature({
              geometry: line
            });
            const isValid = isValidLineString(newFeature);
            if (isValid) {
              newFeature.setId(unq_id);
              this.layer.getSource().addFeature(newFeature);
            }
          });
        } else {
          const unq_id = generateUniqueID('ptl');
          polyLine.setId(unq_id);
          this.layer.getSource().addFeature(polyLine);
          setTimeout(() => undoRedoPush(), 0);
        }
      }
    }
  };

  off(): void {
    this.mapObj.map!.getViewport().style.cursor = '';
    unByKey(this.evenListener);
  }
}

export default PolygonToLine;
