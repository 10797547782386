import { Draw } from 'ol/interaction';
import { Type } from 'ol/geom/Geometry';
import { LineString, Point } from 'ol/geom';
import * as turf from '@turf/turf';

import { DATA_PROJECTION, FEATURE_PROJECTION, GEOMETRY_TYPES } from 'woodpecker';
import { getRectangleCoordinates } from 'macaw';

import MapBase from '../../mapLayer/mapBase';
import { getAllFeaturesSource, isOutOfExtent } from '../../../helpers/helpers';
import AddSegment from './AddSegment';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { crossHairStyle, labelStyle, polylineStyle } from '../../../hooks/tools/helpers/styles';
import { formatLineRectanglePerimeter } from '../../../hooks/tools/helpers';

class AddLineRectangle extends AddSegment {
  constructor(mapObj: MapBase) {
    super(mapObj);
    this.mapObj = mapObj;
    this.draw = null;
    this.snap = null;
  }

  /**
   * Initializes the tool for drawing lines on the map.
   * Turns off any existing tool and sets up the draw interaction for the specified layer ID.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init(id: string, featureTracing: boolean = false) {
    this.off();

    const layer = this.mapObj.getLayerById(id);
    this.layer = layer;
    if (layer) {
      const source = layer.getSource();

      const targetLayerSource = getAllFeaturesSource();
      this.draw = new Draw({
        source,
        type: GEOMETRY_TYPES.LINESTRING as Type,
        style: (feature: any) => {
          return this.styleFunction(feature);
        },
        dragVertexDelay: 0,
        snapTolerance: 1,
        maxPoints: 3,
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick === 3 || mouseClick === 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        },
        trace: featureTracing,
        traceSource: targetLayerSource,
        geometryFunction: this.geometryFunction
      });

      this.mapObj.map?.addInteraction(this.draw);
      this.draw.on('drawstart', this.onDrawStart);
      this.draw?.on('drawend', this.onDrawEnd);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  }

  geometryFunction = (coordinates: any, geometry: any) => {
    if (!geometry) {
      geometry = new LineString(coordinates);
    } else {
      geometry.setCoordinates(coordinates);
    }
    const points = coordinates;
    if (points && points.length > 1) {
      const firstPoint = turf.point(
        new Point(points[0])
          .transform(FEATURE_PROJECTION, DATA_PROJECTION)
          // @ts-ignore: Unreachable code error
          .getCoordinates()
      );
      const secondPoint = turf.point(
        new Point(points[1])
          .transform(FEATURE_PROJECTION, DATA_PROJECTION)
          // @ts-ignore: Unreachable code error
          .getCoordinates()
      );

      if (points.length > 2) {
        const thirdPoint = turf.point(
          new Point(points[2])
            .transform(FEATURE_PROJECTION, DATA_PROJECTION)
            // @ts-ignore: Unreachable code error
            .getCoordinates()
        );

        let finalCoordinates = getRectangleCoordinates(firstPoint, secondPoint, thirdPoint);

        finalCoordinates = finalCoordinates.map((pt: any) => {
          return (
            new Point(pt.geometry.coordinates)
              .transform(DATA_PROJECTION, FEATURE_PROJECTION)
              // @ts-ignore: Unreachable code error
              .getCoordinates()
          );
        });
        geometry.setCoordinates(finalCoordinates);
      }
      return geometry;
    }
  };

  styleFunction(feature: any) {
    const { scale, dpi } = globalStore.AppStore.worksheetParams;

    let styles = [...polylineStyle];

    if (globalStore.AppStore.ortho_mode) {
      styles = [crossHairStyle];
    }

    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.LINESTRING && scale !== null) {
      const coordinates = geometry.getCoordinates();

      // ensure that it's a closed rectangle (minimum 5 points: 4 corners + closing point)
      if (coordinates.length > 4) {
        const lineString = new LineString(coordinates);
        const perimeter = lineString.getLength();

        const formattedPerimeter = formatLineRectanglePerimeter(perimeter, dpi, scale || 0);

        // Get the midpoint of the first edge for label placement
        const centerPoint = coordinates
          .reduce((acc: number[], coord: number[]) => [acc[0] + coord[0], acc[1] + coord[1]], [0, 0])
          .map((coord: number) => coord / coordinates.length);
        const _labelStyle = labelStyle.clone();
        _labelStyle.setGeometry(new Point(centerPoint));
        _labelStyle.getText().setText(formattedPerimeter);
        styles.push(_labelStyle);
      }
    }
    return styles;
  }
}

export default AddLineRectangle;
