import axios, { AxiosError, AxiosResponse } from "axios";
import { getLocalStorageData, handleLogout } from ".";

const axiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_SERVER_URL,
});

const handleUnAuthorization = () => {
  handleLogout();
  window.location.href = "/login";
};

axiosClient.interceptors.request.use(
  function (request) {
    let tokenData = getLocalStorageData("token", true);
    if (tokenData) {
      request.headers["Authorization"] = `Token ${tokenData}`;
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getAPI = <T>(url: string, params = {}): Promise<T> => {
  return new Promise((resolve, reject) => {
    axiosClient
      .get(url, { params })
      .then((res: AxiosResponse<T>) => {
        resolve(res.data);
      })
      .catch((err: AxiosError) => {
        if (err?.response?.status === 401) {
          handleUnAuthorization();
        }
        reject(err as AxiosError);
      });
  });
};

export const postAPI = (
  endpoint: string,
  payload = {},
  type: "login" | "default" = "default"
) => {
  return new Promise((resolve, reject) => {
    if (type === "login") {
      //remove headers from axiosClient
      axiosClient.interceptors.request.clear();
    }
    axiosClient
      .post(endpoint, payload, { signal: AbortSignal.timeout(20000) })
      .then((res) => resolve(res.data))
      .catch((err: AxiosError) => {
        if (err?.response?.status === 401) {
          handleUnAuthorization();
        }
        reject(err);
      });
  });
};

export const putAPI = (endpoint: string, payload = {}) => {
  return new Promise((resolve, reject) => {
    axiosClient
      .put(endpoint, payload, { signal: AbortSignal.timeout(20000) })
      .then((res) => resolve(res.data))
      .catch((err: AxiosError) => {
        if (err?.response?.status === 401) {
          handleUnAuthorization();
        }
        reject(err);
      });
  });
};

export const deleteAPI = (endpoint: string, payload = {}) => {
  return new Promise((resolve, reject) => {
    axiosClient
      .delete(endpoint, payload)
      .then((res) => resolve(res.data))
      .catch((err: AxiosError) => {
        if (err?.response?.status === 401) {
          handleUnAuthorization();
        }
        reject(err);
      });
  });
};
