import { v4 as uuid4 } from 'uuid';
import { captureException } from '@sentry/react';

import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { getUid } from 'ol/util';
import { Icon, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import Select from 'ol/interaction/Select';
import VectorImageLayer from 'ol/layer/VectorImage';
import { boundingExtent } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'ol-e... Remove this comment to see the full error message
import Transform from 'ol-ext/interaction/Transform';

import { BP_PREFIX, LAYER_INDEX, MAP_LAYERS } from '../../../Constants/Constant';
import { addPixelInCoords, getDiffInPixelsFromCoords } from '../../../Utils/olutils';
import { layerTracker, outputMap } from '../MapInit';
import { interpolate, multipartAPI, patchAPI } from '../../../Utils/ApiCalls';
import { ICONS, ICON_DELETE } from '../../../Constants/Urls';
import { changeMapCursor } from '../../../Utils/HelperFunctions';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { useRequest } from '../../../Stores/Request';

// returns true if every pixel's uint32 representation is 0 (or "blank")
const checkCanvasBlank = (canvas: $TSFixMe) => {
  try {
    const context = canvas.getContext('2d');
    const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
    return !pixelBuffer.some(color => color !== 0);
  } catch (error) {
    return false;
  }
};
const createIcon = (image: $TSFixMe) => {
  return new Icon({
    anchor: [0.5, 0.5],
    crossOrigin: 'anonymous',
    img: image,
    imgSize: image ? [image.width, image.height] : undefined
  });
};

const createCanvas = (image: $TSFixMe, width: $TSFixMe, height: $TSFixMe, scale = 1) => {
  const hasHW = height && width;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  image.height = hasHW ? height : image.height * scale;
  image.width = hasHW ? width : image.width * scale;
  canvas.width = image.width;
  canvas.height = image.height;

  // @ts-expect-error TS(2531): Object is possibly 'null'.
  context.drawImage(image, 0, 0, image.width, image.height);
  return canvas;
};

const getImage = (id: $TSFixMe, src: $TSFixMe) => {
  let image = document.getElementById(`upload-icon-node-${id}`);
  if (!image) {
    image = new Image();
    image.id = `upload-icon-node-${id}`;
    image.style.display = 'none';
    document.body.appendChild(image);
  }
  // @ts-expect-error TS(2339): Property 'src' does not exist on type 'HTMLElement... Remove this comment to see the full error message
  if (src && image.src !== src) image.src = src;
  return image;
};

function loadImage(imgSrc: $TSFixMe, iconId: $TSFixMe) {
  return new Promise(resolve => {
    const image = new Image();
    image.src = imgSrc;
    image.addEventListener('load', () => resolve([iconId, image]), { once: true });
  });
}

/**
 *
 * @param {*} mapObj
 * @param {Map} cache
 * @returns
 */
const getFeatureStyle = (mapObj: $TSFixMe, cache: $TSFixMe) => {
  let scale;
  let thresholdResolution;
  let isCanvasBlank;
  let isChanged;
  let initResolution;
  let originalIcon;
  let layerId;
  let canvas;
  let icon;
  return (feature: $TSFixMe, resolution: $TSFixMe) => {
    const styles = [];
    isChanged = feature.get('isChanged');
    initResolution = feature.get('initResolution');
    // eslint-disable-next-line no-multi-assign
    isCanvasBlank = layerId = feature.get('layerId');
    originalIcon = cache.get(layerId);

    icon = feature.get('icon');
    canvas = cache.get(feature.get('timestamp'));

    if (icon) isCanvasBlank = checkCanvasBlank(icon.getImage());

    if (!icon || isCanvasBlank || isChanged) {
      const [width, height] = getNewSize(feature, mapObj.map);
      if (!icon || isCanvasBlank || isChanged || width !== icon.getImage().width || height !== icon.getImage().height) {
        canvas = createCanvas(originalIcon.getImage().cloneNode(), width, height);
        cache.set(feature.get('timestamp'), canvas);
        icon = createIcon(canvas);
        feature.setProperties({ isChanged: false, icon });
      }
    }

    if (!initResolution || isChanged) {
      initResolution = resolution;
      feature.set('initResolution', resolution);
      feature.set('threshold-resolution', 1);
    }

    if (initResolution !== resolution) {
      scale = initResolution / resolution;
      icon.setScale(scale);
      thresholdResolution = feature.get('threshold-resolution');
      if (Math.abs(scale - thresholdResolution) > 1) {
        feature.set('threshold-resolution', Math.abs(scale));
        feature.set('isChanged', true);
      }
    }

    styles.push(
      new Style({
        image: icon,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        geometry: feature => feature.getGeometry().getInteriorPoint()
      })
    );
    return styles;
  };
};

/**
 *
 * @param {ol/Feature} feature
 * @param {ol/Map} map
 * @returns {Array} [width ,height]
 */
const getNewSize = (feature: $TSFixMe, map: $TSFixMe) => {
  const extent = feature.getGeometry().getExtent();

  const firstCoords = [extent[0], extent[1]];
  const lastCoords = [extent[2], extent[3]];

  const pixels = getDiffInPixelsFromCoords(map, firstCoords, lastCoords);

  return pixels;
};

const blackListProperties = [
  'icon',
  'iconInfo',
  'isChanged',
  'originalIcon',
  'initResolution',
  'imageSrc',
  'threshold-resolution'
];

class UploadIcon extends Observer {
  cache: $TSFixMe;

  iconVisibility: $TSFixMe;

  imageNode: $TSFixMe;

  imageOptions: $TSFixMe;

  imageSrc: $TSFixMe;

  layer: $TSFixMe;

  mapObj: $TSFixMe;

  select: $TSFixMe;

  selectStyle: $TSFixMe;

  shouldLoadIcons: $TSFixMe;

  transform: $TSFixMe;

  constructor(mapObj: $TSFixMe) {
    super();
    this.mapObj = mapObj;
    this.layer = null;
    this.imageOptions = {};
    this.imageSrc = null;
    this.imageNode = null;
    this.select = null;
    this.transform = null;
    this.selectStyle = {};
    this.iconVisibility = true;
    this.cache = new Map();
    this.shouldLoadIcons = true;
  }

  on() {
    this.select = new Select({
      // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: (_: Fe... Remove this comment to see the full error message
      wrapX: false,
      filter: (_, layer) => layer?.get('name') === MAP_LAYERS.ICON,
      style: feature => {
        const styles = [];
        const originalIcon = feature.get('originalIcon');
        let icon = feature.get('icon');

        if (icon) {
          const [width, height] = getNewSize(feature, this.mapObj.map);
          if (icon.getImage().width !== width || icon.getImage().height !== height || checkCanvasBlank(icon))
            icon = createIcon(createCanvas(originalIcon.getImage().cloneNode(), width, height));
        }
        styles.push(
          new Style({
            image: icon,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            geometry: feature => feature.getGeometry().getInteriorPoint()
          })
        );
        return styles;
      }
    });

    this.transform = new Transform({
      translate: true,
      scale: true,
      hitTolerance: 2,
      stretch: false,
      rotate: true,
      noFlip: true,
      filter: (_: $TSFixMe, layer: $TSFixMe) => layer?.get('name') === MAP_LAYERS.ICON
    });

    outputMap.setVisibilityByName(MAP_LAYERS.ICON, true);
    this.mapObj.map.addInteraction(this.select);
    this.mapObj.map.addInteraction(this.transform);
    this.mapObj.map.on('singleclick', this.uploadIcon);
    this.transform.on('scaleend', this.handleChangedLayers);
    this.transform.on('translateend', this.handleChangedLayers);

    document.addEventListener('keydown', this.onKeyPress);
  }

  onAddImage = (imageOptions = {}) => {
    // @ts-expect-error TS(2339): Property 'image' does not exist on type '{}'.
    const imageSrc = imageOptions.image;
    this.imageOptions = imageOptions || {};
    this.imageSrc = imageSrc;
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    this.imageNode = getImage(imageOptions.id, imageSrc);

    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    this.layer = this.mapObj.getLayerById(imageOptions.id);
    if (!this.layer) {
      const src = new VectorSource({ wrapX: false });
      this.layer = new VectorImageLayer({
        source: src,
        // @ts-expect-error TS(2345): Argument of type '{ source: VectorSource<Geometry>... Remove this comment to see the full error message
        id: imageOptions.id,
        name: MAP_LAYERS.ICON,
        layerData: { name: MAP_LAYERS.ICON },
        zIndex: LAYER_INDEX.ICON,
        blackListProperties: blackListProperties.filter(bl => !['initResolution', 'imageSrc'].includes(bl)),
        style: getFeatureStyle(this.mapObj, this.cache)
      });

      this.mapObj.addLayer(this.layer);
    }
    this.setActiveIconState(imageOptions);
    setTimeout(() => changeMapCursor(true, 'crosshair'), 200);
  };

  onRemoveImage = () => {
    this.imageOptions = {};
    this.imageSrc = null;
    this.imageNode = null;
    this.layer = null;
    this.removeActiveIcon();
    setTimeout(() => changeMapCursor(true, 'default'), 200);
  };

  handleChangedLayers = ({ feature }: $TSFixMe) => {
    feature.set('isChanged', true);
    const [width, height] = getNewSize(feature, this.mapObj.map);
    feature.setProperties({ width, height });

    this.shouldLoadIcons = true;

    layerTracker.push(MAP_LAYERS.ICON, feature.get('layerId'));

    this.notifyObservers(TOOL_EVENT.ICON_TOOL);
  };

  getActiveIconState() {
    // @ts-expect-error TS(2339): Property 'activeIcon' does not exist on type 'neve... Remove this comment to see the full error message
    return useRequest.getState().toolbar?.active?.activeIcon;
  }

  setActiveIconState(iconInfo: $TSFixMe) {
    const { active } = useRequest.getState().toolbar || {};
    if (!active) return;

    // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
    useRequest.getState()?.dispatch?.({ type: 'SET_TOOL_ACTIVE', payload: { ...active, activeIcon: iconInfo } });
  }

  removeActiveIcon() {
    const { active } = useRequest.getState().toolbar || {};
    if (!active) return;

    // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
    useRequest.getState()?.dispatch?.({ type: 'SET_TOOL_ACTIVE', payload: { ...active, activeIcon: null } });
  }

  onKeyPress = (event: $TSFixMe) => {
    const KeyID = event.keyCode;
    if (KeyID === 46 || KeyID === 8) {
      this.select
        .getFeatures()
        .getArray()
        .forEach((feature: $TSFixMe) => {
          const layer = this.mapObj.getLayerById(feature.get('layerId'));
          if (layer?.getSource()?.hasFeature(feature)) {
            this.shouldLoadIcons = true;
            layer.getSource().removeFeature(feature);
          }
        });
    }
  };

  uploadFileToServer = async (icon: $TSFixMe) => {
    if (!icon) return Promise.reject(new Error('icon is required'));
    const form = new FormData();
    form.append('image', icon);
    const { params, prefix } = this.getRequestParams();
    return multipartAPI(ICONS, { data: form, params, prefix }).then(iconInfo => {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      this.onAddImage(iconInfo);
      return iconInfo;
    });
  };

  uploadIcon = (e: $TSFixMe) => {
    const selected = this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
      if (_layer?.get('name') === MAP_LAYERS.ICON) {
        return _feature;
      }
      return null;
    });
    if (selected || !this.imageNode) return;
    const { coordinate } = e;
    if (this.imageNode.complete) {
      this.addImageFeature(this.imageNode, coordinate);
    } else {
      this.imageNode.onload = () => {
        this.addImageFeature(this.imageNode, coordinate);
      };
    }
  };

  addImageFeature = (imageNode: $TSFixMe, startCoords: $TSFixMe) => {
    const origin = startCoords.slice();
    let aspectRatio;

    let { height } = imageNode;
    let { width } = imageNode;
    const isHeightGrWidth = height > width;
    if (isHeightGrWidth ? height > 50 : width > 50) {
      aspectRatio = width / height;
      if (isHeightGrWidth) {
        height = 50;
        width = aspectRatio * height;
      } else {
        width = 50;
        height = width / aspectRatio;
      }
    }
    const polygonGeom = fromExtent(
      boundingExtent([origin, addPixelInCoords(this.mapObj.map, startCoords, width, height)])
    );

    const layerId = this.imageOptions.id;
    const originalIcon = createIcon(imageNode);

    const feature = new Feature({
      geometry: polygonGeom,
      layerId,
      imageSrc: this.imageOptions.image,
      originalIcon,
      iconInfo: this.imageOptions ? { ...this.imageOptions } : null,
      timestamp: Date.now(),
      height,
      width
    });

    this.cache.set(this.imageOptions.id, originalIcon);
    feature.setId(getUid(feature));

    this.layer.getSource().addFeature(feature);
    this.layer.set('icon_url', this.imageOptions.image);

    this.shouldLoadIcons = true;

    layerTracker.push(MAP_LAYERS.ICON, feature.get('layerId'));

    this.notifyObservers(TOOL_EVENT.ICON_TOOL);
  };

  getIconsGeojson = () => {
    const iconId = layerTracker.getValuesByKey(MAP_LAYERS.ICON)[0];
    const layer = this.mapObj.getLayerById(iconId);
    const icon_url = layer.get('icon_url');

    let iconRequestId;
    if (layer.get('iconRequestId')) {
      iconRequestId = layer.get('iconRequestId');
    } else {
      iconRequestId = uuid4();
      layer.set('iconRequestId', iconRequestId);
    }
    const geojson = this.getGeojson(layer);
    const data = { icon: iconId, geojson, id: iconRequestId, icon_url };

    return data;
  };

  addIcons = (icons: $TSFixMe) => {
    if (!this.shouldLoadIcons) return;
    this.removeLayers();

    Promise.allSettled(icons.map((icon: $TSFixMe) => loadImage(icon.icon_url, icon.icon))).then((res: $TSFixMe) => {
      res
        .filter((r: $TSFixMe) => r.status === 'fulfilled')
        .forEach((r: $TSFixMe) => {
          const [iconId, image] = r.value;
          this.cache.set(iconId, createIcon(image));
        });

      try {
        icons.forEach((icon: $TSFixMe) => {
          const iconRequestId = icon.id;
          const iconId = icon.icon;
          const { geojson } = icon;
          const imageSrc = icon.icon_url;

          if (!this.cache.has(iconId)) {
            captureException(new Error(`Image not found in the feature ${iconId}`));
            return;
          }

          this.addIcon({ iconId, iconRequestId, geojson, imageSrc, originalIcon: this.cache.get(iconId) });
        });

        this.shouldLoadIcons = false;
      } catch (err) {
        captureException(err);
      }
    });
  };

  addIcon = ({ iconId, iconRequestId, geojson, imageSrc, originalIcon }: $TSFixMe) => {
    const prevLayer = this.mapObj.getLayerById(iconId);
    if (prevLayer) this.mapObj.removeLayer(prevLayer);

    // features have these properties: width, height, layerId (same as iconId), timestamp (in response that came from backend)
    const src = new VectorSource({
      wrapX: false,
      features: this.mapObj.isBlueprintMap
        ? new GeoJSON().readFeatures(geojson)
        : new GeoJSON().readFeatures(geojson, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          })
    });

    let time;
    const iconInfo = { id: iconId, image: imageSrc };

    src.forEachFeature(f => {
      time = f.get('timestamp');
      f.setProperties({
        layerId: iconId,
        imageSrc,
        originalIcon,
        iconInfo,
        timestamp: time || Date.now()
      });
    });
    const layer = new VectorImageLayer({
      // @ts-expect-error TS(2345): Argument of type '{ id: any; name: string; source:... Remove this comment to see the full error message
      id: iconId,
      name: MAP_LAYERS.ICON,
      source: src,
      iconRequestId,
      layerData: { name: MAP_LAYERS.ICON },
      icon_url: imageSrc,
      zIndex: LAYER_INDEX.ICON,
      blackListProperties: blackListProperties.filter(bl => !['initResolution', 'imageSrc'].includes(bl)),
      style: getFeatureStyle(this.mapObj, this.cache)
    });
    layer.setVisible(this.iconVisibility);
    this.mapObj.addLayer(layer);
  };

  deleteIcon = async (iconId: $TSFixMe) => {
    const { icons, dispatch } = useRequest.getState() || {};
    dispatch({ type: 'SET_ICONS_LIST', payload: { loading: true } });

    patchAPI(interpolate(ICON_DELETE, [iconId]), {
      method: 'DELETE',
      prefix: this.mapObj.isBlueprintMap ? BP_PREFIX : ''
    })
      .then(() => {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        const newList = icons?.list?.slice().filter(i => i.id !== iconId);
        dispatch({ type: 'SET_ICONS_LIST', payload: { list: newList, loading: false } });
      })
      .catch(err => {
        dispatch({ type: 'SET_ICONS_LIST', payload: { loading: false } });
        captureException(err);
      });
  };

  getRequestParams() {
    let params = {};
    let prefix = '';
    if (this.mapObj.isBlueprintMap) {
      const worksheet_id = this.mapObj.baseLayer?.getProperties()?.bp_page_id;
      params = { worksheet_id };
      prefix = BP_PREFIX;
    }
    return { params, prefix };
  }

  removeLayers = () => {
    this.mapObj.getLayers().forEach((layer: $TSFixMe) => {
      if (layer?.get('name') === MAP_LAYERS.ICON) {
        this.mapObj.removeLayer(layer);
      }
    });
  };

  // getters

  getGeojson = (layer: $TSFixMe, { whiteListPropeties = [], includeEmpty }: $TSFixMe = {}) => {
    blackListProperties.filter(blP => !whiteListPropeties.includes(blP));

    // @ts-expect-error TS(2322): Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
    return outputMap.getGeojsonByLayer(layer, { blackListProperties, includeEmpty });
  };

  // setters

  setIconVisibility = (val: $TSFixMe) => {
    this.iconVisibility = val;
    outputMap.setVisibilityByName(MAP_LAYERS.ICON, val);
  };

  off = () => {
    this.selectStyle = {};
    this.mapObj.map.removeInteraction(this.select);
    this.mapObj.map.removeInteraction(this.transform);
    this.mapObj.map.un('singleclick', this.uploadIcon);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (document.getElementById('map')) document.getElementById('map').style.cursor = '';
    outputMap.setVisibilityByName(MAP_LAYERS.ICON, this.iconVisibility);
  };
}

export default UploadIcon;
