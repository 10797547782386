import { undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { globalSnapStore } from '../../utilityclasses/SnapStoreListener';
import VectorSource from 'ol/source/Vector';
import CustomSnap from './overrides/Snap';
import { GeoJSON } from 'ol/format';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Stroke, Style } from 'ol/style';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';

const POLYLINE_FILLING = 'polyline_filling';

class PolylineFilling extends ToolAbstract {
  private geoJSON: VectorSource | null;
  private layer: any;
  private snapRef: CustomSnap | null;
  private mapObj: MapBase;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.layer = null;
    this.geoJSON = null;
    this.snapRef = null;
  }

  mountWorksheetGeoJSON = () => {
    const _source = new VectorSource({
      features: new GeoJSON({ featureProjection: 'EPSG:404000' }).readFeatures(globalSnapStore?.SnapStore?.geoJson),
      wrapX: false
    });
    this.geoJSON = _source;
    let _layer = new VectorImageLayer({
      source: _source,
      style: new Style({
        stroke: new Stroke({
          width: 1
        })
      }),
      zIndex: 100,
      // @ts-ignore: Unreachable code error
      id: 'snapping_polygon' // TODO: need to be unique
    });
    this.layer = _layer;
    this.mapObj?.map?.addLayer(_layer);
  };

  init = (id: string) => {
    this.mountWorksheetGeoJSON();
    const selectedLayer = this.mapObj?.getLayerById(id);
    this.snapRef = new CustomSnap({
      source: this.geoJSON,
      pixelTolerance: 5,
      map: this.mapObj?.map,
      selectedLayer: selectedLayer,
      type: POLYLINE_FILLING,
      scale: globalStore?.AppStore?.worksheetParams?.scale,
      dpi: globalStore?.AppStore?.worksheetParams?.dpi,
      undoRedoPush: undoRedoPush
    });
    setTimeout(() => {
      if (this.snapRef) {
        this.mapObj.map?.addInteraction(this.snapRef);
      }
    }, 1000);
  };

  off = () => {
    this.snapRef?.off();
    if (this.snapRef) this?.mapObj?.map?.removeInteraction(this.snapRef);
    this?.mapObj?.map?.removeLayer(this.layer);
    this.snapRef = null;
  };
}

export default PolylineFilling;
