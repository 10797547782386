import { css } from "@emotion/css";

export const mainContainer = css`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f5f9;
  background-image: url("login-background-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
`;
export const Flockcontainer = css`
  padding: 3rem 3rem;
  border-radius: 20px;
  background-color: #f8fafc;
  box-shadow: 0px 10px 40px rgba(24, 0, 94, 0.07);
  margin-left: 48rem;
  width: 25rem;
`;

export const sectionContainer = css`
  padding: 3rem 3rem;
  border-radius: 20px;
  background-color: #f8fafc;
  box-shadow: 0px 10px 40px rgba(24, 0, 94, 0.07);
  margin: 1rem;
  width: 100%;
`;

export const textContainer = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1rem;
  gap: 3px;
`;

export const subtitleText = css`
  margin-top: 0.5rem;
  font-weight: bold;
  color: #808080;
`;

export const titleText = css`
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 1.75rem;
`;

export const inputStyles = css`
  margin-bottom: 1rem;
`;

export const btnStyles = css`
  font-family: Poppins, sans-serif;
  width: 100%;
`;

export const flexCol = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
