import {
  Button,
  Input,
  Typography,
  Toast,
  showToast,
  CloseEyeIcon,
  VisiblityIcon,
} from "@attentive-platform/stem-ui/";
import React, { useCallback, useState } from "react";
import {
  mainContainer,
  sectionContainer,
  textContainer,
  subtitleText,
  inputStyles,
  btnStyles,
  titleText,
  flexCol,
  Flockcontainer,
} from "./styles";
import { EmailIcon } from "../Assets/email";
import { PasswordIcon } from "../Assets/password";
import { postAPI } from "utils";
import {
  LoginProps,
  LoginPayloadDataType,
  LoginAPIResponseType,
} from "./login.types";
import { isEmailValid, setLocalStorage } from "../utils";

export const Login = (props: LoginProps) => {
  const [usernameInput, setUsernameInput] = useState("");
  const [passwordInput, setpasswordInput] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsernameInput(e.target.value.toLowerCase());
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setpasswordInput(e.target.value);
  };

  const handleLoginSubmission = async (e: React.FormEvent) => {
    e.preventDefault();
    setBtnLoading(true);
    let username: string = usernameInput.trim();
    let password: string = passwordInput.trim();

    // Basic validation
    if (!username.trim() || !password.trim()) {
      showToast("Please enter username", "error");
      setBtnLoading(false);
      return;
    }
    if (!password.trim()) {
      showToast("Please enter password", "error");
      setBtnLoading(false);
      return;
    }
    if (!isEmailValid(username)) {
      showToast("Please enter correct email id", "error");
      setBtnLoading(false);
      return;
    }

    let payload: LoginPayloadDataType = {
      email: username,
      password,
    };

    try {
      let response = (await postAPI(
        props.endpoint,
        payload,
        "login"
      )) as LoginAPIResponseType;
      setLocalStorage("user", response);
      setLocalStorage("token", response.token);
      if (props.setCookies) props.setCookies("token", response.token);
      showToast("Logged in successfully", "success");
      window.location.replace(props.redirectURL);
    } catch (error: any) {
      if (props.mode === "Performance Tracking Module") {
        showToast(error?.response?.data?.msg, "error");
      } else {
        showToast(error?.response?.data.errors[0].message, "error");
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const hideIcon = useCallback(
    () => <CloseEyeIcon height={18} width={24} color="black" />,
    []
  );
  const showIcon = useCallback(() => <VisiblityIcon height={18} />, []);

  return (
    <div
      className={mainContainer}
      style={{
        backgroundImage: `url(${props.backgroundImageURL})`,
      }}
    >
      <form onSubmit={handleLoginSubmission}>
        <section
          className={props.mode === "Flock" ? Flockcontainer : sectionContainer}
        >
          <div className={flexCol}>
            <div className={textContainer}>
              <Typography variant="h5-semi-bold" className={titleText}>
                {props.mode}
              </Typography>
              <Typography variant="subtitle2-medium" className={subtitleText}>
                Welcome back! please enter your details to login
              </Typography>
            </div>
            <Input
              fullWidth
              type="text"
              placeholder="Enter username"
              value={usernameInput}
              onChange={handleUsername}
              id="product-username"
              required
              className={inputStyles}
              color={
                props.mode === "Feathers" || props.mode === "Flock"
                  ? "primary"
                  : "secondary"
              }
              startIcon={{
                icon: EmailIcon,
              }}
            />

            <Input
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              value={passwordInput}
              onChange={handlePassword}
              id="product-password"
              required
              className={inputStyles}
              color={
                props.mode === "Feathers" || props.mode === "Flock"
                  ? "primary"
                  : "secondary"
              }
              startIcon={{
                icon: PasswordIcon,
              }}
              endIcons={[
                {
                  icon: showPassword ? hideIcon : showIcon,
                  onClick: () => setShowPassword((val) => !val),
                },
              ]}
            />

            <Button
              color="primary"
              variant="contained"
              className={btnStyles}
              // onClick={handleLoginSubmission}
              loading={btnLoading}
              type="submit"
            >
              Login
            </Button>
          </div>

          <Toast
            newestOnTop={false}
            limit={1}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </section>
      </form>
    </div>
  );
};

Login.defaultProps = {
  mode: "PTM",
};
