import { captureException } from '@sentry/react';

import Feature from 'ol/Feature';
import { DragBox, Select, Translate } from 'ol/interaction';
import { fromExtent } from 'ol/geom/Polygon';
import { platformModifierKeyOnly, pointerMove } from 'ol/events/condition';

import { layerTracker } from '../MapInit';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { changeMapCursor, isNumericalLayer } from '../../../Utils/HelperFunctions';
import { Observer } from '../../../Utils/Observer';
import { BASE_LAYER_ID, MAP_LAYERS, moveNonHighLightLayers } from '../../../Constants/Constant';
import { HIGHLIGHT_STYLE_NUMERICAL, getHighlightTextStyle, highlightStyle } from '../MapBase';

class MoveFeature extends Observer {
  bpLotExtent: $TSFixMe;

  dragBox: $TSFixMe;

  dragbox: $TSFixMe;

  hover: $TSFixMe;

  invalidSpace: $TSFixMe;

  lastFeatures: $TSFixMe;

  lotFeature: $TSFixMe;

  mapObj: $TSFixMe;

  select: $TSFixMe;

  translate: $TSFixMe;

  constructor(mapObj: $TSFixMe) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.hover = null;
    this.dragbox = null;
    this.translate = null;
    this.bpLotExtent = null;
    this.lotFeature = null;
    this.invalidSpace = false;
    this.lastFeatures = [];
  }

  on() {
    this.off();

    this.mapObj.map.on('pointermove', this.changeCursor);

    if (this.mapObj.isBlueprintMap) {
      const bpSheetExtent = this.mapObj.baseLayer.get('bp_page_extent');
      this.bpLotExtent = new Feature(fromExtent(bpSheetExtent));
    }

    // for single and multi select(using shift key)
    this.select = new Select({
      // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: (featu... Remove this comment to see the full error message
      wrapX: false,
      filter: (feature, layer) => {
        // Do not select parcel layer  while editing features
        return !moveNonHighLightLayers(layer?.get('name'));
      }
    });
    this.mapObj.map.addInteraction(this.select);

    this.hover = new Select({
      condition: pointerMove,
      toggleCondition: () => false,
      filter: (feature, layer) =>
        !moveNonHighLightLayers(layer?.get('name')) && !this.select.getFeatures().getArray().includes(feature),
      style: feature => {
        let layer = this.mapObj.getLayerById(feature.get('layerId'));
        if (layer && isNumericalLayer(layer)) {
          return HIGHLIGHT_STYLE_NUMERICAL;
        }
        if (feature.get('isLabel')) {
          layer = this.mapObj.getLayerById(MAP_LAYERS.LABELS);
          const style = layer.getStyle()?.(feature);
          return getHighlightTextStyle(style);
        }
        // @ts-expect-error TS(2322): Type 'FeatureLike' is not assignable to type 'null... Remove this comment to see the full error message
        return highlightStyle({ layer, feature });
      }
    });
    this.mapObj.map.addInteraction(this.hover);

    // a DragBox interaction used to select features by drawing boxes
    this.dragBox = new DragBox({
      condition: platformModifierKeyOnly
    });

    this.mapObj.map.addInteraction(this.dragBox);

    // clear selection when drawing a new box and when clicking on the map
    this.dragBox.on('boxstart', () => {
      this.select.getFeatures().clear();
    });
    this.dragBox.on('boxend', this.handleDragboxEnd);

    this.translate = new Translate({
      features: this.select.getFeatures()
    });
    this.mapObj.map.addInteraction(this.translate);

    this.translate.on('translatestart', (e: $TSFixMe) => {
      this.lastFeatures = e.features.getArray().map((f: $TSFixMe) => f.clone());
    });

    this.translate.on('translateend', this.handleTranslateEnd);
  }

  handleDragboxEnd = () => {
    const boxExtent = this.dragBox.getGeometry().getExtent();

    const selected: $TSFixMe = [];

    this.mapObj.getLayers().forEach((layer: $TSFixMe) => {
      if (!moveNonHighLightLayers(layer?.get('name')) && layer?.get('id') !== BASE_LAYER_ID) {
        try {
          layer.getSource().forEachFeatureIntersectingExtent(boxExtent, (feature: $TSFixMe) => {
            selected.push(feature);
          });
        } catch (err) {
          captureException(err);
        }
      }
    });

    this.select.getFeatures().extend(selected);
  };

  handleTranslateEnd = (e: $TSFixMe) => {
    e.features.forEach((feature: $TSFixMe) => {
      const geom = feature.getGeometry();

      // handling out_of_extent case
      const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
        geom,
        boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
      });

      if (is_out_of_extent) {
        this.lastFeatures.forEach((lastFeature: $TSFixMe) => {
          if (lastFeature.get('layerId') === feature.get('layerId') && lastFeature.get('id') === feature.get('id')) {
            feature.setGeometry(lastFeature.getGeometry());
          }
        });
      } else {
        const layerId = feature.get('layerId');
        // Push layer in tracker
        layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
      }
    });
    if (layerTracker.getArray().length) {
      this.notifyObservers(TOOL_EVENT.MOVE_FEATURE);
    }
  };

  changeCursor = (e: $TSFixMe) => {
    this.invalidSpace = !this.mapObj.coordsExistsInParcel(
      e.coordinate,
      this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
    );
    changeMapCursor(this.invalidSpace, 'not-allowed');
    this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
      if (!moveNonHighLightLayers(_layer?.get('name'))) {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        document.getElementById('map').style.cursor = 'pointer';
        return true;
      }
      return false;
    });
  };

  off() {
    this.mapObj.map.un('pointermove', this.changeCursor);
    if (this.select) {
      this.select.getFeatures().clear();
      this.mapObj.map.removeInteraction(this.select);
    }
    if (this.hover) {
      this.mapObj.map.removeInteraction(this.hover);
    }
    if (this.dragBox) {
      this.mapObj.map.removeInteraction(this.dragBox);
      this.dragBox.un('boxend', this.handleDragboxEnd);
    }
    if (this.translate) {
      this.mapObj.map.removeInteraction(this.translate);
      this.translate.un('translateend', this.handleTranslateEnd);
    }
    this.lotFeature = null;
    this.bpLotExtent = null;
    this.lastFeatures = [];
  }
}

export default MoveFeature;
