import create from 'zustand';
import { devtools } from 'zustand/middleware';

const TOOL_DATA = {
  edgeSnapping: false,
  vertexSnapping: false,
  isOutputParcelEdit: false,
  avoidOverlap: false,
  overrideOverlap: false,
  oldUndoRedoStack: [],
  cancelParcelEditState: null
};

const reducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case 'SET_TOOL_SETTINGS':
      return { ...state, [payload.type]: payload.value };
    case 'SET_IS_PARCEL_OUTPUT_EDIT':
      return { ...state, isOutputParcelEdit: payload };
    case 'SET_OLD_UNDO_REDO_STACK':
      return {
        ...state,
        oldUndoRedoStack: payload.stack,
        isOutputParcelEdit: payload.isOutputParcelEdit
      };
    case 'SET_CANCEL_PARCEL_EDIT_STATE':
      return { ...state, cancelParcelEditState: payload };
    default:
      return state;
  }
};

const store = (set: any) => ({
  ...TOOL_DATA,
  dispatch: (args: any) => set((state: any) => reducer(state, args))
});

export const useToolData = create(devtools(store));
