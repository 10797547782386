import { DATA_PROJECTION, FEATURE_PROJECTION, GEOMETRY_TYPES, UNSET_PROPERTIES } from 'woodpecker';
import { drawStyle, labelStyle, selectStyle } from '../../../hooks/tools/helpers/styles';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import Draw from 'ol/interaction/Draw';
import Select from 'ol/interaction/Select';
import { formatArea } from '../../../hooks/tools/helpers';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import { generateUniqueID, getRectangleCoordinates } from 'macaw';
import * as turf from '@turf/turf';
import { avoidOverlap, isOutOfExtent } from '../../../helpers/helpers';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { Circle, LinearRing, MultiPolygon, Point } from 'ol/geom';
import Geometry, { Type } from 'ol/geom/Geometry';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { GeoJSON } from 'ol/format';
import { getArea } from 'ol/sphere';

class AddRectangularFill extends ToolAbstract {
  private mapObj: MapBase;
  private layer: any;
  private draw: Draw | null;
  private select: Select | null;
  private feature: Feature | null;
  private lastOKCoord: Coordinate | null;
  private lastFinalCoordinates: Coordinate | null;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.select = null;
    this.feature = null;
    this.lastOKCoord = null;
    this.lastFinalCoordinates = null;
  }

  init(id: string): void {
    this.layer = this.mapObj.getLayerById(id);

    this.select = new Select({
      layers: [this.layer],
      style: selectStyle()
    });

    this.select.setActive(false);

    const options = {
      type: GEOMETRY_TYPES.POLYGON as Type,
      dragVertexDelay: 0,
      clickTolerance: 12,
      features: this.select.getFeatures(),
      style: (feat: any) => {
        if (feat?.getGeometry().getType() === GEOMETRY_TYPES.LINESTRING) {
          return null;
        }
        return this.styleFunction(feat);
      },
      geometryFunction: this.geometryFunction,
      maxPoints: 3,
      condition: (e: any) => {
        const mouseClick = e.originalEvent.which;
        if (mouseClick === 3 || mouseClick === 2 || isOutOfExtent(e, this.mapObj.map)) {
          return false;
        }
        return true;
      }
    };

    this.draw = new Draw(options as any);
    this.mapObj.map?.addInteraction(this.select);
    this.mapObj.map?.addInteraction(this.draw);
    this.draw?.on('drawend', this.onDrawEnd);
    this.draw?.on('drawstart', this.onDrawStart);
    window.addEventListener('keydown', this.keyDownHandler);
  }

  styleFunction = (feature: Feature) => {
    let styles = [drawStyle()];
    const { scale, dpi } = globalStore.AppStore.worksheetParams;
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.POLYGON && scale !== null) {
      const label = formatArea(geometry, dpi, scale);
      const _labelStyle = labelStyle.clone();
      _labelStyle.setGeometry(geometry);
      _labelStyle.getText().setText(label);
      styles.push(_labelStyle);
    }
    return styles;
  };

  keyDownHandler = (e: KeyboardEvent) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  onDrawEnd = (e: any) => {
    const hole = e.feature;
    const holeGeom = hole.getGeometry();
    if (!holeGeom) {
      return;
    }

    if (Math.round(getArea(holeGeom)) === 0) {
      this.onAbortDrawing();
      return;
    }

    let intersectFeature = false;
    const poly = this.feature?.getGeometry() as any;
    const coords = holeGeom.getCoordinates()[0];
    for (const coord of coords) {
      if (!poly?.intersectsCoordinate(coord)) {
        intersectFeature = true;
        break;
      }
    }

    let appended = false;
    const linearRing = new LinearRing(coords);
    if (poly?.getType() == 'Polygon') {
      poly.appendLinearRing(linearRing);
      appended = true;
    } else if (poly?.getType() == 'MultiPolygon') {
      const newGeom = new MultiPolygon([]);
      for (let i = 0, pi; (pi = poly.getPolygon(i)); i++) {
        pi.appendLinearRing(new LinearRing(linearRing as any));
        newGeom.appendPolygon(pi);
      }
      this.feature?.setGeometry(newGeom);
      appended = true;
    }

    if (appended) {
      if (this.layer) {
        const feat = new Feature({
          geometry: hole.getGeometry().clone()
        });
        const unq_id = generateUniqueID('rectangle');
        feat.setId(unq_id);
        this.layer.getSource().addFeature(feat);
      }
    }
    UNSET_PROPERTIES.forEach(property => {
      this.feature?.unset(property, false);
    });

    undoRedoPush();

    this.select?.getFeatures().clear();
    this.select?.setActive(false);
    this.feature = null;
    this.lastOKCoord = null;
    this.lastFinalCoordinates = [];
  };

  onDrawStart = (e: any) => {
    this.layer.getSource().forEachFeatureIntersectingExtent(e.feature.getGeometry().getExtent(), (feat: any) => {
      this.feature = feat;
    });

    if (!this.feature) {
      e.target.abortDrawing();
      this.onAbortDrawing();
      return;
    }

    this.select?.setActive(true);
    this.draw?.setActive(true);
  };

  geometryFunction = (coordinates: any, geometry: any) => {
    /**
     * It first check if the new coordinate lies inside the select polygon
     */

    const coord = coordinates[0].pop();
    if (!this.feature || this.feature?.getGeometry()?.intersectsCoordinate(coord)) {
      this.lastOKCoord = [coord[0], coord[1]];
    }
    coordinates[0].push([this.lastOKCoord![0], this.lastOKCoord![1]]);

    if (geometry) {
      geometry.setCoordinates([coordinates[0].concat([coordinates[0][0]])]);
    } else {
      geometry = new Polygon(coordinates);
    }

    const points = coordinates[0];
    try {
      if (points && points.length > 1) {
        const first_point = turf.point(
          new Point(points[0])
            .transform(FEATURE_PROJECTION, DATA_PROJECTION)
            // @ts-ignore: Unreachable code error
            .getCoordinates()
        );
        const second_point = turf.point(
          new Point(points[1])
            .transform(FEATURE_PROJECTION, DATA_PROJECTION)
            // @ts-ignore: Unreachable code error
            .getCoordinates()
        );

        if (points.length > 2) {
          const third_point = turf.point(
            new Point(points[2])
              .transform(FEATURE_PROJECTION, DATA_PROJECTION)
              // @ts-ignore: Unreachable code error
              .getCoordinates()
          );

          let final_coordinates = getRectangleCoordinates(first_point, second_point, third_point);

          final_coordinates = final_coordinates.map(pt => {
            return (
              new Point(pt.geometry.coordinates)
                .transform('EPSG:4326', 'EPSG:3857')
                // @ts-ignore: Unreachable code error
                .getCoordinates()
            );
          });

          let allPointsIntersect = true;
          /**
           * check if all coordinates points lies inside the polygon
           */
          final_coordinates.forEach(coord => {
            if (this.feature?.getGeometry()?.intersectsCoordinate(coord) === false) allPointsIntersect = false;
          });

          const newPolygonGeometry = new Polygon([final_coordinates] as any);

          const format = new GeoJSON();

          const intersection: Feature = format.readFeature(
            turf.intersect(
              format.writeFeatureObject(this.feature as Feature) as any,
              format.writeFeatureObject(
                new Feature({
                  geometry: newPolygonGeometry
                })
              ) as any
            )
          );
          /**
           * Find the difference in area of intersection and hole Feature
           * If its zero then that means hole totally lies inside the polygon
           */
          const diff_value = Math.abs(
            Math.round(getArea(intersection.getGeometry() as Geometry) - getArea(newPolygonGeometry))
          );

          if (allPointsIntersect && diff_value === 0) {
            this.lastFinalCoordinates = final_coordinates;
          }
          geometry?.setCoordinates([this.lastFinalCoordinates]);
        }
      }
    } catch (error) {
      this.onAbortDrawing();
      return new Polygon([]);
    }
    return geometry;
  };

  onAbortDrawing = () => {
    this.lastFinalCoordinates = [];
    this.select?.getFeatures().clear();
    this.select?.setActive(false);
    this.feature = null;
    this.lastOKCoord = null;
    this.lastFinalCoordinates = [];
    this.draw?.abortDrawing();
  };

  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.mapObj.map?.removeInteraction(this.select as Select);
    this.draw && this.draw.un('drawstart', this.onDrawStart);
    this.draw && this.draw.un('drawend', this.onDrawEnd);
    window.removeEventListener('keydown', this.keyDownHandler);
  }
}

export default AddRectangularFill;
