import { create } from 'zustand';

export enum HandDrawingToolTypeEnum {
  draw = 1,
  box = 2,
  circle = 3,
  cloud = 4
}

interface StoreVariableType {
  type: HandDrawingToolTypeEnum;
  width: number;
  color: string;
  toolInstance: any;
}

interface StoreType extends StoreVariableType {
  reset: () => void;
  updateState: (updater: ((prev: StoreType) => StoreType) | Partial<StoreType>) => void;
}

const INITIAL_STATE: StoreVariableType = {
  type: HandDrawingToolTypeEnum.draw,
  width: 3,
  color: '#212121',
  toolInstance: null
};

const useHandDrawStore = create<StoreType>(set => ({
  ...INITIAL_STATE,
  updateState: updater =>
    set((state: StoreType) => ({
      ...state,
      ...(typeof updater === 'function' ? updater(state) : updater)
    })),
  reset: () => set((state: StoreType) => ({ ...state, ...INITIAL_STATE }))
}));

export default useHandDrawStore;
