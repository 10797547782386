import MapBase from '../../mapLayer/mapBase';
import { undoRedoObj } from '../../mapLayer/mapInit';

class Undo {
  private mapObj: MapBase;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
  }

  execute(event: any): void {
    setTimeout(() => {
      undoRedoObj.undo();
    }, 10);
  }
}

export default Undo;
