import Snap from 'ol/interaction/Snap';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { GEO_JSON, generateUniqueID, getTurfFeature, isValid, isValidLineString } from 'macaw';
import _ from 'lodash';
import { Translate } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature.js';
import { lineSegment } from '@turf/turf';
import { fromUserCoordinate, getUserProjection, toUserCoordinate, toUserExtent } from 'ol/proj.js';
import { closestOnCircle, closestOnSegment, squaredDistance } from 'ol/coordinate.js';
import { boundingExtent, buffer } from 'ol/extent.js';
import { LineString } from 'ol/geom.js';
import { Fill, Stroke, Style } from 'ol/style';
import { flatternArray, getIndexOfArrayIntoArrayOfArray, removeDuplicateArrays, roundOffArrayOfArray } from './utils';
import { drawStyle, labelStyle, lineStyles, segmentHighlightStyle } from '../../../../hooks/tools/helpers/styles';
import CustomDraw from './Draw';
import { GEOMETRY_TYPES } from 'woodpecker';
import { formatArea, formatLength } from '../../../../hooks/tools/helpers';
import { unByKey } from 'ol/Observable';
import { ZERO_LENGTH } from '../../../../hooks/tools/helpers/constants';

const tempSegment = [];

const POLYGON_FILLING = 'polygon_filling';
const SNAP_POLYGON = 'snap_polygon';
const SNAP_POLYLINE = 'snap_polyline';
const POLYLINE_FILLING = 'polyline_filling';

class CustomSnap extends Snap {
  constructor(options) {
    console.log('Calling This Constructor');
    super({ source: options.source, pixelTolerance: options.pixelTolerance });
    this.snapSource = options.source;
    this.mount = false;
    this.map = options.map;
    this.overlayRef = new VectorLayer({
      id: 'modify-segment-overlay',
      source: new VectorSource({ wrapX: false }),
      style: lineStyles,
      zIndex: 10000
    });
    this.type = options.type;
    this.translateRef = new Translate({
      layers: [this.overlayRef],
      hitTolerance: 10
    });
    this.translateStartRef = false;
    this.firstIndexRef = 0;
    this.lastIndexRef = 0;
    this.firstIndexArrRef = [0, 0];
    this.lastIndexArrRef = [0, 0];
    this.modifiableRef = null;
    this.coordsRef = null;
    this.translatingRef = false;
    this.ifPolyHole = false;
    this.cursorCoordinates = [0, 0];
    this.history = [];
    this.scale = options.scale;
    this.dpi = options.dpi;
    this.undoRedoPush = options.undoRedoPush;
    this.selectedLayer = options.selectedLayer;
    this.drawRef = new CustomDraw({
      source: options.selectedLayer.getSource(),
      type: this.type === SNAP_POLYGON ? 'Polygon' : 'LineString',
      style: feature => this.styleFunction(feature),
      dragVertexDelay: 0,
      snapTolerance: 1,
      condition: e => {
        // const mouseClick = e.originalEvent.which;
        // if (mouseClick == 3 || mouseClick == 2) {
        //   return false;
        // }
        // return true;
      }
    });

    this.eventListenerKey = e => {
      {
        // if (e.code == "Backspace") {
        //   this.drawRef?.removeLastPoint();
        //   this.reset();
        // } else
        if (e.code == 'Space') {
          this.drawRef?.finishDrawing();
          this.reset();
        }
      }
    };

    this.drawRef.on('drawend', event => this.onDrawEnd(event));

    this.map?.addInteraction(this.drawRef);

    if ((this.type === SNAP_POLYGON || this.type === SNAP_POLYLINE) && !!this.drawRef) {
      this.pointerEvent = this.map.on('pointermove', event => {
        const mouseCoordinates = event.coordinate;

        if (!this.selectedVertex1 || !this.selectedVertex2) {
          return;
        }

        const distance1 = squaredDistance(this.selectedVertex1, mouseCoordinates);
        const distance2 = squaredDistance(this.selectedVertex2, mouseCoordinates);
        let masterVertex = undefined;
        let childVertex = undefined;
        if (distance1 < distance2) {
          masterVertex = this.selectedVertex1;
          childVertex = this.selectedVertex2;
        } else {
          masterVertex = this.selectedVertex2;
          childVertex = this.selectedVertex1;
        }

        const masterInd = this.selectedFeatureCoordinates.findIndex(
          coord => coord[0] === masterVertex[0] && coord[1] === masterVertex[1]
        );
        const childInd = this.selectedFeatureCoordinates.findIndex(
          coord => coord[0] === childVertex[0] && coord[1] === childVertex[1]
        );

        if (masterInd === -1 || childInd === -1) {
          return;
        }
        let newCoordinates = [];
        if (masterInd === 0 && childInd === this.selectedFeatureCoordinates.length - 1) {
          newCoordinates = [this.selectedFeatureCoordinates[0], this.selectedFeatureCoordinates[1]];
        } else if (masterInd === this.selectedFeatureCoordinates.length - 1 && childInd === 0) {
          newCoordinates = [this.selectedFeatureCoordinates[masterInd], this.selectedFeatureCoordinates[masterInd - 1]];
        } else if (masterInd === 0 && childInd === 1) {
          newCoordinates = [
            this.selectedFeatureCoordinates[masterInd],
            this.selectedFeatureCoordinates[this.selectedFeatureCoordinates.length - 1]
          ];
        } else if (masterInd === childInd + 1) {
          let lastIndex = masterInd + 1 === this.selectedFeatureCoordinates.length ? 0 : masterInd + 1;
          newCoordinates = [this.selectedFeatureCoordinates[masterInd], this.selectedFeatureCoordinates[lastIndex]];
        } else {
          newCoordinates = [this.selectedFeatureCoordinates[masterInd], this.selectedFeatureCoordinates[masterInd - 1]];
        }

        const newFeature = new Feature({
          geometry: new LineString(newCoordinates)
        });

        const segments = lineSegment(getTurfFeature(newFeature));
        const highFeatures = GEO_JSON.readFeatures(segments);
        const coordinatesArray = roundOffArrayOfArray(this.drawRef.sketchLineCoords_);

        const startIndex = getIndexOfArrayIntoArrayOfArray(coordinatesArray, newCoordinates[0]);

        if (startIndex !== -1 && startIndex !== 0 && startIndex !== coordinatesArray.length - 1) {
          if (
            (coordinatesArray[startIndex + 1][0] === newCoordinates[1][0] &&
              coordinatesArray[startIndex + 1][1] === newCoordinates[1][1]) ||
            (coordinatesArray[startIndex - 1][0] === newCoordinates[1][0] &&
              coordinatesArray[startIndex - 1][1] === newCoordinates[1][1])
          ) {
            return;
          }
        }
        if (newFeature) {
          newFeature.setStyle(segmentHighlightStyle);
          this.addToOverlay(highFeatures);
        }
      });
    }
    this.map.addEventListener('click', () => {
      const _features = this.overlayRef.getSource().getFeatures();
      // this.drawRef?.abortDrawing();
      _features.forEach(feat => {
        // This will highlight the first click line
        const coordinatesOfLineString = feat?.getGeometry()?.getCoordinates();
        this.history.push(coordinatesOfLineString);
        this.drawRef?.appendCoordinates(coordinatesOfLineString);

        // Coordinates need to be trimmed because CV team sending geojson with trimmed value
        const trimmedCoordinate0 = this.fixedPrecision(coordinatesOfLineString[0]);
        const trimmedCoordinate1 = this.fixedPrecision(coordinatesOfLineString[1]);

        const featuresLo = this.getFeaturesAtVertex(trimmedCoordinate0, options.source);
        // TODO: As of now considering only 1 feature. For later we can take help of mouse moment
        // Now Store the vertexes
        this.selectedVertex1 = trimmedCoordinate0;
        this.selectedVertex2 = trimmedCoordinate1;
        this.selectedFeature = featuresLo[0];
        const coordinates = this.selectedFeature?.getGeometry().getCoordinates();

        this.selectedFeatureCoordinates = removeDuplicateArrays(coordinates[0]);
        // It should be only 1 length
        // This is For Polygon Filling
        if (this.type === POLYLINE_FILLING) {
          this.fillPolyline(featuresLo);
        }
        if (this.type === POLYGON_FILLING) {
          this.fillPolygon(featuresLo);
        }
      });
    });

    this.map.addEventListener('dblclick', () => {
      this.drawRef?.finishDrawing();
      this.reset();
    });

    window.addEventListener('keydown', this.eventListenerKey);

    // TODO: When User clicks find the current coordinate and find all lines between last selected
  }

  styleFunction(feature) {
    let styles = [];
    if (this.type === SNAP_POLYGON) {
      styles = [drawStyle()];
      const geometry = feature.getGeometry();
      const type = geometry.getType();
      if (type === GEOMETRY_TYPES.POLYGON && this.scale !== null) {
        const label = formatArea(geometry, this.dpi, this.scale);
        const _labelStyle = labelStyle.clone();
        _labelStyle.setGeometry(geometry);
        _labelStyle.getText().setText(label);
        styles.push(_labelStyle);
      }
    } else {
      styles = [...lineStyles];
      const geometry = feature.getGeometry();
      const type = geometry.getType();
      if (type === GEOMETRY_TYPES.LINESTRING && this.scale !== null) {
        geometry?.forEachSegment((a, b) => {
          const segment = new LineString([a, b]);
          const label = formatLength(segment, this.dpi, this.scale || 0);
          if (label !== ZERO_LENGTH) {
            const _labelStyle = labelStyle.clone();
            _labelStyle.setGeometry(segment);
            _labelStyle.getText().setText(label);
            styles.push(_labelStyle);
          }
        });
      }
    }
    return styles;
  }

  getUniqCoordArr(coords, isPolygon = true) {
    const map = new Map();
    const newCoords = [];
    for (let index = 0; index < coords.length - 1; index++) {
      const element = coords[index];
      const key = JSON.stringify(element);
      if (!!map.get(key) === false) {
        newCoords.push(element);
        map.set(key, true);
      }
    }
    if (isPolygon) newCoords.push(coords[0]);
    return newCoords;
  }

  onDrawEnd(event) {
    const feature = event.feature;
    if (this.type === SNAP_POLYGON) {
      const coords = this.getUniqCoordArr(feature?.getGeometry()?.getCoordinates()[0]);
      feature?.getGeometry()?.setCoordinates([coords]);
      let isIntersecting = isValid(feature);
      try {
        if (feature.getGeometry().getType() === GEOMETRY_TYPES.POLYGON && isIntersecting) {
          const unq_id = generateUniqueID('polygon');
          feature.setId(unq_id);
          setTimeout(() => {
            this.undoRedoPush();
          }, 0);
        } else {
          setTimeout(() => {
            this.selectedLayer.getSource()?.removeFeature(feature);
          }, 0);
        }
      } catch (error) {}
    } else {
      try {
        if (feature.getGeometry().getType() === GEOMETRY_TYPES.LINESTRING) {
          const coordMap = new Map();
          let index = 1;
          feature.getGeometry()?.forEachSegment((a, b) => {
            const segment = new LineString([a, b]);
            coordMap.set([a, a].toString(), 1);
            coordMap.set([b, b].toString(), 1);
            if (!(coordMap.has([a, b].toString()) || coordMap.has([b, a].toString()))) {
              const newFeature = new Feature({
                geometry: segment
              });
              if (isValidLineString(newFeature)) {
                const unq_id = generateUniqueID('snap_line') + index;
                newFeature.setId(unq_id);
                this.selectedLayer.getSource()?.addFeature(newFeature);
              }
            }
            index++;
            coordMap.set([a, b].toString(), 1);
            coordMap.set([b, a].toString(), 1);
          });
          setTimeout(() => {
            this.selectedLayer.getSource()?.removeFeature(feature);
            this.undoRedoPush();
          }, 0);
        } else {
          setTimeout(() => {
            this.selectedLayer.getSource()?.removeFeature(feature);
          }, 0);
        }
      } catch (error) {
        setTimeout(() => {
          this.selectedLayer.getSource()?.removeFeature(feature);
        }, 0);
      }
    }
  }

  reset() {
    this.selectedFeature = null;
    this.selectedVertex1 = null;
    this.selectedVertex2 = null;
    this.selectedFeatureCoordinates = null;
    this.overlayRef?.getSource()?.clear();
  }

  fillPolyline(features) {
    this.drawRef.setActive(false);
    const _source = new VectorSource({
      features: features,
      wrapX: false
    });

    let _layer = new VectorImageLayer({
      source: _source,
      style: new Style({
        stroke: new Stroke({
          color: 'pink',
          width: 2
        })
      }),
      zIndex: 100
    });
    this.map?.addLayer(_layer);
  }

  fillPolygon(features) {
    this.drawRef.setActive(false);
    const _source = new VectorSource({
      features: features,
      wrapX: false
    });

    let _layer = new VectorImageLayer({
      source: _source,
      style: new Style({
        fill: new Fill({
          color: 'pink'
        })
      }),
      zIndex: 100
    });
    this.map?.addLayer(_layer);
  }

  getFeaturesAtVertex(targetCoordinates, source) {
    const featuresMatchingVertices = [];
    // Iterate through the features in the vector source
    source.forEachFeature(feature => {
      // Get the feature's geometry (assuming it's a LineString or Polygon)
      const geometry = feature.getGeometry();

      const containsTargetCoordinates = coords => {
        if (coords[0] === targetCoordinates[0] && coords[1] === targetCoordinates[1]) {
          return true;
        }
        return false;
      };

      if (geometry) {
        const closest = geometry?.getClosestPoint(targetCoordinates);
        if (containsTargetCoordinates(closest)) {
          if (feature.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_POLYGON) {
            const multiPolygon = feature?.getGeometry();
            const polygons = multiPolygon?.getPolygons();
            for (let index = 0; index < polygons.length; index++) {
              const polygon = polygons[index];
              const newFeature = new Feature({
                geometry: polygon
              });
              featuresMatchingVertices.push(newFeature);
            }
          } else {
            featuresMatchingVertices.push(feature);
          }
        }
      }
    });

    return featuresMatchingVertices;
  }

  fixedPrecision(coords) {
    var roundOfValue = coords.map(function (coord) {
      return coord.toFixed(3); // 3 decimal places
    });
    var roundOfValueStringify = roundOfValue.map(function (coord) {
      return coord.toString();
    });

    var trimmedCoordinate = roundOfValueStringify.map(function (coordStr) {
      return parseFloat(coordStr);
    });
    return trimmedCoordinate;
  }

  addToOverlay(features) {
    const _features = this.overlayRef.getSource().getFeatures();
    let newFeatures = [...features];
    if (_features.length > 0) {
      newFeatures.push(..._features);
    }

    _features.forEach(feat => {
      this.overlayRef.getSource().removeFeature(feat);
    });

    this.overlayRef.getSource().addFeatures(newFeatures);
  }

  snapTo(pixel, pixelCoordinate, map) {
    if (this.selectedFeatureCoordinates) {
      return;
    }
    if (!this.mount) {
      map.addLayer(this.overlayRef);
      map.addInteraction(this.translateRef);
      this.mount = true;
    }
    const projection = map.getView().getProjection();
    const projectedCoordinate = fromUserCoordinate(pixelCoordinate, projection);

    const box = toUserExtent(
      buffer(boundingExtent([projectedCoordinate]), map.getView().getResolution() * this.pixelTolerance_),
      projection
    );

    const segments = this.rBush_.getInExtent(box);
    const segmentsLength = segments.length;
    if (segmentsLength === 0) {
      const _features = this.overlayRef.getSource().getFeatures();

      _features.forEach(feat => {
        this.overlayRef.getSource().removeFeature(feat);
      });
      return null;
    }

    let closestVertex;
    let minSquaredDistance = Infinity;

    const squaredPixelTolerance = this.pixelTolerance_ * this.pixelTolerance_;
    const getResult = () => {
      if (closestVertex) {
        const vertexPixel = map.getPixelFromCoordinate(closestVertex);
        const squaredPixelDistance = squaredDistance(pixel, vertexPixel);
        if (squaredPixelDistance <= squaredPixelTolerance) {
          return {
            vertex: closestVertex,
            vertexPixel: [Math.round(vertexPixel[0]), Math.round(vertexPixel[1])]
          };
        }
      }
      return null;
    };

    if (this.edge_) {
      for (let i = 0; i < segmentsLength; ++i) {
        let vertex = null;
        const segmentData = segments[i];
        if (segmentData.feature.getGeometry().getType() === 'Circle') {
          let circleGeometry = segmentData.feature.getGeometry();
          const userProjection = getUserProjection();
          if (userProjection) {
            circleGeometry = circleGeometry.clone().transform(userProjection, projection);
          }
          vertex = closestOnCircle(
            projectedCoordinate,
            /** @type {import("../geom/Circle.js").default} */ circleGeometry
          );
        } else {
          const [segmentStart, segmentEnd] = segmentData.segment;
          // points have only one coordinate
          if (segmentEnd) {
            tempSegment[0] = fromUserCoordinate(segmentStart, projection);
            tempSegment[1] = fromUserCoordinate(segmentEnd, projection);

            const feature = new Feature({
              geometry: new LineString(tempSegment)
            });

            const segments = lineSegment(getTurfFeature(feature));
            const features = GEO_JSON.readFeatures(segments);
            if (feature) {
              feature.setStyle(segmentHighlightStyle);
              this.addToOverlay(features);
            }
            vertex = closestOnSegment(projectedCoordinate, tempSegment);
          }
        }
        if (vertex) {
          const delta = squaredDistance(projectedCoordinate, vertex);
          if (delta < minSquaredDistance) {
            closestVertex = toUserCoordinate(vertex, projection);
            minSquaredDistance = delta;
          }
        }
      }

      const result = getResult();
      if (result) {
        return result;
      }
    }

    return null;
  }

  off() {
    console.log('off from custom snap');
    this.map?.removeInteraction(this.drawRef);
    this.map?.removeInteraction(this.translateRef);
    this.map?.removeLayer(this.overlayRef);
    unByKey(this.pointerEvent);
    this.drawRef = null;
    window.removeEventListener('keydown', this.eventListenerKey);
  }
}

export default CustomSnap;

// Psuedo Code
// 1. Will check pointer on the map and find the closest line segment
// 2. Will add overlay on the line segment
// 3. We will wait for user to click on map and see if anything is added in overlay if yes
// 4. We will append the coordinates to the draw interaction
// 5. Now if user take it's cursor need to check the closed segment to that pointer
// 6. Need to check if that segment comes
