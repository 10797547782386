import Map from "ol/Map.js";
import VectorSource from "ol/source/Vector";
import GEO_JSON from "../geoJson";
import {
  GEOMETRY_TYPE,
  GEOMETRY_TYPES,
  MEASUREMENT_TYPE_KEY,
} from "woodpecker";
import { Feature } from "ol";
import { isValid, isValidLineString } from "../polygonValidate";
import { generateUniqueID } from "../miscellaneous";
import { MultiLineString, MultiPoint, MultiPolygon, Point } from "ol/geom";
import { containsExtent } from "ol/extent";

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const checkTags = (feature: Feature) => {
  const tags_info = feature?.get("tags_info");
  if (typeof tags_info !== "object") {
    if (typeof tags_info === "string" && isJsonString(tags_info)) {
      feature.set("tags_info", JSON.parse(tags_info));
    } else {
      feature.set("tags_info", {});
    }
  }
};

type JsonType = {
  type: string;
  features: any[];
};

type DataType = {
  color: string;
  geometry_type: number;
  layer_type: string;
  z_index: number;
  measurement_type: number;
  id: string;
};

const isValidPoint = (feature: Feature) => {
  const geometry = feature?.getGeometry() as Point;
  const coords = geometry?.getCoordinates();
  if (coords.length === 2) {
    if (typeof coords[0] === "number" && typeof coords[1] === "number")
      return true;
    else return false;
  } else return false;
};

export const FeatureisOutOfExtent = (featureExtent: any, map: any) => {
  const width = map.get("PROJECTED_WIDTH");
  const height = map.get("PROJECTED_HEIGHT");
  const extent = [0, -1 * height, width, 0];
  const outOfExtent = !containsExtent(extent, featureExtent);
  return outOfExtent;
};

const getValidFeatures = (json: JsonType, map: Map, data: DataType) => {
  const projection = map.getView().getProjection() as any;
  const source = new VectorSource({
    features: GEO_JSON.readFeatures(json, projection),
    wrapX: false,
  });

  const { geometry_type, measurement_type } = data;
  const original_length = source?.getFeatures()?.length;

  if (geometry_type === GEOMETRY_TYPE.POLYGON) {
    const features = source.getFeatures() as Feature[];
    for (let index = 0; index < features.length; index++) {
      const feature = features[index];
      if (!feature?.getGeometry()) {
        source.removeFeature(feature);
        continue;
      }
      if (
        !(
          feature?.getGeometry()?.getType() === GEOMETRY_TYPES.POLYGON ||
          feature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_POLYGON
        )
      ) {
        source.removeFeature(feature);
      } else {
        if (
          feature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_POLYGON
        ) {
          const multiPolygon = feature?.getGeometry() as MultiPolygon;
          const polygons = multiPolygon?.getPolygons();
          for (let index = 0; index < polygons.length; index++) {
            const polygon = polygons[index];
            const newFeature = new Feature({
              geometry: polygon,
            });

            const isIntersecting = isValid(newFeature);
            const isOutOfExtent = FeatureisOutOfExtent(
              newFeature?.getGeometry()?.getExtent(),
              map
            );
            if (!isIntersecting || isOutOfExtent) {
              continue;
            }
            checkTags(newFeature);
            newFeature?.setId(generateUniqueID("polygon") + index);
            source.addFeature(newFeature);
          }
          source.removeFeature(feature);
        } else {
          const isIntersecting = isValid(feature);
          const isOutOfExtent = FeatureisOutOfExtent(
            feature?.getGeometry()?.getExtent(),
            map
          );
          if (!isIntersecting || isOutOfExtent) {
            source.removeFeature(feature);
            continue;
          }
          checkTags(feature);
          feature?.setId(generateUniqueID("polygon") + index);
        }
      }
    }
  }

  if (geometry_type === GEOMETRY_TYPE.LINE) {
    const features = source.getFeatures() as Feature[];
    for (let index = 0; index < features.length; index++) {
      const feature = features[index];
      if (!feature?.getGeometry()) {
        source.removeFeature(feature);
        continue;
      }
      if (
        !(
          feature?.getGeometry()?.getType() === GEOMETRY_TYPES.LINESTRING ||
          feature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_LINESTRING
        )
      ) {
        source.removeFeature(feature);
      } else {
        if (
          feature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_LINESTRING
        ) {
          const multiLine = feature?.getGeometry() as MultiLineString;
          const lineStrings = multiLine?.getLineStrings();
          for (let index = 0; index < lineStrings.length; index++) {
            const lineString = lineStrings[index];
            const newFeature = new Feature({
              geometry: lineString,
            });

            const isValid = isValidLineString(newFeature);
            const isOutOfExtent = FeatureisOutOfExtent(
              feature?.getGeometry()?.getExtent(),
              map
            );
            if (!isValid || isOutOfExtent) {
              continue;
            }
            checkTags(newFeature);
            newFeature?.setId(generateUniqueID("polyline") + index);
            source.addFeature(newFeature);
          }
          source.removeFeature(feature);
        } else {
          const isValid = isValidLineString(feature);
          const isOutOfExtent = FeatureisOutOfExtent(
            feature?.getGeometry()?.getExtent(),
            map
          );
          if (!isValid || isOutOfExtent) {
            source.removeFeature(feature);
            continue;
          }
          checkTags(feature);
          feature?.setId(generateUniqueID("polyline") + index);
        }
      }
    }
  }

  if (geometry_type === GEOMETRY_TYPE.ABSTRACT) {
    let id = 1;
    const features = source.getFeatures() as Feature[];
    for (let index = 0; index < features.length; index++) {
      const feature = features[index];
      if (!feature?.getGeometry()) {
        source.removeFeature(feature);
        continue;
      }
      if (feature?.getGeometry()?.getType() !== GEOMETRY_TYPES.POINT) {
        source.removeFeature(feature);
      } else {
        const properties = feature.getProperties();
        const isOutOfExtent = FeatureisOutOfExtent(
          feature?.getGeometry()?.getExtent(),
          map
        );
        if (
          !properties ||
          !properties?.hasOwnProperty(
            MEASUREMENT_TYPE_KEY[`${measurement_type}`]
          ) ||
          isOutOfExtent ||
          !isValidPoint(feature)
        ) {
          source.removeFeature(feature);
          continue;
        }
        checkTags(feature);
        feature?.setId(generateUniqueID() + index);
        feature?.setProperties({ ...properties, id: id++ });
      }
    }
  }

  if (geometry_type === GEOMETRY_TYPE.POINT) {
    const features = source.getFeatures() as Feature[];
    for (let index = 0; index < features.length; index++) {
      const feature = features[index];
      if (!feature?.getGeometry()) {
        source.removeFeature(feature);
        continue;
      }
      if (
        !(
          feature?.getGeometry()?.getType() === GEOMETRY_TYPES.POINT ||
          feature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_POINT
        )
      ) {
        source.removeFeature(feature);
      } else {
        if (feature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_POINT) {
          const multiPoints = feature?.getGeometry() as MultiPoint;
          const points = multiPoints?.getPoints();
          for (let index = 0; index < points.length; index++) {
            const point = points[index];
            const newFeature = new Feature({
              geometry: point,
            });
            const properties = newFeature.getProperties();
            const isOutOfExtent = FeatureisOutOfExtent(
              feature?.getGeometry()?.getExtent(),
              map
            );
            if (
              (properties && !properties?.hasOwnProperty("count")) ||
              isOutOfExtent ||
              !isValidPoint(feature)
            ) {
              continue;
            }
            checkTags(newFeature);
            newFeature?.setId(generateUniqueID() + index);
            source.addFeature(newFeature);
          }
          source.removeFeature(feature);
        } else {
          const properties = feature.getProperties();
          const isOutOfExtent = FeatureisOutOfExtent(
            feature?.getGeometry()?.getExtent(),
            map
          );

          if (
            (properties && !properties?.hasOwnProperty("count")) ||
            isOutOfExtent ||
            !isValidPoint(feature)
          ) {
            source.removeFeature(feature);
            continue;
          }
          checkTags(feature);
          feature?.setId(generateUniqueID() + index);
        }
      }
    }
  }

  return {
    source: source,
    invalidFeaturesCount: original_length - source?.getFeatures()?.length,
    validFeatures: source?.getFeatures()?.length,
  };
};

export default getValidFeatures;
