import { Translate } from 'ol/interaction';
import { undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { highlightFeatureIfInvalid, triggerOverrideOverlap } from '../../../helpers/helpers';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { MAP_TYPE, UNSET_PROPERTIES } from 'woodpecker';
import { GEO_JSON, distanceBetweenTwoPoints, getTurfFeature } from 'macaw';
import { lineStyles, segmentHighlightStyle } from '../../../hooks/tools/helpers/styles';
import { FeatureisOutOfExtent } from 'macaw/src/getValidFeatures';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { lineSegment, polygonToLine } from '@turf/turf';
import { unByKey } from 'ol/Observable';
import { SimpleGeometry } from 'ol/geom';

class ModifySegment extends ToolAbstract {
  private mapObj: MapBase;
  private translate: Translate | null;
  private originalFeature: any;
  private translating: boolean;
  private overlayLayerId: string;
  private translateStart: boolean | null;
  private overlay: VectorLayer<VectorSource> | null;
  private overlayFeature: Feature | null;
  private firstIndex: number;
  private lastIndex: number;
  private modifiableRef: Feature<SimpleGeometry> | null;
  private coords: any;
  private hitTolerance: number;
  private firstIndexArr: number[];
  private lastIndexArr: number[];
  private pointerMove: any;
  private layer: any = null;
  private ifPolyHole: boolean;
  private snap: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.layer = null;
    this.translate = null;
    this.originalFeature = null;
    this.translating = false;
    this.overlayLayerId = 'modify-segment-overlay';
    this.translateStart = false;
    this.overlay = null;
    this.overlayFeature = null;
    this.firstIndex = 0;
    this.lastIndex = 0;
    this.modifiableRef = null;
    this.coords = null;
    this.hitTolerance = 10;
    this.firstIndexArr = [0, 0];
    this.lastIndexArr = [0, 0];
    this.pointerMove = null;
    this.ifPolyHole = false;
  }

  init = (id: string) => {
    this.layer = this.mapObj.getLayerById(id);
    if (this.layer) {
      this.overlay = new VectorLayer({
        id: this.overlayLayerId,
        source: new VectorSource({ wrapX: false }),
        style: lineStyles,
        zIndex: this.layer.getZIndex() + 10
      } as any);

      this.translate = new Translate({
        layers: [this.overlay],
        hitTolerance: this.hitTolerance
      });

      this.mapObj?.map?.addLayer(this.overlay);
      this.mapObj?.map?.addInteraction(this.translate);

      this.pointerMove = this.mapObj?.map?.on('pointermove', this.pointerMoveHandler);
      this.translate?.on('translatestart', this.onTranslateStart);
      this.translate?.on('translating', this.onTranslating);
      this.translate?.on('translateend', this.onTranslateEnd);
    }
  };

  onTranslateStart = (e: any) => {
    this.translateStart = true;
    const features = this.overlay?.getSource()?.getFeatures() || [];
    const translable = e.features.getArray()[0];
    try {
      const polyline = GEO_JSON.readFeature(polygonToLine(getTurfFeature(this.originalFeature) as any));
      features.forEach((f: Feature) => {
        if (f !== translable) {
          this.overlay?.getSource()?.removeFeature(f);
        }
      });
      this.overlay?.getSource()?.addFeature(polyline);
      // @ts-ignore: Unreachable code error
      const coords = polyline.getGeometry()?.getCoordinates();
      let firstPointIndex = 0,
        lastPointIndex = 0;

      let firstPointIndexArr = [0, 0],
        lastPointIndexArr = [0, 0];

      // finding the closest points index
      translable
        .getGeometry()
        .getCoordinates()
        .forEach((coord: any[], index: number) => {
          let min = Infinity;
          if (this.ifPolyHole) {
            for (let i = 0; i < coords.length; i++) {
              for (let j = 0; j < coords[i].length; ++j) {
                const dist = distanceBetweenTwoPoints(coord, coords[i][j]);
                if (dist < min) {
                  min = dist;
                  if (index === 0) {
                    firstPointIndexArr = [i, j];
                  } else {
                    lastPointIndexArr = [i, j];
                  }
                }
              }
            }
          } else {
            for (let i = 0; i < coords.length; i++) {
              const dist = distanceBetweenTwoPoints(coord, coords[i]);
              if (dist < min) {
                min = dist;
                if (index === 0) {
                  firstPointIndex = i;
                } else {
                  lastPointIndex = i;
                }
              }
            }
          }
        });

      this.firstIndex = firstPointIndex;
      this.lastIndex = lastPointIndex;
      this.firstIndexArr = firstPointIndexArr;
      this.lastIndexArr = lastPointIndexArr;
      this.modifiableRef = polyline as any;
      this.coords = this.modifiableRef?.getGeometry()?.getCoordinates() || [];
    } catch (e) {}
  };

  onTranslating = (e: any) => {
    try {
      this.translating = true;
      const updatedCoords = e.features.getArray()[0].getGeometry().getCoordinates();

      if (this.ifPolyHole) {
        this.coords[this.firstIndexArr[0]][this.firstIndexArr[1]] = updatedCoords[0];
        this.coords[this.lastIndexArr[0]][this.lastIndexArr[1]] = updatedCoords[1];
        if (this.lastIndexArr[1] === 0 || this.firstIndexArr[1] === 0) {
          this.coords[this.firstIndexArr[0]].splice(-1, 1, this.coords[this.firstIndexArr[0]][0]); // make first and last same for valid polygon
        }
      } else {
        this.coords[this.firstIndex] = updatedCoords[0];
        this.coords[this.lastIndex] = updatedCoords[1];
        if (this.lastIndex === 0 || this.firstIndex === 0) {
          this.coords.splice(-1, 1, this.coords[0]); // make first and last same for valid polygon
        }
      }
      this.modifiableRef?.getGeometry()?.setCoordinates(this.coords);
    } catch (error) {}
  };

  onTranslateEnd = (e: any) => {
    // put this.translating check bcz if there is no movement then no changes happen in original feature
    if (this.modifiableRef && this.translating) {
      try {
        const lastCoordinates: any = this.originalFeature?.getGeometry()?.getCoordinates();
        let newCoords: any = this.modifiableRef?.getGeometry()?.getCoordinates();
        if (!this.ifPolyHole) newCoords = [newCoords];
        this.originalFeature?.getGeometry()?.setCoordinates(newCoords);
        const isOutExtent =
          this.mapObj?.map_type === MAP_TYPE.AERIAL
            ? false
            : FeatureisOutOfExtent(this.modifiableRef?.getGeometry()?.getExtent(), this.mapObj?.map);
        if (isOutExtent) {
          this.originalFeature?.getGeometry()?.setCoordinates(lastCoordinates);
        } else if (!highlightFeatureIfInvalid(this.originalFeature)) {
          this.originalFeature?.getGeometry()?.setCoordinates(lastCoordinates);
        } else {
          triggerOverrideOverlap(this.originalFeature);
          setTimeout(() => undoRedoPush(), 0);
        }
        UNSET_PROPERTIES.forEach(property => {
          this.originalFeature?.unset(property, false);
        });
      } catch (error) {}
    }
    this.resetAll();
  };

  getFeatureAtPixel = (px: any, lId: any) => {
    return this.mapObj?.map?.forEachFeatureAtPixel(
      px,
      (feature: any) => {
        return feature;
      },
      {
        layerFilter: (layer_candidate: any) => {
          return lId === layer_candidate.get('id');
        },
        hitTolerance: this.hitTolerance
      }
    );
  };

  addToOverlay = (features: Feature[]) => {
    this.overlay?.getSource()?.addFeatures(features);
  };

  pointerMoveHandler = (e: any) => {
    let canvas = document.getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.style.cursor = '';

      const feature = this.getFeatureAtPixel(e.pixel, this.layer.get('id'));
      if (feature && feature?.getGeometry()?.getType() !== 'Polygon') return;

      if (feature) {
        if (!this.originalFeature) {
          canvas.style.cursor = 'move';
          this.originalFeature = feature;
          const segments = lineSegment(getTurfFeature(this.originalFeature) as any);
          this.addToOverlay(GEO_JSON.readFeatures(segments));
          try {
            if (this.originalFeature && this.originalFeature?.getGeometry()?.getLinearRings()?.length > 1) {
              this.ifPolyHole = true;
            }
          } catch (error) {
            this.ifPolyHole = false;
          }
        } else {
          const overlayFeature = this.getFeatureAtPixel(e.pixel, this.overlayLayerId);
          this.overlayFeature && this.overlayFeature?.setStyle(undefined);

          if (overlayFeature && !overlayFeature.getStyle() && !this.translateStart) {
            this.overlayFeature = overlayFeature;
            overlayFeature.setStyle(segmentHighlightStyle);
          }
        }
      } else if (!this.translateStart) {
        this.resetAll();
      }
    }
  };

  resetAll = () => {
    this.originalFeature = null;
    this.translating = false;
    this.translateStart = false;
    this.overlay && this.overlay?.getSource()?.clear();
    this.ifPolyHole = false;
    this.firstIndexArr = [0, 0];
    this.lastIndexArr = [0, 0];
    this.firstIndex = 0;
    this.lastIndex = 0;
  };

  off = () => {
    this.resetAll();
    unByKey(this.pointerMove);
    this.translate && this.translate.un('translatestart', this.onTranslateStart);
    this.translate && this.translate.un('translating', this.onTranslating);
    this.translate && this.translate.un('translateend', this.onTranslateEnd);
    this.translate && this.mapObj?.map?.removeInteraction(this.translate);
    if (!!this.overlay) this.mapObj?.map?.removeLayer(this.overlay);
  };
}

export default ModifySegment;
