import { captureException } from '@sentry/react';
import GeoJSON from 'ol/format/GeoJSON';
import { ReshapePolygon } from 'flamingo';
import { Map, Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import booleanIntersects from '@turf/boolean-intersects';

import { DrawEvent } from 'ol/interaction/Draw';
import { MAP_LAYERS } from '../../../Constants/Constant';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { layerTracker } from '../MapInit';

type Layer = VectorLayer<any> | VectorImageLayer<VectorSource>;

function getIntersectingFeaturesByFeature(
  drawnFeature: Feature<any>,
  map: Map,
  options?: {
    layerFilterFn?: (layer: Layer) => boolean;
  }
): Feature<any>[] {
  const { layerFilterFn } = options || {};
  const intersectingFeatures: Feature<any>[] = [];
  const drawnGeoJSON = new GeoJSON().writeFeatureObject(drawnFeature, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857'
  });

  let layers: Layer[] = map
    .getLayers()
    .getArray()
    .filter(l => {
      // const layerClassName = l.constructor.name; // Need to do due to ol version change instanceof not working here
      const isVectorLayer = l instanceof VectorLayer || l instanceof VectorImageLayer;
      if (!layerFilterFn) return isVectorLayer;
      return isVectorLayer && layerFilterFn(l as Layer);
    }) as Layer[];

  layers.forEach(layer => {
    const source = layer.getSource();
    source.forEachFeature((feature: Feature) => {
      const featureGeoJSON = new GeoJSON().writeFeatureObject(feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      if (booleanIntersects(drawnGeoJSON, featureGeoJSON)) {
        intersectingFeatures.push(feature);
      }
    });
  });

  return intersectingFeatures;
}

class Reshape extends Observer {
  private baseReshapeTool: ReshapePolygon;

  constructor(mapObj: any) {
    super();
    this.baseReshapeTool = new ReshapePolygon(mapObj);
    this.baseReshapeTool.drawEnd = this.drawEnd.bind(this);
  }

  on(options: Record<string, unknown> = {}): void {
    this.baseReshapeTool.off(); // Cleanup before enabling reshape
    this.baseReshapeTool.bootstrap();
  }

  drawEnd = (e: DrawEvent): void => {
    try {
      setTimeout(() => {
        const features = getIntersectingFeaturesByFeature(
          e.feature as any,
          this.baseReshapeTool.getMapObj().map as any,
          {
            layerFilterFn: layer => layer.get('name') === MAP_LAYERS.OUTPUT && layer.getVisible()
          }
        );

        if (features?.length) {
          this.baseReshapeTool.reshapeFeatures(features, e);
          features.forEach(f => {
            const layerId = f.get('layerId');
            const layerName = this.baseReshapeTool.getMapObj().getLayerName(layerId);
            layerTracker.push(layerName, layerId);
          });
          this.notifyObservers(TOOL_EVENT.RESHAPE_FEATURES);
        }
        this.baseReshapeTool.removeAddedLayer();
      }, 10);
    } catch (error) {
      if (process.env.APP_ENV === 'prod') {
        captureException(error);
      } else {
        console.error('Error in drawEnd:', error);
      }
    }
  };

  off(): void {
    this.baseReshapeTool.off();
  }
}

export default Reshape;
