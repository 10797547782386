import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { getParameterFromUrl, inIframe } from '../Utils/HelperFunctions';
// Set isEmbed property to true on window object if application is embedded inside an iframe
// USLawns embed our app in iframe in their proposal system.

import Storage from '../Utils/Storage';

export const storage = new Storage();

// @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
window.isEmbed = inIframe();
let apiKey: $TSFixMe;

// @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
if (window.isEmbed) {
  // In embedded iframe authentication is done through api_key instead of token
  // api_key is parsed from the URL.
  apiKey = getParameterFromUrl('api_key');
  storage.remove('invalid_token_message_displayed');
  storage.set('api_key', apiKey); // set api key
  storage.set('userId', getParameterFromUrl('user_id')); // set user id
}

const token = storage.get('token');
const userId = storage.get('userId');

const reducer = (state: $TSFixMe, { type, payload }: $TSFixMe) => {
  switch (type) {
    case 'SET_LOGIN':
      if (payload.isLoggedIn) {
        storage.remove('accelerateToken');
        storage.set('token', payload.user.token);
        storage.set('userId', payload.user.id);
        storage.set('emailId', payload.user.email);
      } else {
        storage.remove('token');
        storage.remove('accelerateToken');
        storage.remove('userId');
        storage.remove('emailId');
        storage.remove('dash-setMyDraft');
        storage.remove('dash-setMyQueue');
        storage.remove('dash-setMyRequest');
        storage.remove('dash-setMyTeamRequest');
      }
      return {
        ...state,
        isLoggedIn: payload.isLoggedIn,
        token: payload.user && payload.user.token,
        userId: payload.user && payload.user.id,
        afterLoginRoute: payload.afterLoginRoute
      };
    case 'SET_ACCELERATE_USER_DETAILS':
      return {
        ...state,
        user: {
          id: payload.userId,
          user_id: payload.userId,
          company: payload.company
        }
      };
    case 'SET_USER':
      return {
        ...state,
        user: payload,
        userLoading: false
      };
    case 'SET_ACCELERATE_USER':
      return { ...state, isAccelerateUser: true };
    case 'SET_IS_LOADING_LOGO':
      return { ...state, loadingLoginLogo: payload };
    case 'SET_AFTER_LOGIN_ROUTE':
      return { ...state, afterLoginRoute: payload };
    default:
      return state;
  }
};

const store = (set: $TSFixMe) => ({
  user: {},
  userLoading: true,
  userId,
  isAccelerateUser: false,
  token,
  // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
  isLoggedIn: Boolean(window.isEmbed ? apiKey : token),
  afterLoginRoute: '/',
  loadingLoginLogo: true,
  dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
});

export const useUserDetails = create(devtools(store));
