// @ts-nocheck

import React from "react";
import { css, cx } from "@emotion/css";

type ContainerProps = {
  children: React.ReactNode;
  maxWidth?: boolean;
};

export const Container = ({ children, maxWidth }: ContainerProps) => {
  return (
    <div
      className={
        maxWidth ? cx(containerStyles, maxWidthStyles) : containerStyles
      }
    >
      {children}
    </div>
  );
};

const containerStyles = css`
  padding: 2rem;
  width: 100%;
`;

const maxWidthStyles = css`
  max-width: 1800px;
  margin: auto;
`;

Container.defaultProps = {
  maxWidth: false,
};
