import Split from 'ol-ext/interaction/Split';
import { undoRedoPush, undoRedoObj } from '../../mapLayer/mapInit';
import { Stroke, Style, Fill, Circle } from 'ol/style';
import MultiPoint from 'ol/geom/MultiPoint';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { getLayerType } from '../../../helpers/helpers';
import { UNSET_PROPERTIES } from 'woodpecker';
import { generateUniqueID } from 'macaw';
import { Feature } from 'ol';

class SplitLine extends ToolAbstract {
  private mapObj: MapBase;
  private split: Split | null;
  private current_style: any;
  private split_style: Style | null;
  private layer: any;
  private splitStyleFn: any;

  constructor(mapObj: MapBase) {
    super();

    this.mapObj = mapObj;
    this.split = null;
    this.current_style = null;
    this.split_style = null;
  }

  init(id: string) {
    this.off();
    // Style to visualize endpoints of line
    this.split_style = new Style({
      image: new Circle({
        radius: 4,
        stroke: new Stroke({
          color: getLayerType(id)?.color || 'blue'
        })
      }),
      geometry: function (feature) {
        //@ts-ignore
        var coordinates = feature.getGeometry()?.getCoordinates();
        let first_pt = coordinates[0];
        let last_pt = coordinates[coordinates.length - 1];
        return new MultiPoint([first_pt, last_pt]);
      }
    });

    this.layer = this.mapObj.getLayerById(id);
    if (this.layer) {
      this.current_style = this.layer.getStyle();
      if (this.current_style instanceof Function) {
        this.splitStyleFn = (feature: any, resolution: any) => {
          const current_feat_style = this.current_style(feature, resolution);
          return [current_feat_style, this.split_style];
        };
        this.layer.setStyle(this.splitStyleFn);
      } else {
        this.layer.setStyle([this.current_style, this.split_style]);
      }
      this.split = new Split({
        sources: this.layer.getSource()
      });

      // @ts-ignore: Unreachable code error
      const source = this.split?.overlayLayer_?.getSource();
      if (source?.wrapX_) source.wrapX_ = false;

      this.mapObj.map?.addInteraction(this.split);
      this.split.on('aftersplit', this.onDrawEnd);
      window.addEventListener('keydown', this.handleKeyDawn);
    }
  }

  handleKeyDawn = (e: any) => {
    if (e.key == 'Backspace') {
      undoRedoObj.undo();
    }
  };

  onDrawEnd = (e: any) => {
    const features = e.features;
    features.forEach((feat: Feature, index: number) => {
      const unique_id = generateUniqueID() + `${index + Math.round(Math.random() * 100)}`;
      feat.setId(unique_id);
      UNSET_PROPERTIES.forEach(property => {
        feat.unset(property, false);
      });
    });
    setTimeout(() => {
      undoRedoPush();
    }, 0);
  };

  off() {
    this.split && this.mapObj.map?.removeInteraction(this.split);
    this.split && this.split.un('aftersplit', this.onDrawEnd);
    window.removeEventListener('keydown', this.handleKeyDawn);
    this.current_style && this.layer.setStyle(this.current_style);
    this.current_style = null;
  }
}

export default SplitLine;
