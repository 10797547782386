/**
 * Instance of this class can be used to set, get or remove values from local storage if available
 * else it uses temporary in memory storage.
 */
class Storage {
  store = {};

  set = (key: $TSFixMe, value: $TSFixMe) => {
    if (canAccessLocalStorage()) return localStorage.setItem(key, JSON.stringify(value));

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    this.store[key] = value;
    return true;
  };

  get = (key: $TSFixMe) => {
    if (canAccessLocalStorage()) {
      try {
        // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        return JSON.parse(localStorage.getItem(key));
      } catch (err) {
        return localStorage.getItem(key);
      }
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    } else return this.store[key];
  };

  remove = (key: $TSFixMe) => {
    if (canAccessLocalStorage()) return localStorage.removeItem(key);
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return delete this.store[key];
  };
}

export default Storage;

// It checks if local storage is available
const canAccessLocalStorage = function canAccessLocalStorage() {
  const access = 'access';
  try {
    localStorage.setItem(access, access);
    localStorage.removeItem(access);
    return true;
  } catch (e) {
    return false;
  }
};
