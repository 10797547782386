import { showToast } from 'ui';
import { GEO_JSON } from 'macaw';
import { distance } from 'ol/coordinate';
import { Fill, Stroke, Style } from 'ol/style';
import { LineString, MultiPoint, Polygon } from 'ol/geom';
import { EMPTY_GEOJSON, GEOMETRY_TYPES } from 'woodpecker';
import Feature from 'ol/Feature';
import CircleStyle from 'ol/style/Circle';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';

interface SelectedVertexProps {
  coordinate: number[];
  index: number;
}

class CopySegment extends ToolAbstract {
  private mapObj: MapBase;
  private selectedFeature: Feature | null;
  private selectedVertices: SelectedVertexProps[];
  private selectedLayerID: string;
  private layer: any;
  private addNewLayer: ((id: string, geojson: any) => void) | null;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.selectedFeature = null;
    this.selectedVertices = [];
    this.selectedLayerID = '';
    this.layer = null;
    this.addNewLayer = null;
  }

  init(
    id: string,
    featureTracing: boolean = false,
    mode: string = '',
    addTempLayer: ((id: string, geojson: any) => void) | null
  ) {
    this.layer = this.mapObj.getLayerById(id);
    this.addNewLayer = addTempLayer;
    this.selectedLayerID = id;

    this.mapObj.map?.on('singleclick', this.handleClick);
    // @ts-expect-error
    this.mapObj.map?.on('contextmenu', this.handleVertexClick);
  }

  handleVertexClick = (evt: any) => {
    if (!this.selectedFeature) {
      showToast('No feature selected!');
      return;
    }

    const mapCoordinate = this.mapObj.map?.getCoordinateFromPixel(evt.pixel);
    if (!mapCoordinate) return;

    const polygonCoords = this.selectedFeature
      ?.getGeometry()
      // @ts-expect-error
      ?.getCoordinates()[0];

    let nearestVertex: any = null;
    let minDistance = Infinity;

    for (let i = 0; i < polygonCoords.length; i++) {
      const vertex = polygonCoords[i];
      const distanceToVertex = distance(mapCoordinate, vertex);
      if (distanceToVertex < minDistance) {
        minDistance = distanceToVertex;
        nearestVertex = vertex;
      }
    }

    if (nearestVertex) {
      const vertexIndex = polygonCoords.findIndex(
        (coord: any) => coord[0] == nearestVertex[0] && coord[1] == nearestVertex[1]
      );
      this.selectedVertices.push({
        coordinate: nearestVertex,
        index: vertexIndex
      });

      if (this.selectedVertices.length === 2) {
        this.createLineSegment();
        this.selectedVertices = [];
      }
    }
  };

  getFeatureAtPixel = (px: any) => {
    return this.mapObj.map?.forEachFeatureAtPixel(
      px,
      (feature: any, layer: any) => {
        if (layer == this.layer) return feature;
      },
      {
        hitTolerance: 10
      }
    );
  };

  setFeatureStyle = () => {
    const polygonStyle = new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 2
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)'
      })
    });

    const vertexStyle = new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'red'
        })
      }),
      geometry: function (feature) {
        // @ts-expect-error
        const coordinates = feature?.getGeometry()?.getCoordinates()[0];
        return new MultiPoint(coordinates);
      }
    });

    const combinedStyle = [polygonStyle, vertexStyle];
    if (this.selectedFeature) this.selectedFeature.setStyle(combinedStyle);
  };

  setOriginalStyle = (feature: any) => {
    this.layer.getStyle();
    feature.setStyle(this.layer.getStyle());
  };

  handleClick = (evt: any) => {
    const feature = this.getFeatureAtPixel(evt.pixel);
    if (feature && feature?.getGeometry()?.getType() !== GEOMETRY_TYPES.POLYGON) {
      return;
    }

    if (!this.selectedFeature) {
      this.selectedFeature = feature;
      this.setFeatureStyle();
      return;
    } else if (this.selectedFeature !== feature) {
      this.setOriginalStyle(this.selectedFeature);
      this.selectedFeature = feature;
      this.setFeatureStyle();
      this.selectedVertices = [];
    }
  };

  createLineSegment = () => {
    if (!this.selectedFeature || this.selectedVertices.length !== 2) return;

    const geometry = this.selectedFeature?.getGeometry();
    if (!(geometry instanceof Polygon)) return;

    const coordinates = geometry?.getCoordinates()[0];

    const startIndex = this.selectedVertices[0].index;
    const endIndex = this.selectedVertices[1].index;

    if (startIndex === endIndex) {
      this.selectedVertices = [];
      showToast('Please select two different vertices!');
      return;
    }

    let segment1, segment2;

    if (startIndex <= endIndex) {
      segment1 = coordinates.slice(startIndex, endIndex + 1);
      segment2 = [...coordinates.slice(endIndex), ...coordinates.slice(0, startIndex + 1)];
    } else {
      segment1 = coordinates.slice(endIndex, startIndex + 1);
      segment2 = [...coordinates.slice(startIndex), ...coordinates.slice(0, endIndex + 1)];
    }

    // We are choosing the shorter segment
    const lineCoordinates = segment1.length <= segment2.length ? segment1 : segment2;

    const lineString = new LineString(lineCoordinates);
    const midLineFeature = new Feature({
      geometry: lineString
    });

    const layerName = `temp_layer_${this.selectedLayerID}`;
    let midlineLayer = this.mapObj.getLayerById(layerName);
    if (!midlineLayer) {
      this.mapObj.addVectorLayer(
        // @ts-expect-error
        EMPTY_GEOJSON,
        layerName,
        30
      );
      midlineLayer = this.mapObj.getLayerById(layerName);
    }
    midLineFeature.set('temp_layer', true);
    midLineFeature.set('vector_layer_id', layerName);
    midlineLayer.getSource().addFeature(midLineFeature);

    const featureCollection = GEO_JSON.writeFeaturesObject(midlineLayer.getSource().getFeatures());
    if (this.addNewLayer) this.addNewLayer(layerName, featureCollection);

    this.setOriginalStyle(this.selectedFeature);
    this.selectedFeature = null;
  };

  off() {
    this.mapObj.map?.un('singleclick', this.handleClick);
    // @ts-expect-error
    this.mapObj.map?.un('contextmenu', this.handleVertexClick);
    if (this.selectedFeature) {
      this.setOriginalStyle(this.selectedFeature);
      this.selectedFeature = null;
    }
    this.selectedVertices = [];
  }
}

export default CopySegment;
