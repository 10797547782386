export function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export function removeDuplicateArrays(arrayOfArrays) {
  const uniqueArrays = [];

  for (const arr of arrayOfArrays) {
    // Check if the current array is not already in uniqueArrays
    if (!uniqueArrays.some(uniqueArr => areArraysEqual(arr, uniqueArr))) {
      uniqueArrays.push(arr);
    }
  }

  return uniqueArrays;
}

function rotateArrayClockwise(arr, positions) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return arr;
  }

  const n = arr.length;
  const shiftAmount = positions % n;

  if (shiftAmount === 0) {
    return arr; // No need to rotate if shiftAmount is 0
  }

  // Slice the array and concatenate the two parts in reverse order
  return arr.slice(-shiftAmount).concat(arr.slice(0, n - shiftAmount));
}

function rotateArrayCounterclockwise(arr, positions) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return arr;
  }

  const n = arr.length;
  const shiftAmount = positions % n;

  if (shiftAmount === 0) {
    return arr; // No need to rotate if shiftAmount is 0
  }

  // Slice the array and concatenate the two parts in reverse order
  return arr.slice(shiftAmount).concat(arr.slice(0, shiftAmount));
}

export function flatternArray(arr, masterVertex, childVertex) {
  let masterIndex = -1;
  let childIndex = -1;

  arr.forEach((subArr, _index) => {
    if (masterVertex[0] === subArr[0] && masterVertex[1] === subArr[1]) {
      masterIndex = _index;
    }
    if (childVertex[0] === subArr[0] && childVertex[1] === subArr[1]) {
      childIndex = _index;
    }
  });

  if (masterIndex < childIndex) {
    // This is Anticlockwise
    return rotateArrayCounterclockwise(arr, masterIndex);
  }
  if (masterIndex > childIndex) {
    // This is clockwise
    return rotateArrayClockwise(arr, masterIndex);
  }
}

export const roundOffArrayOfArray = coordinates => {
  if (!Array.isArray(coordinates)) {
    return coordinates;
  }
  return coordinates.map(coordinate => {
    return coordinate.map(value => {
      return Math.round(value * 1000000) / 1000000;
    });
  });
};

export const getIndexOfArrayIntoArrayOfArray = (arrayOfArray, array) => {
  if (!arrayOfArray || !array) {
    return -1;
  }
  return arrayOfArray.findIndex(item => {
    return item[0] === array[0] && item[1] === array[1];
  });
};
