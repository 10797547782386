import { create } from 'zustand';

type GeoJsonType = {
  type: 'FeatureCollection';
  features: [
    {
      type: 'Feature';
      geometry: {
        type: 'Point';
        coordinates: [number, number];
      };
      properties: any;
      id: string;
    }
  ];
};

export type CommentType = {
  id: string;
  user_email: string;
  feature_id: string | null;
  sheet_id: string | null;
  content: string;
  parent_comment_id: string | null;
  replies: CommentType[];
  status: number;
  created_at: string | Date;
  comment_geojson: GeoJsonType;
  modified_at: string | Date;
  triggeredFromRightPanel?: boolean;
};

interface StoreVariableType {
  commentList: CommentType[];
  selectedComment: CommentType | null;
  commentGeojson: GeoJsonType | null;
  selectedCommentToolID: string;
}

interface StoreType extends StoreVariableType {
  updateState: (callback: (arg: StoreType) => any) => void;
  setSelectedComment: (tool_id: string) => void;
  reset: () => void;
}

const INITIAL_STATE: StoreVariableType = {
  selectedComment: null,
  commentList: [],
  commentGeojson: null,
  selectedCommentToolID: ''
};

const useCommentStore = create<StoreType>(set => ({
  ...INITIAL_STATE,
  updateState: callback => set((state: StoreType) => ({ ...state, ...callback(state) })),
  setSelectedComment: (tool_id: string) =>
    set((state: StoreType) => {
      if (state.selectedCommentToolID === tool_id) return state;

      const commentList = state.commentList;
      const selectedComment =
        commentList.find(comment => {
          const geoJson = comment?.comment_geojson;
          const id = geoJson?.features[0]?.id;
          return id === tool_id;
        }) || null;

      return {
        ...state,
        selectedComment: selectedComment,
        selectedCommentToolID: tool_id
      };
    }),
  updateSelectedComment: () =>
    set((state: StoreType) => {
      const commentList = state.commentList;
      const tool_id = state.selectedCommentToolID;
      const selectedComment =
        commentList.find(comment => {
          const geoJson = comment?.comment_geojson;
          const id = geoJson?.features[0]?.id;
          return id === tool_id;
        }) || null;

      return {
        ...state,
        selectedComment: selectedComment
      };
    }),
  reset: () => set((state: StoreType) => ({ ...state, ...INITIAL_STATE }))
}));

export default useCommentStore;
