import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { Snap } from 'ol/interaction';

class AddSnapping extends ToolAbstract {
  private mapObj: MapBase;
  private snap: Array<any>;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.snap = [];
  }

  init(id: string, edge_snapping: boolean = false, snap_tolerance: number = 10): void {
    this.off();

    this.snap = this.mapObj.getSnap(snap_tolerance, edge_snapping);
    this.snap.forEach(snap => {
      this.mapObj.map!.addInteraction(snap);
    });
  }

  off(): void {
    const interactions = this.mapObj.map!.getInteractions();
    const snapsToRemove = interactions.getArray().filter(interaction => interaction instanceof Snap);

    snapsToRemove.forEach(snap => {
      interactions.remove(snap);
    });
  }
}

export default AddSnapping;
