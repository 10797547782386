import { isBeamUser } from '../Utils/usersTypes';

const beamUser = isBeamUser();
export const COMPANY_NAME = beamUser ? 'Beam AI' : 'Attentive';

// default title
export const DEFAULT_TITLE = `${COMPANY_NAME} | Property Measurements Simplified`;
// /dashboard?tab=team
export const DASHBOARD_TEAM_TITLE = `Team Measurements - ${COMPANY_NAME}`;
// dashboard?tab=qu
export const DASHBOARD_QUEUE_TITLE = `Request Queue - ${COMPANY_NAME}`;
// dashboard?tab=shared
export const DASHBOARD_SHARED_TITLE = `Shared Measurements - ${COMPANY_NAME}`;
// dashboard?tab=draft
export const DASHBOARD_DRAFT_TITLE = `Drafts - ${COMPANY_NAME}`;
// dashboard?tab=mr
export const DASHBOARD_MEASUREMENT_TITLE = `Measurements Dashboard - ${COMPANY_NAME}`;
// manage/takeoff
export const MANAGE_TAKEOFF_TITLE = `Takeoff Settings - ${COMPANY_NAME}`;
// manage/tags
export const MANAGE_TAGS_TITLE = `Tags - ${COMPANY_NAME}`;
// referrals
export const REFERRAL_TITLE_TITLE = `Referrals and credits - ${COMPANY_NAME}`;
// plan
export const PLANS_TITLE = (beamUser ? 'Pricing - ' : 'Plan and Payments - ') + COMPANY_NAME;
// manage/tickets
export const MANAGE_TICKETS_TITLE = `Tickets - ${COMPANY_NAME}`;
// manage/settings
export const MANAGE_SETTINGS_TITLE = `Profile Settings - ${COMPANY_NAME}`;
// manage/summary
export const MANAGE_SUMMARY_TITLE = `Usage Summary - ${COMPANY_NAME}`;
//manage/team
export const MANAGE_TEAM_TITLE = `Manage Team - ${COMPANY_NAME}`;
