import { Draw, Interaction } from 'ol/interaction';
import { undoRedoObj, undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { isOutOfExtent, triggerOverrideOverlap } from '../../../helpers/helpers';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { GEOMETRY_TYPES } from 'woodpecker';
import { Type } from 'ol/geom/Geometry';
import { generateUniqueID, isValid } from 'macaw';
import { drawStyle, labelStyle } from '../../../hooks/tools/helpers/styles';
import { formatArea } from '../../../hooks/tools/helpers';
import { Coordinate } from 'ol/coordinate';
import { Polygon } from 'ol/geom';
import * as turf from '@turf/turf';
import { showToast } from 'ui';

class AddCurvedPolygon extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null = null;
  private snap: Interaction[] | null = null;
  private prevState: any;
  private drawDone: any;
  private listener: any = null;
  private layer: any = null;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.snap = null;
    this.listener = null;
    this.layer = null;
  }

  /**
   * Initializes the tool for drawing polygons on the map.
   * Turns off any existing tool and sets up the draw interaction for the specified layer ID.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init(id: string): void {
    this.off();

    const layer = this.mapObj.getLayerById(id);
    this.layer = layer;
    if (layer) {
      const source = layer.getSource();
      this.draw = new Draw({
        source: source,
        type: GEOMETRY_TYPES.POLYGON as Type,
        style: feature => this.styleFunction(feature),
        dragVertexDelay: 0,
        snapTolerance: 1,
        condition: e => {
          const mouseClick = e.originalEvent.which;

          if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        },
        geometryFunction: this.geometryFunction
      });
      this.mapObj.map?.addInteraction(this.draw);
      this.draw?.on('drawend', this.onDrawEnd);
      this.draw?.on('drawstart', this.onDrawStart);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  }

  onDrawStart = () => {
    this.drawDone = false;
    this.prevState = null;
  };

  styleFunction = (feature: any) => {
    const { dpi, scale } = globalStore.AppStore.worksheetParams;

    if (feature?.getGeometry().getType() === GEOMETRY_TYPES.LINESTRING) {
      return [];
    }
    let styles = [drawStyle()];
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.POLYGON && scale !== null) {
      const label = formatArea(geometry, dpi, scale);
      const _labelStyle = labelStyle.clone();
      _labelStyle.setGeometry(geometry);
      _labelStyle.getText().setText(label);
      styles.push(_labelStyle);
    }
    return styles;
  };

  resetGeometry = () => {
    undoRedoObj.undo();
    this.prevState
      ?.getGeometry()
      ?.getCoordinates()
      .forEach((coord: any) => {
        this.draw?.appendCoordinates(coord);
      });
    this.draw?.removeLastPoint();
  };

  /**
   * Handles key down events, specifically removing the last point from the draw interaction if the Backspace key is pressed,
   * and finishing the drawing if the Space key is pressed.
   * @param {KeyboardEvent} e - The keyboard event object.
   */
  keyDownHandler = (e: KeyboardEvent): void => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  /**
   * Event handler for the drawend event.
   * @param e - The drawend event object.
   */
  onDrawEnd = (event: any) => {
    const feature = event.feature;
    try {
      if (feature.getGeometry().getType() === 'Polygon') {
        const isIntersecting = isValid(feature);
        if (!isIntersecting) {
          showToast('Invalid feature: Intersecting polygon not allowed', 'error', {
            position: 'top-center',
            hideProgressBar: false
          });
          setTimeout(() => {
            this.layer.getSource().removeFeature(feature);
          }, 0);
        } else {
          const unq_id = generateUniqueID('polygon');
          feature.setId(unq_id);
          setTimeout(() => {
            triggerOverrideOverlap(feature);
            this.prevState = feature.clone();
            undoRedoPush();
            this.drawDone = true;
          }, 0);
        }
      }
    } catch (error) {}
  };

  getUniqCoordArr = (coords: Coordinate[]) => {
    const map = new Map<string, boolean>();
    const newCoords: Coordinate[] = [];
    for (let index = 0; index < coords.length - 1; index++) {
      const element = coords[index];
      const key = JSON.stringify(element);
      if (!!map.get(key) === false) {
        newCoords.push(element);
        map.set(key, true);
      }
    }
    newCoords.push(coords[0]);
    return newCoords;
  };

  geometryFunction = (coordinates: any, geometry: any) => {
    if (!geometry) {
      geometry = new Polygon(coordinates);
    } else {
      geometry.setCoordinates(coordinates);
    }

    const geoCoords = [...coordinates];

    if (geoCoords[0].length > 3) {
      const turfPolygon = turf.polygon([[...geoCoords[0], geoCoords[0][0]]]);
      const curvedPolygon = turf.polygonSmooth(turfPolygon, { iterations: 3 });
      const curveCoordinates = curvedPolygon?.features[0]?.geometry?.coordinates;
      geometry.setCoordinates([[...this.getUniqCoordArr(curveCoordinates[0])]]);
    }

    return geometry;
  };

  /**
   * Turns off the draw interaction by removing it from the map, removing the snap interaction, and removing event listeners.
   */
  off(): void {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.draw?.un('drawend', this.onDrawEnd);
    window.removeEventListener('keydown', this.keyDownHandler);
    this.drawDone = false;
    this.prevState = null;
  }
}

export default AddCurvedPolygon;
