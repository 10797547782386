import React from "react";
import {
  rhumbBearing,
  rhumbDestination,
  distance,
  nearestPointOnLine,
  lineString,
} from "@turf/turf";

const getRectangleCoordinates = (
  first_point: any,
  second_point: any,
  third_point: any
) => {
  const bearing = rhumbBearing(second_point, first_point);
  const bearing2 = rhumbBearing(third_point, second_point);
  let angle_bw = bearing2 - bearing;
  if (angle_bw < 0) {
    angle_bw += 360;
  }
  const dir = angle_bw > 180 ? 1 : -1;
  const perpendicular_bearing = bearing + dir * 90;
  const dist = distance(second_point, third_point);
  const perpendicular_line_point = rhumbDestination(
    second_point,
    dist,
    perpendicular_bearing
  );
  const perpendicular_line_coords = [
    second_point.geometry.coordinates,
    perpendicular_line_point.geometry.coordinates,
  ];
  const perpendicular_line = lineString(perpendicular_line_coords);
  const nearest_point_perpendicular = nearestPointOnLine(
    perpendicular_line,
    third_point
  );

  const side_1_length = distance(second_point, nearest_point_perpendicular);
  const perpendicular_point_2 = rhumbDestination(
    first_point,
    side_1_length,
    perpendicular_bearing
  );

  let final_coordinates = [
    first_point,
    second_point,
    nearest_point_perpendicular,
    perpendicular_point_2,
    first_point,
  ];

  return final_coordinates;
};

export default getRectangleCoordinates;
