import { toLonLat } from 'ol/proj';
import MapBase from '../../mapLayer/mapBase';
import { showToast } from 'ui';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { CORRDINATES_LAYER } from 'woodpecker';
import { Coordinate } from 'ol/coordinate';

class CopyCoordinatesTool {
  private mapObj: MapBase;
  private layer: any;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
    this.layer = null;
  }

  init(id: string) {
    this.off();
    showToast('Click anywhere on map to copy coordinates');
    this.layer = new VectorImageLayer({
      source: new VectorSource({
        wrapX: false
      }),
      //@ts-ignore
      id: CORRDINATES_LAYER, // need to be unique,
      zIndex: 10000
    });
    this.mapObj.map?.addLayer(this.layer);
    this.mapObj.map?.on('click', this.addLeftClick);
    this.mapObj.map?.getViewport().addEventListener('contextmenu', this.onRightClick);
    this.mapObj.map?.on('pointermove', this.setPointer);
  }

  setPointer = () => {
    this.mapObj.map!.getViewport().style.cursor = 'crosshair';
  };

  removePointer = () => {
    this.mapObj.map!.getViewport().style.cursor = '';
  };

  addLeftClick = (e: any) => {
    const coords = toLonLat(e.coordinate);
    const coordString = coords[1] + ',' + coords[0];
    try {
      let isCopied = this.copyToClipboard(coordString);
      if (isCopied) {
        showToast('Coordinates: ' + coordString + ' ' + 'Successfully copied!!');
      } else {
        showToast('Unable to copy on clipboard', 'error');
      }
    } catch (err) {
      showToast('Copy not supported on your browser');
    }

    // this.off();
  };

  onRightClick = (e: MouseEvent) => {
    const googleMapUrl = 'https://maps.google.com?q=';
    const coords = toLonLat(this.mapObj.map?.getEventCoordinate(e) as Coordinate);
    const coordString = coords[1] + ',' + coords[0];
    window.open(googleMapUrl + coordString, '_blank');
  };

  copyToClipboard(text: string) {
    var dummy = document.createElement('input');
    dummy.value = text;
    document.body.appendChild(dummy);
    dummy.select();
    let success = document.execCommand('copy');
    document.body.removeChild(dummy);
    return success;
  }

  off() {
    this.mapObj.map!.getViewport().style.cursor = '';
    this.mapObj.map?.un('click', this.addLeftClick);
    this.mapObj.map?.un('pointermove', this.setPointer);
    this.mapObj.map?.getViewport().removeEventListener('contextmenu', this.onRightClick);
    this.mapObj.map?.removeLayer(this.layer);
    this.removePointer();
  }
}

export default CopyCoordinatesTool;
