import useHandDrawStore from '../../../store/handDrawStore';

class HandDrawStoreListener {
  public AppStore: any = null;
  private static instance: HandDrawStoreListener;

  private constructor() {
    useHandDrawStore.subscribe(state => this.handleStoreChange(state));
    this.AppStore = useHandDrawStore?.getState();
  }

  handleStoreChange(state: any) {
    this.AppStore = state;
  }

  static getInstance() {
    if (!HandDrawStoreListener.instance) {
      HandDrawStoreListener.instance = new HandDrawStoreListener();
    }
    return HandDrawStoreListener.instance;
  }
}

export const handDrawStore = HandDrawStoreListener.getInstance();

export default HandDrawStoreListener;
