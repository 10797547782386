// @ts-expect-error TS(7016): Could not find a declaration file for module 'ol-r... Remove this comment to see the full error message
import RotateFeatureInteraction from 'ol-rotate-feature';
import Feature from 'ol/Feature';
import { Style } from 'ol/style';
import { Select } from 'ol/interaction';
import { pointerMove } from 'ol/events/condition';
import { ARROW_TOOL, DIMENSION_TOOL_LAYER, GEOMETRY_TYPES, HIGHLIGHT_TOOL_LAYER } from 'woodpecker';
import { Extent } from 'ol/extent';
// eslint-disable-next-line import/no-cycle
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { FeatureisOutOfExtent } from '../../../helpers/helpers';
import { ROTATE_STYLE } from '../../../hooks/tools/helpers/styles';
import { highlightStyle } from '../../../hooks/tools/helpers';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { globalStore } from '../../utilityclasses/AppStoreListener';

class DragRotate extends ToolAbstract {
  hover: Select | null;

  lastFeature: Feature | null;

  mapObj: MapBase;

  rotate: any;

  select: Select | null;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.hover = null;
    this.lastFeature = null;
  }

  init() {
    this.off();

    this.select = new Select({
      toggleCondition: () => false,
      // @ts-expect-error TS(2345): Argument of type '{ toggleCondition: () => false; ... Remove this comment to see the full error message
      wrapX: false,
      filter: (feature, layer) =>
        ![DIMENSION_TOOL_LAYER, HIGHLIGHT_TOOL_LAYER, ARROW_TOOL].includes(layer?.get('name')) &&
        !(feature.getGeometry()?.getType() === GEOMETRY_TYPES.POINT),
      style: ROTATE_STYLE()
    });

    this.mapObj.map?.addInteraction(this.select);

    this.hover = new Select({
      condition: pointerMove,
      toggleCondition: () => false,
      filter: (feature, layer) =>
        ![DIMENSION_TOOL_LAYER, HIGHLIGHT_TOOL_LAYER, ARROW_TOOL].includes(layer?.get('name')) &&
        !this.select?.getFeatures().getArray().includes(feature) &&
        !(feature.getGeometry()?.getType() === GEOMETRY_TYPES.POINT),
      style: () => {
        return highlightStyle();
      }
    });
    this.mapObj.map?.addInteraction(this.hover);

    this.rotate = new RotateFeatureInteraction({
      features: this.select.getFeatures(),
      style: [new Style({})]
    });
    this.rotate.on('rotatestart', this.rotateStart);
    this.rotate.on('rotateend', this.rotateEnd);
    this.mapObj.map?.addInteraction(this.rotate);
  }

  rotateStart = () => {
    this.hover?.setActive(false);
    this.lastFeature = this.select?.getFeatures().getArray()[0].clone() || null;
  };

  rotateEnd = () => {
    this.hover?.setActive(true);
    if (!this.select?.getFeatures().getArray().length) return;

    const feature = this.select?.getFeatures().getArray()[0];
    const geom = feature.getGeometry();
    const isOutOfExtent = FeatureisOutOfExtent(geom?.getExtent() as Extent, this.mapObj.map);

    if (isOutOfExtent) {
      if (!this.lastFeature) return;

      if (
        this.lastFeature.get('vector_layer_id') === feature.get('vector_layer_id') &&
        this.lastFeature.get('id') === feature.get('id')
      ) {
        feature.setGeometry(this.lastFeature.getGeometry());
      }
    } else {
      setTimeout(() => {
        undoRedoPush();
      }, 0);
    }
  };

  off() {
    if (this.select) {
      // Push the selected features in the global state
      if (this.select?.getFeatures().getLength()) {
        globalStore.AppStore.setSelectedFeatures([...this.select.getFeatures().getArray()] ?? []);
      }
      setTimeout(() => {
        this.select?.getFeatures().clear();
      }, 100);
      this.mapObj.map?.removeInteraction(this.select);
    }
    if (this.hover) this.mapObj.map?.removeInteraction(this.hover);
    if (this.rotate) {
      this.mapObj.map?.removeInteraction(this.rotate);
      this.rotate.un('rotatestart', this.rotateStart);
      this.rotate.un('rotateend', this.rotateEnd);
    }
    this.lastFeature = null;
  }
}

export default DragRotate;
