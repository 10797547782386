import { Draw } from 'ol/interaction';
import MapBase from '../../mapLayer/mapBase';
import { isOutOfExtent } from '../../../helpers/helpers';
import { GEOMETRY_TYPES, lengthScript } from 'woodpecker';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { LINE_COLOR, drawStyle, lineMeasureStyles } from '../../../hooks/tools/helpers/styles';
import { Coordinate } from 'ol/coordinate';
import { MultiPoint } from 'ol/geom';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GEO_JSON } from 'macaw';
import asyncRun2 from '../../../hooks/tools/workers/py-worker2';
import { Icon, Style } from 'ol/style';
import { LineSvg } from '../../../hooks/tools/helpers/constants';

class LineScale extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null;
  private overlayLayerId: string;
  private overlay: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.overlayLayerId = 'Line-scale';
    this.overlay = null;
  }
  /**
   * Initializes the tool for drawing lines on the map.
   * Turns off any existing tool and sets up the draw interaction for the specified layer ID.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init(id: string) {
    this.off();

    this.overlay = new VectorLayer({
      id: this.overlayLayerId,
      source: new VectorSource({ wrapX: false }),
      style: drawStyle(),
      zIndex: 1000
    } as any);

    const source = this.overlay.getSource();

    this.draw = new Draw({
      source: source,
      type: 'LineString',
      style: (feature: any) => {
        return this.styleFunction(feature);
      },
      dragVertexDelay: 0,
      snapTolerance: 1,
      condition: e => {
        const mouseClick = e.originalEvent.which;
        if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj?.map)) {
          return false;
        }
        return true;
      }
    });
    this.mapObj?.map?.addLayer(this.overlay);
    this.mapObj?.map?.addInteraction(this.draw);
    this.draw?.on('drawend', this.onDrawEnd);
    window.addEventListener('keydown', this.keyDownHandler);
    if (this.mapObj?.map?.getViewport()?.style?.cursor) this.mapObj.map.getViewport().style.cursor = 'crosshair';
  }

  styleFunction = (feature: any) => {
    let styles = [...lineMeasureStyles];
    const geometry = feature.getGeometry() as any;
    const type = geometry.getType();
    if (type === GEOMETRY_TYPES.LINESTRING) {
      let count = 0;
      const totalSegment = geometry?.getCoordinates().length - 1;
      geometry?.forEachSegment((start: Coordinate, end: Coordinate) => {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx);
        let point: Coordinate[] = [];
        if (count === 0) point = [start];
        if (count === totalSegment - 1) point.push(end);
        styles.push(
          new Style({
            geometry: new MultiPoint(point),
            image: new Icon({
              src: `data:image/svg+xml;utf8,${encodeURIComponent(LineSvg(LINE_COLOR))}`,
              anchor: [0.5, 0.5],
              rotateWithView: true,
              rotation: -rotation
            })
          })
        );
        count++;
      });
    }
    return styles;
  };

  keyDownHandler = (e: any) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  onDrawEnd = (e: any) => {
    const lineFeature = e.feature;
    const geojson = JSON.parse(GEO_JSON.writeFeatures([lineFeature] || [], globalStore?.AppStore?.bptProjection));

    asyncRun2(lengthScript, {
      layers: geojson,
      scale: 1
    })
      .then((data: { geojson: { len: number } }) => {
        globalStore?.AppStore?.updateState({
          addScaleLength: data?.geojson.len
        });
        setTimeout(() => globalStore?.AppStore?.setShowModalLineScale(true), 1);
      })
      .catch(() => {});
  };

  off = () => {
    if (this.draw) this.mapObj?.map?.removeInteraction(this.draw);
    this.draw?.un('drawend', this.onDrawEnd);
    this.mapObj?.map?.removeLayer(this.overlay);
    this.overlay = null;
    window.removeEventListener('keydown', this.keyDownHandler);
    if (this.mapObj.map) this.mapObj.map.getViewport().style.cursor = 'pointer';
    globalStore?.AppStore?.updateState({ addScaleLength: 1 });
  };
}

export default LineScale;
