import VectorImageLayer from 'ol/layer/VectorImage';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import VectorSource from 'ol/source/Vector';
import { GEOMETRY_TYPES, LAYER } from 'woodpecker';
import { lineStyles } from '../../../hooks/tools/helpers/styles';
import { Pixel } from 'ol/pixel';

class ShowVertex extends ToolAbstract {
  private mapObj: MapBase;
  private featureMap: any;
  private layer: any;
  private highlightedFeature: any;
  private toleranceDistance = 1;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.highlightedFeature = null;
    this.featureMap = new Map();
  }

  init(id: string): void {
    this.layer = new VectorImageLayer({
      source: new VectorSource({
        wrapX: false
      }),
      //@ts-ignore
      id: 'show_vertex_layer',
      zIndex: 200,
      style: lineStyles
    });
    this.mapObj.map?.on('pointermove', this.handlePointerMove);
    this.mapObj.map?.addLayer(this.layer);
    this.mapObj.map?.getAllLayers().forEach(layer => {
      if (layer instanceof VectorImageLayer && layer.get('id') !== 'parcel' && layer.get('id') !== 'overlays_layer') {
        const source = layer.getSource();
        const features = source.getFeatures();
        features.forEach((feature: any) => {
          this.featureMap.set(feature, layer.get('id')); // add feature and its layer ID to the map
        });

        // Listen to the addfeature event on the vector source
        source.on('addfeature', (e: any) => {
          const drawnFeature = e.feature;
          this.featureMap.set(drawnFeature, layer.get('id')); // add new feature to the map
        });

        // Listen to the removefeature event on the vector source
        source.on('removefeature', (e: any) => {
          const removedFeature = e.feature;
          this.featureMap.delete(removedFeature);
        });
      }
    });
  }

  getDistance = (point1: any, point2: any) => {
    const dx = point1[0] - point2[0];
    const dy = point1[1] - point2[1];
    return Math.sqrt(dx * dx + dy * dy);
  };

  highlightFeature = (feature: any) => {
    if (this.highlightedFeature !== feature) {
      if (this.highlightedFeature) {
        this.layer.getSource().removeFeature(this.highlightedFeature);
      }
      if (feature) {
        this.layer.getSource().addFeature(feature);
      }
      this.highlightedFeature = feature;
    }
  };
  handlePointerMove = (e: any) => {
    const coordinate = e.coordinate;

    const pixel = this.mapObj.map?.getEventPixel(e.originalEvent);

    const features = this.mapObj.map?.getFeaturesAtPixel(pixel as Pixel);

    if (!features?.length) {
      if (this.highlightedFeature) {
        this.layer.getSource().removeFeature(this.highlightedFeature);
        this.highlightedFeature = null;
      }
      return;
    }
    features?.forEach((feature: any) => {
      if (feature.getGeometry() !== GEOMETRY_TYPES.POINT && feature?.get('vector_layer_id') != LAYER.PARCEL) {
        this.highlightFeature(feature);
      }
    });
  };

  off(): void {
    this.mapObj.map?.removeLayer(this.layer);
    this.mapObj.map?.un('pointermove', this.handlePointerMove);
  }
}

export default ShowVertex;
