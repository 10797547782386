import { GEOMETRY_TYPE, GEOMETRY_TYPES, HIGHLIGHT_LAYER_COLOR, HIGHLIGHT_TOOL_LAYER, TOOL_SCOPE } from 'woodpecker';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { Draw } from 'ol/interaction';
import { drawStyle } from '../../../hooks/tools/helpers/styles';
import { isOutOfExtent } from '../../../helpers/helpers';
import { FeatureisOutOfExtent } from 'macaw/src/getValidFeatures';
import { generateUniqueID } from 'macaw';
import { Feature } from 'ol';
import { boundingExtent, getBottomLeft, getBottomRight, getTopLeft, getTopRight } from 'ol/extent';
import { fromUserCoordinate, getUserProjection } from 'ol/proj';
import { Polygon } from 'ol/geom';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import useHighlightColorStore from '../../../store/highlightColorStore';

class HighlightTool extends ToolAbstract {
  mapObj: MapBase;
  draw: Draw | null;
  layer: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.draw = null;
    this.layer = null;
  }

  init(id: string): void {
    this.layer = this.mapObj.getLayerById(HIGHLIGHT_TOOL_LAYER);
    if (this.layer) {
      const source = this.layer?.getSource();
      this.draw = new Draw({
        source: source,
        type: GEOMETRY_TYPES.CIRCLE as any,
        style: (feat: any, res) => {
          if (feat?.getGeometry().getType() == 'LineString') {
            return null;
          }
          return drawStyle() as any;
        },
        dragVertexDelay: 0,
        snapTolerance: 1,
        maxPoints: 3,
        condition: e => {
          const mouseClick = e.originalEvent.which;
          if (mouseClick === 3 || mouseClick === 2 || isOutOfExtent(e, this.mapObj.map)) {
            return false;
          }
          return true;
        },
        geometryFunction: this.createBoxv2()
      });

      this.mapObj.map?.addInteraction(this.draw);

      this.draw.on('drawend', this.pushTimeout);
      window.addEventListener('keydown', this.keyDownHandler);
    }
  }

  /**
   * Event handler for the Backspace key press event.
   * @param event - The keydown event object.
   */
  keyDownHandler = (event: any) => {
    if (event.code === 'Backspace') {
      this.draw?.removeLastPoint();
    }
  };

  pushTimeout = (e: any) => {
    const feature = e.feature as Feature;
    const unq_id = generateUniqueID('highlight');
    const { color } = useHighlightColorStore.getState();
    feature.setId(unq_id);
    feature.setProperties({
      highlighted: true,
      vector_layer_id: HIGHLIGHT_TOOL_LAYER,
      excluded: true,
      color: color,
      external: !!globalStore?.AppStore?.tool?.external,
      tool_source: !!globalStore?.AppStore?.tool?.external ? TOOL_SCOPE.EXTERNAL : TOOL_SCOPE.INTERNAL
    });

    const isOutExtent = FeatureisOutOfExtent(feature?.getGeometry()?.getExtent(), this.mapObj.map);
    if (isOutExtent) {
      this.layer?.getSource()?.removeFeature(feature);
      return;
    }
  };

  createBoxv2 = () => {
    return function (coordinates: any, geometry: any, projection: any) {
      const extent = boundingExtent(
        /** @type {LineCoordType} */ [coordinates[0], coordinates[coordinates.length - 1]].map(function (coordinate) {
          return fromUserCoordinate(coordinate, projection);
        })
      );
      const boxCoordinates = [
        [getBottomLeft(extent), getBottomRight(extent), getTopRight(extent), getTopLeft(extent), getBottomLeft(extent)]
      ];
      if (geometry) {
        geometry.setCoordinates(boxCoordinates);
      } else {
        geometry = new Polygon(boxCoordinates);
      }
      const userProjection = getUserProjection();
      if (userProjection) {
        geometry.transform(projection, userProjection);
      }
      return geometry;
    };
  };

  off(): void {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.draw && this.draw.un('drawend', this.pushTimeout);
    window.removeEventListener('keydown', this.keyDownHandler);
  }
}

export default HighlightTool;
