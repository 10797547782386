//@ts-nocheck
import VectorSource from 'ol/source/Vector';
import {
  GEOM_SIMPLIFICATION_TOLERANCE,
  MULTI_GEOMETRIES,
  SLIVER_POLYGON_AREA_LIMIT,
  SLIVER_POLYGON_AREA_LIMIT_AERIAL
} from 'woodpecker';
import { GEOMETRY_TYPES } from 'woodpecker';
import { highlightFeature, makeOlFeature } from '../../../helpers/helpers';
import { Geometry, Polygon } from 'ol/geom';
import { Feature } from 'ol';
import { GEO_JSON, getEditableLayers, getTurfFeature } from 'macaw';
import { cleanCoords, kinks, union, unkinkPolygon } from '@turf/turf';

import { point } from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import { transform } from 'ol/proj';
import { showToast } from 'ui';
import { mapObj } from '../mapInit';

export interface CustomVectorSource extends VectorSource<Geometry> {
  convertFeatureGeometry: (feature: Feature) => void;
  convertMultiGeometryFeatures: (features: Feature[]) => void;
}

/**
 * Converts multi-geometry features in the VectorSource to single geometry features.
 * If no features are provided, converts all multi-geometry features in the VectorSource.
 * @param {Array<Feature>} [features=[]] - The array of features to convert.
 */
//@ts-ignore
VectorSource.prototype.convertMultiGeometryFeatures = function (features = []) {
  const _features = features.length ? features : this.getFeatures();
  const multiGeomteryFeatures = _features.filter(f => MULTI_GEOMETRIES.includes(f.getGeometry().getType()));
  multiGeomteryFeatures.forEach(mgf => {
    //@ts-ignore
    this.convertFeatureGeometry(mgf);
  });
};

/**
 * Converts a multi-geometry feature to multiple single-geometry features in the VectorSource.
 * @param {Feature} feature - The multi-geometry feature to convert.
 */
//@ts-ignore
VectorSource.prototype.convertFeatureGeometry = function (feature: Feature) {
  let geoms = [];
  let _features: Array<any> = [];
  const geometry = feature.getGeometry()?.getType();
  const props = feature.getProperties();
  delete props['geometry'];
  if (geometry === GEOMETRY_TYPES.MULTI_POINT) {
    geoms = feature.getGeometry()?.getPoints();
  } else if (geometry === GEOMETRY_TYPES.MULTI_LINESTRING) {
    geoms = feature.getGeometry()?.getLineStrings();
  } else if (geometry === GEOMETRY_TYPES.MULTI_POLYGON) {
    geoms = feature.getGeometry()?.getPolygons();
  }

  geoms.forEach((geom: Geometry) => {
    const _feature = makeOlFeature(geom, props);
    _features.push(_feature);
  });

  if (_features.length) {
    this.addFeatures(_features);
    this.removeFeature(feature);
  }
};

Feature.prototype.getLayer = function () {
  let layer;
  const layers = getEditableLayers(mapObj.map);

  for (const l of layers) {
    const features = l
      .getSource()
      .getFeatures()
      .filter(f => f === this);
    if (features.length) {
      layer = l;
      break;
    }
  }
  return layer;
};

Feature.prototype.isValid = function () {
  try {
    const hasRedundantCoords = this.hasRedundantCoords();
    const isSelfIntersect = this.isSelfIntersect();
    const isSliverPolygon = this.isSliverPolygon();

    return !(hasRedundantCoords || isSelfIntersect || isSliverPolygon);
  } catch (e) {
    // setExtra('featuretovalidate', JSON.stringify(GEO_JSON.writeFeatureObject(this)));
    // captureException(e);
  }
};

Feature.prototype.makeValid = function () {
  try {
    if (this.hasRedundantCoords() && this.isSliverPolygon() && this.isSelfIntersect()) {
      this.simplify();
      this.removeSliverPolygon();
    } else if (this.hasRedundantCoords() && this.isSliverPolygon()) {
      this.removeSliverPolygon();
    } else if (this.hasRedundantCoords() && this.isSelfIntersect()) {
      this.simplify();
    } else if (this.hasRedundantCoords()) {
      this.simplify();
    } else if (this.isSelfIntersect()) {
      this.simplify();
      this.removeSelfIntersect();
    } else if (this.isSliverPolygon()) {
      this.removeSliverPolygon();
    }
  } catch (e) {
    // setExtra('invalid_feature', JSON.stringify(GEO_JSON.writeFeatureObject(this)));
    // this.getLayer() && this.getLayer().getSource().removeFeature(this);
    showToast('System was not able to fix highlighted features, please resolve them manually', 'error');
    highlightFeature(this);
    // captureException(e);
  }
};

Feature.prototype.hasRedundantCoords = function () {
  const poly = getTurfFeature(this);
  const initialCoords = poly.geometry.coordinates[0];
  const cleanPoly = cleanCoords(poly);
  const finalCoords = cleanPoly.geometry.coordinates[0];
  return initialCoords.length !== finalCoords.length;
};

Feature.prototype.simplify = function () {
  const geom = this.getGeometry();
  const simplifiedGeom = geom.simplify(GEOM_SIMPLIFICATION_TOLERANCE);
  this.setGeometry(simplifiedGeom);
};

Feature.prototype.isSelfIntersect = function () {
  const poly = getTurfFeature(this);
  const invalid = kinks(poly);
  return Boolean(invalid.features.length);
};

Feature.prototype.removeSelfIntersect = function () {
  const coords = this.getGeometry().getCoordinates()[0];
  const [, ...linearRings] = this.getGeometry().getLinearRings();
  const polyg = new Polygon([coords]);
  this.setGeometry(polyg);
  const poly = getTurfFeature(this);

  const unkink = unkinkPolygon(poly);
  if (unkink.features.length) {
    const layer = this.getLayer();
    if (layer) {
      const features = GEO_JSON.readFeatures(unkink);
      layer.getSource().addFeatures(features);
      layer.getSource().removeFeature(this);
      if (features.length <= 1) {
        layer.getSource().removeFeatures(features);
      }
      features.map(feature => {
        if (feature.getGeometry().getArea() < SLIVER_POLYGON_AREA_LIMIT) {
          feature.getLayer().getSource().removeFeature(feature);
        }
        linearRings.map(linearRing => {
          const singleFeature = getTurfFeature(feature);
          const pointOfLinearRing = point(transform(linearRing.getFirstCoordinate(), 'EPSG:3857', 'EPSG:4326'));
          const checkForOverlap = booleanIntersects(pointOfLinearRing, singleFeature);
          checkForOverlap && feature.getGeometry().appendLinearRing(linearRing);
        });
      });
    }
  }
};

Feature.prototype.isSliverPolygon = function () {
  return Boolean(this.getGeometry().getArea() < SLIVER_POLYGON_AREA_LIMIT_AERIAL);
};

Feature.prototype.removeSliverPolygon = function () {
  const featureExtent = this.getGeometry().getExtent();
  const featuresInExtent = this.getLayer().getSource().getFeaturesInExtent(featureExtent);
  const featuresArr = featuresInExtent.filter(feat => feat != this);
  if (this.getGeometry().getArea() > 0 && featuresArr.length > 0) {
    const largestFeature = featuresArr.reduce(function (currentLargest, feature) {
      return currentLargest.getGeometry().getArea() > feature.getGeometry().getArea() ? currentLargest : feature;
    });
    const geojson = GEO_JSON.writeFeaturesObject([largestFeature, this]);
    const merged = turfMerge(geojson);
    this.setGeometry(GEO_JSON.readFeature(merged).getGeometry().simplify(0.00001));
    this.getLayer().getSource().removeFeature(largestFeature);
  } else {
    this.getLayer().getSource().removeFeature(this);
  }
};

Feature.prototype.removeRedundantCoords = function () {
  const poly = getTurfFeature(this);
  const cleanPoly = cleanCoords(poly);
  const _union = GEO_JSON.readFeature(union(poly, cleanPoly)); // we take union bcz cleanCoords totally remove redundant coords this may change shape of actual geometry
  const layer = this.getLayer();
  if (layer) {
    /**
     * Instead of setGeometry we add _union as a feature bcz union may return multipolygon
     * To handle this we have 2 options either put a check and convert here or
     * just remove this (feature) and add _union as feature in this way we already have conversion onaddfeature
     * */

    layer.getSource().removeFeature(this);
    layer.getSource().addFeature(_union);
  }
};
