import MapBase from '../../mapLayer/mapBase';
import { globalStore } from '../../utilityclasses/AppStoreListener';

// Function to make angle range from -2*PI to 2*PI
export function normalizeRotation(rotation: number): number {
  const TWO_PI = 2 * Math.PI;
  while (rotation > TWO_PI) {
    rotation -= TWO_PI;
  }
  while (rotation < -TWO_PI) {
    rotation += TWO_PI;
  }
  return rotation;
}

export class AntiClockwiseRotation {
  private mapObj: MapBase;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
  }

  /**
   * Rotate the map 90 degrees clockwise.
   */
  execute() {
    const { setIsRotationAngleChanged, isRotationAngleChanged } = globalStore.AppStore;
    const view = this.mapObj.map?.getView();
    if (view && !isRotationAngleChanged) {
      const currentRotation = view?.getRotation() || 0; // Get the current rotation (in radians)
      const newRotation = normalizeRotation(currentRotation - Math.PI / 2); // Subtract 90 degrees (anticlockwise)
      setIsRotationAngleChanged(true);
      view.setRotation(newRotation); // Apply the new rotation
    }
  }
}

export class ClockwiseRotation {
  private mapObj: MapBase;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
  }

  /**
   * Rotate the map 90 degrees anticlockwise.
   */
  execute() {
    const view = this.mapObj.map?.getView();
    const { setIsRotationAngleChanged, isRotationAngleChanged } = globalStore.AppStore;
    if (view && !isRotationAngleChanged) {
      const currentRotation = view?.getRotation() || 0; // Get the current rotation (in radians)
      const newRotation = normalizeRotation(currentRotation + Math.PI / 2); // Add 90 degrees (clockwise)
      setIsRotationAngleChanged(true);
      view.setRotation(newRotation); // Apply the new rotation
    }
  }
}
