import useSnapStore from '../../../store/snapStore';

class SnapStoreListener {
  public SnapStore: any = null;
  private static instance: SnapStoreListener;

  private constructor() {
    useSnapStore.subscribe(state => this.handleStoreChange(state));
  }

  handleStoreChange(state: any) {
    this.SnapStore = state;
  }

  static getInstance() {
    if (!SnapStoreListener.instance) {
      SnapStoreListener.instance = new SnapStoreListener();
    }
    return SnapStoreListener.instance;
  }
}

export const globalSnapStore = SnapStoreListener.getInstance();

export default SnapStoreListener;
