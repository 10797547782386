import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Extent, boundingExtent, buffer } from 'ol/extent';
import { GEOMETRY_TYPES } from 'woodpecker';

export const getExtentOfFeatures = (features: Feature[], buffer_: number = 15) => {
  let coordinateArray: Coordinate[] = [];
  features.forEach((feature: Feature) => {
    if (feature?.getGeometry()?.getType() === GEOMETRY_TYPES.POLYGON) {
      coordinateArray = [
        ...coordinateArray,
        // @ts-ignore id is private
        ...feature?.getGeometry()?.getCoordinates()[0]
      ];
    } else if (feature?.getGeometry()?.getType() === GEOMETRY_TYPES.LINESTRING) {
      coordinateArray = [
        ...coordinateArray,
        // @ts-ignore id is private
        ...feature?.getGeometry()?.getCoordinates()
      ];
    } else
      coordinateArray = [
        ...coordinateArray,
        // @ts-ignore id is private
        ...[feature?.getGeometry()?.getCoordinates()]
      ];
  });
  let extent = buffer(boundingExtent(coordinateArray), buffer_);
  let p0: Coordinate = [extent[0], extent[1]];
  let p1: Coordinate = [extent[0], extent[3]];
  let p2: Coordinate = [extent[2], extent[3]];
  let p3: Coordinate = [extent[2], extent[1]];
  let coordinates = [p0, p1, p2, p3, p0];
  return coordinates;
};

export const getExtentMidCoordinate = (extent: Extent, buffer: number = 0) => {
  const coord1 = [(extent[0] + extent[2]) / 2, extent[3] - buffer];
  const coord2 = [(extent[0] + extent[2]) / 2, extent[1] + buffer];
  return [coord1, coord2];
};
