import area from '@turf/area';
import kinks from '@turf/kinks';
import lineSlice from '@turf/line-slice';
import turfDistance from '@turf/distance';
import lineSegment from '@turf/line-segment';
import lineIntersect from '@turf/line-intersect';
import booleanContains from '@turf/boolean-contains';
import pointToLineDistance from '@turf/point-to-line-distance';
import { getGeom, getCoords } from '@turf/invariant';
import { point as turfPoint, polygon as turfPolygon } from '@turf/helpers';

import Draw from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { DATA_PROJECTION, FEATURE_PROJECTION, GEOMETRY_TYPES, MAP_TYPE, UNSET_PROPERTIES } from 'woodpecker';
import { GEO_JSON, generateUniqueID } from 'macaw';
import { isOutOfExtent, triggerOverrideOverlap } from '../../../helpers/helpers';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { drawStyle } from '../../../hooks/tools/helpers/styles';
import MapBase from '../../mapLayer/mapBase';

const DISTANCE_FACTOR = 1e-7;

// Variable to choose to enable debug layers. Make sure it if false before commiting
const _DEBUG = false;
class ReshapePolygon {
  private mapObj: MapBase;

  private draw: Draw | null;

  private lineLayer: any;

  private intersection_src: any;

  private layer: any;

  private intersection_src1: any;

  private intersection_src2: any;

  constructor(mapObj: MapBase) {
    this.mapObj = mapObj;
    this.draw = null;
    this.lineLayer = null;
    this.layer = null;
  }

  getMapObj() {
    return this.mapObj;
  }

  init(id: string) {
    this.off();

    this.layer = this.mapObj.getLayerById(id);
    this.bootstrap();
  }

  bootstrap() {
    const sourceDrawnLines = new VectorSource({ wrapX: false });
    this.lineLayer = new VectorLayer({
      source: sourceDrawnLines
    });
    this.mapObj.map?.addLayer(this.lineLayer);

    this.draw = new Draw({
      source: sourceDrawnLines,
      type: GEOMETRY_TYPES.LINESTRING as any,
      style: drawStyle(),
      dragVertexDelay: 0,
      snapTolerance: 1,
      condition: e => {
        const mouseClick = e.originalEvent.which;
        if (mouseClick == 3 || mouseClick == 2 || isOutOfExtent(e, this.mapObj.map)) {
          return false;
        }
        return true;
      }
    });
    this.mapObj.map?.addInteraction(this.draw);
    this.draw.on('drawend', this.drawEnd);

    window.addEventListener('keydown', this.handleKeyDown);
  }

  getZoom() {
    return this.mapObj.map?.getView().getZoom();
  }

  getDistanceFactor() {
    if (this.mapObj.map_type === MAP_TYPE.BLUEPRINT) {
      let distanceFactor = DISTANCE_FACTOR;
      distanceFactor = 1e-14;
      return distanceFactor;
    }
    // @ts-expect-error
    const zoom = parseInt(this.getZoom());
    let distanceFactor = DISTANCE_FACTOR;
    if (zoom > 16) {
      distanceFactor = 1e-7;
    } else if (zoom < 10) {
      distanceFactor = 1;
    } else {
      distanceFactor = 10 ** (-zoom % 10);
    }

    return distanceFactor;
  }

  handleKeyDown = (e: any) => {
    if (e.code == 'Backspace') {
      this.draw?.removeLastPoint();
    } else if (e.code == 'Space') {
      this.draw?.finishDrawing();
    }
  };

  /**
   * Check whether the two points are closer by the DISTANCE_FACTOR
   * Use this to determine if the points are the same or not
   * @param {} p1
   * @param {*} p2
   */
  checkClosePoints(p1: [number, number], p2: [number, number]) {
    const distance = turfDistance(turfPoint(p1), turfPoint(p2));
    const distanceFactor = this.getDistanceFactor();
    return distance < distanceFactor;
  }

  /**
   * Use in debug mode to draw the required feature on the map
   * idx allows you to choose between two layers, which have different style colors
   * @param {GeoJSONObject} p
   * @param {int} idx
   */
  debugAddF = (p: any, idx = 1) => {
    if (_DEBUG) {
      if (idx == 1) {
        const _p = GEO_JSON.readFeature(p, false);
        _p.getGeometry()!.transform(DATA_PROJECTION, FEATURE_PROJECTION);
        this.intersection_src1.addFeature(_p);
      } else {
        const _p = GEO_JSON.readFeature(p, false);
        _p.getGeometry()!.transform(DATA_PROJECTION, FEATURE_PROJECTION);
        this.intersection_src2.addFeature(_p);
      }
    }
  };

  /**
   * Use in debug mode to init debug layers
   */
  debugSetup = () => {
    if (_DEBUG) {
      if (this.intersection_src == undefined) {
        this.intersection_src1 = new VectorSource();
        const intersection_lyr1 = new VectorLayer({
          //@ts-ignore
          id: 'int_lyr1',
          source: this.intersection_src1,
          style: new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({
                color: 'rgba(255, 255, 0, 0.6)'
              }),
              stroke: new Stroke({
                color: 'rgb(255, 255, 0)',
                width: 1.5
              })
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 0, 0.6)'
            }),
            stroke: new Stroke({
              color: 'rgb(255, 255, 0)',
              width: 1.5
            })
          })
        });
        this.mapObj.map?.addLayer(intersection_lyr1);
        this.intersection_src2 = new VectorSource();
        const intersection_lyr2 = new VectorLayer({
          //@ts-ignore
          id: 'int_lyr2',
          source: this.intersection_src2,
          style: new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({
                color: 'rgba(0, 255, 0, 0.6)'
              }),
              stroke: new Stroke({
                color: 'rgb(0, 255, 0)',
                width: 1.5
              })
            }),
            fill: new Fill({
              color: 'rgba(0, 255, 0, 0.6)'
            }),
            stroke: new Stroke({
              color: 'rgb(0, 255, 0)',
              width: 1.5
            })
          })
        });
        this.mapObj.map?.addLayer(intersection_lyr2);
      }
    }
  };

  drawEnd = (e: any) => {
    try {
      setTimeout(() => {
        this.debugSetup();
        const layerPoly = this.layer;
        if (layerPoly) {
          this.reshapeLayers([layerPoly], e);
          undoRedoPush();
        }
      }, 10);

      this.removeAddedLayer();
    } catch (error) {}
  };

  removeAddedLayer() {
    this.mapObj.map?.removeLayer(this.lineLayer);
  }

  reshapeLayers(layers: any, drawEvent: any) {
    layers.forEach((layer: any) => {
      const sourcePoly = layer.getSource();
      const featuresPoly = sourcePoly.getFeatures();

      const drawnGeoJSON: any = GEO_JSON.writeFeatureObject(drawEvent.feature);
      const drawnGeometry = getGeom(drawnGeoJSON);
      if (drawnGeometry.type == GEOMETRY_TYPES.LINESTRING) {
        featuresPoly.forEach((featurePoly: any, index: number) => {
          const featureGeo = GEO_JSON.writeFeatureObject(featurePoly);
          const props = featurePoly.getProperties();
          delete props.geometry;
          try {
            const reshapedPoly = this.reshape(featureGeo, drawnGeoJSON);
            if (reshapedPoly) {
              const reshapedFeature = GEO_JSON.readFeature(reshapedPoly);
              reshapedFeature.setProperties({ ...props });
              UNSET_PROPERTIES.forEach(property => {
                reshapedFeature.unset(property, false);
              });
              reshapedFeature.setId(`${generateUniqueID()}${index}`);
              sourcePoly.addFeature(reshapedFeature);
              sourcePoly.removeFeature(featurePoly);

              triggerOverrideOverlap(reshapedFeature);
            }
          } catch (err) {}
        });
      }
    });
  }

  reshapeFeatures(features: any, drawEvent: any, shouldAvoidOverlap = true) {
    const drawnGeoJSON = GEO_JSON.writeFeatureObject(drawEvent.feature);
    features.forEach((featurePoly: any, index: number) => {
      const layer = featurePoly.get('layerId');
      const sourcePoly = this.mapObj.getLayerById(layer).getSource();
      const featureGeo = GEO_JSON.writeFeatureObject(featurePoly);
      const props = featurePoly.getProperties();
      delete props.geometry;
      try {
        const reshapedPoly = this.reshape(featureGeo, drawnGeoJSON);
        if (reshapedPoly) {
          const reshapedFeature = GEO_JSON.readFeature(reshapedPoly);
          reshapedFeature.setProperties({ ...props });
          reshapedFeature.setId(`${generateUniqueID()}${index}`);
          sourcePoly.addFeature(reshapedFeature);
          sourcePoly.removeFeature(featurePoly);

          if (shouldAvoidOverlap) {
            triggerOverrideOverlap(reshapedFeature);
          }
        }
      } catch (err) {}
    });
  }

  /**
   * Determine whether both intersection points sets are same
   * @param {Array[2]} firstIntersectLinePoints
   * @param {Array[2]} lastIntersectLinePoints
   */
  checkIntersectionPtsMatch = (firstIntersectLinePoints: any, lastIntersectLinePoints: any) => {
    return (
      (this.checkClosePoints(firstIntersectLinePoints[0], lastIntersectLinePoints[0]) ||
        this.checkClosePoints(firstIntersectLinePoints[0], lastIntersectLinePoints[1])) &&
      (this.checkClosePoints(firstIntersectLinePoints[1], lastIntersectLinePoints[0]) ||
        this.checkClosePoints(firstIntersectLinePoints[1], lastIntersectLinePoints[1]))
    );
  };

  /**
   * Generates the new polygon based on a start intersect point and the intersecting line
   * @param {Array[]} polygonPoints
   * @param {Array[2]} firstIntersectLinePoints
   * @param {int} startPtIdx
   * @param {Array[2]} lastIntersectLinePoints
   * @param {LineString} lineWithIntersect
   */
  generatedReshapedPoly(
    polygonPoints: Array<any>,
    firstIntersectLinePoints: Array<any>,
    startPtIdx: number,
    lastIntersectLinePoints: Array<any>,
    lineWithIntersect: any
  ) {
    /**
     * We need to iterate in order starting from a point on the firstIntersectLine.
     * Here, we fetch the starting index to iterate
     */
    let ptIndex;
    for (let i = 0; i < polygonPoints[0].length; i++) {
      const polygonPoint = polygonPoints[0][i];
      if (this.checkClosePoints(polygonPoint, firstIntersectLinePoints[startPtIdx])) {
        ptIndex = i;
        break;
      }
    }
    const polyCoords = [];
    const polyPtsLen = polygonPoints[0].length;

    // Adding the points of the line to the final polygon
    polyCoords.push(...lineWithIntersect.geometry.coordinates);
    const polyCoordsNew = [];

    /**
     * Determine the order of iteration.
     * If the next point is on the same edge (firstIntersectLine), use reverse order
     */
    const indexSecond = (ptIndex! + 1 + polyPtsLen) % polyPtsLen;
    const secondPt = polygonPoints[0][indexSecond];
    let dir = 1;
    if (this.checkClosePoints(secondPt, firstIntersectLinePoints[1 - startPtIdx])) {
      dir = -1;
    }
    /**
     * Handle special case when both intersectLineSegments are the same
     * In this case:
     * One polygon will contain all points from polygon along with line segment
     * Second polygon will contain only the line segment points
     * This is determined based on when the starting point of the line is closer to which startIntersectingLinePoint
     */
    if (this.checkIntersectionPtsMatch(firstIntersectLinePoints, lastIntersectLinePoints)) {
      const startPt = firstIntersectLinePoints[startPtIdx];
      const otherStartPt = firstIntersectLinePoints[1 - startPtIdx];
      const lineStartPt = polyCoords[0];
      const distStartPt = turfDistance(turfPoint(startPt), turfPoint(lineStartPt));
      const distOtherStartPt = turfDistance(turfPoint(otherStartPt), turfPoint(lineStartPt));
      if (distStartPt < distOtherStartPt) {
        for (let i = 0; i < polyPtsLen; i++) {
          const index = (ptIndex! + i * dir + polyPtsLen) % polyPtsLen;
          const polygonPoint = polygonPoints[0][index];
          polyCoordsNew.push(polygonPoint);
        }
      }
    } else {
      /**
       * Iterate over all points to polygon till we reach any of the lastIntersectLinePoints
       */
      for (let i = 0; i < polyPtsLen; i++) {
        const index = (ptIndex! + i * dir + polyPtsLen) % polyPtsLen;
        const polygonPoint = polygonPoints[0][index];

        polyCoordsNew.push(polygonPoint);

        if (
          this.checkClosePoints(polygonPoint, lastIntersectLinePoints[0]) ||
          this.checkClosePoints(polygonPoint, lastIntersectLinePoints[1])
        ) {
          break;
        }
      }
    }

    // We need to reverse these to ensure all the points of polyCoords are in correct order
    polyCoordsNew.reverse();
    polyCoords.push(...polyCoordsNew);
    //remove duplicates here
    const uniqueCoordinates = [...new Set(polyCoords.map(JSON.stringify as any))].map(JSON.parse as any);
    // add the first coord again at last to make a polygon-feature
    uniqueCoordinates.push(uniqueCoordinates[0]);

    return turfPolygon([uniqueCoordinates as any]);
  }

  /**
   *
   * @param {Turf Polygon} polygon
   * @param {Turf LineString} line
   */
  reshape(polygon: any, line: any) {
    /**
     * reshape with rings
     * 1. Check if line is intersecting only with 1 one of polygon
     * 2. Run reshaped method on the intersected ring
     * 3. Push other rings in array
     * 4. Remove extra rings if they are outside of outer ring
     */

    const polyGeom = getGeom(polygon);
    const polyRings = getCoords(polyGeom);
    const reshapedPolygon = [];
    const finalPoly = [];
    if (this.isIntersectWithSingleRing(polyRings, line)) {
      for (let x = 0; x < polyRings.length; x++) {
        const polyRing = turfPolygon([polyRings[x]]);
        const reshaped = this.reshapeSingle(polyRing, line);
        if (reshaped) {
          reshapedPolygon.push(reshaped.geometry.coordinates[0]);
        } else {
          reshapedPolygon.push(polyRings[x]);
        }
      }
      const reshapedPolyWithExtraRings = turfPolygon(reshapedPolygon);
      const reshapedPolyWithExtraRingsCoords = reshapedPolyWithExtraRings.geometry.coordinates;
      const outerPoly = reshapedPolyWithExtraRingsCoords[0];
      finalPoly.push(outerPoly);
      for (let i = 1; i < reshapedPolyWithExtraRingsCoords.length; i++) {
        if (booleanContains(turfPolygon([outerPoly]), turfPolygon([reshapedPolyWithExtraRingsCoords[i]]))) {
          finalPoly.push(reshapedPolyWithExtraRingsCoords[i]);
        }
      }
      return turfPolygon(finalPoly);
    }
  }

  /**
   *
   * @param {Array} polyRings
   * @param {LineString} line
   */
  isIntersectWithSingleRing(polyRings: any, line: any) {
    let num = 0;
    for (let x = 0; x < polyRings.length; x++) {
      const polyRing = turfPolygon([polyRings[x]]);
      const intersect = lineIntersect(polyRing, line);
      if (intersect.features.length) {
        num++;
      }
    }
    return num == 1;
  }

  /**
   *
   * @param {Turf Polygon} polygon
   * @param {Turf LineString} line
   */
  reshapeSingle(polygon: any, line: any) {
    /**
     * reshape Tool
     *
     * This tool reshapes a given polygon based on an intersecting line.
     * We take the two points where the line intersects the polygon
     * Then replace all the point of the polygon, which lie between those intersection points,
     * with the points of the line
     *
     * Algorithm steps
     * 1. Get the intersection points. Need these points in order to choose first and last as required intersection points
     * 2. Find the edges of the polygon where these two intersection points lie
     * 3. Take the first edge to start with. Consider the first point of this edge
     * 4. Starting from this point, collect all points of polygon till you reach the second intersecting edge.
     * 5. Combining these points with the points of the line, we can get a polygon (outerPolygon)
     * 6. Similarly, taking the second point in step 3 gives us the second polygon (innerPolygon)
     * 7. Choose whichever polygon is larger in area and a valid polygon and return
     *
     */
    const intersectPoints = lineIntersect(polygon, line);

    // If the line intersects the polygon at less than 2 points, no need to go ahead
    if (intersectPoints.features.length < 2) {
      return;
    }
    /**
     * The intersection points obtained from turf are unordered.
     * The below section orders them in order from the starting point of the line
     */
    let orderedIntersectPoints: Array<any> = [];
    const intersectionLineSegements = lineSegment(line); // Break line into segments
    const distanceFactor = this.getDistanceFactor();

    intersectionLineSegements.features.forEach(lineseg => {
      const lineStartPt = turfPoint(lineseg.geometry.coordinates[0]);
      // For each segment, find the points lying on that segment in order
      const orderedIntersectPointsOnLineSeg: Array<any> = [];
      const orderedIntersectPointsOnLineSegDists: Array<any> = [];
      intersectPoints.features.forEach(point => {
        // Check if point lies on segment using distance function
        const dist = pointToLineDistance(point, lineseg);
        if (dist < distanceFactor) {
          let currPointIdx = 0;
          const currPointDist = turfDistance(point, lineStartPt);
          // Logic to collect points sorted by their distance from lineStartPt
          let i;
          for (i = 0; i < orderedIntersectPointsOnLineSegDists.length; i++) {
            const dist = orderedIntersectPointsOnLineSegDists[i];
            if (currPointDist < dist) {
              // Find the index where this point should be inserted
              currPointIdx = i;
              break;
            } else {
              // If currPointDist is larger than all dist till now, insert it at the end
              if (i == orderedIntersectPointsOnLineSegDists.length - 1) {
                currPointIdx = i + 1;
              }
            }
          }
          // Insert at index sorted by distance from lineStartPt
          orderedIntersectPointsOnLineSegDists.splice(currPointIdx, 0, currPointDist);
          orderedIntersectPointsOnLineSeg.splice(currPointIdx, 0, point);
        }
      });
      // Add all points for this segment into the main array
      orderedIntersectPointsOnLineSeg.forEach(_p => {
        orderedIntersectPoints.push(_p);
      });
    });

    /* Issue to be fixed:
            So while snapping points are coincident on the feature edges as well due
            to which the intersection points are duplicated one from the output of lastIntersect function and 
            the point-coordinate itself. so we had to remove the duplicates and just keep the unique coords
        */
    orderedIntersectPoints = [...new Set(orderedIntersectPoints)];
    const firstIntersect = orderedIntersectPoints[0];
    const lastIntersect = orderedIntersectPoints[orderedIntersectPoints.length - 1];

    // Slice line based on the points. We do not need the line part lying outside these points
    const lineWithIntersect = lineSlice(firstIntersect, lastIntersect, line);

    /* 
            Issues to be fixed:
            The above lineWithItersect Takes a line , a start Point (in our case firstIntersect) ,
            and a stop point (in our case lastIntersect) and returns a subsection of the line in-between 
            those points. So while snapping points are coincident on the feature edges as well due
            to which the lastIntersect point was duplicated one from the output of lastIntersect and 
            the point-coordinate itself.

            Solution:
            For such cases remove the duplicate coords from lineWithIntersect
        */

    const uniqueCoordinates: Array<any> = [];

    lineWithIntersect.geometry.coordinates.forEach(coord => {
      const isDuplicate = uniqueCoordinates.some(
        uniqueCoord => uniqueCoord[0] === coord[0] && uniqueCoord[1] === coord[1]
      );
      if (!isDuplicate) {
        uniqueCoordinates.push(coord);
      }
    });

    lineWithIntersect.geometry.coordinates = uniqueCoordinates;

    // Split polygon into line segments
    const polyLineSegments = lineSegment(polygon);

    let polygonPoints = getGeom(polygon).coordinates;

    // Cloning coordinates array before performing the pop operations below so not to modify original polygon
    polygonPoints = JSON.parse(JSON.stringify(polygonPoints));

    // First and last point is same in a polygon, so removing the duplicate point
    polygonPoints[0].pop();

    let firstIntersectLine;
    let lastIntersectLine;

    // Obtain the polygon line segments corresponding to the two intersection points
    for (let i = 0; i < polyLineSegments.features.length; i++) {
      const singlePolygonLine = polyLineSegments.features[i];

      const firstIntersectDistance = pointToLineDistance(firstIntersect, singlePolygonLine);
      const lastIntersectDistance = pointToLineDistance(lastIntersect, singlePolygonLine);

      if (firstIntersectDistance < distanceFactor || lastIntersectDistance < distanceFactor) {
        if (firstIntersectDistance < distanceFactor) {
          firstIntersectLine = singlePolygonLine;
        }

        if (lastIntersectDistance < distanceFactor) {
          lastIntersectLine = singlePolygonLine;
        }
      }
    }
    if (!firstIntersectLine || !lastIntersectLine) {
      return null;
    }
    const firstIntersectLinePoints = getGeom(firstIntersectLine).coordinates;
    const lastIntersectLinePoints = getGeom(lastIntersectLine).coordinates;

    const outerPolygon = this.generatedReshapedPoly(
      polygonPoints,
      firstIntersectLinePoints,
      0,
      lastIntersectLinePoints,
      lineWithIntersect
    );
    const innerPolygon = this.generatedReshapedPoly(
      polygonPoints,
      firstIntersectLinePoints,
      1,
      lastIntersectLinePoints,
      lineWithIntersect
    );

    // Check for self intersection in polgons to determine if they are valid
    const innerPolygonKinks = kinks(innerPolygon);
    const outerPolygonKinks = kinks(outerPolygon);

    const innerPolygonValid = innerPolygonKinks.features.length == 0;
    const outerPolygonValid = outerPolygonKinks.features.length == 0;

    if (innerPolygonValid) {
      if (outerPolygonValid) {
        const innerPolygonArea = area(innerPolygon);
        const outerPolygonArea = area(outerPolygon);
        return innerPolygonArea > outerPolygonArea ? innerPolygon : outerPolygon;
      }
      return innerPolygon;
    }
    if (outerPolygonValid) {
      return outerPolygon;
    }
    return null;
  }

  off() {
    this.mapObj.map?.removeInteraction(this.draw as Draw);
    this.lineLayer && this.mapObj.map?.removeLayer(this.lineLayer);
    window.removeEventListener('keydown', this.handleKeyDown);
  }
}

export default ReshapePolygon;
