import { Draw } from 'ol/interaction';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { drawStyle, isOutOfExtent } from '../../../helpers/helpers';
import { AbstractSvg } from 'woodpecker';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style, Fill, Icon } from 'ol/style';
import { generateUniqueID } from 'macaw';
import { Point } from 'ol/geom';
import { Feature, MapBrowserEvent } from 'ol';
import { DrawEvent } from 'ol/interaction/Draw';

const overlayStyle = new Style({
  stroke: new Stroke({
    color: 'orange',
    width: 3
  }),
  fill: new Fill({
    color: 'orange'
  }),
  image: new Icon({
    src: `data:image/svg+xml;utf8,${encodeURIComponent(AbstractSvg('orange'))}`
  })
});

class AddAbstract extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null = null;
  private contextMenuElement: HTMLElement | null = null;
  private layer: any;
  private overlayLayerId = 'abstract-feature';
  private overlayRef: VectorLayer<VectorSource> | null = null;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.contextMenuElement = document.getElementById('context-menu');
  }

  init(id: string) {
    this.off();
    this.contextMenuElement = document.getElementById('context-menu');

    this.layer = this.mapObj?.getLayerById(id);
    if (!this.layer || !this.mapObj.map) return;

    this.overlayRef = new VectorLayer({
      id: this.overlayLayerId,
      source: new VectorSource({ wrapX: false }),
      style: overlayStyle,
      zIndex: 10000
    } as any);

    this.draw = new Draw({
      source: this.overlayRef.getSource(),
      type: 'Point',
      style: drawStyle(id),
      dragVertexDelay: 0,
      snapTolerance: 1,
      condition: (event: MapBrowserEvent<UIEvent>) => {
        const mouseClick = event.originalEvent.which;
        return !(mouseClick === 3 || mouseClick === 2 || isOutOfExtent(event, this.mapObj.map));
      }
    } as any);

    this.mapObj.map.addLayer(this.overlayRef);
    this.mapObj.map.addInteraction(this.draw);

    this.draw.on('drawstart', () => {
      this.overlayRef?.getSource()?.clear();
      this.hideContextMenu();
    });

    this.draw.on('drawend', (event: DrawEvent) => {
      this.onDrawEnd(event);

      const feature = event.feature as Feature<Point>;
      const geometry = feature.getGeometry();
      if (geometry) {
        const coordinates = geometry.getCoordinates();
        const pixel = this.mapObj.map!.getPixelFromCoordinate(coordinates);

        if (pixel) {
          const viewport = this.mapObj.map!.getViewport();
          const viewportRect = viewport.getBoundingClientRect();
          const x = viewportRect.left + pixel[0];
          const y = viewportRect.top + pixel[1];
          this.showContextMenu(x, y);
        }
      }
    });

    document.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('keydown', this.keyDownHandler);
  }

  private hideContextMenu() {
    if (this.contextMenuElement) {
      this.contextMenuElement.style.display = 'none';
    }
  }

  private showContextMenu(x: number, y: number) {
    if (!this.contextMenuElement) return;

    const root = document.documentElement;
    const oneRemInPx = parseFloat(getComputedStyle(root).fontSize);
    const rightPanelWidth = document.getElementById('worksheet-panel')?.offsetWidth || 320;
    const menuWidth = parseFloat(getComputedStyle(root).getPropertyValue('--abstract-menu-width')) * oneRemInPx || 200;
    const menuHeight =
      parseFloat(getComputedStyle(root).getPropertyValue('--abstract-menu-height')) * oneRemInPx || 100;
    const buffer = 10;

    let left = x;
    let top = y;

    if (left + menuWidth + rightPanelWidth + buffer >= window.innerWidth) {
      left -= menuWidth;
    }
    if (top + menuHeight + buffer >= window.innerHeight) {
      top -= menuHeight;
    }

    Object.assign(this.contextMenuElement.style, {
      display: 'block',
      position: 'fixed',
      left: `${left}px`,
      top: `${top}px`,
      visibility: 'visible',
      opacity: '1'
    });
  }

  handleOutsideClick = (event: MouseEvent) => {
    if (!this.mapObj.map || !this.contextMenuElement) return;

    const mapElement = this.mapObj.map.getTargetElement();
    const menuClicked = this.contextMenuElement.contains(event.target as Node);
    const mapClicked = mapElement.contains(event.target as Node);

    if (!menuClicked && !mapClicked) {
      this.overlayRef?.getSource()?.clear();
      this.hideContextMenu();
    }
  };

  keyDownHandler = (event: KeyboardEvent) => {
    if (event.key === 'Backspace') {
      this.draw?.removeLastPoint();
    }
  };

  onDrawEnd(event: DrawEvent) {
    try {
      const unq_id = generateUniqueID('polyline');
      event.feature.setId(unq_id);
    } catch (error) {
      console.error('Error in onDrawEnd:', error);
    }
  }

  off() {
    if (this.draw) {
      this.mapObj.map?.removeInteraction(this.draw);
    }

    document.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('keydown', this.keyDownHandler);

    if (this.overlayRef) {
      this.mapObj.map?.removeLayer(this.overlayRef);
    }

    this.hideContextMenu();

    this.draw = null;
    this.overlayRef = null;
  }
}

export default AddAbstract;
