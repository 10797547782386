import { Draw } from 'ol/interaction';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import MapBase from '../../mapLayer/mapBase';
import { isOutOfExtent } from '../../../helpers/helpers';
import { drawStyle } from '../../../hooks/tools/helpers/styles';
import { globalTextBoxStore } from '../../utilityclasses/TextBoxStoreListener';
import { GEOMETRY_TYPES, LABEL_ACTIONS, Tool } from 'woodpecker';
import { generateUniqueID } from 'macaw';
import { TOOL_TYPE } from '../constants';
import { boundingExtent, containsCoordinate, getBottomLeft, getBottomRight, getTopLeft, getTopRight } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { fromUserCoordinate, getUserProjection } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { DragPan, DoubleClickZoom, MouseWheelZoom } from 'ol/interaction';
import html2canvas from 'html2canvas';
import { Overlay } from 'ol';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { OverlayDataType as LabelDataProps } from '../../../store/textBoxStore';
import { unByKey } from 'ol/Observable';
import { showToast } from 'ui';

const getFontSize = (str: string) => {
  let re = '';
  for (let index = 0; index < str.length; index++) {
    const char = str[index];
    if (char >= '0' && char <= '9') re += char;
  }
  return +re;
};

interface DomElementsStyleProps {
  mapContainer: HTMLElement;
  box_lonlat: number[];
  width: number;
  height: number;
  box_color?: string;
  text_color?: string;
  text_size?: number;
}
class TextBox extends ToolAbstract {
  private mapObj: MapBase;
  private draw: Draw | null;
  private dragPan: any;
  private lonLat: Coordinate | null;
  private dblClickZoom: any;
  private mouseWheelZoom: any;
  private textareaWidthAdjusted: number | null;
  private textareaHeightAdjusted: number | null;
  private domElements: {
    container: any;
    textArea: any;
    labelText: any;
    dateArea: any;
  };
  private editedOverlay: any;
  private copiedLabelElement: any;
  private selectedOverlay: any;
  private isToolActive: boolean;
  private positionChanged: boolean;
  private notifier: (action: number, data: LabelDataProps) => void;
  private updateState: (data: any) => void;
  private tool: Tool;
  private onClickRef: any;
  private pointerMoveRef: any;
  private pointerUpRef: any;
  private disabledSave: boolean;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.dragPan = null;
    this.draw = null;
    this.lonLat = null;
    this.dblClickZoom = null;
    this.mouseWheelZoom = null;
    this.textareaWidthAdjusted = null;
    this.textareaHeightAdjusted = null;
    this.editedOverlay = null;
    this.copiedLabelElement = null;
    this.selectedOverlay = null;
    this.isToolActive = false;
    this.domElements = {
      container: document.getElementById('text-box-container'),
      textArea: document.getElementById('text-area'),
      labelText: document.getElementById('text-label'),
      dateArea: document.getElementById('date-area')
    };
    this.positionChanged = false;
    this.notifier = globalTextBoxStore?.TextStore?.notifier;
    this.updateState = globalTextBoxStore?.TextStore?.updateState;
    this.tool = globalStore?.AppStore?.tool;
    this.onClickRef = null;
    this.pointerMoveRef = null;
    this.pointerUpRef = null;
    this.disabledSave = false;
  }

  /**
   * Initializes the tool for the specified layer ID.
   * Turns off any existing tool and sets up the draw interaction for the layer's source.
   * @param {string} id - The ID of the layer to initialize the tool for.
   */
  init(id: string) {
    this.off();
    this.tool = globalStore?.AppStore?.tool;
    this.notifier = globalTextBoxStore?.TextStore?.notifier;
    this.updateState = globalTextBoxStore?.TextStore?.updateState;

    this.isToolActive = this.tool?.tool_id === TOOL_TYPE.TEXT_BOX;

    if (!this.isToolActive) return;

    this.onClickRef = this.mapObj?.map?.on('singleclick', this.onMapClick);
    this.pointerMoveRef = this.mapObj?.map?.on('pointermove', this.handlePointerMove);
    //@ts-ignore
    this.pointerUpRef = this.mapObj?.map?.on('pointerup', this.handlePointerUp);

    this.mapObj?.map?.getInteractions().forEach((interaction: any) => {
      if (interaction instanceof DragPan) {
        this.dragPan = interaction;
      } else if (interaction instanceof DoubleClickZoom) {
        this.dblClickZoom = interaction;
      } else if (interaction instanceof MouseWheelZoom) {
        this.mouseWheelZoom = interaction;
      }
    });
    this.mapObj.map?.removeInteraction(this.dblClickZoom);

    this.draw = new Draw({
      type: GEOMETRY_TYPES.CIRCLE as any,
      style: (feat: any, res) => {
        if (feat?.getGeometry().getType() == 'LineString') {
          return null;
        }
        return drawStyle() as any;
      },
      dragVertexDelay: 0,
      snapTolerance: 1,
      maxPoints: 3,
      condition: e => {
        const mouseClick = e.originalEvent.which;
        if (mouseClick === 3 || mouseClick === 2 || isOutOfExtent(e, this.mapObj.map)) {
          return false;
        }
        return true;
      },
      geometryFunction: this.createBoxv2()
    });
    this.draw?.on('drawend', this.onDrawEnd);
    this.draw?.on('drawstart', this.onDrawStart);

    const overlays = [...(this.mapObj.map?.getOverlays()?.getArray() || [])];
    if (overlays.length) {
      overlays.forEach(overlay => {
        if (overlay.get('labelBoxData')) {
          this.addListeners(overlay);
        }
      });
    }

    this.domElements = {
      container: document.getElementById('text-box-container'),
      textArea: document.getElementById('text-area'),
      labelText: document.getElementById('text-label'),
      dateArea: document.getElementById('date-area')
    };

    this.mapObj.map?.addInteraction(this.draw);
  }

  createBoxv2 = () => {
    return function (coordinates: any, geometry: any, projection: any) {
      const extent = boundingExtent(
        /** @type {LineCoordType} */ [coordinates[0], coordinates[coordinates.length - 1]].map(function (coordinate) {
          return fromUserCoordinate(coordinate, projection);
        })
      );
      const boxCoordinates = [
        [getBottomLeft(extent), getBottomRight(extent), getTopRight(extent), getTopLeft(extent), getBottomLeft(extent)]
      ];
      if (geometry) {
        geometry.setCoordinates(boxCoordinates);
      } else {
        geometry = new Polygon(boxCoordinates);
      }
      const userProjection = getUserProjection();
      if (userProjection) {
        geometry.transform(projection, userProjection);
      }
      return geometry;
    };
  };

  onMapClick = (e: MapBrowserEvent<MouseEvent>) => {
    if (!this.lonLat) this.lonLat = e.coordinate;
  };

  onDrawStart = (event: any) => {
    if (!this.lonLat) this.lonLat = event.feature?.getGeometry()?.getCoordinates()[0][0];
  };

  onDrawEnd = (event: any) => {
    if (!this.lonLat) return;

    this.draw?.setActive(false);
    this.dragPan.setActive(false);
    this.mouseWheelZoom?.setActive(false);

    const extent = event.feature.getGeometry().getExtent();

    const bottomLeft = getBottomLeft(extent);
    const topRight = getTopRight(extent);
    const pixelBottomLeft = this.mapObj.map?.getPixelFromCoordinate(bottomLeft);
    const pixelTopRight = this.mapObj.map?.getPixelFromCoordinate(topRight);
    const mapContainer = document.getElementById('map-container');

    if (mapContainer && pixelBottomLeft?.length && pixelTopRight?.length) {
      const resolution = this.mapObj.map?.getView()?.getResolution() || 1;
      const scaleFactor = 1 / resolution;

      const width = pixelTopRight[0] - pixelBottomLeft[0];
      const height = pixelBottomLeft[1] - pixelTopRight[1];

      // This part calculates the difference between the original width/height and the
      // width/height scaled by the scaleFactor. This effectively calculates the difference
      // between the original width and what the width would be at the map's current resolution.
      this.textareaWidthAdjusted = width + (width - width * scaleFactor) / scaleFactor;
      this.textareaHeightAdjusted = height + (height - height * scaleFactor) / scaleFactor;

      this.addDomElementsStyle({
        mapContainer,
        box_lonlat: this.lonLat,
        width,
        height
      });
    }
  };

  addDomElementsStyle = ({
    mapContainer,
    box_lonlat,
    width,
    height,
    box_color,
    text_color,
    text_size
  }: DomElementsStyleProps) => {
    const mapContainerRect = mapContainer.getBoundingClientRect();
    const boxCoordinates = this.mapObj.map?.getPixelFromCoordinate(box_lonlat);
    if (!mapContainerRect || !boxCoordinates?.length) return;

    // Calculate default position based on coordinates and map container offset
    let leftPosition = mapContainerRect.left + boxCoordinates[0];
    let topPosition = mapContainerRect.top + boxCoordinates[1];

    // Offset for buffer space from the edges (adjust as needed)
    const buffer = 10;

    // Get right panel dimensions
    const rightPanelWidth = document.getElementById('worksheet-panel')?.offsetWidth || 320;

    const oneRemInPx = parseFloat(getComputedStyle(document.documentElement).fontSize);

    const minWidth = 10 * oneRemInPx;
    const minHeight = 5 * oneRemInPx;

    const textToolBarEl = this.domElements.container.querySelector('span');

    if (textToolBarEl) textToolBarEl.style.left = 0;

    width = Math.max(width, minWidth);
    height = Math.max(height, minHeight);

    const heightToConsider = height + 2.5 * oneRemInPx;

    // Check if the box goes beyond the right edge
    if (leftPosition + width + rightPanelWidth + buffer > mapContainerRect.right) {
      leftPosition = mapContainerRect.right - width - buffer - rightPanelWidth;
      if (textToolBarEl) {
        const textToolBarElWidth = textToolBarEl?.offsetWidth || 14.5 * oneRemInPx;
        if (leftPosition + textToolBarElWidth + rightPanelWidth > mapContainerRect.right) {
          const textToolBarLeftPosition =
            mapContainerRect.right - leftPosition - textToolBarElWidth - rightPanelWidth - buffer;

          textToolBarEl.style.left = `${parseInt(`${textToolBarLeftPosition}`, 10)}px`;
        }
      }
    }

    // Check if the box goes beyond the bottom edge
    if (topPosition + heightToConsider + buffer > mapContainerRect.bottom) {
      topPosition = mapContainerRect.bottom - heightToConsider - buffer;
    }

    this.domElements.container.style.left = `${parseInt(
      //@ts-ignore
      leftPosition,
      10
    )}px`;
    this.domElements.container.style.top = `${parseInt(
      //@ts-ignore
      topPosition,
      10
    )}px`;
    this.domElements.container.style.display = 'block';
    this.domElements.textArea.style.width = `${width}px`;
    this.domElements.textArea.style.height = `${height}px`;
    this.domElements.textArea.style.backgroundColor = box_color || '#EBEBEB';
    this.domElements.textArea.style.color = text_color || '#000000';
    this.domElements.textArea.style.fontSize = `${text_size}px`;
    this.domElements.textArea.style.display = 'block';
    this.domElements.textArea.focus();
    this.domElements.labelText.style.display = 'none';

    this.updateState({
      color: text_color || '#000000',
      box_color: box_color || '#EBEBEB',
      fontSize: text_size || 12
    });
  };

  addListeners = (overlay: any) => {
    const element = overlay.getElement();

    element.addEventListener('mouseenter', this.handleMouseEnter);
    element.addEventListener('mouseout', this.handleMouseOut);
    element.addEventListener('dblclick', this.editLabelBox);
    element.addEventListener('mousedown', this.handleMouseDown);
  };

  hideContainer() {
    if (this.editedOverlay) {
      this.mapObj.map?.addOverlay(this.editedOverlay);
      this.editedOverlay = null;
    }
    if (this.mouseWheelZoom) this.mapObj.map?.addInteraction(this.mouseWheelZoom);
    this.draw?.setActive(true);
    this.dragPan.setActive(true);
    this.lonLat = null;
    this.selectedOverlay = null;
    this.domElements.container.style.display = 'none';
    this.domElements.textArea.value = '';
  }

  getLabelData() {
    const labelData = {
      text: this.domElements?.textArea.value,
      text_color: this.domElements?.textArea.style.color,
      text_size: getFontSize(this.domElements?.textArea.style.fontSize) || 12,
      box_color: this.domElements?.textArea.style?.backgroundColor,
      excluded: true,
      external: !!this.tool?.external
    };

    return labelData;
  }

  addLabelBox = (labelData: LabelDataProps, isCopied: boolean = false) => {
    const domElement = isCopied ? this.copiedLabelElement : this.domElements?.labelText;

    // this.domElements.dateArea.innerHTML = labelData.date;

    html2canvas(domElement, {
      useCORS: true,
      allowTaint: true
    })
      .then((canvas: HTMLCanvasElement) => {
        return new Promise<HTMLCanvasElement>(resolve => {
          canvas.toBlob(blob => {
            if (blob) {
              labelData.box_image = blob;
            }
            resolve(canvas);
          }, 'image/png');
        });
      })
      .then((canvas: HTMLCanvasElement) => {
        const overlay = new Overlay({
          position: this.lonLat || labelData?.box_lonlat,
          element: canvas,
          stopEvent: false
        });

        const updatedLonLat = overlay.getPosition();
        let editedExtent;
        if (updatedLonLat) {
          const lonLatString = updatedLonLat[0];
          editedExtent = [
            lonLatString,
            updatedLonLat[1] - (this.textareaHeightAdjusted || 0),
            lonLatString + (this.textareaWidthAdjusted || 0),
            updatedLonLat[1]
          ];
        }

        const data: LabelDataProps = {
          ...labelData,
          box_lonlat: this.lonLat || labelData?.box_lonlat,
          width: this.textareaWidthAdjusted || labelData?.width,
          height: this.textareaHeightAdjusted || labelData?.height,
          extent: this.textareaWidthAdjusted ? editedExtent : labelData?.extent || editedExtent
        };

        overlay.set('labelBoxData', data);
        this.mapObj.map?.addOverlay(overlay);

        if (this.isToolActive) {
          this.lonLat = null;
          this.selectedOverlay = null;

          if (this.editedOverlay) {
            this.editedOverlay = null;
          }
          canvas.addEventListener('mouseenter', this.handleMouseEnter);
          canvas.addEventListener('mouseout', this.handleMouseOut);
          canvas.addEventListener('dblclick', this.editLabelBox);
          canvas.addEventListener('mousedown', this.handleMouseDown);

          this.hideContainer();
          this.draw?.setActive(true);
          this.dragPan.setActive(true);
        }

        this.notifier(data?.action, data);
        this.reset();
        this.disabledSave = false;
        this.selectedOverlay = null;
      });
    this.selectedOverlay = null;
  };

  handleMouseEnter = (e: Event) => {
    e.preventDefault();
    if (this.draw?.getActive()) {
      this.draw.setActive(false);
      this.lonLat = null;
    }
    if (!(this.domElements.container.style.display === 'block')) {
      // changeMapCursor(false, "", "move");
    }
  };

  handleMouseOut = (e: Event) => {
    e.preventDefault();
    if (!(this.domElements.container.style.display === 'block')) {
      this.draw?.setActive(true);
      // changeMapCursor(false, "", "crosshair");
    }
  };

  handleMouseDown = (e: Event) => {
    e.preventDefault();
    if (this.domElements.container.style.display === 'block' || !this.selectedOverlay) return;

    this.dragPan.setActive(false);
    this.selectedOverlay && this.selectedOverlay.set('dragging', true);
  };

  findOutOfExtend = (extent: any[], coordinate: Coordinate) => {
    let bottomRight = getBottomRight(extent);
    let topLeft = getTopLeft(extent);
    let newCoord = [bottomRight[0] + (coordinate[0] - topLeft[0]), bottomRight[1] + (coordinate[1] - topLeft[1])];
    return isOutOfExtent({ coordinate: newCoord }, this?.mapObj?.map, false);
  };

  handlePointerMove = (evt: MapBrowserEvent<MouseEvent>) => {
    if (this.domElements.container.style.display === 'block') return;
    evt.preventDefault();
    if (this.dragPan.getActive()) {
      const overlays = this.mapObj.map?.getOverlays().getArray() || [];
      overlays.find((overlay: any) => {
        if (overlay.get('labelBoxData')) {
          const { extent } = overlay.get('labelBoxData');
          if (containsCoordinate(extent, evt.coordinate)) {
            this.selectedOverlay = overlay;
            return overlay;
          }
        }
        return null;
      });
    }

    if (
      this.selectedOverlay &&
      this.selectedOverlay.get('dragging') === true &&
      !this.findOutOfExtend(this.selectedOverlay?.get('labelBoxData')?.extent, evt?.coordinate) &&
      !isOutOfExtent(evt, this.mapObj.map, false)
    ) {
      this.selectedOverlay.setPosition(evt.coordinate);
      this.positionChanged = true;
    }
  };

  handlePointerUp = (evt: MapBrowserEvent<MouseEvent>) => {
    if (this.selectedOverlay && this.selectedOverlay.get('dragging') === true) {
      this.dragPan.setActive(true);
      this.selectedOverlay.set('dragging', false);

      const labelBoxData = this.selectedOverlay.get('labelBoxData');

      if (this.positionChanged) {
        this.positionChanged = false;

        const { extent } = labelBoxData;
        const deltaLon = evt.coordinate[0] - labelBoxData.box_lonlat[0];
        const deltaLat = evt.coordinate[1] - labelBoxData.box_lonlat[1];

        const newExtent = [+extent[0] + deltaLon, +extent[1] + deltaLat, +extent[2] + deltaLon, +extent[3] + deltaLat];

        const updatedLabelBoxData = {
          ...labelBoxData,
          extent: newExtent,
          box_lonlat: evt.coordinate
        };

        this.selectedOverlay.set('labelBoxData', updatedLabelBoxData);

        if (!updatedLabelBoxData.extent || !updatedLabelBoxData?.extent[0]) {
          showToast('Something went wrong, please try again', 'error');
          return;
        }

        this.notifier(LABEL_ACTIONS.MOVED, updatedLabelBoxData);
      }
    }
  };

  loadLabelBoxes(labelBoxes: LabelDataProps[]) {
    try {
      if (labelBoxes.length) {
        for (let i = 0; i < labelBoxes.length; i++) {
          this.loadLabelBox(labelBoxes[i]);
        }
      }
    } catch (err) {
      // captureException(err);
    }
  }

  loadLabelBox = (labelData: LabelDataProps) => {
    if (typeof labelData.box_image !== 'string') return;

    const image = new Image();
    image.src = labelData.box_image;

    image.onload = () => {
      const overlay = new Overlay({
        position: this.lonLat || labelData?.box_lonlat,
        element: image,
        stopEvent: false
      });

      const originalWidth = labelData.width;
      const originalHeight = labelData.height;

      const resolution = this.mapObj?.map?.getView()?.getResolution() || 1;
      const scaleFactor = 1 / resolution;

      const adjustedWidth = originalWidth * scaleFactor;
      const adjustedHeight = originalHeight * scaleFactor;

      const divElement = overlay.getElement();
      if (divElement) {
        divElement.style.width = `${adjustedWidth}px`;
        divElement.style.height = `${adjustedHeight}px`;
      }

      const updatedLonLat = overlay.getPosition();
      let editedExtent;
      if (updatedLonLat) {
        const lonLatString = updatedLonLat[0];
        editedExtent = [
          lonLatString,
          // @ts-ignore
          updatedLonLat[1] - this.textareaHeightAdjusted,
          // @ts-ignore
          lonLatString + this.textareaWidthAdjusted,
          updatedLonLat[1]
        ];
      }

      const data = {
        ...labelData,
        box_lonlat: this.lonLat || labelData?.box_lonlat,
        width: this.textareaWidthAdjusted || labelData?.width,
        height: this.textareaHeightAdjusted || labelData?.height,
        extent: this.textareaWidthAdjusted || this.textareaWidthAdjusted ? editedExtent : labelData?.extent
      };

      overlay.set('labelBoxData', data);
      this.mapObj.map?.addOverlay(overlay);
    };
  };

  editLabelBox = () => {
    if (!this.selectedOverlay || this.domElements.container.style.display === 'block') return;

    this.mapObj.map?.removeInteraction(this.mouseWheelZoom);
    this.draw?.setActive(false);
    this.dragPan.setActive(false);

    const {
      text,
      text_color,
      text_size,
      box_color,
      width: intialWidth,
      height: initialHeight,
      box_lonlat
    } = this.selectedOverlay.get('labelBoxData');

    this.updateState({
      text: text,
      color: text_color,
      fontSize: text_size || 12
    });

    this.lonLat = box_lonlat;
    this.editedOverlay = this.selectedOverlay;
    this.mapObj.map?.removeOverlay(this.selectedOverlay);

    const mapContainer = document.getElementById('map-container');

    if (mapContainer) {
      const resolution = this.mapObj.map?.getView().getResolution() || 1;
      const scaleFactor = 1 / resolution;

      const width = intialWidth * scaleFactor;
      const height = initialHeight * scaleFactor;
      this.domElements.textArea.value = text;

      this.addDomElementsStyle({
        mapContainer,
        box_lonlat,
        width,
        height,
        box_color,
        text_color,
        text_size
      });
    }
  };

  addEditLabelBox() {
    if (this.disabledSave) return;
    else this.disabledSave = true;

    const resolution = this.mapObj.map?.getView().getResolution() || 1;
    const scaleFactor = 1 / resolution;
    const textAreaRect = this.domElements?.textArea.getBoundingClientRect();
    this.textareaWidthAdjusted =
      textAreaRect.width + (textAreaRect.width - textAreaRect.width * scaleFactor) / scaleFactor;
    this.textareaHeightAdjusted =
      textAreaRect.height + (textAreaRect.height - textAreaRect.height * scaleFactor) / scaleFactor;

    this.domElements.labelText.style.display = 'flex';
    this.domElements.labelText.style.width = `${textAreaRect.width}px`;
    this.domElements.labelText.style.height = `${textAreaRect.height}px`;
    this.domElements.labelText.style.color = this.domElements.textArea.style.color;
    this.domElements.labelText.style.backgroundColor = this.domElements.textArea.style.backgroundColor;
    this.domElements.labelText.style.fontSize = `${this.domElements.textArea.style.fontSize}`;
    this.domElements.textArea.style.display = 'none';

    let labelBoxData: LabelDataProps;
    if (this.editedOverlay) {
      if (this.mouseWheelZoom) this.mapObj.map?.addInteraction(this.mouseWheelZoom);

      labelBoxData = {
        ...this.editedOverlay.get('labelBoxData'),
        ...this.getLabelData(),
        width: textAreaRect.width,
        height: textAreaRect.height,
        action: LABEL_ACTIONS.EDITED
      };
    } else {
      const date = new Date();
      const formattedDate = date.toISOString().split('T')[0];

      labelBoxData = {
        ...this.getLabelData(),
        properties: {
          tool_id: generateUniqueID('textBox'),
          external: !!this.tool?.external
        },
        width: textAreaRect.width,
        height: textAreaRect.height,
        action: LABEL_ACTIONS.CREATED,
        date: formattedDate
      };
    }
    this.updateState({
      color: this.domElements.labelText.style.color || '#000000',
      fontSize: getFontSize(this.domElements.labelText.style.fontSize) || 12,
      date: labelBoxData.date
    });

    this.addLabelBox(labelBoxData);
  }

  deleteLabelBox() {
    if (this.editedOverlay) {
      const data = {
        ...this.editedOverlay.get('labelBoxData'),
        action: LABEL_ACTIONS.DELETED
      };
      this.notifier(LABEL_ACTIONS.DELETED, data);
      this.mapObj.map?.removeOverlay(this.editedOverlay);
      this.selectedOverlay = null;
      this.editedOverlay = null;
      this.hideContainer();
    }
    this.reset();
  }

  removeOverlayEventListeners() {
    const overlays = this.mapObj?.map?.getOverlays().getArray() || [];
    overlays.forEach((overlay: any) => {
      if (overlay.get('labelBoxData')) {
        overlay.getElement().removeEventListener('mouseenter', this.handleMouseEnter);
        overlay.getElement().removeEventListener('mouseout', this.handleMouseOut);
        overlay.getElement().removeEventListener('dblclick', this.editLabelBox);
        overlay.getElement().removeEventListener('mousedown', this.handleMouseDown);
      }
    });
  }

  reset = () => {
    this.updateState = globalTextBoxStore?.TextStore?.updateState;
    if (!!this?.domElements?.container) this.domElements.container.style.display = 'none';
    this.lonLat = null;
    if (this.updateState) {
      this.updateState({
        text: '',
        color: '#000000',
        fontSize: 12,
        date: '',
        box_color: '#EBEBEB'
      });
    }
    this.draw?.setActive(true);
    this.dragPan?.setActive(true);
    this.mouseWheelZoom?.setActive(true);
  };

  /**
   * Turns off the draw interaction by removing it from the map and removing event listeners.
   */
  off() {
    this.reset();
    if (this.draw) {
      this.mapObj.map?.removeInteraction(this.draw);
    }
    this.mapObj?.map?.un('singleclick', this.onMapClick);
    this.removeOverlayEventListeners();
    this.pointerMoveRef && unByKey(this.pointerMoveRef);
    this.onClickRef && unByKey(this.onClickRef);
    this.pointerUpRef && unByKey(this.pointerUpRef);
    this.dragPan?.setActive(true);
  }
}

export default TextBox;
