import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { toLonLat } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';

import { MAP_LAYERS } from '../../Constants/Constant';
import { tileImagery } from './MapInit';

class DropPin {
  coords: $TSFixMe;

  disableConfirm: $TSFixMe;

  mapObj: $TSFixMe;

  marker: $TSFixMe;

  constructor(mapObj: $TSFixMe) {
    this.mapObj = mapObj;
    this.marker = null;
    this.coords = null;
    this.disableConfirm = false;
  }

  on(config = {}) {
    // @ts-expect-error TS(2339): Property 'disableConfirm' does not exist on type '... Remove this comment to see the full error message
    this.disableConfirm = config?.disableConfirm;
    this.mapObj.map.on('singleclick', this.addMarker);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    document.getElementById('map').style.cursor = 'crosshair';
    if (this.disableConfirm) {
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      document.getElementById('confirm-loc-btn')?.setAttribute('disabled', true);
    }
    // The removeLngLat argument is sent as false
    tileImagery.off(false);
  }

  addMarker = (evt: $TSFixMe) => {
    if (this.marker) {
      this.mapObj.removeLayer(this.marker);
    }
    this.coords = evt.coordinate;
    const feature = new Feature({
      geometry: new Point(evt.coordinate)
    });

    const style = new Style({
      image: new Icon({
        src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/pin.png',
        scale: 0.7,
        crossOrigin: 'anonymous'
      })
    });
    feature.setStyle(style);

    const src = new VectorSource({
      wrapX: false,
      features: [feature]
    });

    this.marker = new VectorLayer({
      source: src,
      // @ts-expect-error TS(2345): Argument of type '{ source: VectorSource<Geometry>... Remove this comment to see the full error message
      id: MAP_LAYERS.MARKER,
      name: MAP_LAYERS.MARKER
    });

    this.mapObj.addLayer(this.marker);
    if (this.disableConfirm) {
      document.getElementById('confirm-loc-btn')?.removeAttribute('disabled');
    }
  };

  getPinLocation() {
    if (this.coords?.length) {
      const coords = toLonLat(this.coords);
      return { lat: coords[1], lon: coords[0] };
    }
    return null;
  }

  off() {
    this.mapObj.map.un('singleclick', this.addMarker);
    this.mapObj.removeLayer(this.marker);
    const mapNode = document.getElementById('map');
    if (mapNode) mapNode.style.cursor = '';
    if (this.disableConfirm) {
      document.getElementById('confirm-loc-btn')?.removeAttribute('disabled');
      this.disableConfirm = false;
    }
  }
}
export default DropPin;
