import { create } from 'zustand';
import { FETCHING_STATUS, FilterBy, LogicalOperation, TAG_ASSIGN_TYPE } from '../constants';
import { constructTagsByScope, constructTagsMap, massageTags } from '../helpers/tagging';

export const taggingActions = {
  SET_TAGS: 'SET_TAGS',
  SET_SELECTED_FEATURES: 'SET_SELECTED_FEATURES',
  SET_SELECTED_LAYERS: 'SET_SELECTED_LAYERS',
  SET_SELECT_INS: 'SET_SELECT_INS',
  SET_FILTER_QUERY: 'SET_FILTER_QUERY',
  SET_SORT_QUERY: 'SET_SORT_QUERY',
  SET_UNFILTERED_GEOMS: 'SET_UNFILTERED_GEOMS',
  RESET_OL_FEATS: 'RESET_OL_FEATS',
  CLEAR: 'CLEAR',
  SET_GROUPS_MUTATION: 'SET_GROUPS_MUTATION',
  SET_FETCHING_STATUS: 'SET_FETCHING_STATUS',
  SET_ASSIGN_TYPE: 'SET_ASSIGN_TYPE'
};

export interface TagsType {
  id: string;
  name: string;
  tag_type_scope: number;
  tags: {
    bg_color: string;
    color: string;
    id: string;
    name: string;
    subtags: { id: string; name: string; tag: string }[];
    tag_type: string;
  }[];
}
export interface TagsInfo {
  [key: string]: {
    [key: string]: string[];
  };
}

interface ISortQuery {
  [key: string]: {
    [key: string]: string[];
  };
}

export interface FilterQuery {
  type: FilterBy;
  operation: LogicalOperation;
  tagsInfo: TagsInfo | null;
}

interface StoreVariable {
  tags: TagsType[];
  selectedFeatures: any[];
  selectedLayers: any[];
  selectIns: any;
  tagsByScope: any;
  tagsMap: any;
  massagedTags: TagsInfo;
  filterQuery: FilterQuery;
  sortQuery: ISortQuery | null;
  unFilteredGeoms: any[];
  groupsMutation: Function;
  fetchingStatus: any;
  assignType: TAG_ASSIGN_TYPE;
}
interface StoreType extends StoreVariable {
  dispatch: any;
  reset: () => void;
}

const INIT_STATE: StoreVariable = {
  tags: [],
  tagsByScope: {},
  tagsMap: {},
  selectedFeatures: [],
  selectedLayers: [],
  selectIns: null,
  massagedTags: {},
  fetchingStatus: FETCHING_STATUS.IDLE,
  filterQuery: {
    type: FilterBy.BOTH,
    operation: LogicalOperation.OR,
    tagsInfo: null
  },
  sortQuery: null,
  unFilteredGeoms: [],
  groupsMutation: () => {},
  assignType: TAG_ASSIGN_TYPE.NOT_ALLOWED
};

const reducer = (state: StoreType, { type, payload = null }: any): StoreType => {
  switch (type) {
    case taggingActions.SET_TAGS: {
      const tagsByScope = constructTagsByScope(payload);
      const tagsMap = constructTagsMap(payload);
      const massagedTags = massageTags(payload);
      return { ...state, tags: payload, tagsByScope, tagsMap, massagedTags };
    }

    case taggingActions.SET_SELECTED_FEATURES: {
      return { ...state, selectedFeatures: [...payload] };
    }
    case taggingActions.SET_SELECTED_LAYERS: {
      return { ...state, selectedLayers: payload };
    }
    case taggingActions.SET_SELECT_INS: {
      return { ...state, selectIns: payload };
    }
    case taggingActions.SET_FILTER_QUERY: {
      return { ...state, filterQuery: payload };
    }
    case taggingActions.SET_SORT_QUERY: {
      return { ...state, sortQuery: payload };
    }
    case taggingActions.SET_UNFILTERED_GEOMS: {
      return {
        ...state,
        unFilteredGeoms: { ...state.unFilteredGeoms, ...payload }
      };
    }
    case taggingActions.SET_ASSIGN_TYPE: {
      return {
        ...state,
        assignType: payload
      };
    }

    case taggingActions.RESET_OL_FEATS: {
      return {
        ...state,
        selectedFeatures: [],
        selectedLayers: [],
        selectIns: null
      };
    }

    case taggingActions.CLEAR: {
      !!state?.selectIns && state?.selectIns?.getFeatures()?.clear?.();
      return {
        ...state,
        selectedFeatures: [],
        selectedLayers: []
      };
    }
    case taggingActions.SET_GROUPS_MUTATION: {
      return {
        ...state,
        groupsMutation: payload
      };
    }
    case taggingActions.SET_FETCHING_STATUS: {
      return {
        ...state,
        fetchingStatus: payload
      };
    }

    default:
      return state;
  }
};
const store = (set: any) => ({
  ...INIT_STATE,
  dispatch: (action: any) => set((state: StoreType) => reducer(state, action)),
  reset: () =>
    set((state: StoreType) => ({
      ...state,
      selectedFeatures: [],
      selectedLayers: []
    }))
});
export const useTags = create<StoreType>(store);
