import { cloneDeep, isPlainObject } from 'lodash';
import { FETCHING_STATUS, TAGGING_SCOPES } from '../constants';
import { TagsInfo, TagsType, useTags } from '../store/tagsStore';
import { TagsInfoType } from '../store/mapStore';

export const massageTags = (tags: TagsType[]): { [tagTypeId: string]: { [tagId: string]: string[] } } => {
  return tags.reduce((ac, tagType) => {
    const tagTypeData = tagType.tags?.reduce((ac, tag) => {
      const subtagIds = tag.subtags?.map(subtag => subtag?.id);
      return { ...ac, [tag.id]: subtagIds };
    }, {});
    return { ...ac, [tagType.id]: tagTypeData };
  }, {});
};

export const constructTagsByScope = (tags: TagsType[], isLibraryPanel = false, isDetailedReport = false) => {
  const tagsByScope: { [scope: number]: TagsType[] } = {
    [TAGGING_SCOPES.GLOBAL]: [],
    [TAGGING_SCOPES.COMPANY]: [],
    [TAGGING_SCOPES.TAKEOFF]: []
  };

  tags.forEach(tag => {
    switch (tag.tag_type_scope) {
      case TAGGING_SCOPES.GLOBAL:
        tagsByScope[TAGGING_SCOPES.GLOBAL].push(tag);
        break;
      case TAGGING_SCOPES.COMPANY:
        tagsByScope[TAGGING_SCOPES.COMPANY].push(tag);
        break;
      case TAGGING_SCOPES.TAKEOFF:
        tagsByScope[TAGGING_SCOPES.TAKEOFF].push(tag);
        break;
      default:
        break;
    }
  });
  if (isLibraryPanel) {
    return { [TAGGING_SCOPES.GLOBAL]: tagsByScope[TAGGING_SCOPES.GLOBAL] };
  }
  if (isDetailedReport) {
    for (let key in tagsByScope) {
      if (!tagsByScope[key]?.length) {
        delete tagsByScope[key];
      }
    }
  }
  return tagsByScope;
};

export const constructTagsMap = (tags: TagsType[]) => {
  let tagTypeMap: { [tagTypeId: string]: { id: string; name: string; scope: number } } = {};
  let tagMap: { [tagId: string]: { id: string; name: string } } = {};
  let subTagMap: { [subTagId: string]: { id: string; name: string } } = {};

  for (let i = 0; i < tags.length; i++) {
    const tagType = tags[i];
    tagTypeMap[tagType.id] = {
      id: tagType.id,
      name: tagType.name,
      scope: tagType.tag_type_scope
    };
    for (let j = 0; j < tagType.tags.length; j++) {
      const tag = tagType.tags[j];
      tagMap[tag.id] = {
        id: tag.id,
        name: tag.name
      };
      for (let k = 0; k < tag.subtags.length; k++) {
        const subtag = tag.subtags[k];

        subTagMap[subtag.id] = {
          id: subtag.id,
          name: subtag.name
        };
      }
    }
  }
  return {
    tagTypeMap,
    tagMap,
    subTagMap
  };
};

export const isTagPresentInTagsLib = (
  tagsData: TagsInfo,
  tagTypeId: string,
  tagId: string,
  subtagId: string | null
) => {
  if (
    Object.prototype.hasOwnProperty.call(tagsData, tagTypeId) &&
    Object.prototype.hasOwnProperty.call(tagsData[tagTypeId], tagId)
  ) {
    if (subtagId) {
      return tagsData[tagTypeId][tagId].includes(subtagId);
    }

    return true;
  }
  return false;
};

export const filterOutDeletedTags = (tags_info: TagsInfoType, checkFetchingStatus = false) => {
  const { massagedTags, fetchingStatus } = useTags?.getState() || {};
  const deletedtagTypeIds: string[] = [];
  let hasDeleted = false;
  if (!isPlainObject(tags_info) || (checkFetchingStatus && fetchingStatus !== FETCHING_STATUS.SUCCESS))
    return { hasDeleted, tags_info, deletedtagTypeIds };

  const cloned_tags = cloneDeep(tags_info || {});
  Object.keys(cloned_tags).forEach(tagTypeId => {
    const { tagId, subtagId } = cloned_tags[tagTypeId] || {};

    // if any tag/subtag of particular tagtype is deleted from tagsLib
    // we're removing entire tagtype from feature

    if (!isTagPresentInTagsLib(massagedTags, tagTypeId, tagId, subtagId)) {
      hasDeleted = true;
      deletedtagTypeIds.push(tagTypeId);
      delete cloned_tags?.[tagTypeId];
    }
  });
  return { tags_info: cloned_tags, hasDeleted, deletedtagTypeIds };
};

export const mergeFeatAndGeomTags = (featTags: TagsInfoType = {}, geomTags: TagsInfoType = {}) => {
  const mergedTags = cloneDeep(featTags);
  for (const key in mergedTags) {
    if (Object.prototype.hasOwnProperty.call(mergedTags, key) && typeof mergedTags[key] === 'object') {
      mergedTags[key].isFeatureTag = true;
    }
  }
  if (!Object.keys(geomTags)?.length) return mergedTags;
  Object.keys(geomTags)?.forEach(tagTypeId => {
    // if feature has already that tagType
    // we'll give prefrence to that one
    if (!mergedTags[tagTypeId]) {
      mergedTags[tagTypeId] = geomTags[tagTypeId];
    }
  });

  return mergedTags;
};
