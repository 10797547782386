import { Coordinate } from "ol/coordinate";

type LineType = {
  angle: number;
  coordinate: Coordinate;
};

function getTanFromDegrees(degrees: number) {
  return Math.tan((degrees * Math.PI) / 180);
}

const getIntersection = (sourceLine: LineType, variableLine: LineType) => {
  if (sourceLine?.angle === 90 || sourceLine?.angle === 270) {
    return [sourceLine?.coordinate[0], variableLine?.coordinate[1]];
  } else {
    const sourceSlope = getTanFromDegrees(sourceLine?.angle);
    const interactionPoint = variableLine.coordinate;
    
    interactionPoint[1] =
      sourceSlope * (interactionPoint[0] - sourceLine?.coordinate[0]) +
      sourceLine?.coordinate[1];

    return interactionPoint;
  }
};

export default getIntersection;
