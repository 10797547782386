import Feature from 'ol/Feature';
import { getCenter } from 'ol/extent';
import { FeatureisOutOfExtent } from '../../../helpers/helpers';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { GEOMETRY_TYPES } from 'woodpecker';
import { LineString, Polygon } from 'ol/geom';
import { TOOL_TYPE } from '../constants';
import { showToast } from 'ui';
import { undoRedoPush } from '../../mapLayer/mapInit';
import { selectStyle } from '../../../hooks/tools/helpers/styles';

class RotateTool extends ToolAbstract {
  private mapObj: MapBase;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
  }

  init() {
    this.off();

    let selectedFeatures: Feature[] = [...globalStore.AppStore.selectedFeatures] || [];

    if (!selectedFeatures.length) {
      showToast('Please select some features', 'error');
      globalStore.AppStore.setTool({ tool_id: TOOL_TYPE.SELECT });
      return;
    }

    selectedFeatures = selectedFeatures.filter(f => f.getGeometry()?.getType() !== GEOMETRY_TYPES.POINT);

    if (selectedFeatures.length) {
      selectedFeatures.forEach(f => {
        f.setStyle(selectStyle());
        const geom = f.getGeometry() as Polygon | LineString;
        const originalCoords = geom?.getCoordinates();
        geom.rotate(
          globalStore.AppStore.tool?.tool_id === TOOL_TYPE.ROTATE_COUNTER_CLOCKWISE ? Math.PI / 2 : -Math.PI / 2,
          getCenter(geom.getExtent())
        );

        // handling out_of_extent case
        const isOutExtent = FeatureisOutOfExtent(geom.getExtent(), this.mapObj.map);

        if (isOutExtent) {
          geom?.setCoordinates(originalCoords as any);
        } else {
          setTimeout(() => {
            undoRedoPush();
          }, 0);
        }
      });
    }
  }

  off() {
    globalStore.AppStore.selectedFeatures?.forEach((feature: Feature) => {
      feature.setStyle(undefined);
    });
  }
}

export default RotateTool;
