import React from "react";

const MenuIcon = ({ fill = "#4D4D4D", ...props }) => {
  return (
    <svg
      width="0.25rem"
      height="1rem"
      viewBox="0 0 4 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 14.3959C1.52778 14.3959 1.11806 14.2259 0.770833 13.8859C0.423611 13.5454 0.25 13.132 0.25 12.6459C0.25 12.1737 0.423611 11.7673 0.770833 11.4267C1.11806 11.0867 1.52778 10.9167 2 10.9167C2.48611 10.9167 2.89944 11.0867 3.24 11.4267C3.58 11.7673 3.75 12.1737 3.75 12.6459C3.75 13.132 3.58 13.5454 3.24 13.8859C2.89944 14.2259 2.48611 14.3959 2 14.3959ZM2 9.25008C1.52778 9.25008 1.11806 9.07647 0.770833 8.72925C0.423611 8.38203 0.25 7.9723 0.25 7.50008C0.25 7.01397 0.423611 6.60064 0.770833 6.26008C1.11806 5.92008 1.52778 5.75008 2 5.75008C2.48611 5.75008 2.89944 5.92008 3.24 6.26008C3.58 6.60064 3.75 7.01397 3.75 7.50008C3.75 7.9723 3.58 8.38203 3.24 8.72925C2.89944 9.07647 2.48611 9.25008 2 9.25008ZM2 4.08341C1.52778 4.08341 1.11806 3.91314 0.770833 3.57258C0.423611 3.23258 0.25 2.82647 0.25 2.35425C0.25 1.86814 0.423611 1.4548 0.770833 1.11425C1.11806 0.774248 1.52778 0.604248 2 0.604248C2.48611 0.604248 2.89944 0.774248 3.24 1.11425C3.58 1.4548 3.75 1.86814 3.75 2.35425C3.75 2.82647 3.58 3.23258 3.24 3.57258C2.89944 3.91314 2.48611 4.08341 2 4.08341Z"
        fill={fill}
      />
    </svg>
  );
};

export default MenuIcon;
