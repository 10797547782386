abstract class ToolAbstract {
  constructor() {}

  abstract init(
    id: string,
    featureTracing?: boolean,
    mode?: string,
    addTempLayer?: ((id: string, geojson: any) => void) | null
  ): void;

  abstract off(): void;
}

export default ToolAbstract;
