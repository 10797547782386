import { getCoords, getType } from '@turf/turf';
import { GEOMETRY_TYPES } from 'woodpecker';
import { polygon, multiPolygon, lineString, multiLineString, point, multiPoint } from '@turf/helpers';
import GEO_JSON from '../geoJson';

const { MULTI_POLYGON, POLYGON, MULTI_LINESTRING, LINESTRING, MULTI_POINT, POINT } = GEOMETRY_TYPES;

const CONVERT = {
  [MULTI_POLYGON](coords: any) {
    return multiPolygon(coords);
  },
  [POLYGON](coords: any) {
    return polygon(coords);
  },
  [LINESTRING](coords: any) {
    return lineString(coords);
  },
  [MULTI_LINESTRING](coords: any) {
    return multiLineString(coords);
  },
  [POINT](coords: any) {
    return point(coords);
  },
  [MULTI_POINT](coords: any) {
    return multiPoint(coords);
  }
};

export const getTurfFeature = (feature: any) => {
  const featureGeoJson = GEO_JSON.writeFeatureObject(feature);
  const geomType = getType(featureGeoJson);
  const coords = getCoords(featureGeoJson as any);
  return CONVERT[geomType](coords);
};

export default getTurfFeature;
