export const getImageWidthAndHeight = async (image: string): Promise<[number, number]> => {
  const img = new Image();
  // Set the image's source to the file.
  img.src = image;
  return new Promise(res => {
    img.onload = function () {
      const result: [number, number] = [img.naturalWidth, img.naturalHeight];
      res(result);
    };
  });
};
