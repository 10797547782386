import React from "react";

const TickMark = ({
  height = "1.125rem",
  width = "1.063rem",
  color = "#3B9B67",
}) => {
  return (
    <svg
      width="1.125rem"
      height="1.063rem"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00033 16.8334C4.39783 16.8334 0.666992 13.1026 0.666992 8.50008C0.666992 3.89758 4.39783 0.166748 9.00033 0.166748C13.6028 0.166748 17.3337 3.89758 17.3337 8.50008C17.3337 13.1026 13.6028 16.8334 9.00033 16.8334ZM8.16949 11.8334L14.0612 5.94092L12.8828 4.76258L8.16949 9.47675L5.81199 7.11925L4.63366 8.29758L8.16949 11.8334Z"
        fill={color}
      />
    </svg>
  );
};

export default TickMark;
