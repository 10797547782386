import { undoRedoPush } from '../../mapLayer/mapInit';
import MapBase from '../../mapLayer/mapBase';
import { highlightFeatureIfInvalid, triggerOverrideOverlap } from '../../../helpers/helpers';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { GEOMETRY_TYPES, MAP_TYPE, UNSET_PROPERTIES } from 'woodpecker';
import { drawStyle } from '../../../hooks/tools/helpers/styles';
import { FeatureisOutOfExtent } from 'macaw/src/getValidFeatures';
import { Modify } from 'ol/interaction';
import { showToast } from 'ui';

class ModifyVertex extends ToolAbstract {
  private mapObj: MapBase;
  private originalFeature: any;
  private originalFeatureCoordinates: any;
  private modify: Modify | null;
  private layer: any;

  constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.originalFeature = null;
    this.originalFeatureCoordinates = null;
    this.modify = null;
    this.layer = null;
  }

  init = (id: string) => {
    this.layer = this.mapObj.getLayerById(id);
    if (this.layer) {
      const source = this.layer?.getSource();

      this.modify = new Modify({
        source: source,
        style: drawStyle()
        // pixelTolerance: AppStore.tolerance_value,
      });

      this.mapObj?.map?.addInteraction(this.modify);
      this.modify?.on('modifystart', this.onModifyStart);
      this.modify?.on('modifyend', this.onModifyEnd);
      window.addEventListener('keydown', this.handleKeydown, false);
    }
  };

  /**
   * Event handler for the modifystart event.
   * Placeholder function for the modifystart event. Can be implemented as needed.
   * @param e - The modifystart event object.
   */
  onModifyStart = (e: any) => {
    this.originalFeatureCoordinates = e.features.getArray()[0]?.getGeometry().getCoordinates();
    this.originalFeature = e.features.getArray()[0];
  }; // todo

  /**
   * Event handler for the modifyend event.
   * Placeholder function for the modifyend event. Can be implemented as needed.
   * @param e - The modifyend event object.
   */
  onModifyEnd = (event: any) => {
    const feature = event.features.getArray()[0];
    let notIntersecting = true;
    if (
      this.originalFeature &&
      (this.originalFeature?.getGeometry()?.getType() === GEOMETRY_TYPES.POLYGON ||
        this.originalFeature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_POLYGON)
    ) {
      notIntersecting = highlightFeatureIfInvalid(this.originalFeature);
    }
    const isOutOfExtent =
      this.mapObj?.map_type === MAP_TYPE.AERIAL
        ? false
        : FeatureisOutOfExtent(feature.getGeometry().getExtent(), this.mapObj?.map);
    if (isOutOfExtent || !notIntersecting) {
      feature?.getGeometry().setCoordinates(this.originalFeatureCoordinates);

      if (
        !notIntersecting &&
        (this.originalFeature?.getGeometry()?.getType() === GEOMETRY_TYPES.POLYGON ||
          this.originalFeature?.getGeometry()?.getType() === GEOMETRY_TYPES.MULTI_POLYGON)
      ) {
        showToast('Invalid feature: Intersecting polygon not allowed', 'error', {
          position: 'top-center',
          hideProgressBar: false
        });
      }

      return undoRedoPush();
    } else {
      UNSET_PROPERTIES.forEach(property => {
        feature.unset(property, false);
      });
    }

    setTimeout(() => {
      triggerOverrideOverlap(feature);
      undoRedoPush();
    }, 0);
  };

  handleKeydown = (evt: any) => {
    const key = evt.keyCode;

    if (key == 8) {
      this.deleteVertex();
    }
  };

  deleteVertex() {
    this?.modify?.removePoint();
  }

  off = () => {
    if (this.modify) this.mapObj?.map?.removeInteraction(this.modify);
    this.modify && this.modify.un('modifyend', this.onModifyEnd);

    window.removeEventListener('keydown', this.handleKeydown);
  };
}

export default ModifyVertex;
