import { Select, DragBox } from 'ol/interaction';

import Feature from 'ol/Feature';
import { GEOMETRY_TYPES_FEATURE } from 'woodpecker';
import { selectStyle } from '../../../hooks/tools/helpers/styles';
import MapBase from '../../mapLayer/mapBase';
import ToolAbstract from '../../utilityclasses/ToolAbstractClass';
import { globalStore } from '../../utilityclasses/AppStoreListener';
import { TOOL_TYPE } from '../constants';
import { taggingActions, useTags } from '../../../store/tagsStore';
import { isTypicalLayer } from '../../../helpers/helpers';

class TaggingTool extends ToolAbstract {
  private mapObj: MapBase;

  private select: Select | null;

  private drag: DragBox | null;

  private layers: any;

  private selectedToolId: string | null;

  private constructor(mapObj: MapBase) {
    super();
    this.mapObj = mapObj;
    this.select = null;
    this.drag = null;
    this.layers = new Map();
    this.selectedToolId = null;
  }

  init() {
    this.off();

    this.layers = globalStore?.AppStore?.layers || new Map();
    this.selectedToolId = globalStore.AppStore?.tool.tool_id || null;
    this.mapObj?.map?.on('pointermove', this.handlePointerMove);
    this.select = new Select({
      filter: feature => {
        const layerId = feature?.get('vector_layer_id');
        const layerExists = this.layers?.has(layerId);
        if (this.selectedToolId === TOOL_TYPE.TAG) {
          return layerExists && !isTypicalLayer(this.layers.get(layerId));
        }
        return layerExists;
      },
      style: feature =>
        selectStyle(
          this.layers.get(feature?.get('vector_layer_id'))?.geometry_type === GEOMETRY_TYPES_FEATURE.ABSTRACT,
          this.layers.get(feature?.get('vector_layer_id'))?.geometry_type === GEOMETRY_TYPES_FEATURE.TYPICAL
        )
    });
    this.mapObj.map?.addInteraction(this.select);

    this.dispatch({
      store: useTags,
      type: taggingActions.SET_SELECT_INS,
      payload: this.select
    });

    this.drag = new DragBox();

    this.mapObj.map?.addInteraction(this.drag);

    this.drag.on('boxdrag', this.emptyFeatureArray);

    this.drag.on('boxend', () => {
      const boxExtent = this?.drag?.getGeometry().getExtent();

      const selected: Feature[] = [];

      this.layers?.forEach((_: any, layerId: string) => {
        try {
          const layer = this.mapObj?.getLayerById(layerId);

          if (
            layer?.getVisible?.() &&
            (!isTypicalLayer(this.layers.get(layerId)) || this.selectedToolId !== TOOL_TYPE.TAG)
          ) {
            layer.getSource().forEachFeatureIntersectingExtent(boxExtent, (feature: Feature) => {
              selected.push(feature);
            });
          }
        } catch (err: any) {
          console.error(err?.message);
        }
      });

      this.select?.getFeatures().extend(selected);

      if (this.selectedToolId === TOOL_TYPE.TAG) {
        this.dispatch({
          store: useTags,
          type: taggingActions.SET_SELECTED_FEATURES,
          payload: selected
        });
      }

      if (this.selectedToolId === TOOL_TYPE.GROUP_TAG) {
        const payload = Array.from(new Set(selected.flatMap(feat => feat.get('vector_layer_id') || [])));

        this.dispatch({
          store: useTags,
          type: taggingActions.SET_SELECTED_LAYERS,
          payload
        });
      }
    });

    this.select?.on('select', e => {
      const selectedFeatures = e.target.getFeatures().getArray();
      if (this.selectedToolId === TOOL_TYPE.TAG) {
        this.dispatch({
          store: useTags,
          type: taggingActions.SET_SELECTED_FEATURES,
          payload: selectedFeatures
        });
      }

      if (this.selectedToolId === TOOL_TYPE.GROUP_TAG) {
        const payload = Array.from(
          new Set(selectedFeatures.flatMap((feat: Feature) => feat.get('vector_layer_id') || []))
        );

        this.dispatch({
          store: useTags,
          type: taggingActions.SET_SELECTED_LAYERS,
          payload
        });
      }
    });
  }

  handlePointerMove = (e: any) => {
    const mapContainer = document.getElementById('map-container');
    if (mapContainer) {
      mapContainer.style.cursor = 'default';

      this.mapObj?.map?.forEachFeatureAtPixel(e.pixel, (_feature: any) => {
        const layerId = _feature?.get('vector_layer_id');
        if (
          this.layers?.has(layerId) &&
          (!isTypicalLayer(this.layers.get(layerId)) || this.selectedToolId !== TOOL_TYPE.TAG)
        ) {
          mapContainer.style.cursor = 'pointer';
          return true;
        }

        return false;
      });
    }
  };

  dispatch = ({ store, type, payload }: any) => {
    store.getState()?.dispatch({ type, payload });
  };

  emptyFeatureArray = () => {
    if (this.select) {
      this.select.getFeatures().clear();
    }
  };

  off = () => {
    this.dispatch({
      store: useTags,
      type: taggingActions.RESET_OL_FEATS
    });

    this.emptyFeatureArray();
    this.mapObj.map?.removeInteraction(this.select as Select);
    this.mapObj.map?.removeInteraction(this.drag as DragBox);
    this.mapObj?.map?.un('pointermove', this.handlePointerMove);
  };
}

export default TaggingTool;
