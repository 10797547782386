import TOOL_MAP, { ACTION_MAP, TOOL_TYPE } from '../../toolLayer/constants';
import UndoRedo from '../../toolLayer/tools/UndoRedo';
import MapBase from '../mapBase';

/*The single instance of out singleton Mapbase class */
export const mapObj = MapBase.getInstance();
export let undoRedoObj: UndoRedo;

/**
 * Initializes the map based on the provided map type and sets up the undo/redo functionality.
 * @param {number} map_type - The type of map to initialize.
 */
export const initOlMap = function (map_type: number): void {
  mapObj.init(map_type);
  undoRedoObj = new UndoRedo(mapObj);
};

/**
 * Initializes the selected tool based on the provided type and layer ID.
 * Turns off all tools before initializing the new tool, except for the pan tool.
 * @param {string} type - The tool_id of the tool to initialize.
 * @param {string} layer_id - The ID of the layer associated with the tool.
 */
export const initTool = function (
  type: string,
  layer_id: string,
  enable_snapping: boolean = false,
  add_comment: boolean = false,
  show_vertex: boolean = false,
  edge_snapping: boolean = false,
  feature_tracing: boolean = false,
  snap_tolerance: number = 10,
  addTempLayer: ((id: string, geojson: any) => void) | null = null
): void {
  offAllTools();
  /*Enable comment tool */

  const snapTool = TOOL_MAP['enable_snapping']?.getObject();
  snapTool.off();

  const commentTool = TOOL_MAP[TOOL_TYPE.ADD_COMMENT]?.getObject();
  if (add_comment) {
    commentTool.init();
  } else {
    commentTool.off();
  }

  if (type && type !== TOOL_TYPE.PAN) {
    const tool = TOOL_MAP[type]?.getObject();
    if (tool) {
      if (type === TOOL_TYPE.LINE_SNIPPING || type == TOOL_TYPE.LINE_SNIPPING_TWO_POINT) {
        tool.init(layer_id, null, type);
      } else if (type === TOOL_TYPE.ADD_POLYGON || type == TOOL_TYPE.ADD_POLYLINE || type == TOOL_TYPE.ADD_SEGMENT) {
        tool.init(layer_id, feature_tracing);
      } else tool.init(layer_id, null, null, addTempLayer);
    }
  }

  if (type === TOOL_TYPE.POLYGON_SNAPPING || type === TOOL_TYPE.POLYLINE_SNAPPING) {
    return;
  }

  /*Enable/disable snapping on tools */
  if (enable_snapping) {
    snapTool.init('', edge_snapping, snap_tolerance);
  } else snapTool.off();

  const showVertexTool = TOOL_MAP[TOOL_TYPE.SHOW_VERTICES].getObject();

  if (show_vertex) {
    showVertexTool.init();
  } else {
    showVertexTool.off();
  }
};

/**
 * Turns off all tools by calling their 'off' method.
 */
export function offAllTools(): void {
  Object.entries(TOOL_TYPE).forEach(([key, value]) => {
    const tool = TOOL_MAP[value]?.getObject();
    tool && tool.off();
  });
}

/**
 * Executes an action based on the provided type.
 * @param {string} type - The type of the action to execute.
 */

export function executeAction(type: string, ...args: any): void {
  const action = ACTION_MAP[type]?.getObject();
  action && action.execute(...args);
}

/**
 * Pushes an action onto the undo/redo stack.
 */
export const undoRedoPush = function (msg = ''): void {
  undoRedoObj.push(msg);
};

/**
 * Resets the undo/redo stack.
 */
export const undoRedoReset = function (): void {
  undoRedoObj.reset();
};
