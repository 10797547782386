import { Coordinate } from "ol/coordinate";
import getRadiusWith3Points from "../getRadiusWith3Points.ts";

export const getAngle = (origin: Coordinate, point: Coordinate) => {
  let angle =
    Math.atan((point[1] - origin[1]) / (point[0] - origin[0])) *
    (180 / Math.PI);

  let x = point[0] - origin[0],
    y = point[1] - origin[1];

  if (angle < 0) {
    if (x < 0 && y >= 0) angle += 180;
    if (x >= 0 && y < 0) angle += 360;
  } else {
    if (x < 0 && y < 0) angle += 180;
  }
  return angle;
};

const checkDirection = (angle1: number, angleMid: number, angle2: number) => {
  for (let theta = 0; theta < 360; theta++) {
    if ((Math.round(angle1) + theta) % 360 === Math.round(angleMid)) return 1;
    if ((Math.round(angle1) + theta) % 360 === Math.round(angle2)) return -1;
    // if (Math.abs(angle1 + theta - angleMid) <= 1) return 1;
    // if (Math.abs(angle1 + theta - angle2) <= 1) return -1;
  }

  return -1;
};

const isColinear = (
  point1: Coordinate,
  point2: Coordinate,
  point3: Coordinate
) => {
  if (
    JSON.stringify(point1) === JSON.stringify(point2) ||
    JSON.stringify(point1) === JSON.stringify(point3) ||
    JSON.stringify(point2) === JSON.stringify(point3)
  ) {
    return true;
  }
  return false;
};

const getArc = (point1: Coordinate, point2: Coordinate, point3: Coordinate) => {
  if (isColinear(point1, point2, point3)) {
    if (JSON.stringify(point1) === JSON.stringify(point2))
      return [point1, point3];
    else return [point1, point2];
  }
  const { radius, center } = getRadiusWith3Points(point1, point2, point3);
  let anglePt1 = getAngle(center, point1);
  let angleMid = getAngle(center, point2);
  let anglePt2 = getAngle(center, point3);

  let coords: number[][] = [];
  const direction = checkDirection(anglePt1, angleMid, anglePt2);
  const theta1 = anglePt1;
  const theta2 = anglePt2;
  let limit = 0;

  for (let theta = theta1; limit < 360; theta += direction * 4, ++limit) {
    let angle = (theta + 360) % 360;
    let currCoord = [
      radius * Math.cos(angle * (Math.PI / 180)) + center[0],
      radius * Math.sin(angle * (Math.PI / 180)) + center[1],
    ];
    if (Math.abs(angle - theta2) < 4) break;
    coords.push(currCoord);
  }

  if (limit === 0) {
    return [point1, point2, point3];
  }

  return coords;
};

export const getSnappingAngle = (angle: number, regionAngle: number) => {
  const separationLines: number[] = [];
  let initalAngle = 0;
  while (initalAngle <= 360) {
    separationLines.push(initalAngle);
    initalAngle += regionAngle;
  }

  let closestLine = separationLines[0];
  let minDifference = Math.abs(angle - closestLine);

  for (let i = 1; i < separationLines.length; i++) {
    let difference = Math.abs(angle - separationLines[i]);
    if (difference < minDifference) {
      minDifference = difference;
      closestLine = separationLines[i];
    }
  }

  return closestLine % 360;
};

export default getArc;
