export class Observer {
  observers: any[];

  constructor() {
    this.observers = [];
  }

  addObserver(observer: any) {
    this.observers.push(observer);
  }

  removeObserver(observer: any) {
    const index = this.observers.indexOf(observer);
    if (index > -1) {
      this.observers.splice(index, 1);
    }
  }

  notifyObservers(eventType: any, data: any = null) {
    this.observers.forEach((observer: any) => {
      observer(eventType, data);
    });
  }
}
