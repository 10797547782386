import React, { useState, useMemo, useRef } from 'react';
import { Popover, DropdownMenu, CirlceCheckedIconFilled, Tooltip, PaintBucket } from 'ui';
import { ChromePicker } from 'react-color';
import ToolBarDropIcon from 'ui/src/Assets/ToolBarDropIcon';
import SimpleDeleteIcon from 'ui/src/Assets/SimpleDeleteIcon';
import TOOL_MAP, { TOOL_TYPE } from '../../js-layer/toolLayer/constants';
import { container, styleBox, textBox, textColor, textLabel } from './styles';
import useTextBoxStore from '../../store/textBoxStore';
import { getOS } from '../../helpers/helpers';

const isMac = getOS() === 'MacOS';

export const KEY_SYMBOL_MAP: Record<string, string> = {
  Period: '°',
  KeyD: '∅'
};

const TextBox = () => {
  const textBoxRef = useRef<HTMLDivElement | null>(null);
  const {
    text,
    color,
    fontSize,
    update,
    box_color: boxColor
  } = useTextBoxStore(state => ({
    text: state.text,
    color: state.color,
    fontSize: state.fontSize,
    update: state?.updateState,
    date: state?.date,
    box_color: state.box_color
  }));

  const [fontOpen, setFontOpen] = useState<boolean>(false);
  const textBoxObj = TOOL_MAP[TOOL_TYPE.TEXT_BOX]?.getObject();

  const handleColor = (newColor: { hex: string }, mode = 'text') => {
    if (mode === 'text') update({ color: newColor?.hex });
    else update({ box_color: newColor?.hex });
  };

  const handleFontSize = (value: number) => {
    update({ fontSize: value });
  };

  const handleText = (newText: string) => {
    update({ text: newText });
  };

  const handleSave = () => {
    if (!text.trim()) {
      return;
    }
    if (textBoxObj) textBoxObj.addEditLabelBox();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // we're handling 2 shortcuts namely adding degree and diameter symbols
    // (cmd/ctrl)+alt+. will print degree symbol, alt+d will print diameter symbol
    const isDegreeSymbol = isMac ? e.metaKey && e.altKey && e.code === 'Period' : e.altKey && e.code === 'Period';

    const isDiaSymbol = e.altKey && e.code === 'KeyD';

    if (isDegreeSymbol || isDiaSymbol) {
      e.preventDefault();
      handleText((text || '') + KEY_SYMBOL_MAP[e.code]);
    }
  };

  const fontMenu = useMemo(() => {
    const types = [];
    for (let index = 1; index < 100; index++) {
      types.push({
        label: String(index),
        value: index
      });
    }
    return types;
  }, []);

  return (
    <div id='text-box-container' className={container} ref={textBoxRef}>
      <span className={styleBox}>
        <Popover
          content={<ChromePicker color={color} onChange={newColor => handleColor(newColor, 'text')} disableAlpha />}
          triggerEvent='click'>
          <div
            id='text-color'
            className={textColor}
            style={{
              background: color
            }}
          />
        </Popover>

        <DropdownMenu
          noValuePlaceholder={`${fontSize}`}
          color='neutral'
          open={fontOpen}
          style={{
            background: 'none',
            height: '2rem',
            border: '1px solid rgba(235, 235, 235, 1)',
            fontSize: '12px'
          }}
          menuItems={fontMenu}
          onButtonClick={() => setFontOpen(open => !open)}
          handleClose={() => setFontOpen(false)}
          value={fontSize}
          // @ts-ignore
          onMenuItemClick={(e: React.MouseEvent<Element, MouseEvent>, item: { label: string; value: number }) => {
            handleFontSize(item.value);
            setFontOpen(false);
          }}
          endDownIcon={<ToolBarDropIcon />}
          endUpIcon={<ToolBarDropIcon />}
        />

        <Popover
          content={<ChromePicker color={boxColor} onChange={newColor => handleColor(newColor, 'box')} disableAlpha />}
          triggerEvent='click'>
          <PaintBucket fill={boxColor} />
        </Popover>

        <span onClick={() => textBoxObj?.deleteLabelBox()}>
          <SimpleDeleteIcon />
        </span>
      </span>

      <textarea
        id='text-area'
        className={textBox}
        onChange={e => handleText(e.target.value)}
        style={{
          fontSize: `${fontSize}px`,
          color,
          background: boxColor
        }}
        maxLength={2500}
        onKeyDown={handleKeyDown}
      />

      <p className={textLabel} id='text-label'>
        <p
          style={{
            flex: 1,
            width: '100%',
            fontSize: `${fontSize}px`,
            color,
            overflow: 'auto',
            background: boxColor
          }}>
          {text}
        </p>
        {/* <span
          id="date-area"
          style={{
            fontSize: `8px`,
          }}
        >
          {date}
        </span> */}
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0.5rem 0 0 0.25rem'
        }}>
        <Tooltip title='Save'>
          <span>
            <CirlceCheckedIconFilled fill='green' height='1.4rem' width='1.4rem' onClick={handleSave} />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default TextBox;
